<div class="page-purchases">
    <div class="d-flex align-items-center title-purchases">
        <span (click)="backTab()"><i class="icon-back fa-light fa-arrow-left"></i></span>
        <p class="name-profile m-0">Atrás</p>
    </div>
    <div class="tab-tickets loading-height">
        <!-- Header -->
        <div class="section d-flex align-items-center header-tickets-profile">
            <i class="fa-light fa-ticket"></i>
            <p class="m-0">Mis entradas</p>
        </div>
        <!-- Tickets list -->
        <div #containerEvents>
            <div *ngFor="let item of purchases">
                <!-- Header purchase -->
                <div class="header-purchase d-flex justify-content-between align-items-end" [class.header-refunded]="item.status == 'refunded' && item.refund">
                    <div>
                        <p class="m-0 title-purchase">{{item.createdAt | date:'dd/MM/yyyy'}}</p>
                        <p class="m-0 date-purchase">Pedido #{{item.idOdoo}}</p>
                    </div>
                    <div class="d-flex options-purchase" *ngIf="item.status != 'refunded' || !item.refund">
                        <!-- <div>
                            <p class="m-0">Consultar pedido</p>
                        </div> -->
                        <div (click)="printPurchase(item.idOdoo)">
                            <!-- <i class="fa-light fa-file-arrow-down"></i> -->
                            <p class="m-0">Descargar entradas</p>
                        </div>
                    </div>
                    <div class="d-flex option-refunded" *ngIf="item.status == 'refunded' && item.refund">
                        <p class="m-0">Pedido devuelto</p>
                    </div>
                </div>
                <div #containerEvents>
                    <app-tickets-list (closeAction)="closeAction($event)" [compra]="item.entradas"
                        [type]="index"></app-tickets-list>
                </div>
            </div>
            <!-- Tab bar -->
            <!-- <div>
                <app-events-list (emitAction)="editAction($event)" [compras]="purchases"
                    [type]="index"></app-events-list>
            </div> -->

            <!-- <ng-template #divTickets>
            <div #containerEvents>
                <app-tickets-list (closeAction)="closeAction($event)" [compra]="tickets"
                    [type]="index"></app-tickets-list>
            </div>-->
        </div>
        <!-- Loading Tickets -->
        <div class="loading-container" *ngIf="loadingPage">
            <ng-lottie class="lottie" [options]="loadingLottie" containerClass="loading-box"></ng-lottie>
        </div>
    </div>
</div>