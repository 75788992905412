import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-orders-billing',
  templateUrl: './orders-billing.component.html',
  styleUrls: ['./orders-billing.component.scss']
})
export class OrdersBillingComponent implements OnInit {

  user!: any;
  order!: any;
  idOrder!: any;
  num_ticket!: any;

  constructor(@Inject(MAT_DIALOG_DATA) private data, private reportsService: ReportsService) { }

  ngOnInit(): void {
    if(this.data) {
      this.user = this.data.user;
      this.idOrder = this.data.id;
      this.order = this.data.order;
      this.num_ticket = this.data.num_ticket;
    }
  }

}
