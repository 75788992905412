import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-create-discount',
  templateUrl: './create-discount.component.html',
  styleUrls: ['./create-discount.component.scss']
})
export class CreateDiscountComponent {

  formNewDiscount: FormGroup = new FormGroup({});
  editDiscount: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CreateDiscountComponent>,
    private formBuilder: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    this.formNewDiscount = this.formBuilder.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      type: [null, Validators.required],
      amount: [''],
      percentage: [],
      cuantity: [null, Validators.required],
      description: []
    });
    if (this.data) {
      this.editDiscount = true;
      this.formNewDiscount.setValue({
        name: this.data.name,
        code: this.data.discountCode,
        type: this.data.amount ? 'amount' : 'percentage',
        amount: this.data.amount,
        percentage: this.data.percentage,
        cuantity: this.data.amount ? this.data.amount : this.data.percentage,
        description: this.data.description
      })
    }
  }

  editCodeSpace() {
    let form = this.formNewDiscount.value;
    form.code = form.code.replaceAll(' ', '');
    this.formNewDiscount.setValue(form);
  }

  saveDiscount() {
    // Quitar esacios al codigo
    // Si tiene algu espacio sacar mensaje
    if (this.formNewDiscount.value.code.indexOf(' ') != -1) {
      this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'El código no puede contener espacios.',
          btnCancel: false
        }
      });
    } else {
      const formValue = this.formNewDiscount.value;
      if (this.formNewDiscount.valid) {
        if (formValue.type == 'amount') {
          formValue.amount = this.formNewDiscount.value.cuantity;
          formValue.percentage = null;
        } else {
          formValue.percentage = this.formNewDiscount.value.cuantity;
          formValue.amount = null;
        }
        this.dialogRef.close(formValue);
      } else {
        this.dialog.open(InfoCardComponent, {
          panelClass: 'info-dialog',
          data: {
            icon: false,
            text: 'Debes rellenar todos los campos.',
            btnCancel: false
          }
        });
      }
    }
  }
}
