import { HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppSettings } from 'src/app/app-settings';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-cityblog',
  templateUrl: './cityblog.component.html',
  styleUrls: ['./cityblog.component.scss']
})
export class CityblogComponent implements OnInit {

  posts: any[];
  searchBlog: string = '';
  scrollTopActive: boolean = false;

  items: number = 12;
  page: number = 1;
  totalPages: any = 1;

  constructor(private blogService: BlogService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.blogService.getPostsHeaders().subscribe({
      next: (data: any) => {
        const dataHeaders = data.headers as HttpHeaders;
        this.totalPages = +dataHeaders.get('X-WP-TotalPages')
        this.posts = data.body;
      },
      error: (e) => {if(AppSettings.DEBUG) {console.log(e)}},
      complete: () => {}
    })
  }

  getSanitizer(text: any) {
    return this.sanitizer.bypassSecurityTrustHtml(
      text.rendered.replace(' [&hellip;]', '...')
    );
  }

  goToPage(event: any) {
    this.page = event;
    if(this.page <= this.totalPages) {
    this.blogService.getPostsPagination(this.page, this.items).subscribe({
      next: (data: any) => {
        this.posts = data;
      },
      error: (e) => {if(AppSettings.DEBUG) {console.log(e)}},
      complete: () => {
        var height = document.getElementById('topPosts').offsetTop;
        window.scrollTo(0, height-120);
      }
    })
  }
  }

  searchPosts(search: any) {

  }

  @HostListener('window:scroll') 
  onScrollParrallax() {
    var height = document.getElementById('topPosts').offsetTop;
    this.scrollTopActive = window.scrollY > height;
  }

  onScrollToTop() {
    window.scrollTo(0,0);
  }
}
