import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AppSettings } from 'src/app/app-settings';
import { CategoriesListComponent } from 'src/app/components/bloques/categories-list/categories-list.component';
import { CropperImageComponent } from 'src/app/components/bloques/cropper-image/cropper-image.component';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { CiudadesService } from 'src/app/services/ciudades.service';
import { CreateEventService } from 'src/app/services/create-event.service';
import { GooglePlacesService } from 'src/app/services/google-places.service';
import { PlacesService } from 'src/app/services/places.service';
import { HashtagsEventComponent } from './hashtags-event/hashtags-event.component';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
}) export class CreateEventComponent {
  event: any = {};
  submenuActive: any = 1;
  titleTab: string = 'Paso 1: Información del evento';
  categorySelected: any;
  urlImages: string = AppSettings.MEDIA_URI + '/images/events/';
  urlImagesVenues: string = AppSettings.MEDIA_URI + '/images/venues/';
  imgControl: FormControl = new FormControl(null);
  changedImg: boolean = false;
  @Input() textArea: any = '';
  @Input() title: any = '';
  @Input() otherAddress: boolean = false;
  @Input() onlineEvent: boolean = false;
  @Input() otherAddresString: any = '';

  hashtags: any[] = [];

  // Start and End Date
  startStringDate!: any;
  endStringDate!: any;
  startDate!: NgbDate;
  endDate!: NgbDate;
  typeDatepicker!: string;

  dates!: any;

  place!: any;

  placeList: any[] = [];

  payload: any = {};

  // Options days
  repeatWeek: any[] = [
    { day: 'L', number: 1, text: 'Lunes' },
    { day: 'M', number: 2, text: 'Martes' },
    { day: 'X', number: 3, text: 'Miércoles' },
    { day: 'J', number: 4, text: 'Jueves' },
    { day: 'V', number: 5, text: 'Viernes' },
    { day: 'S', number: 6, text: 'Sábados' },
    { day: 'D', number: 0, text: 'Domingos' }
  ];
  repeatMonth: any[] = [];
  repeatDays: any[] = [];

  selectRepeat: any[] = []; // Selected repeat days
  repeatDate!: NgbDate; // If repeat end on exact date
  repeatStringDate!: string;

  selectedSchedules: any[] = [];
  selectedSchedulesRepeat: any[] = [];
  infoRep: string = '';

  maxType: number = 54;
  profile!: any;
  dateOld!: any;
  now!: any;

  idBussiness: any;

  public isEmojiPickerVisible: boolean;
  public addEmoji(event) {
    this.isEmojiPickerVisible = false;
    return event.emoji.native;
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '250px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe una descripción del evento',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Satoshi-Regular',
    defaultFontSize: '',
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  eventInfo: FormGroup;
  croppedImage!: any;
  extraFileControl!: any;
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() changePlaceEmit: EventEmitter<any> = new EventEmitter<any>();
  loading: boolean = true;

  lugar: string = '';
  searchResults: any[] = [];

  // Private Event
  privateEvent: boolean = false;
  accessCodeSales!: any;

  constructor(public dialog: MatDialog, private placeService: PlacesService, private createEvent: CreateEventService,
    private formBuilder: FormBuilder, private apiBusiness: ApiBusinessService, private googlePlacesService: GooglePlacesService,
    private ciudadService: CiudadesService) {
    if (localStorage.getItem('business')) {
      this.idBussiness = JSON.parse(localStorage.getItem('business')).id;
    }
  }

  ngOnInit() {
    let business = JSON.parse(localStorage.getItem('business') || '{}');
    this.eventInfo = this.formBuilder.group({
      company: [business.id],
      titulo: ['', Validators.required],
      descripcion: ['', Validators.required],
      horarios: [[], Validators.required],
      fechas: [[], Validators.required],
      url: [],
      hashtags: [],
      categoria: [null, Validators.required],
      online: [false],
      place: [],
      file: ['', Validators.required],
      extraFile: [null],
      latitude: [],
      longitude: [],
      localizacion: [],
      draft: [true],
      activo: [false],
      infoDates: [],
      infoHours: [],
      cropped: [],
      ciudad: [],
      entradas: [],
      mapEvent: [],
      external: [],
      onlyTpvSales: [],
      informativo: [],
      hiddenEvent: [],
      privateEvent: [],
      accessCodeSales: []
    });

    // Comprobar si cambia la empresa
    this.apiBusiness.businessStorage.subscribe({
      next: (value) => {
        this.loading = true;
        this.idBussiness = value?.id;
        if (this.idBussiness) {
          this.changePlace(null);
          this.placeService.getPlacesUser(this.idBussiness).subscribe((data) => {
            this.placeList = data;
            this.loading = false;
            let infoSaved = this.createEvent.getInfoEvent();
            if (infoSaved) {
              let placeSelect = infoSaved.values.place;
              if (placeSelect) {
                let place = this.placeList.find(value => value.id == placeSelect.id);
                this.place = place;
                this.eventInfo.controls['place'].setValue(place);
              }
            }
          });
        }
      },
    });
    this.getData();

    this.eventInfo.valueChanges.subscribe((value) => {
      // console.log('valor guardado')
      // console.log(value)
      let payloadService = { valid: this.eventInfo.valid, values: value };
      this.createEvent.setInfoDetail(payloadService);
    });
  }

  ngAfterViewInit(): void {
    this.googlePlacesService.openMap();
    this.googlePlacesService.mapsLocatorStorage.subscribe({
      next: (data) => {
        if (data) {
          this.lugar = data;
          this.eventInfo.controls['latitude'].setValue(data.geometry?.location.lat());
          this.eventInfo.controls['longitude'].setValue(data.geometry?.location.lng());
          this.eventInfo.controls['localizacion'].setValue(data.formatted_address);

          const objectCity = data.address_components.find(value => value.types.includes('locality'));
          if (objectCity) {
            this.ciudadService.getAllCitieByName(objectCity.long_name).subscribe(res => {
              if (res.length > 0) {
                this.eventInfo.controls['ciudad'].setValue(res[0]['id']);
              } else {
                this.eventInfo.controls['ciudad'].setValue(null);
              }
            });
          }
        }
      }
    });
  }

  onSearchPlaces() {
    if (this.lugar) {
      this.googlePlacesService.searchPlaces(this.lugar).then((results) => {
        this.searchResults = results;
      });
    } else {
      this.searchResults = [];
    }
  }

  onSelectPlace() {
    this.eventInfo.controls['place'].setValue(null);
    this.googlePlacesService.selectPlace(this.lugar);
  }

  onSelectTextPlace(text: string) {
    this.googlePlacesService.searchPlaces(text).then((results) => {
      this.lugar = results[0];
      this.onSelectPlace();
    });
  }

  displayFn(location: any): string {
    if (location) {
      return location.description ? location.description : location.formatted_address;
    }
    return '';
  }

  getData() {
    let infoSaved = this.createEvent.getInfoEvent();
    if (infoSaved) {
      this.otherAddress = infoSaved.values.localizacion;
      this.onlineEvent = infoSaved.values.online;
      this.categorySelected = infoSaved.values.categoria;
      this.croppedImage = infoSaved.values.cropped;
      this.dates = infoSaved.values.infoDates;
      this.selectedSchedules = infoSaved.values.infoHours;
      this.place = infoSaved.values.place;
      this.imgControl.setValue(this.croppedImage);
      this.eventInfo.setValue(infoSaved.values);
      this.hashtags = infoSaved.values.hashtags || [];
      this.privateEvent = infoSaved.values.privateEvent;
      this.accessCodeSales = infoSaved.values.accessCodeSales;
    }
  }

  openCategories() {
    const dialogRef = this.dialog.open(CategoriesListComponent, {
      panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
      data: { categorySelect: '' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.categorySelected = result[0];
        this.eventInfo.controls['categoria'].setValue(this.categorySelected);
      }
    });
  }

  changePlace(event: any) {
    this.eventInfo.controls['place'].setValue(event);
    this.changePlaceEmit.emit(event);
  }

  onFileChange(event: any) {
    let filePath = '';
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size >= 20000000) {
        this.openInfoCard('La imagen no puede ocupar más de 2 Mb.');
      } else {
        const dialogRef = this.dialog.open(CropperImageComponent, {
          panelClass: 'info-dialog',
          data: event
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.croppedImage = result.pathUrl;
            this.eventInfo.patchValue({
              file: result.object.base64,
              cropped: this.croppedImage
            });
            this.imgControl.setValue(this.croppedImage);
          }
        });
      }
    }
    if (!event.target.files) {
      this.eventInfo.controls['file'].reset();
      this.croppedImage = '';
    }
  }

  onExtraFile(event: any) {
    const reader = new FileReader();
    let filePath = '';
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (event.target.files[0].size >= 20000000) {
        this.openInfoCard('La imagen no puede ocupar más de 2 Mb.');
      } else {
        if (file.type.match('text.*|application.*')) {
          reader.readAsDataURL(file);
          reader.onload = () => {
            var docSrc = reader.result as string;
            filePath = reader.result as string;
            this.eventInfo.patchValue({
              extraFile: docSrc
            });
            this.extraFileControl = filePath;
          };
        } else {
          this.openInfoCard('El archivo debe ser un documento', true);
        }
      }
    }
    if (!event.target.files) {
      if (this.event.extraFile) {
        this.extraFileControl = null;
      }
    }
  }

  saveHashtags(hashtags: any) {
    this.eventInfo.controls['hashtags'].setValue(hashtags);
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

  openDatePicker(type: string) {
    if (this.typeDatepicker) {
      this.typeDatepicker = null;
      return;
    }
    this.typeDatepicker = type;
  }

  getDate(event: any) {
    this.dates = event;
    this.eventInfo.controls['fechas'].setValue([event.fecha]);
    this.eventInfo.controls['infoDates'].setValue(event);
  }

  getSchedules(event: any) {
    this.selectedSchedules = event;
    this.eventInfo.controls['horarios'].setValue(event);
    this.eventInfo.controls['infoHours'].setValue(event);
  }

  hasOtherAddress() {
    if (this.otherAddress) {
      this.onlineEvent = false;
      this.place = null;
    }
    this.eventInfo.controls['online'].setValue(false);
    this.eventInfo.controls['place'].setValue(null);
  }

  isOnlineEvent() {
    if (this.onlineEvent) {
      this.otherAddress = false;
      this.place = null;
      this.otherAddresString = '';
    }
    this.eventInfo.controls['localizacion'].setValue(null);
    this.eventInfo.controls['place'].setValue(null);
  }

  nextStep() {
    let step = { tab: 2, name: 'Venta de entradas', slug: 'ticket-event' };
    this.changeTab.emit(step);
  }

}
