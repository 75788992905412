import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { EventosService } from 'src/app/services/eventos.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';

@Component({
  selector: 'app-reports-event',
  templateUrl: './reports-event.component.html',
  styleUrls: ['./reports-event.component.scss']
})
export class ReportsEventComponent implements OnInit {

  idEvent!: any;
  event!: any;

  ticketsSold!: any;
  loaded: boolean = false;
  totalReaded: number = 0;
  displayedColumns: string[] = ['actions', 'date', 'time', 'sold', 'readed'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();


  constructor(private activatedRoute: ActivatedRoute, private panelAdminService: PanelAdminService,
    private dialog: MatDialog, private eventService: EventosService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.idEvent = +params.idEvent;
        this.eventService.getEventById(this.idEvent).subscribe({
          next: (res: any) => {
            this.event = res;
            this.eventService.getInfoSalesByEvent(this.idEvent).subscribe({
              next: (res: any) => {
                this.ticketsSold = res.data.tickets;
                this.totalReaded = res.data.totalReaded||0;
                this.dataSource = new MatTableDataSource(this.ticketsSold);
                this.loaded = true;
              }
            });
          }
        });
      }
    });
  }

  downloadPdf() {
    this.panelAdminService.downloadReportEvent(this.idEvent).subscribe({
      next: (res) => {
        const dateFormat = formatDate(this.event.datesRange?.start, 'dd-MM-yyyy', 'es');
        const nameEvent: string = this.event.titulo.replaceAll(/[^\w]/gi, '');
        const byteArray = new Uint8Array(atob(res.data).split('').map((char) => char.charCodeAt(0)));
        let contentPdf = new Blob([byteArray], { type: 'data:application/pdf'});
        var fileName = nameEvent+'_'+dateFormat+'.pdf';
        saveAs(contentPdf, fileName)
      },
      error: (err) => this.infoDialog('¡Vaya! Algo ha fallado, no se ha podido descargar el informe. Vuelve a intentarlo más tarde.')
    })
  }

  infoDialog(msg: string, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

}
