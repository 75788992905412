<div class="page-tickets-type">
    <div class="d-flex align-items-center title-edit">
        <p class="m-0 title-tab">Editar configuración de venta</p>
        <!-- <i class="fal fa-pen pointer-in" (click)="openConfig()"></i> -->
    </div>
    <div class="config-tickets">
        <form [formGroup]="formConfig" class="form-entrada">
            <div class="info-default">
                <p class="m-0">
                    <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>
                    Fechas de inicio y fin de venta de entradas, en caso de no indicarlas se pondrán a la venta cuando
                    se publique el evento y se terminará cuando comience.
                </p>
            </div>
            <!-- Fecha de venta de entradas -->
            <div class="padding-inputs">
                <div>
                    <p class="title-dates-inputs">Fecha inicio de venta de entradas</p>
                    <div class="section-input">
                        <!-- Fecha inicio venta -->
                        <div class="d-flex align-items-center justify-content-between input-border">
                            <div class="select-style calendar-date pointer-in"
                                [class.active]="typeDatepicker == 'start'" (click)="openDatePicker('start')">
                                <i class="fa-light fa-calendar"></i>
                                <input placeholder="Inicio de venta" class="input-date pointer-in" readonly
                                    [(ngModel)]="startStringDate" [ngModelOptions]="{standalone: true}">
                                <i class="icon-down fa-light fa-chevron-down"></i>
                            </div>
                        </div>

                        <!-- Hora inicio venta -->
                        <div class="d-flex align-items-center justify-content-center inputs-hours" [class.error-input]="hourInit > 23 || hourInit < 0 || minuteInit < 0 || minuteInit > 59 || minuteInit?.length > 2">
                            <div class="input-hour">
                                <input placeholder="20" type="number" min="0" max="23"
                                    (ngModelChange)="changeHourInit($event)" [ngModel]="(hourInit|number:'2.0-0')"
                                    [ngModelOptions]="{standalone: true}" />
                            </div>
                            <span class="points">:</span>
                            <div class="input-hour">
                                <input placeholder="00" type="number" min="0" max="59"
                                    (ngModelChange)="changeMinuteInit($event)" [ngModel]="minuteInit"
                                    [ngModelOptions]="{standalone: true}" />
                            </div>
                        </div>
                    </div>
                    <div class="calendar-option" *ngIf="typeDatepicker == 'start'">
                        <app-calendar-event-dates [selectedDate]="startDate" (saveDate)="saveDate($event)"
                            [minDate]="dateOld"></app-calendar-event-dates>
                    </div>
                </div>
                <div>
                    <p class="title-dates-inputs">Fecha fin de venta de entradas</p>
                    <div class="section-input">
                        <!-- Fecha fin venta -->
                        <div class="d-flex align-items-center justify-content-between input-border">
                            <div class="select-style calendar-date pointer-in" [class.active]="typeDatepicker == 'end'"
                                (click)="openDatePicker('end')">
                                <i class="fa-light fa-calendar"></i>
                                <input placeholder="Fin de venta" class="input-date pointer-in" readonly
                                    [(ngModel)]="endStringDate" [ngModelOptions]="{standalone: true}">
                                <i class="icon-down fa-light fa-chevron-down"></i>
                            </div>
                        </div>
                        <!-- Hora fin venta -->
                        <div class="d-flex align-items-center justify-content-center inputs-hours" [class.error-input]="hourEnd > 23 || hourEnd < 0 || minuteEnd < 0 || minuteEnd > 59 || minuteEnd?.length > 2">
                            <div class="input-hour">
                                <input placeholder="20" type="number" min="0" max="23"
                                    [ngModel]="hourEnd|number:'2.0-0'" (ngModelChange)="changeHourEnd($event)"
                                    [ngModelOptions]="{standalone: true}" />
                            </div>
                            <span class="points">:</span>
                            <div class="input-hour">
                                <input placeholder="00" type="number" min="0" max="59"
                                    (ngModelChange)="changeMinuteEnd($event)" [ngModel]="minuteEnd"
                                    [ngModelOptions]="{standalone: true}" />
                            </div>
                        </div>
                    </div>
                    <div class="calendar-option" *ngIf="typeDatepicker == 'end'">
                        <app-calendar-event-dates [selectedDate]="endDate" (saveDate)="saveDate($event)"
                            [minDate]="dateOld"></app-calendar-event-dates>
                    </div>
                </div>
            </div>
            <!-- Número máximo de entradas -->
            <div class="padding-inputs">
                <div>
                    <p class="title-tickets-max">Entradas máximas por usuario</p>
                    <div class="section-input">
                        <div class="input-border select-style input-ticket">
                            <input placeholder="Entradas" type="number" min="0" formControlName="maxTickets" />
                            <i class="fa-light fa-ticket icon-primary"></i>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <mat-divider *ngIf="!formConfig.value.external"></mat-divider> -->
            <!-- <div class="item-form-link d-flex" *ngIf="formConfig.value.external">
                <i class="fa-light fa-link icon-primary"></i>
                <div class="content-link w-100">
                    <span class="m-0 subtitle-link">Link de venta externa: 'https://www.venta.ejemplo.com' *</span>
                    <input class="w-100 input-link-external" type="text" formControlName="url" required>
                </div>
            </div> -->
        </form>
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <p class="title-tab">Precios de las entradas para este evento</p>
        <div class="btn-create m-0" (click)="openCreateTicket()">
            <i class="fal fa-plus-circle"></i>
            <p class="m-0">Añadir entrada nueva</p>
        </div>
    </div>
    <p class="info-ticket-venue" *ngIf="venue">Se aplican los tipos de entradas del {{venue?.name}}</p>
    <div class="margin-table" *ngIf="(!loadingPage && tickets) || loadingTickets; else divNotTickets">
        <!-- Tabla entradas por nivel (mapa) -->
        <div class="table-events table-panel table-grey" *ngIf="loaded && !loadingTickets">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort
                (matSortChange)="sortData($event)">
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let ticket; let i = index">
                        <div *ngIf="ticket.isSection" (click)="closeTicketsLevel(ticket)">
                            <i class="fal fa-ellipsis-vertical icon-option"></i>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="section">
                    <th mat-header-cell *matHeaderCellDef><b>Zona/Tipo</b></th>
                    <td mat-cell *matCellDef="let ticket">
                        <div class="d-flex justify-content-between align-items-center">
                            <span class="link-titulo"
                                [class.titleBold]="ticket.isSection">{{ticket.name||ticket.nombre}}</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef><b>Precio</b></th>
                    <td mat-cell *matCellDef="let ticket">
                        <div class="input-price" *ngIf="!ticket.isSection">
                            <input type="number" min="0" [(ngModel)]="ticket.price" (ngModelChange)="changePrice(ticket)">
                            <span>€</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef><b>Descripción</b></th>
                    <td mat-cell *matCellDef="let ticket">
                        <span class="link-titulo">{{ticket.description}}</span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.hidden]="true"></tr>
            </table>
        </div>
        <!-- Tabla entradas sin nivel, simples -->
        <!-- <div class="link-open justify-content-center tickets-base" *ngIf="ticketsBase.length > 0">
            <span>Ver las entradas base del Teatro Apolo</span>
        </div> -->
        <!-- Tabla entradas base -->
        <div *ngIf="loadingTickets" class="container-lottie">
            <div class="loading-container">
                <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                    containerClass="loading-box"></ng-lottie>
            </div>
        </div>
    </div>
    <ng-template #divNotTickets>
        <p class="text-center m-5">Aún no existen entradas para este evento</p>
    </ng-template>

    <div class="btn-create btn-next-step m-0" [routerLink]="['/panel-control/events/new-event/map-event']"
        (click)="nextStep()">
        <p class="m-0">Siguiente paso</p>
        <i class="fal fa-arrow-right"></i>
    </div>
</div>