<div class="cropper-dialog">
    <div class="content-img">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
            format="png" output="base64" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="align-items-center d-flex justify-content-between" style="column-gap: 16px;">
        <button class="cancel-button" [mat-dialog-close]="false">Cancelar</button>
        <button class="primary-button" (click)="saveImage()">Guardar</button>
    </div>
</div>