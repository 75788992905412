<div class="detail-order" *ngIf="order">
    <h1 class="title-detail-order m-0">{{order.id}}</h1>
    <mat-divider class="divider-title"></mat-divider>
    <div class="info-order">
        <div class="card-white-panel">
            <div class="d-flex justify-content-between align-items-center padding-25 margin-20">
                <div class="title-detail">
                    <h2 class="m-0"><span>Detalle</span>
                        <mat-divider [vertical]="true"></mat-divider>
                        <span>{{order.num_ticket}}</span>
                    </h2>
                </div>
                <div class="btns-tickets">
                    <!-- <div class="btn-create btn-create-border m-0" (click)="refundOrder()">
                        <i class="fal fa-arrow-rotate-left"></i>
                        <p class="m-0">Devolver pedido</p>
                    </div>-->
                    <div class="btn-create m-0" (click)="openPrintA4()">
                        <i class="fal fa-print"></i>
                        <p class="m-0">Imprimir entradas</p>
                    </div>
                    <div class="btn-create m-0" (click)="openPrintTicket()">
                        <i class="fal fa-ticket"></i>
                        <p class="m-0">Imprimir ticket</p>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <!-- Información general -->
            <div class="general-info-ticket">
                <div class="item-general-info">
                    <p class="title-item-general">ID del pedido</p>
                    <p class="text-item-general">{{order.id}}</p>
                </div>
                <div class="item-general-info">
                    <p class="title-item-general">Nombre y apellidos</p>
                    <p class="text-item-general">{{order.name_citylok || '-'}}</p>
                </div>
                <div class="item-general-info">
                    <p class="title-item-general">Correo electrónico</p>
                    <p class="text-item-general">{{order.email_citylok || '-'}}</p>
                </div>
                <div class="item-general-info">
                    <p class="title-item-general">Canal</p>
                    <p class="text-item-general">{{order.sales_channel.name}}</p>
                </div>
                <div class="item-general-info">
                    <p class="title-item-general">Precio total del pedido</p>
                    <p class="text-item-general">{{order.total_order | number:'1.2-2'}}€</p>
                </div>
                <div class="item-general-info">
                    <p class="title-item-general">Fecha y hora trans.</p>
                    <p class="text-item-general">{{order.created_at | date:'dd/MM/yyyy HH:mm'}}h</p>
                </div>
                <div class="item-general-info">
                    <p class="title-item-general">Número de teléfono</p>
                    <p class="text-item-general">-</p>
                </div>
                <div class="item-general-info">
                    <p class="title-item-general">Estado</p>
                    <p class="text-item-general">{{order.transaction_status.name}}</p>
                </div>
            </div>
        </div>
        <!-- Entradas por evento -->
        <div class="grid-tickets">
            <div *ngFor="let event of seats" class="card-white-panel card-event">
                <div class="info-event-pay">
                    <div class="img-event">
                        <img [src]="event.url_image" [alt]="event.name_event" />
                    </div>
                    <div class="basic-info-event">
                        <p class="title-event m-0" [innerHTML]="event.name_event"></p>
                        <div class="m-0 grey-info-event d-flex">
                            <span>{{event.date_event | date: 'dd/MM/yyyy' }}</span>
                            <mat-divider style="margin: 0px 0.5rem;" [vertical]="true"></mat-divider>
                            <span class="hour-event" *ngIf="event.hour_event">
                                {{event.hour_event }}h</span>
                        </div>
                        <p class="m-0 grey-info-event"><span *ngIf="event.place">{{event.place}} -
                            </span>{{event.location}}</p>
                        <p class="m-0 grey-info-event">{{event.company}}</p>
                    </div>
                </div>
                <div class="info-seats">
                    <div *ngFor="let ticket of event.items">
                        <mat-divider></mat-divider>
                        <div class="info-ticket">
                            <div class="d-flex align-items-center gap-3">
                                <!-- <div [matMenuTriggerFor]="ticketOptions" class="d-flex pointer-in m-0">
                                    <i class="icon-option fal fa-ellipsis-vertical"></i>
                                </div>
                                <mat-menu class="options-menu-control-panel" #ticketOptions="matMenu">
                                    <button mat-menu-item (click)="printA4Ticket(ticket)">Imprimir entrada</button>
                                    <button mat-menu-item (click)="printTicket(ticket)">Imprimir ticket</button>
                                    <button mat-menu-item (click)="refundTicket(ticket)">Devolución parcial</button>
                                </mat-menu> -->
                                <div>
                                    <div class="d-flex align-items-center gap-2">
                                        <p class="m-0 ticket-type">{{ticket.ticket_type}}</p>
                                        <span class="ticket-type" *ngIf="ticket.idbond"
                                            style="color: var(--color-primary);"> - Abono</span>
                                    </div>
                                    <div class="d-flex grey-info-event info-position" *ngIf="ticket.position">
                                        <span>Zona: {{ticket.position.section}},</span>
                                        <span *ngIf="ticket.position.showRow">Fila: {{ticket.position.row}},</span>
                                        <span>Asiento: {{ticket.position.seat}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="price-ticket">
                                <p class="m-0 grey-info-event">{{ticket.price | number:'1.2-2'}}€</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Gastos de gestión y total -->
        <div class="footer-order">
            <div class="d-flex justify-content-end line-footer-order">
                <p class="title-fee">Gastos de gestión</p>
                <p class="number-fees">{{(order.total_fee || fees) | number:'1.2-2'}}€</p>
            </div>
            <mat-divider></mat-divider>
            <div class="d-flex justify-content-end line-footer-order">
                <p class="total-order">Total</p>
                <p class="number-total">{{order.total_order | number:'1.2-2'}}€</p>
            </div>
        </div>
    </div>
</div>