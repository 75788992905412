import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { EntradasService } from 'src/app/services/entradas.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.scss']
})
export class NewTicketComponent implements OnInit {

  @Output() saveTickets = new EventEmitter<any>();
  entrada: FormGroup;

  @Input() ticket!: any;
  @Input() levels: any[] = [];

  @Input() optionsTime: any[] = [];
  edit: boolean = false;
  typeDates: boolean = false;

  specialTimes: any = {};
  repeatWeek: any[] = [
    { day: 'L', number: 1 },
    { day: 'M', number: 2 },
    { day: 'X', number: 3 },
    { day: 'J', number: 4 },
    { day: 'V', number: 5 },
    { day: 'S', number: 6 },
    { day: 'D', number: 0 }
  ];

  typeDatepicker!: string;
  // Start and End Date
  startStringDate!: any;
  endStringDate!: any;
  startDate!: NgbDate;
  endDate!: NgbDate;

  hourInit!: any;
  minuteInit!: any;
  hourEnd!: any;
  minuteEnd!: any;

  priceTickets: any[] = [];
  singlePrice!: number;
  openAdvanced: boolean = false;

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() private dialogRef: MatDialogRef<NewTicketComponent>, public formatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
    if (this.data) {
      this.ticket = this.data.ticket;
      this.optionsTime = this.data.optionsTime;
      this.levels = this.data.levels;
      this.levels?.forEach(element => {
        element.price = null;
      });
    }
    this.entrada = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.maxLength(50)]],
      description: ['', Validators.maxLength(250)],
      specialTime: [false],
      activo: [true],
      dateInit: [],
      dateEnd: [],
      ticketsMin: [1],
      ticketsMax: [null],
      priceTickets: []
    });
    if (this.ticket) {
      if (this.ticket.dateInit) {
        if (typeof (this.ticket.dateInit) == 'string') {
          this.ticket.dateInit = new Date(this.ticket.dateInit);
        }
        this.startStringDate = formatDate(this.ticket.dateInit, 'dd/MM/yyyy', 'es');
        this.hourInit = this.ticket.dateInit.getUTCHours();
        this.minuteInit = this.ticket.dateInit.getUTCMinutes();
      }
      if (this.ticket.dateEnd) {
        if (typeof (this.ticket.dateEnd) == 'string') {
          this.ticket.dateEnd = new Date(this.ticket.dateEnd);
        }
        this.endStringDate = formatDate(this.ticket.dateEnd, 'dd/MM/yyyy', 'es');
        this.hourEnd = this.ticket.dateEnd.getUTCHours();
        this.minuteEnd = this.ticket.dateEnd.getUTCMinutes();
      }

      this.entrada.setValue({
        nombre: this.ticket.nombre,
        description: this.ticket.description,
        specialTime: this.ticket.specialTime,
        activo: this.ticket.activo,
        dateInit: this.ticket.dateInit,
        dateEnd: this.ticket.dateEnd,
        ticketsMin: this.ticket.ticketsMin,
        ticketsMax: this.ticket.ticketsMax,
        priceTickets: this.ticket.priceTickets,
      })
      this.priceTickets = this.ticket.priceTickets;
      this.levels.forEach(element => {
        const elPrice = this.priceTickets.find((value) => value.level == element.id || value.level?.id == element.id);
        element.price = elPrice ? elPrice.price : null;
      });
      this.edit = true;
      if (this.ticket.preciosHorarios) {
        this.ticket.preciosHorarios.forEach(element => {
          this.specialTimes[element.hour] = element.days;
        });
      }
    }
    if (this.optionsTime && (this.optionsTime[0]?.day instanceof Date)) {
      this.typeDates = true;
    }
    if (this.optionsTime && !(this.optionsTime[0]?.day instanceof Date)) {
      this.typeDates = false;
      this.optionsTime.forEach(element => {
        element.stringDate = element.day;
        element.day = this.repeatWeek.find(el => el.day == element.day)?.number;
      });
    }
  }

  openDatePicker(type: string) {
    if (this.typeDatepicker) {
      this.typeDatepicker = null;
      return;
    }
    this.typeDatepicker = type;
  }

  saveDate(event: any) {
    if (event) {
      const date = new Date(this.formatter.format(event));
      if (this.typeDatepicker == 'start') {
        this.startDate = event;
        this.entrada.controls['dateInit'].setValue(date);
        this.startStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
      if (this.typeDatepicker == 'end') {
        this.endDate = event;
        this.entrada.controls['dateEnd'].setValue(date);
        this.endStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
    }
    this.typeDatepicker = null;
  }

  changeSpecialTimes(event: any) {
    if (!this.optionsTime || this.optionsTime.length <= 0) {
      this.entrada.controls['specialTime'].setValue(false);
      this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'No hay horarios disponibles. Añádelos desde el apartado Fecha y Hora.',
          btnCancel: false
        }
      });
    }
  }

  addSpecialTime(dateTime: any, opt: any) {
    let date = new Date();
    let time = new Date(dateTime);
    date.setHours(time.getUTCHours());
    date.setMinutes(time.getUTCMinutes());
    const timeString = formatDate(date, 'HH:mm', 'es');
    const day = opt.day;
    if (opt.times) {
      if (this.specialTimes[timeString]) {
        const index = this.specialTimes[timeString].indexOf(day);
        index != -1 ? this.specialTimes[timeString].splice(index, 1) : this.specialTimes[timeString].push(day);
      } else {
        this.specialTimes[timeString] = [day];
      }
    }
    if (!opt.times) {
      if (!this.specialTimes[timeString]) {
        this.specialTimes[timeString] = [0, 1, 2, 3, 4, 5, 6];
      } else {
        this.specialTimes[timeString] = [];
      }
    }
  }

  addPriceTicket(event: any, level: any, index: any) {
    let indexPrice = -1;
    if(level?.id != null) {
      indexPrice = this.priceTickets.findIndex((value) => value.level == level?.id);
    } else if (this.priceTickets.length > index) {
      indexPrice = index;
    }
    if (indexPrice == -1) {
      indexPrice = this.priceTickets.push({ price: 0, level: level?.id, orderBuy: level?.orderBuy }) - 1;
    }
    event.target.value ? this.priceTickets[indexPrice].price = +event.target.value : this.priceTickets.splice(indexPrice, 1);
  }

  saveTicket() {
    if (this.entrada.invalid) {
      this.openInfo('Entrada no valida. Añade un nombre.');
      return;
    }

    if (this.priceTickets.length <= 0) {
      this.openInfo('Añade un precio de entrada');
      return;
    }
    if (this.entrada.valid && this.priceTickets.length > 0) {
      this.entrada.value.priceTickets = this.priceTickets;
      if (this.ticket) {
        this.entrada.value.id = this.ticket.id;
      }
      this.entrada.value.priceTickets = this.priceTickets;
      this.dialogRef.close(this.entrada.value);
    } else {
      this.openInfo('Entrada no valida. Comprueba todos los campos');
    }
  }

  openInfo(msg: string) {
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: false,
        text: msg,
        btnCancel: false
      }
    });
  }

}
