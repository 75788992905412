<div class="reports-page">
    <div class="section-report">
        <h2 class="m-0">Informes de ventas</h2>
    </div>
    <mat-divider></mat-divider>
    <div class="section-report">
        <!-- Añadir un spinner para descargar el documento -->
        <div>

        </div>

        <!-- Info filters -->
        <div class="container-info-reports" style="margin-bottom: 10px;">
            <i class="fa-solid fa-circle-1 icon-check"></i>
            <p class="m-0 title-section-report">Selecciona primero los datos que quieres exportar</p>
        </div>

        <div class="container-filters">
            <div class="filters-row d-flex justify-content-between">
                <!-- Fecha -->
                <div class="w-100">
                    <div class="">
                        <p class="title-filter-report">Rango de fechas <i class="fa-solid fa-info-circle"
                                matTooltip="Selecciona la fecha inicio y fin de compra para generar el informe."
                                matTooltipHideDelay="200" matTooltipClass="tooltip-reports"></i></p>
                    </div>
                    <mat-form-field appearance="fill" class="select-filter-panel select-icon-report"
                        [class.filter-report-active]="stringDate" (click)="openDatePicker=!openDatePicker">
                        <mat-select-trigger matPrefix style="vertical-align: middle;">
                            <i class="fa-light fa-calendar icon-search"></i>
                        </mat-select-trigger>
                        <input matInput placeholder="Seleccionar" class="pointer-in" readonly [(ngModel)]="stringDate">
                        <mat-select-trigger matSuffix style="vertical-align: middle;">
                            <i class="fal fa-times pointer-in icon-delete-range" *ngIf="stringDate"
                                (click)="clearRangeDates($event)"></i>
                            <i class="fal fa-angle-down pointer-in"></i>
                        </mat-select-trigger>
                    </mat-form-field>
                    <div class="padding-option">
                        <app-custom-calendar-panel [datesRange]="datesRange" [openDatePicker]="openDatePicker"
                            (closeEvent)="openDatePicker = false"
                            (datesEvent)="selectDates($event)"></app-custom-calendar-panel>
                    </div>
                </div>

                <!-- Metodo de pago -->
                <div class="w-100">
                    <div class="">
                        <p class="title-filter-report">Método de pago</p>
                    </div>
                    <mat-form-field appearance="fill" class="select-filter-panel" (click)="openPlacesList=false"
                        [class.filter-report-active]="methodSelected && methodSelected?.length > 0">
                        <mat-select #listPlace class="text-black" panelClass="selectPanelControl"
                            placeholder="Método de pago" multiple [(ngModel)]="methodSelected"
                            disableOptionCentering="false" (selectionChange)="onMethodSelectionChange($event)">
                            <!-- Opción "Todos" -->
                            <!-- <mat-option [value]="'all'">
                                <span>Todos</span>
                            </mat-option> -->
                            <mat-option *ngFor="let method of methodsOptions" [value]="method.id"
                                [disabled]="allSelected">
                                <span>{{method.name}}</span></mat-option>
                            <mat-select-trigger *ngIf="methodSelected?.length == methodsOptions?.length">
                                <!-- Mostrar "Todos" si está seleccionado, de lo contrario mostrar las opciones seleccionadas -->
                                <span>Todos</span>
                            </mat-select-trigger>
                        </mat-select>

                        <mat-select-trigger matSuffix style="vertical-align: middle;">
                            <i class="fal fa-angle-down pointer-in"></i>
                        </mat-select-trigger>
                    </mat-form-field>
                </div>

                <!-- Espacio (si lo tiene) -->
                <div class="w-100" *ngIf="placesEnabled?.length > 0">
                    <div class="">
                        <p class="title-filter-report">Espacio</p>
                    </div>
                    <mat-form-field appearance="fill" class="select-filter-panel" (click)="openPlacesList=false"
                        [class.filter-report-active]="placeSelected && placeSelected?.length > 0">
                        <mat-select #listPlace class="text-black" panelClass="selectPanelControl" placeholder="Espacio"
                            multiple [(ngModel)]="placeSelected" disableOptionCentering="false"
                            (selectionChange)="selectPlaceFilter()">
                            <!-- <mat-option [value]="'all'">
                                <span>Todos</span>
                            </mat-option> -->
                            <mat-option *ngFor="let place of placesEnabled" [value]="place.id">
                                <span>{{place.name}}</span></mat-option>
                            <mat-select-trigger *ngIf="placesEnabled?.length == placeSelected?.length">
                                <!-- Mostrar "Todos" si está seleccionado, de lo contrario mostrar las opciones seleccionadas -->
                                <span>Todos</span>
                            </mat-select-trigger>
                        </mat-select>
                        <mat-select-trigger matSuffix style="vertical-align: middle;">
                            <i class="fal fa-angle-down pointer-in"></i>
                        </mat-select-trigger>
                    </mat-form-field>
                </div>
            </div>
            <!-- Evento (con fecha y hora) -->
            <div class="w-100">
                <div class="">
                    <p class="title-filter-report">Seleccionar evento/s <span class="icon-primary">(obligatorio)</span>
                    </p>
                </div>
                <mat-form-field appearance="fill" class="select-filter-panel select-icon-report"
                    (click)="openPlacesList=false" [class.filter-report-active]="eventsSelected?.length > 0">
                    <mat-select-trigger matPrefix style="vertical-align: middle;">
                        <i class="fa-regular fa-search icon-search"></i>
                    </mat-select-trigger>
                    <mat-select *ngIf="eventsEnabled?.length > 0" #listPlace class="text-black"
                        panelClass="selectEventsReports" placeholder="Buscar" multiple [(ngModel)]="eventsSelected"
                        disableOptionCentering="false" (selectionChange)="changeEventsSelected()">
                        <!-- <mat-option (click)="selectAllEvents()"
                            [selected]="eventsSelected?.length == eventsEnabled?.length">Todos los eventos</mat-option> -->
                        <mat-option *ngFor="let event of eventsEnabled" [value]="event">
                            <span class="name-event">{{event.name | titlecase}} - </span>
                            <span class="dates-event">{{event.date | date:'dd/MM/yyyy'}} </span>
                            <span class="dates-event">{{event.hour | date: 'HH:mm'}}</span>
                            <span *ngIf="event.multidate" class="dates-event">Evento recurrente <i
                                    class="fa-solid fa-info-circle"
                                    matTooltip="Podrás elegir las fechas concretas tras seleccionar el evento."
                                    matTooltipHideDelay="100" matTooltipClass="tooltip-reports"></i></span>
                        </mat-option>
                        <mat-select-trigger *ngIf="eventsSelected?.length > 0">
                            <span>{{eventsSelected.length}} eventos seleccionados</span>
                        </mat-select-trigger>
                    </mat-select>
                    <mat-select *ngIf="eventsEnabled?.length <= 0" #listPlace class="text-black"
                        panelClass="selectPanelControl" placeholder="Buscar" disableOptionCentering="false">
                        <mat-option [disabled]="true">No hay eventos disponibles</mat-option>
                    </mat-select>
                    <mat-select-trigger matSuffix style="vertical-align: middle;">
                        <i class="fal fa-angle-down pointer-in"></i>
                    </mat-select-trigger>
                </mat-form-field>
                <!-- <div class="w-100 d-flex justify-content-end">
                    <p class="title-filter-report m-0">*Campo obligatorios</p>
                </div> -->
            </div>
        </div>

        <!-- Eventos seleccionados -->
        <div class="container-selected-events" *ngIf="eventsSelected?.length > 0">
            <div class="item-selected" *ngFor="let event of eventsSelected; let i = index">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center title-selected">
                        <i class="fa-light fa-times" (click)="removeEventSelected(i)"></i>
                        <p class="m-0">
                            <span class="name-selected">{{event.name | titlecase}} - </span>
                            <span class="date-selected" *ngIf="!event.multidate">{{event.date | date:'dd/MM/yyyy'}}
                                {{event.hour | date: 'HH:mm'}}</span>
                            <span class="multidate-event" *ngIf="event.multidate">Evento recurrente </span>
                            <span class="date-selected" *ngIf="event.multidate">(Todas las fechas)</span>
                        </p>
                    </div>
                    <!-- <div class="d-flex align-items-center open-multidate" *ngIf="event.multidate" (click)="openMultidate(event)">
                        <p class="m-0">Seleccionar opciones</p>
                        <i class="fa-light" [class]="openEventMultidate == event.id ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                    </div> -->
                </div>
                <div class="options-multidate" *ngIf="openEventMultidate == event.id">
                    <mat-radio-group [(ngModel)]="event.typeDates">
                        <mat-radio-button value="all" class="pointer-in"><span>Todas las fechas
                                disponibles</span></mat-radio-button>
                        <mat-radio-button value="selected" class="pointer-in"><span>Elegir fechas
                                concretas</span></mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>

        <!-- Eliminar filtros -->
        <!-- <div class="btn-create btn-filter btn-clear-filter" (click)="clearFilters()"
            *ngIf="stringDate || placeSelected || (eventsSelected && eventsSelected.length > 0)">
            <i class="fal fa-times"></i>
            <p class="m-0">Borrar selección</p>
        </div> -->

        <!-- Info filters -->
        <div class="container-info-reports" style="margin-top: 40px;">
            <i class="fa-solid fa-circle-2 icon-check"></i>
            <p class="m-0 title-section-report">Selecciona el tipo de informe</p>
        </div>
        <section class="container-options">
            <mat-radio-group [(ngModel)]="typeReport" (change)="changeTypeReport()">
                <mat-radio-button value="global" class="pointer-in"><span>Informe global</span> <i
                        matTooltip="Visión general con un desglose de eventos, recaudación total, y el canal de venta utilizado."
                        matTooltipHideDelay="200" matTooltipClass="tooltip-reports"
                        class="fa-solid fa-info-circle"></i></mat-radio-button>
                <mat-radio-button value="detail" class="pointer-in"><span>Informe detalle</span> <i
                        matTooltip="Incluye toda la información específica de un evento. Ideal para un análisis completo."
                        matTooltipHideDelay="200" matTooltipClass="tooltip-reports"
                        class="fa-solid fa-info-circle"></i></mat-radio-button>
            </mat-radio-group>
        </section>

        <!-- Format file -->
        <div class="container-info-reports" style="margin-top: 40px;">
            <i class="fa-solid fa-circle-3 icon-check"></i>
            <p class="m-0 title-section-report">Selecciona el formato del informe</p>
        </div>
        <section class="container-options">
            <mat-radio-group [(ngModel)]="formatReport">
                <mat-radio-button *ngIf="typeReport=='global'" value="pdf" class="pointer-in"><span>Formato PDF</span>
                    <i class="fa-regular fa-file-pdf option-format"></i></mat-radio-button>
                <mat-radio-button value="csv" class="pointer-in"><span>Formato EXCEL</span> <i
                        class="fa-regular fa-file-spreadsheet option-format"></i></mat-radio-button>
            </mat-radio-group>
        </section>
        <div class="container-report-group" *ngIf="formatReport=='csv' && typeReport=='detail'">
            <p class="m-0 title-column-report">Detalles del informe</p>
            <mat-radio-group [(ngModel)]="allColumns" (change)="selectAllColumns()">
                <mat-radio-button class="m-0 option-column-report" [value]="true">Todas</mat-radio-button>
            </mat-radio-group>
            <mat-divider></mat-divider>
            <div class="d-grid">
                <mat-checkbox (click)="selectColumn(column, i)" class="option-column-report"
                    [(ngModel)]="column.selected"
                    *ngFor="let column of reportsGroupEnabled; let i = index">{{column.name}}</mat-checkbox>
            </div>
        </div>

        <!-- Acciones -->
        <div class="btn-action-reports">
            <div class="btn-create btn-filter m-0"
                [class.btn-filter-active]="((eventsSelected && eventsSelected.length > 0)) && typeReport"
                [class.disabled]="loadingReport" (click)="downloadPDF()">
                <i class="fa-light fa-download"></i>
                <p class="m-0">Descargar informe</p>
            </div>
        </div>
    </div>
</div>