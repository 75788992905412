import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discount-pass',
  templateUrl: './discount-pass.component.html',
  styleUrls: ['./discount-pass.component.scss']
})
export class DiscountPassComponent implements OnInit {

  discounts!: any[];
  discountsPlace!: any[];

  constructor() { }

  ngOnInit(): void {
  }

  openCreateDiscount() {

  }

  openDiscountPlace() {
    
  }

}
