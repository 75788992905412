import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  private saveBooking = new BehaviorSubject<any>(null);
  public updateBooking = this.saveBooking.asObservable();

  private ticketsVenueObj = new BehaviorSubject<any>(null);
  public tickets = this.ticketsVenueObj.asObservable();

  private ticketsNumObj = new BehaviorSubject<any>(null);
  public ticketsNum = this.ticketsNumObj.asObservable();

  private configTicketsObj = new BehaviorSubject<any>(null);
  public configTickets = this.configTicketsObj.asObservable();

  constructor(private http: HttpClient) { }

  setBookingsStorage(bookings: any) {
    this.saveBooking.next(bookings);
  }

  getTicketsSaved() {
    return this.ticketsVenueObj.getValue();
  }

  setTicketsVenue(tickets: any) {
    this.ticketsVenueObj.next(tickets);
  }

  getTicketsNum() {
    return this.ticketsNumObj.getValue();
  }

  setTicketsNum(tickets: any) {
    this.ticketsNumObj.next(tickets);
  }

  getTicketsConfig() {
    return this.configTicketsObj.getValue();
  }

  setTicketsConfig(tickets: any) {
    this.configTicketsObj.next(tickets);
  }

  setTicketsEvent(payload: any, idEvent: number) {
    const url = `${AppSettings.API_URI}/edit/tickets/event/${idEvent}`;
    return this.http.put<any>(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBookingSeatsByEvent(event: any, date: any, time: any) {
    const payload = {
      event: event, date: date, time: time
    }
    const url = `${AppSettings.API_URI}/check/booking/seats`;
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getAllBookingsByEvent(event: any) {
    const url = `${AppSettings.API_URI}/bookings/event/${event}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  setDiscountsEvent(payload: any, idEvent: number) {
    const url = `${AppSettings.API_URI}/edit/discounts/event/${idEvent}`;
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  checkDiscountCode(code: any, cart: number) {
    const url = `${AppSettings.API_URI}/check/discount/${cart}/${code}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  deleteDiscountCart(cart: number) {
    const url = `${AppSettings.API_URI}/delete/discount/${cart}`;
    return this.http.delete<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  checkHiddenCode(code: any, event: number) {
    const url = `${AppSettings.API_URI}/check/code/seats/${code}/${event}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getAbonos(idAbono: any) {
    const url = `${AppSettings.API_URI}/abonos/${idAbono}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getTikcetsByEvent(idEvent: any) { 
    const url = `${AppSettings.API_URI}/list/tickets/event/${idEvent}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getTikcetsByVenue(idEvent: any) { 
    const url = `${AppSettings.API_URI}/list/tickets/venue/${idEvent}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
  
}
