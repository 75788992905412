import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, of, retry, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';
import { AuthenticationService } from './authentication.service';
import { CategoriasService } from './categorias.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  // City
  private citySelect = new BehaviorSubject<number>(null);
  public cityStorage = this.citySelect.asObservable();

  // Category
  private categorySelect = new BehaviorSubject<number>(null);
  public categoryStage = this.categorySelect.asObservable();

  // Cercanas
  private ciudadesCercanas = new BehaviorSubject<any[]>([]);
  public cercanas = this.ciudadesCercanas.asObservable();
  private cityId!: any;
  private location!: any;

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  getCiudadesCercanas() {
    if (this.location) {
      const url = `${AppSettings.API_URI}/distance/${this.location.latitud}/${this.location.longitud}`;
      return this.getData(url);
    }
  }

  getCiudadesCercanasById(idCity: any) {
    const url = `${AppSettings.API_URI}/distance-id/${idCity}`;
    return this.getData(url);
  }

  setCercanas(city) {
    this.cityId = city ? city : 1;
    this.getCiudadesCercanasById(this.cityId).subscribe({
      next: (data) => {
        const idCities = data.map(value => value.id);
        this.ciudadesCercanas.next(idCities);
      }
    })
  }

  getCercanas() {
    const value = this.ciudadesCercanas.getValue();
    return this.ciudadesCercanas.getValue();
  }

  setLocation(latitud, longitud) {
    const localizacion = { latitud: latitud, longitud: longitud };
    this.location = localizacion;
    this.getCiudadesCercanas().subscribe({
      next: (data) => {
        const idCities = data.map(value => value.id);
        this.ciudadesCercanas.next(idCities);
        this.cityId = idCities[0];
        localStorage.setItem('city', idCities[0]);
        this.citySelect.next(idCities[0]);
      }
    })
  }

  setCity(city: any | null) {
    this.cityId = city ? city : null;
    this.citySelect.next(city);
    if (city) {
      localStorage.setItem('city', city);
      this.getCiudadesCercanasById(this.cityId).subscribe({
        next: (data) => {
          const idCities = data.map(value => value.id);
          this.ciudadesCercanas.next(idCities);
        }
      })
    } else {
      localStorage.removeItem('city');
      if (this.location) {
        this.getCiudadesCercanas().subscribe({
          next: (data) => {
            const idCities = data.map(value => value.id);
            this.ciudadesCercanas.next(idCities);
          }
        })
      }
    }
  }

  getCategory() {
    return this.categorySelect.getValue();
  }

  setCategory(idCategory: any) {
    this.categorySelect.next(idCategory);
  }

  getDataHydra(url: string) {
    return this.http.get<any>(url).pipe(
      map(data => {
        return data['hydra:member'];
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getData(url: string, single: boolean = false, retryNumber: number = 3) {
    return this.http.get<any>(url).pipe(
      map(
        (data) => {
          if (data) {
            if (single) {
              return data[0];
            } else {
              return data;
            }
          } else {
            return data;
          }
        }
      ),
      retry(retryNumber),
      catchError(this.handleError)
    );
  }

  getDataByParams(url: string, params: any, single: boolean = false) {
    return this.http.get<any>(url, { params: params }).pipe(
      map(data => {
        if (single) {
          return data[0];
        } else {
          return data;
        }
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getDataWithHeaders(url: string) {
    return this.http.get(url, { observe: 'response' }).pipe(map(
      resp => { return resp }
    ),
      retry(2),
      catchError(this.handleError)
    );
  }

  postData(url: string, body: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(url, body).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }),
      retry(2),
      catchError(this.handleError)
    );
  }

  refreshToken(refresh: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(AppSettings.API_URI + '/token/refresh', { refresh_token: refresh }, formHeaders).pipe(
      map(jwt => {
        localStorage.setItem('jwt_token', jwt.token);
        localStorage.setItem('refresh', jwt.refresh_token);
        return jwt;
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (AppSettings.DEBUG) {
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    return throwError(() => new HttpErrorResponse(error));
  }
}
