import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  constructor(private http: HttpClient) { }

  getCartUser() {
    const url = `${AppSettings.API_URI}/shopping/cart`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      })
    );
  }

  addToCart(payload: any) {
    const url = `${AppSettings.API_URI}/booking/tickets`;
    return this.http.put<any>(url, payload).pipe(
      map(data => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  addBondToCard(payload: any) {
    const url = `${AppSettings.API_URI}/booking/bond`;
    return this.http.post<any>(url, payload).pipe(
      map(data => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  addToCartSimple(payload: any) {
    const url = `${AppSettings.API_URI}/booking/simple/tickets`;
    return this.http.put<any>(url, payload).pipe(
      map(data => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  deleteItemToCart(payload: any) {
    const url = `${AppSettings.API_URI}/delete/item/cart`;
    return this.http.put<any>(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  createInvoice(payload: any) {
    const url = `${AppSettings.API_URI}/create/billing/ticket`;
    return this.http.post<any>(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

}
