import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-conditions',
  templateUrl: './info-conditions.component.html',
  styleUrls: ['./info-conditions.component.scss']
})
export class InfoConditionsComponent {

  text: string = "Cargando..."

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data) {
    if (data) {
      this.text = data.text;
    }
  }
}
