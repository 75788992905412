<div class="new-manager">
    <div class="main-new-manager">
        <h1 class="title-new-manager">Nuevo usuario</h1>
        <div class="form-info-user">
            <form class="form-grid" [formGroup]="formNewManager">
                <div class="main-inputs">
                    <div>
                        <p class="label-input">Nombre</p>
                        <div class="form-input-container ">
                            <input type="text" class="w-100" formControlName="name" [required]="true">
                        </div>
                    </div>
                    <div>
                        <p class="label-input">Apellidos</p>
                        <div class="form-input-container ">
                            <input type="text" class="w-100" formControlName="lastname">
                        </div>
                    </div>
                </div>
                <div>
                    <p class="label-input">Correo electrónico</p>
                    <div class="form-input-container ">
                        <input type="email" class="w-100" formControlName="email">
                    </div>
                </div>
            </form>
        </div>
        <mat-divider></mat-divider>
        <div>
            <h2 class="title-section-create">Funciones</h2>
            <div class="select-function-user">
                <div *ngFor="let permission of (companyInfo|async)?.roles">
                    <mat-checkbox (change)="selectFunctions(permission)"
                        color="primary">{{permission.name}}</mat-checkbox>
                </div>
            </div>
            <!-- <div class="btn-open-info">
                <p class="open-link m-0" (click)="openedPermission=!openedPermission">Ver permisos</p>
                <i class="fal" [class]="openedPermission ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </div> -->
            <div *ngIf="openedPermission">
                <div class="form-info-user">
                    <div *ngFor="let permission of (permissionExtra)">
                        <p class="m-0">{{permission.name}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <mat-divider></mat-divider>
        <div>
            <h2 class="title-section-create">Recursos adicionales</h2>
            <div class="">
                <div *ngFor="let permission of (permissionExtra)">
                    <mat-checkbox color="primary">{{permission.name}}</mat-checkbox>
                </div>
            </div>
        </div> -->
    </div>
    <mat-divider></mat-divider>
    <div class="footer-new-manager">
        <div class="btn-new btn-create btn-create-border m-0" (click)="cancelNew()">
            <p class="m-0">Cancelar</p>
        </div>
        <div class="btn-new btn-create m-0" (click)="nextStep()"
            [class.disabled]="formNewManager.invalid || functionsSelected.length <= 0">
            <p class="m-0">{{send?'Invitar':'Siguiente'}}</p>
        </div>
    </div>
</div>