<div class="page-owner">
    <div class="header-owner">
        <div class="container owner-info d-grid d-md-flex">
            <div class="d-flex align-items-center d-sm-none verify-mobile">
                <div class="cont-icon-verify d-grid justify-content-center align-items-center">
                    <i class="fa-light fa-circle-check"></i>
                </div>
                <span class="user-verify">{{isBusiness?'Empresa verificada':'Espacio Verificado'}}</span>
            </div>
            <div class="img-owner-header"><img fallimg
                    src="{{urlImages}}/{{owner.image ? owner.image : 'img_default.png'}}" alt="Imagen creador" /></div>

            <div class="container-owner-header">
                <div class="d-flex justify-content-center justify-content-between align-items-center title-mobile">
                    <p class="title-header m-0 mobile-align">{{ owner.name }}</p>
                    <div class="d-flex">
                        <span class="content-icon"><i *ngIf="sharedSupported"
                                class="fa-light d-md-none icon-options fa-share-nodes fa-lg"></i></span>
                        <!-- <span class="content-icon" [matMenuTriggerFor]="edit"><i *ngIf="user && user == owner.id"
                                class="fa-light fa-ellipsis-vertical fa-2x" style="justify-self: flex-end;"></i></span>
                        <mat-menu #edit="matMenu" xPosition="before" panelClass="menuContentCustom">
                            <button mat-menu-item [routerLink]="['/profile/edit']">Editar</button>
                        </mat-menu> -->
                    </div>
                </div>
                <!-- <p class="nickname-header title-mobile">@{{ owner.nickname }}</p> -->
                <div class="d-none d-sm-flex align-items-center">
                    <div class="cont-icon-verify d-grid justify-content-center align-items-center">
                        <i class="fa-light fa-circle-check"></i>
                    </div>
                    <span class="user-verify">{{isBusiness?'Empresa verificada':'Espacio Verificado'}}</span>
                </div>
                <div *ngIf="checkConditions" class="check-conditions">
                    <i class="fa-solid fa-circle-info icon-primary"></i>
                    <span (click)="openDialogConditions()">Ver condiciones generales de la organización.</span>
                </div>
                <div class="description-user" [innerHTML]="owner.description"></div>
                <!-- Members -->
                <div *ngIf="owner?.allowSubscription">
                    <div class="text-subscriber">
                        <span>¿Quieres ser miembro de <span class="text-name-subscriptors">{{ owner?.membershipTitle ?
                                owner?.membershipTitle : owner?.name }}</span>?</span>
                        <div class="d-flex align-items-end link-subscriber">
                            <span (click)="openNewSubscriber()">Pincha aquí</span>
                            <i class="fas fa-arrow-pointer" (click)="openNewSubscriber()"></i>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex justify-content-end align-items-end w-100 h-100">
                    <div *ngIf="!user || user != owner.id"
                        class="circle-option d-md-grid justify-content-center align-items-center pointer-in d-none"
                        (click)="editSiguiendo()">
                        <i class="fa-heart" [class]="isWishlist ? 'fa-solid icon-primary' : 'fa-light'"></i>
                    </div>
                    <div class="circle-option d-md-grid justify-content-center align-items-center pointer-in d-none"
                        (click)="shareOwner()" *ngIf="sharedSupported">
                        <i class="fa-light fa-share-nodes"></i>
                    </div> 
                </div> -->
            </div>
        </div>
        <div class="ellipse-header"></div>
    </div>
    <div class="container-events-owner">
        <div class="container owner-results">
            <!-- <mat-tab-group (selectedTabChange)="changeTab($event)" dynamicHeight mat-stretch-tabs fitInkBarToContent color="primary" class="selected-tab-primary tab-color owner-tabs" animationDuration="10ms"> -->
            <!-- <mat-tab label="Eventos activos"> -->
            <p class="text-center title-owner" *ngIf="!results">Este creador no tiene ningún evento activo</p>
            <div *ngIf="index == 0 && owner.id" class="tab-events-owner">
                <app-infinity-scroll [isBusiness]="isBusiness" [idCreator]="owner.id" [teatroIdeal]="teatroIdeal"
                    [eventAfter]="'active'" (results)="existsResults($event)"></app-infinity-scroll>
            </div>
            <!-- </mat-tab> -->
            <!-- <mat-tab label="Eventos pasados">
                    <p class="text-center title-owner" *ngIf="!results">Este creador no tiene eventos pasados</p>
                    <div *ngIf="index == 1 && owner.id" class="tab-events-owner">
                        <app-infinity-scroll [idUser]="owner.id" [teatroIdeal]="teatroIdeal" [eventAfter]="'last'" (results)="existsResults($event)"></app-infinity-scroll>
                    </div>
                </mat-tab> -->
            <!-- </mat-tab-group> -->
        </div>
        <!-- <div class="default-cards cards-relations mt-4" *ngIf="owner.id != 39">
            <app-cards title="Otros eventos cerca de tí" [arrow]="false" [events]="eventosCerca"></app-cards>
        </div> -->
    </div>
</div>