<div class="new-select-places">
    <div class="main-select-places">
        <h1 class="title-select-places">Permiso a espacios</h1>
        <p class="info-select-places">Este usuario podrá gestionar los espacios que selecciones.</p>
        <div class="d-flex align-items-center justify-content-between">
            <h2 class="num-places m-0">Espacios ({{(placesCompany)?.length}})</h2>
            <div class="buscador-place container-form d-flex align-items-center borderInput">
                <i class="fal fa-search search-icon pointer-in"></i>
                <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar un espacio" (keyup)="searchPlaces($event)">
            </div>
        </div>
        <mat-divider></mat-divider>
        <div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z28 w-100">
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>                        
                        <mat-checkbox [(ngModel)]="allPlaces" class="checkbox" color="primary" (change)="selectAll()"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let place">
                        <mat-checkbox class="checkbox" [checked]="allPlaces" color="primary" (change)="changeSelectPlace(place)"></mat-checkbox>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef><b></b></th>
                    <td mat-cell *matCellDef="let place">
                        <img class="image-table"
                            src="{{place.image}}">
                        <span class="link-titulo">{{place.image}}</span>
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Nombre</th>
                    <td mat-cell *matCellDef="let place">
                        {{place.name}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef>Localización</th>
                    <td mat-cell *matCellDef="let place">
                        {{place.location}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="footer-new-manager">
        <div class="btn-new btn-create btn-create-border m-0" (click)="goBackNewManager()">
            <p class="m-0">Volver</p>
        </div>
        <div class="btns-confirm">
            <div class="btn-new btn-create btn-create-border m-0" (click)="closeNew()">
                <p class="m-0">Cancelar</p>
            </div>
            <div class="btn-new btn-create m-0" (click)="createNewManager()" [class.disabled]="placesSelected.length <= 0">
                <p class="m-0">Invitar</p>
            </div>
        </div>
    </div>
</div>