import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { FaqsService } from 'src/app/services/faqs.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  faqs: Observable<any[]>;

  constructor(private faqService: FaqsService) { }

  ngOnInit(): void {
    this.faqs = this.faqService.getFAQs(1).pipe();
  }

}
