import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-submenu-panel',
  templateUrl: './submenu-panel.component.html',
  styleUrls: ['./submenu-panel.component.scss']
})
export class SubmenuPanelComponent implements OnInit {

  itemActive: any = 1;
  submenus: any[] = [
    {
      route: '/business',
      name: 'Empresas',
      url: '/business',
      requiredCitylok: true,
      items: [
        {
          name: 'Listado de empresas',
          url: '/business',
          exact: true,
        },
      ]
    },
    {
      route: '/events',
      name: 'Eventos',
      url: '/events',
      items: [
        {
          name: 'Lista de eventos',
          url: '/events/list',
          exact: true
        },
        {
          name: 'Borradores',
          url: '/events/draft',
          exact: true,
          requiredCreator: true
        },
        {
          name: 'Abonos',
          url: '/events/bonds',
          exact: true,
          requiredSales: true
        }
      ]
    },
    {
      route: '/orders',
      name: 'Pedidos',
      url: '/orders',
      requiredOrders: true,
      items: [
        {
          name: 'Lista de pedidos',
          url: '/orders',
          exact: true,
        },
        // {
        //   name: 'Facturación',
        //   url: '/orders/billing',
        //   exact: true
        // }
      ]
    },
    {
      route: '/subscribers',
      name: 'Usuarios',
      url: '/subscribers',
      items: [
        {
          name: 'Listado de usuarios',
          url: '/subscribers/list-users',
          exact: true,
          requiredMarketing: true
        },
        {
          name: 'Miembros',
          url: '/subscribers/list-subscribers',
          exact: false,
          requiredMarketing: true,
        },
        // {
        //   name: 'Comentarios',
        //   url: '/subscribers/comments',
        //   exact: false
        // },
        // {
        //   name: 'Newsletter',
        //   url: '/subscribers/newsletter',
        //   exact: false
        // },
        // {
        //   name: 'Notificaciones push',
        //   url: '/subscribers/notifications',
        //   exact: false
        // }
      ]
    },
    {
      route: '/reports',
      name: 'Informes',
      url: '/reports',
      requiredOrders: true,
      items: [
        {
          name: 'Descarga de informes',
          url: '/reports',
          exact: true,
        },
      ]
    },
    {
      route: '/config',
      name: 'Configuración',
      url: '/config',
      items: [
        {
          name: 'Organización',
          url: '/config/business',
          exact: false,
          requiredSuperadmin: true
        },
        {
          name: 'Espacios',
          url: '/config/venues',
          exact: false,
          requiredAdmin: true
        },
        {
          name: 'Permisos y gestores',
          url: '/config/permission/function',
          exact: false,
          requiredAdmin: true
        },
        {
          name: 'Perfil de usuario',
          url: '/config/info-profile',
          exact: true
        }
      ]
    }
  ];
  submenuActive: any = this.submenus[0];
  profile = this.auth.getProfile();
  rolesProfile: any[] = this.profile?.roleFunctions.map((role: any) => role.role.code);
  superadmin: boolean = false;
  admin: boolean = false;
  allowOrder: boolean = false;
  allowSales: boolean = false;
  allowMarketing: boolean = false;
  allowCreator: boolean = false;
  allowCitylok: boolean = false;

  constructor(private router: Router, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.rolesProfile?.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS') {
        this.superadmin = true;
      }
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER') {
        this.admin = true;
      }
      if (element == 'CREATE_EVENTS'){
        this.allowCreator = true;
      }
      if (element == 'ACCESS_SALES'){
        this.allowSales = true;
      }
      if (element == 'ACCESS_SUPPORT' || element == 'ACCESS_REPORTS' || element == 'ACCESS_SALES'){
        this.allowOrder = true;
      }
      if (element == 'ACCESS_MARKETING' || element == 'ACCESS_SUPPORT' || element == 'CREATE_EVENTS'){
        this.allowMarketing = true;
      }
    });
    var url = this.router.url;
    this.allowCitylok = this.profile.citylok;
    this.submenuActive = this.submenus.find(value => url.indexOf(value.route) != -1);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        var url = event.url;
        this.submenuActive = this.submenus.find(value => url.indexOf(value.route) != -1);
      }
    });
  }

  ngAfterViewInit() {
   
  }

}
