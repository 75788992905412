import { Options } from '@angular-slider/ngx-slider';
import { formatDate } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CategoriasService } from 'src/app/services/categorias.service';
import { CommonService } from 'src/app/services/common.service';
import { FilterService } from 'src/app/services/filter.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  orderActive: boolean = false;
  datesActive: boolean = true;
  categoriesActive: boolean = true;
  pricesActive: boolean = true;

  orderByOptions: any[] = this.filterService.getOrderOptions();
  datesOptions: any[] = this.filterService.getDatesOptions();
  categories: Observable<any[]>;
  maxPrice: number = this.filterService.getMaxPrice();

  categoryEnabled: boolean = true;

  // Swipers
  swiperFilterConfig: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 8,
    slidesOffsetAfter: 20,
    slidesOffsetBefore: 20,
    breakpoints: {
      569: {
        spaceBetween: 12,
        slidesOffsetAfter: 25,
        slidesOffsetBefore: 25,
      }
    }
  };

  // Filters Selected
  orderBy: any;
  dateFilter: any;
  categoriesSelected: any[] = [];

  // Slider
  lowPrice: number = 0;
  highPrice: number = this.maxPrice;
  @ViewChild('customerSlider', {read: ElementRef}) cutomerSlider: any;
  widthContainer: number = 522;

  options: Options = {
    noSwitching: true,
    floor: 0,
    barDimension: this.widthContainer,
    ceil: this.maxPrice,
    hideLimitLabels: true,
    translate: (value: number): any => {
      if(value == 0) {
        return '<span style="color: var(--color-primary);">Gratuito</span>';
      }
      return value + '€';
    },
  };

  // Calendar
  datesRange: any;
  stringDate: any = '';
  openDatePicker: boolean = false;

  constructor(private categoryService: CategoriasService, private filterService: FilterService, private dialog: MatDialog,
    private router: Router, public formatter: NgbDateParserFormatter, private commonService: CommonService) { }

  ngOnInit(): void {
    let id = this.commonService.getCategory();
    if(id) {
      this.categoryEnabled = false;
      let cat = {id: id, name: '', type: 'category'}
      this.categoriesSelected.push(cat);
    } else {
      this.categories = this.categoryService.getAllCategories().pipe();
      this.filterService.categories.subscribe(res => {
        this.categoriesSelected = res
      });
    }

    this.filterService.dates.subscribe(data => {
      if(data) {
        this.dateFilter = data;
        if(data.id == 0) {
          this.datesRange = {min: data.desde, max: data.hasta};
          this.selectDates(this.datesRange)
        }
      } else {
        this.selectDateOption(null);
      }
    });
    this.filterService.orderBy.subscribe(order => {
      this.orderBy = order;
    });
    this.filterService.prices.subscribe(price => {
      if(price) {this.lowPrice = price.min; this.highPrice = price.max}
    })
  }

  ngAfterViewInit() {
    this.getWidthContainer();
  }

  @HostListener('window:resize') 
  getWidthContainer() {
    this.widthContainer = this.cutomerSlider.nativeElement.clientWidth;
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.barDimension =  this.widthContainer;
    this.options = newOptions;
  }

  selectCategory(category: any) {
    let index = this.categoriesSelected.findIndex(value => value.id == category.id)
    if(index != -1) {
      this.categoriesSelected.splice(index, 1);
    } else {
      this.categoriesSelected.push(category);
    }
  }

  isSelectedCategory(category: any) {
    return this.categoriesSelected.find(value => value.id == category.id)
  }

  formatStringDate(date: any) {
    let dateFormat = null;
    if(date) {
      dateFormat = formatDate(new Date(date), 'EEE, dd MMM', 'es'); 
    }
    return dateFormat;
  }

  selectDates(datesRange: any) {
    this.datesRange = datesRange;
    let stringDates = this.formatStringDate(datesRange.min);
    if(datesRange.max) {
      stringDates += ' - ' + this.formatStringDate(datesRange.max);
    }
    this.stringDate = stringDates;
    this.dateFilter = {id: 0, name: this.stringDate, type: 'fecha', desde: this.datesRange.min, hasta: this.datesRange.max};
  }

  clearFilters() {
    this.filterService.resetAllFilters();
  }

  saveFilters() {
    this.filterService.resetFilters();
    this.filterService.setOrderBy(this.orderBy);
    this.filterService.setDatesRange(this.dateFilter);
    this.filterService.setCategories(this.categoriesSelected);
    this.filterService.setPrices(this.highPrice, this.lowPrice);
    this.filterService.saveJsonFilters();
    if(this.router.url == '/') {
      // this.router.navigate(['/results'], {queryParams: this.filterService.getUrlFilters()});
      this.router.navigate(['/results']);
    }
    this.dialog.closeAll();
  }

  selectDateOption(option: any) {
    this.dateFilter = option == this.dateFilter ? null : option;
    this.stringDate = '';
    this.datesRange = {min: '', max: ''};
  }

  resetDates() {
    this.selectDateOption(null);
    this.openDatePicker = !this.openDatePicker;
  }

}
