export const environment = {
  production: true,
  backend: "https://api.citylok.com/api",
  chatbot: "https://api.ventas.citylok.com",
  login: "https://api.citylok.com",
  media: "https://api.citylok.com",
  front: "https://citylok.com",
  sales: "https://ventas.citylok.com",
  keys: {
    stripe: 'pk_live_51N166hBqYAOLE3PaiwLQdB3R63iniMCzACnJIcZy2vCIJZYRxZqeNdzuhWdhbKOtGZX4DfLo2wjz8tvmECcp4mvf009zAOB9wq',
    facebook: '1738809706333953',
    google: '744858171463-2ipje4876setrcv8j3gemaflacci6mn7.apps.googleusercontent.com'
  },
  debug: false
};

// export const environment = {
//   production: true,
//   backend: "https://api.citylokv2.sdi.es/api",
//   login: "https://api.citylokv2.sdi.es",
//   media: "https://api.citylokv2.sdi.es",
//   front: "https://citylokv2.sdi.es",
//   keys: {
//     stripe: 'pk_test_51N166hBqYAOLE3PaTb69ojBA3eg12VzPGvx7bUIZRDJyemLYnDc0Mx3ZmlWngWTvf1IMANMcgJRI7E3fpGAPg8fL00gXeQMExy',
//     facebook: '1738809706333953',
//     google: '744858171463-2ipje4876setrcv8j3gemaflacci6mn7.apps.googleusercontent.com'
//   },
//   debug: false
// };


// export const environment = {
//   production: true,
//   backend: "https://dev.api.citylok.com/api",
//   login: "https://dev.api.citylok.com",
//   media: "https://dev.api.citylok.com",
//   front: "https://dev.citylok.com",
//   keys: {
//     stripe: 'pk_test_51N166hBqYAOLE3PaTb69ojBA3eg12VzPGvx7bUIZRDJyemLYnDc0Mx3ZmlWngWTvf1IMANMcgJRI7E3fpGAPg8fL00gXeQMExy',
//     facebook: '1738809706333953',
//     google: '744858171463-2ipje4876setrcv8j3gemaflacci6mn7.apps.googleusercontent.com'
//   },
//   debug: false
// };
