import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  // Acciones globales
  printA4Order(idOrder: number, channel: any) {
    const url = `${AppSettings.API_URI}/orders/a4/${channel}/${idOrder}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  printAllTicket(idOrder: number, channel: any) {
    const url = `${AppSettings.API_URI}/orders/ticket/${channel}/${idOrder}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getUrlsDownload(idOrder: number) {
    const url = `${AppSettings.API_URI}/urls/download/order/${idOrder}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getUrlsDownloadAll(tickets: any[]) {
    let url = `${AppSettings.API_URI}/urls/download/all?page=1`;
    tickets?.forEach(element => {
      url += `&ticket[]=${element}`;
    });
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  refundAllTickets(idOrder: number) {
    const url = `${AppSettings.API_URI}/orders/refund/all/${idOrder}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  // Acciones individuales
  printSingleTicket(idOrder: number, idTicket: number, channel: any) {
    const url = `${AppSettings.API_URI}/orders/ticket/${idOrder}/${idTicket}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  printA4SingleOrder(idOrder: number, idTicket: any, channel: any) {
    const url = `${AppSettings.API_URI}/orders/ticket/${channel}/${idOrder}/${idTicket}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  refundSingleTicket(idOrder: number, channel: string, idEntry: number) {
    const url = `${AppSettings.API_URI}/orders/refund/ticket/${idOrder}/${idEntry}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  cancelPendingOrder(idOrder: number) {
    const url = `${AppSettings.API_URI}/cancel/order/sale`;
    return this.http.patch<any>(url, { idOrder: idOrder }).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  cancelSeatsOrder(idOrder: number) {
    const url = `${AppSettings.API_URI}/seats/order/sale`;
    return this.http.patch<any>(url, { idOrder: idOrder }).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}
