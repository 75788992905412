import { formatDate, getLocaleFirstDayOfWeek } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDatepickerModule, NgbDatepickerNavigateEvent, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'ddd DD MMM',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-custom-calendar-panel',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class CustomCalendarComponent implements OnInit, OnChanges {

  // Calendar Custom
  minDate: NgbDateStruct;

  displayMonths = 2;
  navigation = 'arrows';
  showWeekNumbers = false;
  outsideDays = 'visible';

  model: NgbDateStruct;
  currentMonth: number;

  date!: any;
  hoveredDate!: any;

  fromDate!: any;
  toDate!: any;

  lang: string = 'es';
  firstDayOfWeek: number = 1;

  @Input() openDatePicker: boolean = true;
  @Input() datesRange: any;

  @ViewChild(NgbInputDatepicker) private dp: any;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() datesEvent = new EventEmitter<any>();

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.model = this.calendar.getToday();
    const today = new Date();
    this.minDate = {
      day: 1,
      month: 0,
      year: 2020
    };
    this.currentMonth = this.model.month;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.openDatePicker) {
      this.dp?.open();
    }
    if (this.datesRange) {
      this.fromDate = this.formatter.parse(this.datesRange.min);
      this.toDate = this.formatter.parse(this.datesRange.max);
    }
  }

  ngOnInit(): void {
    if (this.datesRange) {

    }
  }

  // Calendar custom
  dateNavigate($event: NgbDatepickerNavigateEvent) {
    this.currentMonth = $event.next.month;
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) &&
      date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) { return this.toDate && date.after(this.fromDate) && date.before(this.toDate); }

  isRange(date: NgbDate) {
    return (date.equals(this.fromDate) && this.toDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date);
  }

  isMonday = (date: NgbDate) => this.calendar.getWeekday(date) == this.getFirstDayWeek();
  isSunday = (date: NgbDate) => this.calendar.getWeekday(date) == this.getLastDayWeek();
  isWeekend = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;
  isDisabled = (date: NgbDate) => !(date.equals(this.minDate) || (date.after(this.minDate) && (date.month == this.currentMonth)));

  getFirstDayWeek() {
    let first = getLocaleFirstDayOfWeek(this.lang);
    if (first == 0) {
      first = 7;
    }
    return first;
  }

  getLastDayWeek() {
    let first = getLocaleFirstDayOfWeek(this.lang);
    let lastCal = first + 6;
    if (lastCal > 7) {
      lastCal = first - (lastCal - 7)
    }
    return lastCal;
  }

  isFromDate(date: NgbDate) {
    if (this.fromDate) {
      return date.equals(this.fromDate);
    }
    return false;
  }

  isToDate(date: NgbDate) {
    if (this.toDate) {
      return date.equals(this.toDate);
    }
    return false;
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  closeDatePicker() {
    this.closeEvent.emit(false);
  }

  changeDate(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.dp?.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    const dateString = { min: this.formatter.format(this.fromDate), max: this.formatter.format(this.toDate) };
    this.datesEvent.emit(dateString);
  }

}
