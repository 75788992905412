import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ComprasService } from 'src/app/services/compras.service';
import { ReportsService } from 'src/app/services/reports.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-tickets-profile',
  templateUrl: './tickets-profile.component.html',
  styleUrls: ['./tickets-profile.component.scss']
})
export class TicketsProfileComponent implements OnInit {

  purchases: any[] = [];
  index: number = 0;

  page: number = 1;
  items: number = 12;
  loadingPage = false;

  finishScroll: boolean = false;
  open: boolean = false;
  tickets: any[] = [];

  @ViewChild('containerEvents', { static: false }) containerEvents: ElementRef;
  loadingLottie: AnimationOptions = {
    path: 'assets/lotties/loading.json',
  };

  constructor(private auth: AuthenticationService, private dialog: MatDialog, private router: Router,
    private comprasService: ComprasService, private reportService: ReportsService) { }

  ngOnInit(): void {
    this.initLoading();
  }

  initLoading() {
    this.goToTop();
    this.purchases = [];
    this.loadingPage = true;
    this.page = 1;
    this.finishScroll = false;
    setTimeout(() => {
      this.getData();
    }, 200);
  }

  backTab() {
    this.router.navigate(['/profile']);
  }

  getData() {
    this.comprasService.getCompras(this.page, this.items).subscribe(data => {
      let countTickets = 0;
      if (data) {
        this.purchases = this.purchases.concat(data);
        data.forEach(element => {
          countTickets += element.entradas.length;
        });
      }
      this.finishScroll = countTickets < this.items;
      this.loadingPage = false;
    })
  }

  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolled(event) {
    if (!this.finishScroll && !this.loadingPage) {
      const element = this.containerEvents.nativeElement.getBoundingClientRect();
      let bottomShown = element.bottom <= window.innerHeight;

      if (bottomShown) {
        this.page++;
        this.loadingPage = true;
        setTimeout(() => {
          this.getData();
        }, 500);
      }
    }
  }

  editAction(event: any) {
    if (event) {
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'Acción realizada correctamente',
          btnCancel: false,
        }
      });
    }
  }

  goToTop() {
    window.scrollTo(0, 0);
  }

  printPurchase(idTicket: number) {
    this.reportService.getUrlsDownload(idTicket).subscribe({
      next: (url) => {
        let uriDownload = url['ticket_a4'];
        setTimeout(() => {
          window.open(uriDownload, '_blank');
        }, 100);
        this.openInfoCard('Se ha descargado el ticket correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar las entradas.', true, false);
      }
    })
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

}
