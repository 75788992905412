<div class="container content-terms" [class.content-not-dialog]="!openDialog">
    <h3 class="title-terms" style="color: var(--color-primary); ">Condiciones de uso y compras</h3>

    <p>Las presentes Condiciones Generales de Uso y de la Contratación (en adelante, “las Condiciones Generales”) rigen
        el acceso y la utilización del sitio web accesible a través del nombre de dominio <a
            href="https://www.citylok.com" target="_self">www.citylok.com</a> y sus
        subdominios (en adelante, “el Sitio Web”), así como la contratación de entradas a través del Sitio Web,
        aplicaciones móviles (en adelante, la "APP") y nuestro servicio de venta telefónica (en adelante, conjuntamente
        denominados los "Canales de Venta").
    </p>
    <p>
        Citylok podrá establecer, en su caso, condiciones particulares que regulen la utilización y/o contratación de
        servicios o productos específicos ofrecidos a través de los Canales de Venta. El simple acceso al Sitio Web, a
        la APP o al servicio de venta telefónica atribuye a quien lo realiza la condición de usuario (en adelante, “el
        Usuario”) e implica la aceptación de todos los términos incluidos en estas Condiciones Generales, y en su caso,
        en las condiciones particulares disponibles a través de los Canales de Venta. En caso de no estar de acuerdo con
        estas Condiciones Generales, el Usuario debe abandonar cualquier transacción de compra de entradas, productos y
        servicios a través de los Canales de Venta.
    </p>
    <p>Por medio de la aceptación de las presentes Condiciones Generales, el Usuario manifiesta:
        <li>Que ha leído, entiende y comprende lo aquí expuesto.</li>
        <li>Que, si se dispone a contratar algún producto o servicio, tiene capacidad suficiente para ello.</li>
        <li>Que asume todas las obligaciones aquí dispuestas.</li>
    </p>

    <p class="sub-title-terms">1. Información general</p>

    <p>En cumplimiento de lo establecido por el artículo 10 de la Ley 34/2002, de 11 de julio, de servicios de la
        sociedad de la información y de comercio electrónico, a continuación, se ofrece la información general del Sitio
        Web:
    </p>
    <ul>
        <li><strong>Titular: </strong>KREARE DIGITAL S.L.</li>
        <li><strong>Sede y establecimiento: </strong>Calle Alfonso VI nº 4, bajo, de Logroño (La Rioja)</li>
        <li><strong>C.I.F.: </strong>B-05338504</li>
        <li><strong>Contacto: </strong>Puede contactarnos a través de correo electrónico <a
                href="mailto:hola@citylok.com"> hola&#64;citylok.com</a> y teléfono <a href="tel:+34941135052">941 135
                052</a></li>
    </ul>

    <p class="sub-title-terms">2. Acceso al Sitio Web y/o a la APP</p>
    <p>El simple acceso al Sitio Web, así como a la APP es gratuito salvo en lo relativo al coste de la conexión a
        través de la red de telecomunicaciones suministrada por el proveedor de acceso contratado por el Usuario, que
        será a cargo de éste.
    </p>

    <p class="sub-title-terms">3. Normas de utilización del Sitio Web y/o de la APP</p>
    <p>El Usuario se obliga a utilizar el Sitio Web, así como la APP y todo su contenido y servicios conforme a lo
        establecido en la ley, la moral, el orden público y las presentes Condiciones Generales, no empleándolos para
        realizar actividades ilícitas o constitutivas de delito que atenten contra los derechos de terceros y/o que
        infrinjan cualesquiera normas del ordenamiento jurídico aplicable. Al aceptar estas Condiciones o realizar una
        compra, el usuario declara tener capacidad legal para celebrar contratos.
    </p>
    <p>El Usuario se compromete a:</p>
    <ol>
        <li>No introducir o difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico, de apología
            del terrorismo o que atenten contra los derechos humanos.</li>
        <li>No introducir o difundir en la red programas de datos (virus y software nocivo) susceptibles de provocar
            daños en los sistemas informáticos del proveedor de acceso, sus proveedores o terceros Usuarios de la red
            Internet.</li>
        <li>No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o
            contenido que atente contra los derechos fundamentales y las libertades públicas reconocidos
            constitucionalmente y en los tratados internacionales.</li>
        <li>No realizar actuaciones que puedan suponer la comisión de ilícitos, conductas ilegales o fraudulentas.</li>
        <li>No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o
            contenido que constituya publicidad ilícita o desleal.</li>
        <li>No transmitir publicidad no solicitada o autorizada, material publicitario, "correo basura", "cartas en
            cadena", "estructuras piramidales", o cualquier otra forma de solicitud, excepto en aquellas áreas (tales
            como espacios comerciales) que hayan sido exclusivamente concebidas para ello.</li>
        <li>No introducir o difundir cualquier información y contenidos falsos, ambiguos o inexactos de forma que
            induzca a error a los receptores de la información.</li>
        <li>No suplantar a otros Usuarios utilizando sus claves de registro a los distintos servicios y/o contenidos del
            Sitio Web y/o la APP.</li>
        <li>No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o
            contenido que suponga una violación de los derechos de propiedad intelectual e industrial, patentes, marcas
            o copyright que correspondan a los titulares del Sitio Web, la APP o a terceros.</li>
        <li>No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o
            contenido que suponga una violación del secreto de las comunicaciones y la legislación de datos de carácter
            personal.</li>
    </ol>
    <p>Citylok se reserva el derecho de anular cualquier operación y/o cancelar la cuenta del Usuario cuando existan
        sospechas, indicios o evidencias razonables de que la misma se ha realizado incumplimiento las disposiciones de
        estas Condiciones Generales de Uso, sin previo aviso. Igualmente, Citylok se reserva el derecho de anular las
        entradas si razonablemente se pudiera considerar que la compra se ha realizado de forma fraudulenta.</p>
    <p>El Usuario se obliga a mantener indemne a Citylok ante cualquier posible reclamación, multa, pena o sanción que
        pueda venir obligada a soportar como consecuencia del incumplimiento por parte del Usuario de cualquiera de las
        normas de utilización antes indicadas, reservándose, además, Citylok el derecho a solicitar la indemnización por
        daños y perjuicios que corresponda.</p>

    <p class="sub-title-terms">4. Venta de entradas</p>
    <p>La adquisición de una entrada para un evento a través de cualquier canal disponible gracias a Citylok implica la
        aceptación de dos relaciones contractuales diferenciadas: (i) una relación contractual entre el Usuario y
        Citylok relativa al proceso de compra de la entrada, en virtud de la cual Citylok, como intermediaria, procura
        al Usuario la venta y entrega de la entrada, y (ii) una relación entre el Usuario y el organizador del evento
        relativa a aquellos aspectos relacionados con la organización y ejecución del evento, en virtud de la cual el
        organizador procura al Usuario la celebración del evento a aquellos Usuarios que hayan adquirido un título
        válido para la asistencia al mismo.</p>
    <p>En este sentido, Citylok no es el organizador del evento sino un canal de venta del organizador que actúa como
        intermediaria o distribuidora en la venta de las entradas.</p>
    <p>Citylok le ofrece las siguientes opciones de compra de sus entradas:</p>
    <ul>
        <li><strong>Sitio Web: </strong>Un servicio de compra on-line a través de la página web <a
                href="https://www.citylok.com" target="_self">www.citylok.com</a>, disponible las 24 horas al día, todos
            los días del año.</li>
        <li><strong>APP Citylok: </strong>Un servicio de compra on-line a través de una aplicación móvil compatible con
            iOS y Android, disponible las 24 horas al día, todos los días del año.</li>
        <li><strong>Teléfono: </strong>Un servicio de venta telefónica de entradas llamando al número de contacto
            proporcionado, en horario de lunes a viernes de 8:00h a 18:00h. El coste de la llamada será comunicado
            durante el proceso de compra.</li>
        <li><strong>Venta a Grupos: </strong>La venta a grupos estará disponible en aquellos eventos que tengan
            disponible esta opción. En tales supuestos, la compra se podrá realizar llamando al número de contacto o
            bien enviando un correo electrónico a la dirección especificada.</li>
    </ul>
    <p>En función del organizador de cada evento, la utilización del servicio de venta telefónica individual a través
        del teléfono puede conllevar un cargo por gastos de gestión por la venta de entradas, que será indicado durante
        el proceso de compra.</p>
    <p>En el proceso de compra y siempre antes de que finalice el mismo, el Usuario dispondrá de toda la información
        relativa al precio final completo, incluidos los impuestos, desglosando, en su caso, el importe de los
        incrementos o descuentos que sean de aplicación a la oferta y los gastos adicionales que se repercutan al
        Usuario, siendo el Usuario responsable de facilitar sus datos correctamente, incluidos sus datos personales y
        los datos bancarios requeridos por el sistema, de forma veraz y exacta.</p>
    <p>Los organizadores de espectáculos y/o eventos determinan las condiciones de las entradas y los eventos a los que
        las mismas dan acceso y facilitan a Citylok las entradas para su venta al público a través de distintos canales
        de distribución, entre los que se incluyen los Canales de Venta.</p>
    <p>Citylok actúa como un mero intermediario en la venta de entradas a espectáculos y/o eventos organizados por los
        organizadores de los mismos y, por tanto, no ejerce ningún control sobre los espectáculos y/o eventos y la
        disponibilidad de entradas para los mismos. Por tanto, Citylok no es responsable de la realización del evento,
        ni de ningún retraso, suspensión, cancelación, modificación, anulación y/o cualquier otra circunstancia que
        pueda dar lugar a una reclamación relacionada con el evento del organizador o recinto, tales como, entre otras,
        las condiciones de visibilidad, calidad del sonido, accesibilidad, etc. Toda reclamación sobre la realización,
        suspensión, modificación, aplazamiento y/o anulación del evento contratado es responsabilidad de la empresa
        organizadora.</p>

    <p class="sub-title-terms">5. Contenidos y servicios enlazados a través del Sitio Web y/o la APP</p>
    <p>El Sitio Web y/o la APP puede contener dispositivos técnicos de enlace, directorios e incluso instrumentos de
        búsqueda que permitan al Usuario acceder a otras páginas y portales de Internet (en adelante, los “Sitios
        Enlazados”).</p>
    <p>En ningún caso la existencia de Sitios Enlazados puede presuponer la formalización de acuerdos entre Citylok y
        los responsables o titulares de los mismos, así como tampoco la recomendación o promoción de los Sitios
        Enlazados y/o sus contenidos por parte de Citylok.</p>
    <p>A menos que se indique expresamente lo contrario en el Sitio Web y/o la APP, Citylok no conoce los contenidos y
        servicios de los Sitios Enlazados y, por tanto, no se hace responsable por los daños que su ilicitud, calidad,
        desactualización, indisponibilidad, error e inutilidad puedan producir al Usuario o a cualquier tercero.</p>
    <p> No obstante, Citylok realizará sus mayores esfuerzos para prevenir estas circunstancias, así como para suprimir
        la inclusión de un Sitio Enlazado tan pronto tenga conocimiento efectivo de las mismas. En el supuesto de que el
        Usuario considere que existe un Sitio Enlazado con contenidos ilícitos o inadecuados, podrá comunicarlo a
        Citylok sin que en ningún caso esta comunicación conlleve para Citylok la obligación de retirar el
        correspondiente enlace, cuestión que queda a su discreción.
    </p>

    <p class="sub-title-terms">6. Propiedad intelectual e industrial</p>
    <p>Todos los contenidos del Sitio Web y/o la APP, entendiendo por estos a título meramente enunciativo los textos,
        fotografías, gráficos, imágenes, iconos, tecnología, software, links y demás contenidos audiovisuales o sonoros,
        así como su diseño gráfico y códigos fuente, son propiedad intelectual de Citylok o de terceros, sin que puedan
        entenderse cedidos al Usuario ninguno de los derechos de explotación reconocidos por la normativa vigente en
        materia de propiedad intelectual sobre los mismos.</p>
    <p> Las marcas, nombres comerciales o signos distintivos publicados en el Sitio Web y/o la APP son titularidad de
        Citylok o terceros, sin que pueda entenderse cedido al Usuario ningún derecho sobre los mismos.</p>
    <p> Citylok no representa a los titulares de las marcas de los productos que comercializa.</p>

    <p class="sub-title-terms">7. Condiciones de contratación</p>
    <p class="sub-title-terms">7.1. Condiciones de contratación</p>
    <p>Las características principales y condiciones específicas de asistencia a los espectáculos y/o eventos ofrecidos
        por Citylok pueden consultarse a través del Sitio Web en la propia ficha descriptiva con la que se presentan,
        así como a través de la APP. </p>

    <p class="sub-title-terms">7.2. Contrato. Orden de prelación.</p>
    <p>La relación contractual entre el Usuario y Citylok se rige por las presentes Condiciones Generales, así como por
        cualquier clase de términos y condiciones especiales que puedan publicarse en el Sitio Web y/o la APP y
        especificarse en el email de confirmación de compra, de las cuales los Usuarios serán informados con
        anterioridad a la compra de las entradas.</p>
    <p>En caso de conflicto entre los citados documentos, la discrepancia será resuelta, cuando sea necesario, de
        acuerdo con el siguiente orden de prelación: (1) los términos y condiciones especiales que puedan publicarse en
        el Sitio Web, la APP o el email de confirmación de compra, (2) las presentes Condiciones Generales (en adelante
        conjuntamente, el “Contrato”). Lo anterior, salvo que las Condiciones Generales resulten más beneficiosas para
        el adherente que los términos y condiciones especiales.</p>
    <p>El Usuario podrá formalizar el Contrato en el español (castellano), así como en otras lenguas cuando dicha
        opción esté disponible. En caso de discrepancias entre las versiones del Contrato en español y otras versiones
        en distinta lengua, prevalecerá la versión en español.
    </p>

    <p class="sub-title-terms">7.3. Acceso a la Compra y Registro</p>
    <p>Para la adquisición de entradas a través del Sitio Web será necesario estar registrado.</p>

    <p><strong>Acceso como nuevo Usuario:</strong></p>
    <ol type="a">
        <li>El Usuario deberá rellenar el formulario, cumplimentando, al menos, los datos marcados como obligatorios, y
            aceptar expresamente la Política de privacidad y consentimiento de cookies. Todos los datos introducidos por
            el Usuario deberán ser exactos, actuales y veraces.</li>
        <li>El Usuario recibirá en la dirección de correo electrónico facilitada un e-mail de confirmación de alta.</li>
        <li>El Usuario registrado será responsable en todo momento de la custodia de su contraseña, asumiendo en
            consecuencia cualesquiera daños y perjuicios que pudieran derivarse de su uso indebido, así como de la
            cesión, revelación o extravío de la misma. A estos efectos, el acceso a áreas restringidas y/o el uso de los
            servicios y contenidos del Sitio Web y/o la APP realizado bajo la contraseña de un Usuario registrado se
            reputarán realizados por dicho Usuario registrado, quien responderá en todo caso de dicho acceso y uso.</li>
        <li>El Usuario podrá modificar y/o actualizar en cualquier momento los datos facilitados durante el proceso de
            registro. Una vez que el Usuario ha accedido a su cuenta en su perfil de usuario del Sitio Web y/o la APP,
            podrá consultar el historial y/o el estado de las entradas adquiridas, modificar sus datos personales de
            registro, así como su contraseña de acceso y contactar con el servicio de atención al cliente de Citylok,
            entre otras acciones.</li>
    </ol>
    <p><strong>Acceso como Usuario Registrado: </strong>Si el Usuario ya es cliente registrado en Citylok deberá
        identificarse mediante su correo electrónico y contraseña para realizar compras de entradas.</p>
    <p><strong>Acceso mediante Registro Rápido: </strong>Existe la posibilidad de registro rápido sin proporcionar
        contraseña, que permite al usuario realizar ágilmente su primera compra de entradas. En este caso, el Usuario
        también deberá proporcionar los datos personales que sean obligatorios y aceptar expresamente la Política de
        Privacidad y consentimiento de Cookies de Citylok. Una vez registrado, recibirá en la dirección de correo
        electrónico facilitada un e-mail de confirmación de alta y, a continuación, otro e-mail con la confirmación de
        la compra y el enlace para la descarga de sus entradas. En el caso de que el Usuario que haya adquirido sus
        entradas mediante Registro Rápido quiera acceder nuevamente a las mismas desde el sitio web de Citylok o
        efectuar nuevas compras, se le requerirá la generación de una contraseña que, a partir de ese momento, quedará
        asociada a su registro funcionando como un Usuario Registrado a todos los efectos indicados anteriormente.</p>

    <p class="sub-title-terms">7.4. Procedimiento de compra de entradas</p>
    <p>En el proceso de compra de entradas a través de nuestro servicio telefónico, un agente le guiará a través de todo
        el proceso para la compra de entradas. Para garantizar la calidad del servicio su llamada podrá ser grabada.</p>
    <p>En el caso de compra de más de diez (10) entradas, para aquellos eventos para los que se encuentre disponible la
        opción de venta a grupos, la venta se realizará a través del servicio de venta telefónica de entradas, llamando
        al número de contacto en el horario indicado en el apartado 4 de estas Condiciones Generales (con el posible
        coste asociado), o bien enviando un e-mail a la dirección de contacto proporcionada.</p>
    <p>El procedimiento para comprar entradas a través del Sitio Web y/o la APP (en relación con los eventos que estén
        disponibles para la venta en este canal) será el descrito a continuación, en función del tipo de entradas que
        quiera adquirir el Usuario (entradas de cine; fútbol, baloncesto, Tours; resto de eventos).</p>
    <p>A efectos de evitar prácticas injustas o no autorizadas en la compra de entradas por cada espectáculo, pueden
        limitarse las entradas a un número máximo por persona, por tarjeta de crédito y otras limitaciones que se
        comunicarán a los usuarios en el momento de la reserva, durante el proceso de compra, y se verificarán en cada
        operación, en aquellos eventos en que resulte de aplicación. En este sentido y cuando resulte de aplicación,
        Citylok se reserva el derecho de anular sin previo aviso las entradas adquiridas que excedan de este número o
        incumplan las limitaciones establecidas.</p>
    <p>Prohibida la reventa. La reventa de entradas no autorizada, así como cualquier acto encaminado a la reventa
        ilegal, podrá ser motivo de incautación o cancelación de las mismas sin reembolso ni cualquier otro tipo de
        compensación.
    </p>

    <p class="sub-title-terms">7.5. Confirmación de compra y recogida de entradas</p>
    <p>Si el proceso de compra se completa correctamente, la compra será confirmada en pantalla, facilitando la
        descargar de la misma.</p>
    <p>El número de ticket identifica de manera única la compra efectuada por el Usuario y es la forma de que el
        personal de Citylok identifique su compra en caso de que quisiera hacernos alguna consulta.</p>
    <p>Adicionalmente, el Usuario recibirá, en la dirección de correo electrónico facilitada, un email de confirmación
        de compra. Salvo en el que se indique lo contrario expresamente en dicho email o en las presentes Condiciones
        Generales, el email de confirmación de compra no podrá ser utilizado como entrada para acceder al evento. Las
        entradas podrán ser descargadas desde la pantalla de confirmación, desde el email de confirmación de compra o
        desde el apartado ‘Mis compras’ dentro del perfil.</p>
    <p>Asimismo, para cada evento durante el proceso de compra, se indicarán las opciones disponibles para la recogida
        de las entradas adquiridas. Dichas opciones podrán ser una o varias de entre las siguientes:
    </p>
    <ul>
        <li><strong>Recogida de las entradas directamente en las taquillas del lugar en el que se celebre el evento o
                espectáculo: </strong>En este caso, y de manera imprescindible, el Usuario deberá presentar la
            información enviada en el email de confirmación de compra (localizador o número de pedido). La recogida de
            las entradas en taquilla podrá realizarse hasta el mismo día de la representación del espectáculo o evento y
            siempre en horario de apertura.</li>
        <li><strong>Por descarga de la Entrada Digital en tu dispositivo móvil, seleccionando esta forma de entrega
                durante el proceso de compra: </strong>Para presentarla en el momento de asistir al evento, el Usuario
            debe tener un dispositivo IOS o Android y conexión a Internet. El Usuario también puede guardarla en
            Passbook y Apple Wallet para poder acceder a ella cuando no haya conexión a Internet.</li>
        <li><strong>Por impresión directa de entradas: </strong>El Usuario que adquiera entradas podrá imprimir
            directamente las entradas en su propia casa en formato PDF (Entrada digital imprimible).</li>
        <li><strong>Por descarga en el Wallet de tu smartphone: </strong>Puedes descargar las entradas tanto en formato
            Entrada digital como Entrada digital imprimible en el Wallet de tu teléfono móvil desde la pantalla de
            confirmación de compra o desde el email de confirmación de compra.</li>
    </ul>

    <p class="sub-title-terms">7.6. Procedimiento de Pago</p>
    <p>Con carácter general, el Usuario podrá utilizar como medio de pago las tarjetas Visa y Mastercard, así como las
        plataformas de pago electrónico disponibles. Asimismo, podrá pagar todo o parte del precio de su compra con una
        tarjeta regalo válida o código promocional válido emitido por Citylok o por el organizador del evento. Las
        tarjetas regalo, vouchers o códigos promocionales pueden regirse por condiciones específicas de las cuales el
        Usuario será informado en cada promoción.</p>
    <p>En todo caso, durante el proceso de compra el Usuario será informado de las concretas modalidades de pago
        disponibles para el evento de que se trate. El Usuario deberá indicar los datos requeridos y seleccionar alguna
        de esas modalidades de pago disponibles.</p>
    <p>El sistema de compra de entradas utiliza un servidor seguro y la última tecnología de encriptación. Las compras
        pueden estar sujetas a las comprobaciones de seguridad. La operación podrá anularse por Citylok si no supera el
        proceso de verificación.
    </p>

    <p class="sub-title-terms">7.7. Precio y gastos adicionales</p>
    <p>El precio de cada entrada es el determinado por cada organizador del evento. Los precios de las entradas que se
        indican durante el proceso de compra incluyen, salvo indicación expresa en contra, el Impuesto sobre el Valor
        Añadido (IVA), los impuestos directos aplicables y, en todo caso, se expresan en la moneda Euro (€).</p>
    <p>Dichos precios, salvo que se indique expresamente lo contrario, no incluyen los gastos de gestión por la venta
        de las entradas, la cuota de servicio, los gastos de envío, en caso de enviar las entradas por correo postal al
        Usuario, el seguro de anulación de entradas o cualesquiera otros servicios adicionales y anexos a las entradas,
        los cuales serán indicados expresamente al Usuario como un gasto adicional al precio de la entrada durante el
        proceso de compra y en todo caso antes de que finalice el mismo.
    </p>
    <p>Los gastos de gestión son un suplemento o porcentaje que se añade al precio base de la entrada para hacer posible
        la programación de los eventos, el proceso técnico de venta y la generación de la entrada. Los criterios para
        calcular y facturar dicho porcentaje varían según las características del evento, las condiciones del
        organizador y acuerdo suscrito por este con Citylok.</p>
    <p>En la medida en que los gastos de gestión se configuran como un concepto ajeno tanto al precio base de la
        entrada (fijado por el Organizador), como al desarrollo de los propios eventos y sirven para sufragar servicios
        que se prestan igualmente al Usuario con independencia de cualquier incidencia que afecte al desarrollo del
        evento, Citylok o, en su caso, el Organizador, no estarán obligados a su reembolso en caso de cancelación o
        modificación del mismo.</p>
    <p>La cuota de servicio es una tarifa que se aplica a cada operación de compra de entradas para sufragar los
        servicios que presta el canal Citylok. Esta tarifa contribuye a cubrir los costes técnicos y de mantenimiento
        del sitio web con un servicio 24 horas al día, 7 días a la semana, comisiones bancarias y seguridad de pago,
        atención al cliente y todo lo relacionado con el procesamiento de tu transacción, así como nuevas
        funcionalidades para ofrecerte la mejor experiencia de reserva posible.</p>
    <p>En la medida en que la cuota de servicio se configura igualmente como una tasa independiente tanto del precio
        base de las entradas (fijado por el Organizador) como del desarrollo de los eventos, y sirve para sufragar
        servicios que Citylok presta en todo caso al Usuario, con independencia de la celebración de los mismos, en caso
        de cancelación o modificación de los eventos, Citylok no estará obligada a su reembolso.
    </p>

    <p class="sub-title-terms">8. Derecho de desistimiento en la compra de entradas</p>
    <p>El Usuario debe asegurarse, antes de tramitar la compra, de la exactitud y adecuación de los datos introducidos,
        ya que no es posible la devolución de las entradas adquiridas una vez realizada la compra. No poder asistir al
        espectáculo o cometer un error al adquirir las entradas no son motivos que permitan su devolución.</p>
    <p>Sólo podrán anularse entradas por posibles incidencias técnicas u operativas, imputables a Citylok.</p>
    <p>En este sentido, el Usuario no podrá ejercitar el derecho de desistimiento o resolución previsto en la normativa
        de consumidores y usuarios al estar dicho derecho excluido para los servicios relacionados con actividades de
        ocio o esparcimiento, de acuerdo con lo establecido en el artículo 103.I) del texto refundido de la Ley General
        para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, aprobado por el Real Decreto
        Legislativo 1/2007, de 16 de noviembre.
    </p>

    <p class="sub-title-terms">9. Cancelación de eventos</p>
    <p>Citylok es un mero intermediario en la venta de entradas de eventos organizados por las empresas organizadoras o
        promotoras del evento, no participa en la organización de dichos eventos y, por tanto, no tiene control sobre la
        suspensión, cancelación y/o aplazamiento de los mismos (la “cancelación”), cuya responsabilidad recae
        directamente en el organizador o promotor.</p>
    <p>Una vez reciba las indicaciones correspondientes del organizador, Citylok comunicará a los Usuarios la
        cancelación del evento, indicando el procedimiento establecido por el promotor. Dicha comunicación se realizará
        normalmente por correo electrónico a la dirección de correo de los compradores o bien mediante publicación en
        este sitio web o por cualquier otro medio de comunicación pública en caso de que las circunstancias concretas de
        cada caso así lo aconsejen. Citylok no es responsable de las políticas de devolución, cancelación, reubicación,
        comunicados o procedimientos diseñados y establecidos por el organizador, ni de las propuestas alternativas al
        reembolso que pudiera emitir el organizador, incluso aunque los mismos hubieran sido comunicados por Citylok.
    </p>
    <p>Citylok se reserva el derecho a devolver los gastos de gestión al Usuario en caso de cancelación del evento.</p>
    <p>En la medida en que los gastos de gestión se configuran como un concepto ajeno al precio de las entradas cobrado
        por el Promotor y a la organización del evento, en caso de cancelación o modificación del evento Citylok no le
        reembolsará los gastos de gestión. Los gastos de gestión no son reembolsables, ya que el servicio de Citylok se
        considera prestado en su totalidad una vez que la compra está confirmada.
    </p>

    <p class="sub-title-terms">10. Política de Devolución de Entradas</p>
    <p class="sub-title-terms">10.1. No se admitirán devoluciones ni cambios de entradas</p>
    <p>Una vez confirmada la compra, no se permitirá el cambio o devolución de entradas, salvo en los casos de
        cancelación del evento por parte del organizador. Esto significa que no se realizarán devoluciones bajo ninguna
        circunstancia, incluyendo pero no limitándose a:</p>
    <ul>
        <li>No poder asistir al evento por cualquier motivo personal.</li>
        <li>Cambios en los planes o en las circunstancias personales.</li>
        <li>Errores cometidos al comprar las entradas (por ejemplo, elección de la fecha equivocada, o número de
            entradas incorrecto).</li>
    </ul>

    <p class="sub-title-terms">10.2. Devolución en caso de cancelación del evento</p>
    <p>Si un evento es cancelado por el organizador, Citylok procederá a la devolución del importe de las entradas
        adquiridas, excluyendo los gastos de gestión, cuota de servicio y cualquier otro gasto adicional no
        reembolsable.</p>
    <p>En caso de que el evento sea reprogramado para una nueva fecha, las entradas adquiridas serán válidas para la
        nueva fecha, y no se realizarán devoluciones salvo que el Usuario notifique expresamente su deseo de solicitar
        el reembolso antes de la nueva fecha programada, y según lo permita el organizador del evento.</p>

    <p class="sub-title-terms">10.3. Procedimiento de devolución</p>
    <p>En caso de cancelación del evento, el Usuario será notificado por correo electrónico y se le informará del
        procedimiento para solicitar el reembolso, en caso de que sea aplicable. El reembolso se realizará mediante el
        mismo método de pago utilizado en la compra de las entradas.</p>

    <p class="sub-title-terms">11. Quejas, reclamaciones y solicitudes de información</p>
    <p>El Usuario puede dirigir sus quejas, reclamaciones o solicitudes de información al Servicio de Atención al
        Cliente de Citylok, utilizando para ello cualquiera de las siguientes vías:</p>
    <ul>
        <li>Enviando un escrito al Servicio de Atención al Cliente de Citylok.</li>
        <li>Enviando su solicitud a través del formulario de ayuda.</li>
        <li>Consultando las preguntas más frecuentes en la sección de Atención al Cliente en el sitio web de Citylok.
        </li>
    </ul>
    <p>Citylok dará respuesta a las reclamaciones recibidas en el plazo más breve posible y en todo caso en el plazo
        máximo de un mes desde la presentación de la reclamación.</p>
    <p>Citylok dispone de hojas oficiales de reclamación, que el Usuario puede solicitar al Servicio de Atención al
        Cliente de Citylok.</p>

    <p class="sub-title-terms">12. Nulidad e ineficacia de las cláusulas</p>
    <p>Si cualquier cláusula incluida en las presentes Condiciones Generales fuese declarada total o parcialmente, nula
        o ineficaz, tal nulidad o ineficacia tan sólo afectará a dicha disposición o a la parte de la misma que resulte
        nula o ineficaz, subsistiendo las presentes Condiciones Generales en todo lo demás, considerándose tal
        disposición total o parcialmente por no incluida.</p>


    <p class="sub-title-terms">13. Modificación de las Condiciones Generales y otros contenidos</p>
    <p>Las Condiciones Generales estarán a disposición del Usuario en el Sitio Web y/o la APP.</p>
    <p>Citylok se reserva el derecho de modificar en cualquier momento las presentes Condiciones Generales,
        notificándolo a los Usuarios mediante publicación de las Condiciones Generales actualizadas/modificadas en el
        Sitio Web y/o la APP.</p>
    <p>Asimismo, se reserva el derecho a modificar en cualquier momento la presentación, configuración y localización
        del Sitio Web, así como los contenidos y las condiciones requeridas para utilizar los mismos.
    </p>

    <p class="sub-title-terms">14. Prevalencia de la versión en lengua española</p>
    <p>En caso de discrepancias entre las Condiciones Generales y la versión inglesa de las mismas u otras versiones en
        distinta lengua, prevalecerá la versión en español.</p>

    <p class="sub-title-terms">15. Legislación aplicable</p>
    <p>Estas Condiciones Generales se regirán e interpretarán conforme a la legislación española. Entre otras, por el
        Real Decreto Legislativo 1/2007 de la Ley General Defensa de Consumidores y Usuarios, y la Ley 34/2002 de
        servicios de la sociedad de la información y de comercio electrónico.</p>

    <p class="sub-title-terms">16. Comunicaciones relacionadas con la compra</p>
    <p>Al realizar una compra a través de Citylok, el usuario acepta recibir comunicaciones electrónicas relacionadas
        con el estado de los eventos, actualizaciones de sus pedidos, y cualquier información relevante que pueda
        afectar a su compra. Esto incluye, pero no se limita a, confirmaciones de compra, cambios en el evento,
        cancelaciones, o cualquier otra notificación que sea necesaria para garantizar una correcta gestión de la
        transacción.</p>
</div>