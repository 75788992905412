<div class="container page-event d-row">
  <div *ngIf="!event || loading; else divEvent" class="container-lottie">
    <div class="loading-container">
      <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
        containerClass="loading-box"></ng-lottie>
    </div>
  </div>
  <ng-template #divEvent>
    <div class="header-float-event"></div>
    <div class="header-background-event" style="background-image: url({{imageUrl}});"></div>
    <div class="container container-detail-event row justify-content-between">
      <div class="col-xxl-9 col-xl-8 col-12">
        <div class="header-event row">
          <div class="col col-img">
            <!-- <img fallimg class="img-event"
              src="{{ event.external && event.usuario.id === 1 ? event.imageurl : urlImages }}" /> -->
            <img (error)="imageUrl = '../../../../assets/img/image_default.png'" class="img-event" [src]="imageUrl" />
          </div>
          <div class="col primary-info-event">
            <div class="time-edit-event d-flex justify-content-between">
              <!-- <p class="time-text m-0">31ºC <i class="fa-light fa-cloud-sun"></i></p> -->
              <!-- Pendiente de que el usuario sea el creador -->
              <i *ngIf="false" class="fa-light background-primary-hover pointer-in fa-ellipsis-vertical fa-2x"></i>
            </div>
            <div class="tag-event">
              <div [class.tag-fire]="event.numvaloraciones > 20 || event.mediavaloraciones > 4"
                [class.tag-new]="event.new" [class.tag-today]="datesEquals(event)">
                <i class="fal {{ tagIcon(event) }}"></i> {{ tagString(event) }}
              </div>
            </div>
            <div *ngIf="!event.activo" class="d-flex align-items-center"
              style="gap: 10px; color: var(--color-primary);">
              <i class="fa-light fa-circle-exclamation fa-lg"></i>
              <p class="m-0">Evento desactivado</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="m-0 title-event">{{ event.titulo }}</p>
              <span *ngIf="adminCitylok || isLite" class="content-icon" style="color: white;" [matMenuTriggerFor]="edit">
                <i class="fa-light fa-ellipsis-vertical fa-2x" style="justify-self: flex-end;"></i>
              </span>
              <mat-menu #edit="matMenu" xPosition="before" panelClass="menuContentCustom">
                <button mat-menu-item [routerLink]="['/event/edit/'+slug]" *ngIf="adminCitylok || isLite">Editar</button>
                <button mat-menu-item (click)="editActiveEvent()" *ngIf="adminCitylok">{{ event.activo ? 'Desactivar' :
                  'Volver a activar' }}</button>
              </mat-menu>
            </div>
            <div class="info-event d-flex align-items-center">
              <i class="fa-light fa-calendar d-none d-lg-block"></i><span class="d-none d-lg-block">{{ getDateString()
                }}</span>
              <!-- <span class="separator d-none d-lg-block">|</span>
            <i class="fa-solid fa-eye"></i><span>185</span>
            <span class="separator">|</span>
            <i class="fa-solid fa-heart"></i><span>57</span> -->
            </div>
            <div>
              <div class="info-author d-flex" *ngIf="owner?.id != 1 && createdBy">
                <span class="span-author">Creado por: </span>
                <span class="span-author">{{
                  createdBy?.name }}</span>
                <div class="container-verify-user" [routerLink]="['/events', (createdBy?.slug)]">
                  <div class="verify-user d-flex align-items-center justify-content-center pointer-in">
                    <i class="fa-light fa-circle-check"></i>
                    <span class="text-verify">Creador verificado</span>
                  </div>
                </div>
              </div>
              <div class="info-author d-flex" *ngIf="event.place">
                <span class="span-author">Espacio: </span>
                <span class="title-author pointer-in" [routerLink]="['/venue',(event.place?.slug)]">{{
                  event.place?.name }}</span>
              </div>
              <!-- Members -->
              <div *ngIf="event.place?.allowSubscription && !isMember">
                <div class="text-subscriber">
                  <span>¿Quieres ser miembro de <span class="text-name-subscriptors">{{ event.place?.membershipTitle ?
                      event.place?.membershipTitle : event.place?.name }}</span>?</span>
                  <div class="d-flex align-items-end link-subscriber">
                    <span (click)="openNewSubscriber()">Pincha aquí</span>
                    <i class="fas fa-arrow-pointer" (click)="openNewSubscriber()"></i>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="isMember">
              <div class="text-subscriber">
                <span>Eres miembro de <span class="text-name-subscriptors">{{ event.place?.membershipTitle ?
                    event.place?.membershipTitle : event.place?.name }}</span></span>
              </div>
            </div>
            <div class="circles-event" *ngIf="event.activo">
              <!-- <div class="circle-option d-flex justify-content-center align-items-center pointer-in"
              (click)="goToScrollPage('comments')"><i [matBadgeHidden]="totalItems == 0" matBadge="{{ totalItems }}"
                matBadgeColor="primary" class="fa-light fa-comment"></i></div> -->
              <div *ngIf="(profile|async)"
                class="circle-option d-flex justify-content-center align-items-center pointer-in"
                (click)="editFavorito()">
                <i class="fa-heart" [class]="isWishlist ? 'fa-solid icon-primary' : 'fa-light'"></i>
              </div>
              <div *ngIf="sharedSupported"
                class="circle-option d-grid justify-content-center align-items-center pointer-in"
                (click)="shareEvent()">
                <i class="fa-light fa-share-nodes"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-3 col-xl-4 col-12 d-flex justify-content-end" *ngIf="event.activo && !event.hiddenEvent">
        <div class="float-container container-external" *ngIf="event.onlyTpvSales || !openExternal; else divHoursDates">
          <app-open-external [event]="event" [pastEvent]="pastEvent" [repeat]="repeatEvent"
            (openHours)="openHoursEvent($event)"></app-open-external>
        </div>
        <ng-template #divHoursDates>
          <div class="float-container float-container-hours">
            <div class="container-float">
              <app-hours-dates-event [pastEvent]="pastEvent" [event]="event" [organizer]="organizer"
                (closeHours)="openHoursEvent($event)"></app-hours-dates-event>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="col-xxl-8 col-xl-8 col-12">
        <div class="content-event description-section">
          <h3 class="title-description">Descripción del evento</h3>
          <p style="text-align: justify;" [innerHTML]="event.descripcion"></p>
          <div class="d-flex extra-link-event" *ngIf="event.extraLink">
            <span><i class="fa-light icon-float fa-link fa-md"></i></span>
            <a href="{{event?.extraLink}}" class="external-link" target="_blank">{{event?.extraLink}}</a>
          </div>
          <div class="extra-file-event" *ngIf="event?.extraFile">
            <a [href]="urlFiles+event.id+'/'+event.extraFile" target="_blank">Información adicional</a>
          </div>
        </div>
        <!-- Swiper Images -->
        <div class="content-swiper-img" *ngIf="event.imagenes.length > 0">
          <app-swiper-images [images]="imagesSwiper"></app-swiper-images>
        </div>
        <!-- Map -->
        <div id="map" class="map-section">
          <div class="where">
            <i class="fa-light icon-map fa-map-marker-alt"></i> <span
              style="color: #FF4D80;font-family: 'ClashDisplay-Medium';"> ¿Dónde?</span>
            <p style="font-family: 'Satoshi-Bold'">{{event?.localizacion}}</p>
          </div>
          <div id="maps"></div>
        </div>
        <!-- Comments -->
        <!-- <div id="comments">
        <app-comments [idEvent]="event?.id"></app-comments>
      </div> -->
        <!-- Hashtags -->
        <div *ngIf="hashtags?.length > 0">
          <app-etiquetas *ngIf="external === true" [items]="hashtags"></app-etiquetas>
          <app-etiquetas *ngIf="external === false" [items]="event?.hashtags"></app-etiquetas>
        </div>
        <!-- Shared -->
        <div class="content-shared d-grid d-sm-flex align-items-center justify-content-sm-start justify-content-center"
          *ngIf="event.activo">
          <p class="title-section m-0"><i class="fa-light fa-share-nodes icon-share-small"></i>¡Comparte este evento!
          </p>
          <div class="d-flex icons-share-event justify-content-sm-start justify-content-around">
            <i (click)="shareSocialEvent('twitter')" class="fa-brands icon-primary fa-x-twitter"></i>
            <i (click)="shareSocialEvent('linkedin')" class="fa-brands icon-primary fa-linkedin-in"></i>
            <i (click)="shareSocialEvent('facebook')" class="fa-brands icon-primary fa-facebook-f"></i>
            <i (click)="shareSocialEvent('envelope')" class="fa-solid icon-primary fa-envelope"></i>
            <i *ngIf="sharedSupported" (click)="shareSocialEvent('default')"
              class="fa-solid icon-primary fa-share-nodes"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<!-- <div class="default-cards cards-relations">
  <app-cards title="Eventos relacionados" [arrow]="false" [events]="eventosRelacionados"></app-cards>
</div> -->