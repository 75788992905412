import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventosService } from 'src/app/services/eventos.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { TicketsService } from 'src/app/services/tickets.service';
import Swiper, { SwiperOptions } from 'swiper';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { LoginComponent } from '../../shared/login/login.component';
import { SeatsPanelComponent } from '../seats-panel/seats-panel.component';

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-hours-dates-event',
  templateUrl: './hours-dates-event.component.html',
  styleUrls: ['./hours-dates-event.component.scss']
})
export class HoursDatesEventComponent implements OnInit {

  optionsDates: any[] = [];
  optionsHours: any[] = [];
  optionsTickets: any[] = [];
  enabledTickets: any[] = [];
  selectTickets: any[] = [];

  selectedDate!: NgbDateStruct;
  numDaySelect!: number;
  dateDaySelect!: number;
  hourSelected!: any;
  priceTotal: number = 0.0;

  // Calendar
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  currentMonth!: any;
  currentYear!: any;
  dateString: any;
  hoveredDate!: any;

  fromDate!: any;
  monthsOptions: any[] = [];
  swiperMonthsConfig: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 12,
    navigation: {
      nextEl: '.button-next-custom',
      prevEl: '.button-prev-custom',
      enabled: true
    }
  };
  @Optional() @Input() event!: any;
  @Output() closeHours: EventEmitter<boolean> = new EventEmitter();
  fechasEvent!: any;
  now = new Date();
  buysBuySelect: any[] = [];

  enableAbono: boolean = false;
  @Input() pastEvent: boolean = false;
  @Input() organizer: boolean = false;
  openingSeats: boolean = false;

  countBoughtTickets: number = 0;
  bondEnabled: boolean = false;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private ticketsService: TicketsService,
    @Optional() private dialogRef: MatDialogRef<HoursDatesEventComponent>, private dialog: MatDialog, private auth: AuthenticationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data, private router: Router, private shoppingCartService: ShoppingCartService,
    private eventosService: EventosService) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.event = this.data.event;
      this.organizer = this.data.organizer;
    }
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);

    this.event.fechas = this.event.fechas.filter((fecha: any) => fecha.activo && (new Date(fecha.fechaInicio) >= now || (fecha.fechaFin && new Date(fecha.fechaFin) >= now)));
    this.fechasEvent = this.event.fechas.sort((a, b) => new Date(a.fechaInicio).getTime() - new Date(b.fechaInicio).getTime());
    this.optionsDates = this.fechasEvent.map((fecha: any) => { return new Date(fecha.fechaInicio).toDateString() });
    let first = this.fechasEvent[0];
    let last = this.fechasEvent[this.fechasEvent.length - 1];
    const date = new Date(first.fechaInicio);
    const start = date < this.now ? this.now : date;
    const end = first.fechaFin ? new Date(first.fechaFin) : new Date(last.fechaInicio);
    this.currentMonth = start.getMonth() + 1;
    this.currentYear = start.getFullYear();
    this.minDate = new NgbDate(start.getFullYear(), start.getMonth() + 1, start.getDate());
    this.maxDate = new NgbDate(end.getFullYear(), end.getMonth() + 1, end.getDate())
    this.getMonths();

    if (!this.event.informativo || !this.event.external) {
      this.optionsHours = this.event.horarios.filter(hor => hor.activo == true);
      this.optionsHours = this.optionsHours.sort((a: any, b: any) => {
        return new Date(a.horaInicio).getTime() - new Date(b.horaInicio).getTime();
      })
    }
    if (this.optionsDates.length > 0) {
      this.dateString = this.optionsDates[0];
      this.fromDate = this.formatter.parse(this.optionsDates[0]);
    }
    this.optionsTickets = this.event.entradas.filter(ticket => ticket.activo == true);
    let todayNgb = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
    let initNgb = new NgbDate(start.getFullYear(), start.getMonth() + 1, start.getDate())
    this.changeDateSelected(initNgb, true);
    this.selectedDate = initNgb;
    this.changeHour(this.optionsHours[0]);
  }

  getMonths() {
    let month: number = this.currentMonth;
    const day = this.calendar.getToday();
    day.day = 1;
    month = day.month
    let year: number = this.currentYear;
    for (let index = 0; index < 12; index++) {
      if ((day.month < this.maxDate.month || day.month == this.maxDate.month) && day.month >= this.minDate.month && (day.year <= this.maxDate.year)) {
        this.monthsOptions.push({ value: { month: day.month, year: day.year }, string: this.formatStringDate(day) });
      }
      if (month == 12) {
        month = 1;
        day.year = year + 1;
      } else {
        month = month + 1;
      }
      day.month = month;
    }
  }

  // Calendar
  isHovered(date: NgbDate) {
    return this.hoveredDate && date.before(this.hoveredDate);
  }
  isWeekend = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;

  changeDateSelected(date: NgbDate, selected?: boolean) {
    if (!this.dayDisabled(date)) {
      this.dateString = this.formatter.format(date);
      this.selectedDate = date;
      this.numDaySelect = new Date(this.dateString).getDay();
      this.dateDaySelect = new Date(this.dateString).getDate();
      if (this.optionsTickets.length > 1) {
        if(this.optionsHours?.length > 1) {
          this.hourSelected = null;
        }
        this.getTicketsEnabled();
      }
      // if(selected) {
      //   this.selectedDate = date;
      // }
    }
  }

  formatStringDate(date: any) {
    let dateFormat = null;
    if (date) {
      dateFormat = formatDate(new Date(this.formatter.format(date)), 'MMM YYYY', 'es');
    }
    return dateFormat;
  }

  goToMonth(dp: any, date: any) {
    dp.navigateTo(date);
    this.currentMonth = date.month;
    this.currentYear = date.year;
  }

  dayDisabled(date: NgbDateStruct) {
    let stringDate = this.formatter.format(date);
    const dateNew = new Date(stringDate);
    return (before(date, this.minDate) ||
      (this.optionsDates.length > 1 && !this.optionsDates.includes(dateNew.toDateString()))
      || after(date, this.maxDate));
  }

  isActive(date: NgbDateStruct, dateNew: Date) {
    if (this.fechasEvent.typeRepeat == 'week' && this.fechasEvent.repeatInterval.includes(dateNew.getDay())) {
      return true;
    }
    if (this.fechasEvent.typeRepeat == 'month' && this.fechasEvent.repeatInterval.includes(date.day)) {
      return true;
    }
    if (this.fechasEvent.typeRepeat == 'year' && date.day == this.minDate.day && date.month == this.minDate.month) {
      return true;
    }
    return false;
  }

  // Horarios
  changeHour(hour: any) {
    !this.selectedDate ? this.openInfoCard('Primero debes seleccionar un día en el calendario') : this.hourSelected = hour;
    if (this.selectedDate) {
      this.getTicketsEnabled();
    }
  }

  openSelect(fromSelect: boolean = false) {
    if (!this.selectedDate) {
      this.openInfoCard('Por favor, selecciona un día en el calendario')
    } else if (this.selectedDate && (this.optionsHours.length > 0 && !this.hourSelected)) {
      this.openInfoCard('Por favor, selecciona un horario')
    } else if (!fromSelect && this.optionsTickets.length > 0 && this.selectTickets.length <= 0) {
      this.openInfoCard('Por favor, selecciona una entrada')
    }
  }

  getTicketsEnabled() {
    this.enabledTickets = [];
    this.selectTickets = [];
    if (this.selectedDate && (this.optionsHours.length > 0 && this.hourSelected || this.optionsHours.length <= 0)) {
      let stringDate = this.formatter.format(this.selectedDate);
      const date = new Date(stringDate);
      this.enabledTickets = this.optionsTickets.filter(element => {
        const special = element.ticketSpecial?.find(val => {
          // Comprobar horario seleccionado
          let dateHour = new Date();
          let hour = new Date(val.hour);
          dateHour.setHours(hour.getUTCHours());
          dateHour.setMinutes(hour.getUTCMinutes());

          let hourInit = new Date();
          let init = new Date(this.hourSelected?.horaInicio);

          hourInit.setHours(init.getUTCHours());
          hourInit.setMinutes(init.getUTCMinutes());
          // Comprobar día seleccionado
          return val && (this.optionsHours.length <= 0 || formatDate(dateHour, 'HH:mm', 'es') == formatDate(hourInit, 'HH:mm', 'es') && formatDate(date, 'yyyy/MM/dd', 'es','+0000') == formatDate(val.day, 'yyyy/MM/dd', 'es','+0000'))
        })
        // let daysMonth = [];
        // if (!this.fechasEvent.repeat && !this.hourSelected.fixedSchedule) {
        //   daysMonth = special?.days.map(val => new Date(val).getDate());
        // }
        return (element.ticketSpecial.length == 0 ||
          (element.specialTime == true && special
            // && (this.fechasEvent.typeRepeat == 'month' && special.days.includes(date.getDate()) ||
            //   (this.fechasEvent.typeRepeat == 'week' && special.days.includes(date.getDay())) ||
            //   (!this.fechasEvent.repeat && this.hourSelected.fixedSchedule && special.days.includes(date.getDay())) ||
            //   (!this.fechasEvent.repeat && !this.hourSelected.fixedSchedule && daysMonth.includes(date.getDate()))
            // )
            ))
      });
    }
  }

  // Ticket option
  addTicketOption(option: any) {
    const index = this.selectTickets.findIndex(value => value.ticket.id == option.id);
    if (index == -1) {
      let price = option.priceTickets[0].price;
      option.price = price;
      this.priceTotal += price;
      this.selectTickets.push({ ticket: option, number: 1 });
    }
  }

  changeTypeTicket(option: any, typeAction: any) {
    const index = this.selectTickets.findIndex(value => value.ticket.id == option.ticket.id);
    if (index != -1) {
      const element = this.selectTickets[index];
      const ticketsMax = element.ticket.ticketsMax || this.event.maxTickets;
      let price = element.ticket.priceTickets[0].price;
      if (typeAction == 'delete') {
        option.number--;
        this.priceTotal -= price;
      }
      if (typeAction != 'delete' && (!ticketsMax || ticketsMax > option.number)) {
        option.number++;
        this.priceTotal += price;
      }
      if (option.number == 0 && typeAction == 'delete') {
        this.selectTickets.splice(index, 1);
      }
    }
  }

  openSeatPanel() {
    if (!(this.numDaySelect == null || ((this.optionsHours.length > 0 && !this.hourSelected) || this.optionsHours.length <= 0))) {
      this.openingSeats = true;
      const dateTime = this.getDateTime();
      this.eventosService.getCountTicketsByEvent(this.event.id, dateTime.time, dateTime.date).subscribe({
        next: (count) => {
          // Comprobar si tiene mapa
          let maxCount = this.event.capacity;
          if(!maxCount && this.event.place) {
            maxCount = this.event.place.capacity;
          }
          if(maxCount && this.countBoughtTickets && maxCount <= this.countBoughtTickets) {
            // Informar que no hay entradas disponibles
            this.openInfoCard('Lo sentimos, no hay entradas disponibles para el día y la hora seleccionadas.');
          } else {
            this.openTickets(dateTime);
          }
        }, error: (err) => {
          this.openTickets(dateTime);
        }
      })
      
    }
  }

  openTickets(dateTime: any) {
    this.ticketsService.getBookingSeatsByEvent(this.event.id, dateTime.date, dateTime.time).subscribe({
      next: (res) => {
        this.buysBuySelect = res;
        this.openingSeats = false;
        const dialogRef = this.dialog.open(SeatsPanelComponent, {
          panelClass: ['info-dialog', 'not-padding', 'panel-seats'],
          data: { event: this.event, date: dateTime.date, time: dateTime.time, bookingTickets: this.buysBuySelect, organizer: this.organizer }
        });
        dialogRef.afterClosed().subscribe(res => {
          if (res && res.tickets?.length > 0) {
            this.router.navigate(['/cart-checkout']).then(() => {
              this.priceTotal = res.price;
              this.selectTickets = res.tickets;
              this.dialog.closeAll();
            });
          }
        })
      }
    });
  }

  getDateTime() {
    const date = this.formatter.format(this.selectedDate);
    let dateAux = new Date(date);
    let time = null;
    if (this.hourSelected) {
      let init = new Date(this.hourSelected.horaInicio);
      dateAux.setUTCHours(init.getUTCHours());
      dateAux.setUTCMinutes(init.getUTCMinutes());

      time = dateAux.toISOString();
    }
    return { date: date, time: time };
  }

  openPlatformBuy() {
    // Validar que el horario es correcto
    // Validar que las entradas son correctas
    if (this.event?.preciodesde > 0 && this.priceTotal > 0) {
      this.dialogRef?.close();
      const res = this.getDateTime();
      this.router.navigate(['/checkout/validate'], { state: { price: this.priceTotal, tickets: this.selectTickets, date: res.date, time: res.time, eventName: this.event.titulo, event: this.event.id } });
    }
    if (this.event?.preciodesde <= 0 && !this.event.informativo) {
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'Te has apuntado al evento correctamente',
          btnCancel: false
        }
      });
      dialogRef.afterClosed().subscribe(res => this.dialog.closeAll());
    }
  }

  goBuyTickets() {
    if (!this.pastEvent) {
      if (!this.selectedDate || (this.optionsHours.length > 0 && !this.hourSelected) || (this.optionsTickets.length > 0 && this.selectTickets.length <= 0)) {
        this.openSelect();
      } else {
        let user = this.auth.getProfile();
        if (!user) {
          const dialogRef = this.dialog.open(LoginComponent, {
            panelClass: ['info-dialog', 'dialog-login'],
            data: {
              exit: false,
              seats: true
            }
          });
        }

        if (user) {

          const res = this.getDateTime();
          const payload = { tickets: this.selectTickets, date: res.date, time: res.time, event: this.event.id, eventName: this.event.titulo, price: this.priceTotal };
          this.shoppingCartService.addToCartSimple(payload).subscribe({
            next: (res) => {
              // this.priceTotal = res.price;
              // this.selectTickets = res.tickets;
              this.dialog.closeAll();
              this.router.navigate(['/cart-checkout']);
            },
            error: (err) => {
              if (err.status == 400 && err.error.ticket) {
                // Quitar asientos que ya no están disponibles
                // Volver a llamar a la comprobación de asientos
                this.dialog.open(InfoCardComponent, {
                  panelClass: 'info-dialog',
                  data: {
                    icon: false,
                    text: err.error.msg,
                    btnCancel: false
                  }
                });
              }
            }
          })
        }
      }
    }
  }

  closeHoursEmit() {
    this.closeHours.emit(false);
    this.dialogRef?.close();
  }

  openInfoCard(msg: string) {
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: true,
        text: msg,
        btnCancel: false
      }
    });
  }

}
