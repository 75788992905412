import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, ActivatedRoute, NavigationEnd } from '@angular/router';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import jwt_decode from "jwt-decode";


@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const enableFunctions = route.data.accessRoles;

    const user = this.authenticationService.getProfile() || '{}';
    if (!user.citylok) {
      return of(false);
    }
    return of(true);
  }

}
