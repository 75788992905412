<div class="business-panel">
    <div class="btns-filters">
        <div class="buscador-business container-form d-flex align-items-center borderInput">
            <i class="fal fa-search search-icon pointer-in" (click)="searchBusiness(searchInput.value)"></i>
            <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar empresa"
                [ngModel]="searchText" (input)="searchBusiness(searchInput.value)">
        </div>
    </div>

    <div *ngIf="loadingPage" class="container-lottie">
        <div class="loading-container">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>
    </div>

    <div #containerScroll class="table-business table-panel multiple-lines" *ngIf="loaded"
        (scroll)="isScrolledInfinityView($event)">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort (matSortChange)="sortData($event)">
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item">
                    <!-- <i class="fal fa-ellipsis-vertical icon-option"></i> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef><b>Nombre</b></th>
                <td mat-cell *matCellDef="let item" class="pointer-in">
                    <img fallimg class="image-table" src="{{urlImages+'/'+item.id+'/'+item.image}}">
                    <span class="link-titulo">{{item.name}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="cif">
                <th mat-header-cell *matHeaderCellDef><b>CIF</b></th>
                <td mat-cell *matCellDef="let item" class="pointer-in">
                    <span>{{item.cif}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef><b>Dirección</b></th>
                <td mat-cell *matCellDef="let item">
                    <div class="container-address pointer-in">
                        <i class="fa-light fa-location-dot"></i>
                        <span>{{item.address}}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef><b>Fecha creación</b></th>
                <td mat-cell *matCellDef="let item">
                    <div class="pointer-in">
                        <span>{{item.createdAt | date: 'dd-MM-yyyy'}}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="open">
                <th mat-header-cell *matHeaderCellDef><b>Entrar</b></th>
                <td mat-cell *matCellDef="let item">
                    <div class="pointer-in">
                        <i class="fa-light fa-right-to-bracket"></i>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.row-active]="companySelect?.id==row.id"
                (click)="enterLikeCompany(row)"></tr>
        </table>
        <div class="pagination-component">
            <div class="d-flex align-items-center pagination-panel pagination-table"
                *ngIf="!loadingPage && !finishScroll">
                <div>
                    <ng-lottie class="lottie-pagination" [options]="{path: 'assets/lotties/loading.json'}"
                        containerClass="loading-box"></ng-lottie>
                </div>
            </div>
        </div>
    </div>
    <p *ngIf="loaded && !loadingPage && business?.length <= 0" style="text-align: center; margin-top: 80px;">No hay
        empresas disponibles</p>
</div>