import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fabric } from 'fabric';
import { element } from 'protractor';
import { AreasSeatService } from 'src/app/services/areas-seat.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SeatMapService } from 'src/app/services/seat-map.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { LoginComponent } from '../../shared/login/login.component';
import { LoginCardComponent } from '../login-card/login-card.component';

@Component({
  selector: 'app-seats-panel',
  templateUrl: './seats-panel.component.html',
  styleUrls: ['./seats-panel.component.scss']
})
export class SeatsPanelComponent implements OnInit {

  fromAbono!: any;
  abono!: any;

  profile!: any;
  clicked: boolean = false;

  event!: any;
  canvas: fabric.Canvas;

  objectMap!: any;
  selectTickets: any[] = [];
  pausePanning = true;
  selectedSeats: any[] = [];
  activedSeat!: any;

  totalPrice: any = 0;
  optionSection!: any;

  dateSelect!: any;
  timeSelect!: any;

  // Default colors
  colorDefaultSeat = '#d7d7d7';
  colorDefaultStand = this.areaSeatService.colorDefaultStand;
  colorDefaultFree = this.areaSeatService.colorDefaultFree;
  colorDefaultStage = this.areaSeatService.colorDefaultStage;
  colorDefaultHidden = this.areaSeatService.colorDefaultHidden;
  colorDefaultSelect = this.areaSeatService.colorDefaultSelect;
  colorDefaultMovility = this.areaSeatService.colorDefaultMovility;
  colorDefaultSpecial = '';

  // Comprobar compras de evento, en la fecha y horas seleccionadas
  buysBuySelect: any[] = [];

  // HiddenSeat
  hiddenCode!: string;
  savedCode!: string;
  errorCode: boolean = false;
  openFormDiscount: boolean = false;
  discountObject!: any;

  enabled: number = 0;
  invite: boolean = false;
  checkInvite: boolean = false;

  openDetail: boolean = true;
  openLegend: boolean = false;
  organizer: boolean = false;

  loaded!: boolean;
  selectedAbono: any[] = [];

  // Areas section
  areas: any[] = [];
  infoSectionActive!: any;
  mapBySections: boolean = false;

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<SeatsPanelComponent>,
    private areaSeatService: AreasSeatService, private shoppingCart: ShoppingCartService, private ticketsService: TicketsService,
    private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
    this.event = this.data.event;
    if(this.event.company.id == 3) {
      this.colorDefaultSpecial = '#f3ad33';
      this.colorDefaultMovility = '#4ab1ff';
    }
    this.objectMap = this.event.mapEvent;
    this.dateSelect = this.data.date;
    this.timeSelect = this.data.time;
    this.buysBuySelect = this.data.bookingTickets;
    this.openDetail = window.innerWidth > 998 ? true : false;
    // this.openLegend = window.innerWidth > 767 ? true : false;
    this.fromAbono = this.data.fromAbono;
    this.abono = this.data.abono;
    this.organizer = this.data.organizer;
    if (this.fromAbono) {
      this.selectedAbono = this.data.tickets ? this.data.tickets : [];
      if (this.data.tickets && this.data.actived) {
        this.activedSeat = this.data.actived;
        this.getEnabledTickets();
      }
    }
  }

  ngAfterViewInit(): void {
    this.canvas = new fabric.Canvas('myCanvas', {
      backgroundColor: '#f5f5f5',
      selection: false,
    });
    const json = JSON.parse(this.objectMap.map);
    let maxHeight = 600;
    let maxWidth = 1000;

    json.objects.forEach(element => {
      element.lockMovementY = true;
      element.lockMovementX = true;
      element.lockRotation = true;
      element.lockScalingFlip = true;
      element.lockScalingY = true;
      element.lockScalingX = true;
      element.hoverCursor = 'pointer';
      // Comprobar la capacidad de las secciones y las entradas que hay vendidas par aponer como oculto
      if (element.groupSeat) {
        element.subTargetCheck = true;
        element.perPixelTargetFind = true;
        element.objects[0].hoverCursor = 'default';
        element.objects[0].selectable = false;
        let arrayObject = element.objects[1];
        arrayObject.subTargetCheck = true;
        arrayObject.perPixelTargetFind = true;
        arrayObject.objects.forEach(seat => {
          seat.visible = seat.isVisible;
          seat.eventSeat = seat.eventSeat && seat.isVisible;
          let exist = this.buysBuySelect.find(value => value.section == seat.section?.id && value.row == seat.row && value.seat == seat.seat && value.level?.orderBuy == seat.level?.orderBuy)
          if (exist) {
            seat.hidden = true;
          }
          if (seat.eventSeat) {
            seat.hoverCursor = 'pointer';
            this.enabled += 1;
          }
          if (seat.hidden) {
            seat.hoverCursor = 'default';
            seat.selectable = false;
            if (seat.eventSeat) {
              this.enabled -= 1;
            }
          }
        });
      }
      if (!element.eventSeat || element.hidden || element.isInfoSection) {
        element.selectable = false;
        element.hoverCursor = 'default';
      }
      if (element.isInfoSection) {
        if (element.area) {
          var area = this.areas.find(value => value.id == +element.area);
          if (!area) {
            let index = this.areas.length;
            let name = 'Patio de butacas';
            switch (index) {
              case 1:
                name = 'Primer Anfiteatro'
                break;
              case 2:
                name = 'Segundo Anfiteatro'
                break;
              case 3:
                name = 'Tercer Anfiteatro'
                break;
              default:
                break;
            }
            this.areas.push({ id: +element.area, color: element.fill, name: name });
          }
        }
        element.oldFill = element.fill;
      }
    });

    if (this.areas.length > 0) {
      this.mapBySections = true;
      this.colorDefaultSeat = this.areaSeatService.colorDefaultSeat;
    }

    this.canvas.loadFromJSON(json, this.canvas.renderAll.bind(this.canvas), function (o, object) {
      maxHeight = Math.max(maxHeight, Math.abs(o.top + o.height));
      maxWidth = Math.max(maxWidth, Math.abs(o.left + o.width));
    });
    // if (window.innerWidth < 576) {
    this.canvas.setDimensions({ width: window.innerWidth + 150, height: window.innerHeight + 150 });
    this.canvas.setZoom(0.2);
    // }
    if (window.innerWidth >= 576) {
      // this.canvas.setDimensions({ width: maxWidth + 50, height: maxHeight + 50 });
      this.canvas.setZoom(0.4);
    }
    this.canvas.set('backgroundColor', '#f5f5f5');
    this.canvasColorLevels();
    this.canvas.renderAll();

    if (this.fromAbono && this.activedSeat) {
      this.addTicket(this.selectedAbono[0]?.option[0], false);
    }
    this.loaded = true;

    this.canvas.on('mouse:down', e => {
      if (e.target && (e.target.eventSeat || e.target.groupSeat && e.subTargets.length > 0 && e.subTargets[0].eventSeat)) {
        let elementSeat = e.target.groupSeat ? e.subTargets[0] : e.target;
        if (elementSeat.selectable) {
          this.selectTicket(elementSeat);
        }
      }
      if ((!e.target || e.target.isInfoSection) && this.pausePanning == true) {
        this.pausePanning = false;
        this.canvas.discardActiveObject(this.canvas.getActiveObject());
        this.canvas.defaultCursor = 'move';
      }
      e.e.preventDefault();
      e.e.stopPropagation();
    });

    this.canvas.on('mouse:over', e => {
      if (e.target && (e.target.eventSeat || e.target.groupSeat && e.subTargets?.length > 0)) {
        let elementSeat = e.target.groupSeat ? e.subTargets[0] : e.target;
        let indexStroke = elementSeat._objects.find(value => value.type != 'text');
        indexStroke.set('stroke', 'black');
        this.canvas.renderAll();
      }
    });

    this.canvas.on('mouse:out', e => {
      if (e.target && (e.target.eventSeat || e.target.groupSeat && e.subTargets?.length > 0)) {
        let elementSeat = e.target.groupSeat ? e.subTargets[0] : e.target;
        let indexStroke = elementSeat._objects.find(value => value.type != 'text');
        indexStroke.set('stroke', '#4D4D4D');
        this.canvas.renderAll();
      }
    });

    this.canvas.on('mouse:wheel', opt => {
      var delta = opt.e.deltaY;
      var zoom = this.getZoom(false, delta);
      var zoomPoint = new fabric.Point(opt.e.pageX, opt.e.pageY);
      this.canvas.zoomToPoint(zoomPoint, zoom);
      this.canvas.renderAll();
      opt.e.preventDefault();
      opt.e.stopPropagation();
    })

    this.canvas.on('touch:gesture', opt => {
      var delta = opt.e.deltaY;
      var zoom = this.getZoom(false, delta * 2);
      const touch = opt.e.touches[0];
      var zoomPoint = new fabric.Point(touch.pageX, touch.pageY);
      this.canvas.zoomToPoint(zoomPoint, zoom);
      this.canvas.renderAll();
      opt.self.preventDefault();
      opt.self.stopPropagation();
    })

    var previousTouch;
    this.canvas.on('mouse:move', e => {
      if (this.pausePanning == false && e && e.e) {
        if (!e.e.touches) {
          this.canvas = keepPositionInBounds(this.canvas, e);
        }
        if (e.e.touches) {
          const touch = e.e.touches[0];
          if (previousTouch) {
            // be aware that these only store the movement of the first touch in the touches array
            e.e.movementX = touch.pageX - previousTouch.pageX;
            e.e.movementY = touch.pageY - previousTouch.pageY;
            this.canvas = keepPositionInBounds(this.canvas, e);
          };
          previousTouch = touch;
        }
      }
    });

    this.canvas.on('mouse:up', e => {
      previousTouch = null;
      if (!this.canvas.getActiveObject() && this.pausePanning == false) {
        this.pausePanning = true;
        this.canvas.defaultCursor = 'default';
      }
    });
  }

  // Botones externos de zoom
  zoomMap(plus: boolean, event: any, delta: any = 50) {
    let zoom = this.getZoom(plus, delta * 4);
    this.canvas.setZoom(zoom);
    var zoomPoint = this.canvas.getVpCenter();
    // this.canvas.zoomToPoint(zoomPoint, zoom);
    this.canvas.renderAll();
    event.stopPropagation();
  }

  getZoom(plus: boolean, delta: any = 50) {
    var zoom = this.canvas.getZoom();
    var plusDelta = plus ? -delta : delta;
    zoom *= 0.999 ** plusDelta;
    if (zoom > 10) zoom = 10;
    if (zoom < 0.2) zoom = 0.2;
    return zoom;
  }

  addTicket(ticket: any, close: boolean = true) {
    if (ticket) {
      let actived = this.activedSeat;
      this.activedSeat.selected = true;
      let indexEl = this.selectedSeats.findIndex(value => value.section.id == actived.section.id && value.row == actived.row && value.seat == actived.seat);
      let object = { event: this.event.id, section: actived.section, row: actived.row, seat: actived.seat, option: ticket, element: this.activedSeat, showRow: actived.showRows }
      this.totalPrice += ticket.price;
      indexEl != -1 ? this.selectedSeats[indexEl] = object : this.selectedSeats.push(object);
      if (this.fromAbono && close) {
        const payload = { event: this.event.id, tickets: this.selectedSeats, price: this.totalPrice, date: this.dateSelect, time: this.timeSelect, code: this.hiddenCode, bond: this.abono.id };
        this.dialogRef.close({ payload: payload, actived: this.activedSeat });
      }
      this.closeActived();
    }
  }

  closeActived(close: boolean = true) {
    this.activedSeat.actived = false;
    let elementStroke = this.activedSeat._objects.find(value => value.type != 'text');
    // let defaultColor = (this.profile?.admin || (this.profile?.protocolo && this.organizer) && this.activedSeat.level) ? this.activedSeat.level.color : this.colorDefaultSeat;
    let defaultColor = this.activedSeat.level ? this.activedSeat.level.color : this.colorDefaultSeat;

    let colorFill = this.activedSeat?.selected ? this.colorDefaultSelect : defaultColor;
    elementStroke.set('fill', colorFill);
    let elementsText = this.activedSeat._objects.filter(value => value.type == 'text' || value.type == 'path');
    let colorText = this.activedSeat.selected ? 'white' : 'black';
    elementsText.forEach(elementText => {
      elementText.set('fill', colorText);
    });
    this.canvas.renderAll();
    this.activedSeat = null;
    if (close && this.mapBySections) {
      this.changeOpacitySections(null);
    }
  }

  selectTicket(elementSeat: any) {
    elementSeat.actived = elementSeat.actived ? false : true;
    elementSeat.selected = false;
    // let defaultColor = (this.profile?.admin || (this.profile?.protocolo && this.organizer)) && elementSeat.level ? elementSeat.level.color : this.colorDefaultSeat;
    let defaultColor = elementSeat.level ? elementSeat.level.color : this.colorDefaultSeat;

    // Cambiar color circulo interior
    if (this.activedSeat) {
      this.activeChangeDefault();
    }
    let colorActive = this.mapBySections ? '#ffa2bc' : '#ff4d7f3f';
    let colorBasic = this.mapBySections ? 'white' : defaultColor;

    let elementStroke = elementSeat._objects.find(value => value.type != 'text');
    let colorFill = elementSeat.actived ? colorActive : colorBasic;
    elementStroke.set('fill', colorFill);
    let colorText = 'black';
    if (!elementSeat.actived) {
      let rgb = hexToRgb(colorFill);
      if (rgb) {
        colorText = (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186 ? '#fff' : '#000';
      } else {
        colorText = '#000';
      }
    }
    let elementsText = elementSeat._objects.filter(value => value.type == 'text' || value.type == 'path');
    elementsText.forEach(elementText => {
      elementText.set('fill', colorText);
    });

    let indexEl = this.selectedSeats.findIndex(value => value.section.id == elementSeat.section.id && value.row == elementSeat.row && value.seat == elementSeat.seat);
    if (indexEl != -1) {
      this.totalPrice -= this.selectedSeats[indexEl].option.price;
      this.selectedSeats.splice(indexEl, 1);
    }
    this.activedSeat = elementSeat.actived ? elementSeat : null;
    this.changeOpacitySections(this.activedSeat?.level?.orderBuy);
    this.getEnabledTickets();
    this.canvas.renderAll();
  }

  activeChangeDefault() {
    this.activedSeat.actived = false;
    // let defaultColorActive = (this.profile?.admin || (this.profile?.protocolo && this.organizer)) && this.activedSeat.level ? this.activedSeat.level.color : this.colorDefaultSeat;
    // let colorDefault = this.mapBySections ? 'white' : '';

    // if(this.mapBySections){
    //   let colorFill = elementSeat.actived ? colorActive : colorBasic;
    // } else {
    //   let colorFill = elementSeat.actived ? colorActive : colorDefaultSeat;
    // }
    let colorBasic = this.activedSeat.level ? this.activedSeat.level.color : this.colorDefaultSeat;
    let defaultColorActive = colorBasic;

    let elementActive = this.activedSeat._objects.find(value => value.type != 'text');
    elementActive.set('fill', defaultColorActive);
    let rgb = hexToRgb(defaultColorActive);
    let defaultColorActiveText = '#000';
    if (rgb) {
      defaultColorActiveText = (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186 ? '#000' : '#fff';
    }
    let elementsText = this.activedSeat._objects.filter(value => value.type == 'text' || value.type == 'path');
    elementsText.forEach(elementText => {
      elementText.set('fill', defaultColorActiveText);
    });
  }

  deleteTicket(item: any, index: number) {
    this.activedSeat = item.element;
    this.activeChangeDefault();
    this.activedSeat = null;
    this.changeOpacitySections(null);
    this.totalPrice -= this.selectedSeats[index].option.price;
    this.selectedSeats.splice(index, 1);
    this.canvas.renderAll();
  }

  canvasColorLevels(colorSelect: boolean = false) {
    let objects = this.canvas._objects;
    objects.forEach(element => {
      let hiddenColor = (this.profile?.admin || (this.profile?.protocolo && this.organizer)) && element.hiddenType ? element.hiddenType.color : this.colorDefaultHidden;
      // let levelColor = (this.profile?.admin || (this.profile?.protocolo && this.organizer)) && element.level ? element.level.color : this.colorDefaultSeat;
      let levelColor = element.level ? element.level.color : this.colorDefaultSeat;
      let defaultColor = element.hidden ? hiddenColor : levelColor;
      if (element.isSection) {
        changeSeatColor(element, colorSelect ? this.colorDefaultSelect : defaultColor);
      }
      if (element.groupSeat) {
        let arrayObject = element._objects[1];
        arrayObject._objects.forEach(seat => {
          let hiddenColorSeat = (this.profile?.admin || (this.profile?.protocolo && this.organizer)) && seat.hiddenType ? seat.hiddenType?.color : this.colorDefaultHidden;
          // let levelColorSeat = (this.profile?.admin || (this.profile?.protocolo && this.organizer)) && seat.level ? seat.level.color : this.colorDefaultSeat;
          let levelColorSeat = seat.level ? seat.level.color : this.colorDefaultSeat;
          let defaultColorSeat = seat.hidden ? hiddenColorSeat : levelColorSeat;
          if (seat.eventSeat) {
            changeSeatColor(seat, colorSelect ? this.colorDefaultSelect : defaultColorSeat);
          }
        });
      }
    });
    this.canvas.renderAll();
  }

  getEnabledTickets() {
    this.optionSection = null;
    this.checkInvite = false;
    if (this.activedSeat && this.activedSeat.level) {
      const level = this.activedSeat.level;
      const payload = { section: this.activedSeat.section, row: this.activedSeat.row, seat: this.activedSeat.seat, level: this.activedSeat.level, map: this.objectMap.id };
      this.areaSeatService.getTicketsLevel(level, this.objectMap.id, payload).subscribe({
        next: (res) => {
          if (res) {
            let options = res.sort((a, b) => b.price - a.price);
            this.optionSection = this.fromAbono && options.length > 0 ? [options[0]] : options;
            if (this.activedSeat?.invite && !this.fromAbono) {
              this.checkInvite = true;
              res.forEach(element => {
                element.price = 0;
              });
            }
          }
        }
      })
    }
  }

  goBuyTickets() {
    let user = this.auth.getProfile();
    if (!user) {
      const dialogRef = this.dialog.open(LoginComponent, {
        panelClass: ['info-dialog', 'dialog-login'],
        data: {
          exit: false,
          seats: true
        }
      });
    }
    if (user) {
      const payload = { event: this.event.id, tickets: this.selectedSeats, price: this.totalPrice, date: this.dateSelect, time: this.timeSelect, code: this.hiddenCode };
      this.shoppingCart.addToCart(payload).subscribe({
        next: (res) => {
          this.dialogRef.close(payload);
        },
        error: (err) => {
          if (err.status == 400 && err.error.tickets) {
            // Quitar asientos que ya no están disponibles
            // Volver a llamar a la comprobación de asientos
            this.dialog.open(InfoCardComponent, {
              panelClass: 'info-dialog',
              data: {
                icon: false,
                text: err.error.msg,
                btnCancel: false
              }
            });
          }
        }
      })
    }
  }

  changeOpacitySections(area: number) {
    if (this.mapBySections) {
      let objects = this.canvas._objects;
      if (area) {
        var areaObject = this.areas.find(value => value.id == area);
        // if (this.infoSectionActive != areaObject) {
          this.infoSectionActive = areaObject;
          objects.forEach(element => {
            if (element.isInfoSection && element.area != area) {
              element.set("fill", "#DADADA");
            } else if (element.isInfoSection) {
              var oldFill = element.oldFill;
              element.set("fill", oldFill);
            }
          });
        // }
      } else {
        this.infoSectionActive = null;
        objects.forEach(element => {
          if (element.isInfoSection) {
            var oldFill = element.oldFill;
            element.set("fill", oldFill);
          }
        });
      }
      this.canvas.renderAll();
    }
  }

  selectArea() {
    this.changeOpacitySections(this.infoSectionActive.id);
    this.closeActived(false);
  }

  // HiddenSeats
  openFormCode() {
    this.discountObject = null;
    this.savedCode = null;
    this.openFormDiscount = !this.openFormDiscount;
    if (this.errorCode) {
      this.errorCode = false;
      this.hiddenCode = null;
    }
  }

  applyCode() {
    this.hiddenCode = this.hiddenCode.trim();
    this.ticketsService.checkHiddenCode(this.hiddenCode, this.event.id).subscribe({
      next: (res) => {
        if (res) {
          this.discountObject = res;
          this.errorCode = false;
          this.savedCode = this.hiddenCode;
          if (res.percentage == 100) {
            this.invite = true;
          }
          this.desactiveHiddenSeat(true);
        }
      },
      error: (err) => {
        this.errorCode = true;
        this.invite = false;
      }
    })
  }

  desactiveHiddenSeat(active: boolean) {
    let objects = this.canvas._objects;
    objects.forEach(element => {
      element.hoverCursor = 'pointer';
      if (element.groupSeat) {
        let arrayObject = element._objects[1];
        arrayObject._objects.forEach(seat => {
          seat.visible = seat.isVisible;
          seat.eventSeat = seat.eventSeat && seat.isVisible;
          let hiddenSeat = this.discountObject?.hiddenSeat?.seats?.find(value => value.section.id == seat.section?.id && value.section.name == seat.section?.name && value.row == seat.row && value.seat == seat.seat && value.level?.orderBuy == seat.level?.orderBuy);
          if (hiddenSeat) {
            seat.hidden = !active;
            seat.border = active;
            if (this.invite) {
              seat.invite = true;
            }
          }
          let exist = this.buysBuySelect.find(value => value.section == seat.section?.id && value.row == seat.row && value.seat == seat.seat && value.level?.orderBuy == seat.level?.orderBuy)
          if (exist) {
            seat.hidden = true;
          }
          if (seat.eventSeat) {
            seat.hoverCursor = 'pointer';
            seat.selectable = true;
          }
          if (seat.hidden) {
            seat.hoverCursor = 'default';
            seat.selectable = false;
          }
        });
      }
      if (!element.eventSeat || element.hidden) {
        element.selectable = false;
        element.hoverCursor = 'default';
      }
    });
    this.canvasColorLevels();
  }

  deleteCode() {
    this.savedCode = null;
    this.errorCode = false;
    this.desactiveHiddenSeat(false);
    this.discountObject = null;
    this.invite = false;
    this.activedSeat = null;
    this.changeOpacitySections(null);
  }

  openedLegend() {
    // if (window.innerWidth < 768) {
    this.openLegend = !this.openLegend;
    // }
  }

}

function changeSeatColor(elementSeat, color) {
  let elementStroke = elementSeat._objects.find(value => value.type != 'text');
  elementStroke.set('fill', color);
  let rgb = hexToRgb(color);
  let textColor = (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186 ? '#000' : '#fff'
  let elementsText = elementSeat._objects.filter(value => value.type == 'text' || value.type == 'path');
  elementsText.forEach(elementText => {
    elementText.set('fill', textColor);
  });
  return elementSeat;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function keepPositionInBounds(canvas: any, e: any) {
  canvas.relativePan(new fabric.Point(e.e.movementX, e.e.movementY));
  return canvas;
}
