import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fabric } from 'fabric';
import { AreasSeatService } from 'src/app/services/areas-seat.service';

@Component({
  selector: 'app-edit-section',
  templateUrl: './edit-section.component.html',
  styleUrls: ['./edit-section.component.scss']
})
export class EditSectionComponent implements OnInit {

  @Input() sectionElement!: any;
  @Input() colorDefaultSeat!: any;
  @Input() canvas!: any;
  @Input() cancel!: any;

  @Output() clickEdit = new EventEmitter<any>();
  isEditing: boolean = false;

  // Copies to back
  oldElement!: any;
  oldSectionEl!: any;
  oldSeatsEl!: any;
  oldSeatsArray!: any;

  sectionObj!: any;
  sectionSeats!: any;
  visibilityNameSection: boolean = true;
  visibilityNameRows: boolean = true;
  invisibleSeats: any[] = [];

  valueName!: string;
  valueTilt: number = 0;
  valueAngle: number = 0;
  sectionNumber!: number;
  areaNumber!: number;

  @Output() updateCanvas = new EventEmitter<any>();
  @Output() createObj = new EventEmitter<any>();
  @Output() activeEdit = new EventEmitter<any>();
  @Output() closeTab = new EventEmitter<any>();

  activedEditing: boolean = false;
  firstTime: boolean = false;

  // Edit seat
  @Input() sitSelect!: any;
  @Input() seatsEditSelected!: any;
  @Input() openSeatEditing: boolean = false;

  constructor(private areaSeat: AreasSeatService) { }

  ngOnInit(): void {
    if (this.sectionElement) {
      this.getValuesObjects();
    }
  }

  getValuesObjects() {
    this.valueAngle = this.sectionElement.angle;
    this.areaNumber = this.sectionElement.area;
    let jsonSectionElement = this.sectionElement.toJSON(this.areaSeat.arrayParams);
    this.oldElement = JSON.stringify(jsonSectionElement);

    // Nombre seccion
    this.sectionObj = this.sectionElement._objects[0];
    this.valueName = this.sectionObj.text;
    let jsonSectionObj = this.sectionObj.toJSON(this.areaSeat.arrayParams);
    this.oldSectionEl = JSON.stringify(jsonSectionObj);
    this.visibilityNameSection = this.sectionObj.visible;

    // Asientos 
    this.sectionSeats = this.sectionElement._objects[1];
    this.visibilityNameRows = this.sectionSeats.showRows;
    this.sectionNumber = this.sectionSeats.section.id;
    let jsonSectionSeats = this.sectionSeats.toJSON(this.areaSeat.arrayParams);
    this.oldSeatsEl = JSON.stringify(jsonSectionSeats);
    this.oldSeatsArray = JSON.stringify(this.sectionSeats._objects);
    this.visibilityNameRows = this.sectionSeats._objects[0].visible;
  }

  activeEditing() {
    this.firstTime = true;
    this.activedEditing = true;
    this.activeEdit.emit(true);
  }

  changeVisibilityName() {
    this.sectionObj.set('visible', this.visibilityNameSection);
    this.updateCanvas.emit();
  }

  changeVisibilityRows() {
    this.sectionSeats.set('showRows', this.visibilityNameRows)
    this.sectionSeats._objects.forEach(seatElement => {
      if (!seatElement.eventSeat) {
        seatElement.set('visible', this.visibilityNameRows);
        seatElement.set('isVisible', this.visibilityNameRows);
      }
      if (seatElement.eventSeat) {
        seatElement.set('showRows', this.visibilityNameRows);
      }
    });
    this.updateCanvas.emit();
  }

  changeRows() {
    let rows = this.sectionSeats.filas;
    this.sectionSeats._objects.forEach(seatElement => {
      let rowSeat = +seatElement.row;
      if (seatElement.row) {
        let cal = rows - rowSeat + 1;
        seatElement.set('row', cal);
      }
    });
    console.log(this.sectionSeats._objects);
    this.updateCanvas.emit();
  }

  editName() {
    this.sectionObj.set('text', this.valueName);
    let section = { id: this.sectionNumber, name: this.valueName };
    this.sectionSeats.set('section', section)
    this.sectionSeats._objects.forEach(seatElement => {
      if (seatElement.eventSeat) {
        seatElement.set('section', section);
      }
    });
    this.sectionElement.addWithUpdate();
    this.updateCanvas.emit();
  }

  editId() {
    let section = { id: this.sectionNumber, name: this.valueName };
    this.sectionSeats.set('section', section)
    this.sectionSeats._objects.forEach(seatElement => {
      if (seatElement.eventSeat) {
        seatElement.set('section', section);
      }
    });
    this.sectionElement.addWithUpdate();
    this.updateCanvas.emit();
  }

  editArea() {
    this.sectionElement.set('area', this.areaNumber);
    this.sectionSeats.set('area', this.areaNumber);
    this.sectionSeats._objects.forEach(seatElement => {
      if (seatElement.eventSeat) {
        seatElement.set('area', this.areaNumber);
      }
    });
    this.sectionElement.addWithUpdate();
    this.updateCanvas.emit();
  }

  editRowsColumns() {
    const columns = this.sectionSeats.columns;
    const rows = this.sectionSeats.rows;
    let newGroup = this.addSeat(rows, columns);
    this.sectionElement.remove(this.sectionSeats);
    this.sectionElement.addWithUpdate();
    this.sectionElement.add(newGroup);
    this.sectionElement.addWithUpdate();
    this.sectionSeats = newGroup;
    this.updateCanvas.emit();
  }

  editTilt(value: any) {
    let arrayBefore = JSON.parse(this.oldSeatsEl).objects;
    let columns = +this.sectionSeats.columns;
    this.sectionSeats._objects.forEach((seatElement, index) => {
      if (value < 0) {
        seatElement.set('originY', arrayBefore[index].originY + (value * (columns - arrayBefore[index].column)));
      } else {
        seatElement.set('originY', arrayBefore[index].originY - (value * arrayBefore[index].column));
      }
    });

    this.sectionSeats.addWithUpdate();
    this.sectionElement.addWithUpdate();
    this.updateCanvas.emit();
  }

  editCurve() {

  }

  editRotation(value: any) {
    this.sectionElement.set('angle', parseInt(value, 10)).setCoords();
    this.updateCanvas.emit();
  }

  editSeats() {
    this.openSeatEditing = true;
    this.clickEdit.emit();
  }

  applyChanges() {
    this.getValuesObjects();
    this.activedEditing = false;
    this.updateCanvas.emit();
  }

  cancelEdit(exit: boolean = false) {
    if (this.firstTime) {
      const jsonElement = JSON.parse(this.oldElement)
      const jsonOjElement = JSON.parse(this.oldSectionEl)
      const jsonSeatsElement = JSON.parse(this.oldSeatsEl)
      const seatsArray = JSON.parse(this.oldSeatsArray)

      this.valueAngle = jsonElement.angle;
      this.sectionElement.set(jsonElement);
      this.sectionObj.set(jsonOjElement);
      this.sectionSeats.set(jsonSeatsElement);
      this.sectionSeats._objects.forEach((element, index) => {
        element.set(seatsArray[index]);
      });
      this.activedEditing = false;
    }
    this.updateCanvas.emit(exit);
  }

  cancelEditSeat() {
    this.openSeatEditing = false;
    this.seatsEditSelected = [];
    this.sitSelect = null;
    this.updateCanvas.emit(false);
  }

  saveEditSeats(event: any) {
    if (!event) {
      this.sectionSeats._objects.forEach(seatElement => {
        if (seatElement.eventSeat) {
          seatElement.visible = seatElement.isVisible;
        }
      });
    }
    this.updateCanvas.emit();
  }

  addSeat(filas: any, columns: any) {
    var top = 10;
    var left = 0;
    var arrayElements = [];
    let aux = filas;
    for (let numF = 0; numF < filas; numF++) {
      const file = this.createSeat((filas).toString(), false);
      file.originX = left;
      file.originY = top - numF * 1.2;
      file.column = 1;
      file.isVisible = true;
      arrayElements.push(file);
      for (let numC = 0; numC < columns; numC++) {
        const element = this.createSeat((numC + 1).toString(), true);
        element.originX = left - 1.2 - numC * 1.2;
        element.originY = top - numF * 1.2;
        element.eventSeat = true;
        element.row = filas;
        element.seat = numC + 1;
        element.column = numC + 1;
        element.isVisible = true;
        element.section = { id: this.sectionNumber, name: this.sectionObj.text };
        arrayElements.push(element);
      }
      filas--;
      // const endFile = this.createSeat((numF + 1).toString(), false);
      // endFile.originX = left - 1.2 - columns * 1.2;
      // endFile.originY = top - numF * 1.2;
      // endFile.column = columns;
      // endFile.isVisible = true;
      // arrayElements.push(endFile);
    }
    var group = new fabric.Group(arrayElements, {
      left: 0,
      top: 30,
      scaleX: 0.4,
      scaleY: 0.4,
      hasControls: false,
      arraySeats: true,
      eventSeat: false,
      originX: 'center',
      originY: 'top',
      rows: filas,
      columns: columns,
      showRows: this.visibilityNameRows,
      section: { id: this.sectionNumber, name: this.sectionObj.text },
    });
    group.setControlVisible('mtr', false);
    return group;
  }

  createSeat(seatNumber: any, fill: boolean = true) {
    const circle = new fabric.Circle({
      radius: 50,
      fill: 'transparent',
      strokeWidth: 2,
      originX: 'center',
      originY: 'center',
    });
    var color = 'black';
    if (fill) {
      circle.fill = this.colorDefaultSeat;
      circle.stroke = '#4D4D4D';
      color = 'black';
    }

    var text = this.createTextComponent(seatNumber.toString(), 40, 0, color);

    var group = new fabric.Group([circle, text], {
      hasControls: false,
      radius: 50,
    });
    return group;
  }

  addText() {
    const text = new fabric.Textbox('Escribe un texto...', {
      width: 200,
      height: 100,
      fontSize: 24,
      // fontFamily: 'Satoshi-Regular',
      cursorColor: '#FF4D80',
      left: 50,
      top: 50,
      eventSeat: false
    });
    this.canvas.add(text);
  }

  createTextComponent(text: any, fontsize: number, top: number = 0, color: string = 'black') {
    let textComponent = new fabric.Text(text, {
      // fontFamily: 'Satoshi-Regular',
      fontSize: fontsize,
      fill: color,
      textAlign: 'center',
      originX: 'center',
      originY: 'center',
      eventSeat: false,
      top: top
    });
    return textComponent;
  }

}