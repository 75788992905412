<div class="page-discount-pass">
    <!-- Descuentos -->
    <div class="d-flex justify-content-between align-items-center">
        <p class="title-tab">Descuentos para este evento</p>
        <div class="btn-create m-0" (click)="openCreateDiscount()">
            <i class="fal fa-plus-circle"></i>
            <p class="m-0">Crear descuento para este evento</p>
        </div>
    </div>
    <div class="margin-table" *ngIf="discounts; else divNotDiscounts">
 <!-- Tabla entradas por nivel (mapa) -->
 <div class="table-events table-panel table-grey" *ngIf="loaded">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let discount; let i = index" (click)="editDiscount(discount, i)">
                <i class="fal fa-pen icon-option pointer-in"></i>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef><b>Nombre</b></th>
            <td mat-cell *matCellDef="let discount">
                <span class="link-titulo">{{discount[0].name | uppercase}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef><b>Descuento</b></th>
            <td mat-cell *matCellDef="let discount">
                <span class="link-titulo">{{discount[0].amount ? discount[0].amount : discount[0].percentage}}</span> <span>{{discount[0].amount ? '€' : '%'}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef><b>Descripción</b></th>
            <td mat-cell *matCellDef="let discount">
                <span class="link-titulo">{{discount[0].description}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef><b>CÓDIGO</b></th>
            <td mat-cell *matCellDef="let discount">
                <span>{{discount[0]?.discountCode}}</span>
            </td>
        </ng-container>
        <!-- <ng-container matColumnDef="limits">
            <th mat-header-cell *matHeaderCellDef><b>Inicio/fin</b></th>
            <td mat-cell *matCellDef="let discount">
                <span>{{discount?.code}}</span>
            </td>
        </ng-container> -->
        <ng-container matColumnDef="sold">
            <th mat-header-cell *matHeaderCellDef><b>Vendidas</b></th>
            <td mat-cell *matCellDef="let discount">
                <span>{{discount?.purchases}}</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
    </div>
    <ng-template #divNotDiscounts>
        <p>No existen descuentos para este evento.</p>
    </ng-template>
    <!-- Abonos -->
    <div class="div-pass">
        <!-- <p class="title-tab">Abonos</p> -->
        <!-- <p>Este evento aún no pertenece a ningún abono. Puedes añadirlo a un abono disponible o crear uno nuevo desde el apartado <a class="link-open" target="_blank" href="/panel-control/events/bonds">Abonos.</a></p> -->
        <!-- <div class="btn-create btn-create-border m-0" (click)="openCreateDiscount()">
            <i class="fal fa-plus-circle"></i>
            <p class="m-0">Añadir evento a un abono disponible</p>
        </div> -->
    </div>
    <!-- Descuentos base -->
    <div *ngIf="discountsPlace">
        <div class="d-flex justify-content-between align-items-center">
            <p class="title-tab">Descuentos base del Teatro Apolo</p>
            <div class="link-open m-0" (click)="openDiscountPlace()">
                <i class="fal fa-pencil"></i>
                <p class="m-0">Editar los descuentos base</p>
            </div>
        </div>
    </div>
</div>