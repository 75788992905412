<div class="edit-ticket form-new-lok">
    <div class="d-flex btns-close-section align-items-center justify-content-end pointer-in">
        <div class="icon-close" mat-dialog-close>
            <i class="fa-regular fa-times"></i>
        </div>
    </div>
    <!-- Form new ticket -->
    <form [formGroup]="entrada" class="form-entrada">
        <mat-form-field appearence="fill" class="label-title mb-2" [hideRequiredMarker]="true">
            <mat-label class="d-flex justify-content-between w-100">
                <span>Nombre entrada*</span>
                <span class="count-letters">{{ 50 - nameTicket.value.length }}</span>
            </mat-label>
            <input #nameTicket matInput type="text" placeholder="Nombre entrada" maxlength="50" formControlName="nombre"
                required>
        </mat-form-field>

        <mat-form-field appearence="fill" class="label-title mb-2" [hideRequiredMarker]="true">
            <mat-label class="d-flex justify-content-between w-100">
                <span>Descripción</span>
                <span class="count-letters">{{ 250 - descriptionTicket.value.length }}</span>
            </mat-label>
            <input #descriptionTicket matInput type="text" placeholder="Descripción entrada" maxlength="250"
                formControlName="description">
        </mat-form-field>

        <!-- <div class="d-flex align-items-center justify-content-end edit-advanced" (click)="openAdvanced=!openAdvanced">
            <p class="m-0">Ajustes avanzados</p>
            <i class="fa-light fa-chevron-down icon-primary"></i>
        </div> -->
        <div class="padding-inputs" *ngIf="openAdvanced">
            <div *ngIf="false">
                <p>Inicio de venta de entradas</p>
                <p style="font-size: 14px;">Será prioritaria la fecha de venta general del evento.</p>
                <div class="section-input">
                    <!-- Fecha inicio venta -->
                    <div class="d-flex align-items-center justify-content-between input-border">
                        <div class="select-style calendar-date pointer-in" [class.active]="typeDatepicker == 'start'"
                            (click)="openDatePicker('start')">
                            <i class="fa-light fa-calendar"></i>
                            <input placeholder="Inicio de venta" class="input-date" readonly
                                [(ngModel)]="startStringDate" [ngModelOptions]="{standalone: true}">
                            <i class="icon-down fa-light fa-chevron-down"></i>
                        </div>
                        <div class="calendar-option" *ngIf="typeDatepicker == 'start'">
                            <app-default-calendar [selectedDate]="startDate"
                                (saveDate)="saveDate($event)"></app-default-calendar>
                        </div>
                    </div>
                    <!-- Hora inicio venta -->
                    <div class="d-flex align-items-center justify-content-center inputs-hours">
                        <div class="input-hour">
                            <input placeholder="20" type="number" min="0" max="23" required [(ngModel)]="hourInit"
                                [ngModelOptions]="{standalone: true}" />
                        </div>
                        <span class="points">:</span>
                        <div class="input-hour">
                            <input style="text-align: right;" placeholder="00" type="number" min="0" max="59" required
                                [(ngModel)]="minuteInit" [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                </div>

                <div class="section-input">
                    <!-- Fecha fin venta -->
                    <div class="d-flex align-items-center justify-content-between input-border">
                        <div class="select-style calendar-date pointer-in" [class.active]="typeDatepicker == 'end'"
                            (click)="openDatePicker('end')">
                            <i class="fa-light fa-calendar"></i>
                            <input placeholder="Fin de venta" class="input-date" readonly [(ngModel)]="endStringDate"
                                [ngModelOptions]="{standalone: true}">
                            <i class="icon-down fa-light fa-chevron-down"></i>
                        </div>
                        <div class="calendar-option" *ngIf="typeDatepicker == 'end'">
                            <app-default-calendar [selectedDate]="endDate"
                                (saveDate)="saveDate($event)"></app-default-calendar>
                        </div>
                    </div>
                    <!-- Hora fin venta -->
                    <div class="d-flex align-items-center justify-content-center inputs-hours">
                        <div class="input-hour">
                            <input placeholder="20" type="number" min="0" max="23" required [(ngModel)]="hourEnd"
                                [ngModelOptions]="{standalone: true}" />
                        </div>
                        <span class="points">:</span>
                        <div class="input-hour">
                            <input style="text-align: right;" placeholder="00" type="number" min="0" max="59" required
                                [(ngModel)]="minuteEnd" [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                </div>
            </div>
            <p>Entradas por pedido</p>
            <div class="section-input section-max-sales">
                <!-- Entradas minimas -->
                <div class="input-border input-min">
                    <span>Mínimo:</span>
                    <input class="pointer-in" placeholder="1" type="number" min="1" formControlName="ticketsMin">
                </div>
                <!-- Entradas maximas -->
                <div class="input-border input-max">
                    <span>Máximo:</span>
                    <input class="pointer-in" placeholder="10" type="number" min="1" formControlName="ticketsMax">
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center container-price-ticket">
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Precio:</p>
            </div>
        </div>
        <div class="d-flex align-items-center container-price-ticket">
            <div class="input-border input-ticket" *ngIf="levels?.length <= 0">
                <input matInput class="pointer-in" type="number" [value]="singlePrice"
                    (change)="addPriceTicket($event, null, null)">
                <i class="fa-light fa-euro-sign icon-primary"></i>
            </div>
        </div>

        <div *ngFor="let level of levels; let i = index">
            <div class="d-flex justify-content-between align-items-center section-price">
                <div class="section-item">
                    <div class="color-section" [style.background]="level.color"></div>
                    <p class="m-0">{{level.name}}</p>
                </div>
                <div>
                    <!-- <mat-form-field class="input-number input-border" appearence="fill" [hideRequiredMarker]="true"
                        floatLabel="never">
                        <input matInput class="pointer-in" type="number" [value]="level.price"
                            (change)="addPriceTicket($event, level, i)">
                        <span matSuffix>€</span>
                    </mat-form-field> -->
                    <div class="input-border input-ticket">
                        <input matInput class="pointer-in" type="number" [value]="level.price"
                            (change)="addPriceTicket($event, level, i)">
                        <i class="fa-light fa-euro-sign icon-primary"></i>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>

        <!-- <div class="container-info-hours mb-1" *ngIf="entrada.value.descuento">
            <i class="fa-light fa-circle-info icon-check"></i>
            <p class="m-0">El descuento se aplicará al precio establecido.</p>
        </div> -->
        <!-- Horarios especiales -->
        <mat-divider></mat-divider>
        <!-- <div class="section-free">
            <div class="d-flex align-items-center justify-content-between">
                <p class="title-free">Horarios especiales</p>
                <mat-slide-toggle formControlName="specialTime"
                    (change)="changeSpecialTimes($event)"></mat-slide-toggle>
            </div> 
        -->

        <!-- Seleccionar horarios -->
        <!-- <div class="special-times" *ngIf="entrada.controls['specialTime'].value">
                <div *ngFor="let schedule of optionsTime; let i = index" class="d-flex">
                    <span class="col text-schedule" [class.small]="!typeDates">
                        {{ !typeDates ? (schedule.stringDate) : (schedule.day | date:'EEE dd' | uppercase) }}
                    </span>
                    <div class="chips-times row align-items-center" *ngIf="schedule.times">
                        <div *ngFor="let time of schedule.times; let n = index"
                            (click)="addSpecialTime(time.horaInicio, schedule)" class="chip-time pointer-in col"
                            [class.active]="specialTimes[(time.horaInicio | date:'HH:mm':'+0000')] && specialTimes[(time.horaInicio | date:'HH:mm':'+0000')].includes(schedule.day)">
                            <span>{{ time.horaInicio | date:'HH:mm':"+0000" }}h</span>
                        </div>
                    </div>
                    <div class="chips-times row align-items-center" *ngIf="!schedule.times">
                        <div (click)="addSpecialTime(schedule.horaInicio, schedule)" class="chip-time pointer-in col"
                            [class.active]="specialTimes[(schedule.horaInicio | date:'HH:mm':'+0000')]?.length > 0">
                            <span>{{ schedule.horaInicio | date:'HH:mm':'+0000' }}h</span>
                        </div>
                    </div>
                </div>
            </div> -->
        <!-- </div> -->

    </form>

    <!-- Info horarios -->
    <!-- <div class="container-info-hours">
        <i class="fa-light fa-circle-info icon-check"></i>
        <p class="m-0">¿Necesitas más horarios?
            Añádelos desde el apartado Fecha y Hora</p>
    </div> -->

    <!-- Añadir ticket -->
    <button class="btn-new-form btn-save w-100" (click)="saveTicket()">GUARDAR</button>
</div>