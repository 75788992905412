import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app-settings';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ContactPanelComponent } from '../../contact-panel/contact-panel.component';
import { UpdateBusinessComponent } from '../update-business/update-business.component';

@Component({
  selector: 'app-info-business',
  templateUrl: './info-business.component.html',
  styleUrls: ['./info-business.component.scss']
})
export class InfoBusinessComponent {

  @Input() business: any;
  @Input() idBusiness: any;
  @Input() owner!: any;
  @Input() description!: any;
  user!: any;
  urlImages: string = AppSettings.MEDIA_URI + '/images/business/';
  imgControl: FormControl = new FormControl(null);
  changedImg: boolean = false;
  profile = this.auth.getProfile();
  rolesProfile: any[] = this.profile?.roleFunctions.map((role: any) => role.role.code);
  admin: boolean = false;
  

  constructor(private dialog: MatDialog, private auth: AuthenticationService, private businessService: ApiBusinessService) { }

  ngOnInit(): void {
    this.rolesProfile.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER') {
        this.admin = true;
        return;
      }
    });
  }

  ngAfterViewInit() {
    this.user = this.auth.getProfile();
  }

  ngOnChanges() {
    if (this.business?.image) {
      this.imgControl.setValue(this.urlImages + this.idBusiness + '/' + this.business.image);
    }
  }

  onFileChange(event: any) {
    let filePath = '';
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (!file.type.match('image.*')) {
        alert('Solo se permite utilizar imagenes');
        return;
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        filePath = reader.result as string;
        this.imgControl.setValue(filePath);
        this.changedImg = true;
        this.saveImage();
      }
    }
    if (!event.target.files) {
      if (this.business.imagen) {
        this.imgControl.setValue(this.urlImages + '/' + this.business.imagen);
      }
    }
  }

  saveImage() {
    this.businessService.updateImage(this.idBusiness, {file: this.imgControl.value}).subscribe({
      next: (res) => {
        if (res) {
          this.openInfoCard('La imagen se ha actualizado correctamente', false, false);
          this.changedImg = false;
        }
      }, error: (err) => {
        this.openInfoCard('¡Vaya! Ha ocurrido un error al intentar actualizar la imagen', true, false);
      }
    })
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

  openUpdateBusiness(valueToChange: any, nameValue: string) {
    const dialogRef = this.dialog.open(UpdateBusinessComponent, {
      panelClass: 'info-dialog',
      data: {
        id: this.idBusiness,
        valueChange: valueToChange,
        name: nameValue,
        value: this.business,
        description: this.description
      }
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res.status == 'changed') {
          // this.business.name = res.value.name;
          this.business.general_conditions = res.value.general_conditions;
          this.business.address = res.value.address;
          this.business.city = res.value.city;
          this.business.country = res.value.country;
          this.business.postal_code = res.value.postal_code;
          this.business.state = res.value.state;
          this.description = res.value.description;
          this.openInfoCard('Los cambios se han guardado correctamente', false, false);
        }
        if (res.status == 'error') {
          this.openInfoCard('¡Vaya! Ha ocurrido un error, vuelve a intentarlo más tarde.');
        }
      }
    })
  }

  openContactBusiness() {
    const dialogRef = this.dialog.open(ContactPanelComponent, {
      panelClass: 'info-dialog',
      data: {
        email: this.user.email
      }
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res?.code == '200') {
          this.openInfoCard('Gracias por contactar con nosotros. En un plazo máximo de 48 horas recibirás una respuesta.', false, false);
        }
        if (res == 'error') {
          this.openInfoCard('¡Vaya! No se ha podido mandar el mensaje. Por favor, inténtalo de nuevo más tarde.');
        }
      }
    })
  }
}
