import { Component, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { Categoria } from 'src/app/interfaces/categoria';
import { Ciudad } from 'src/app/interfaces/ciudad';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CategoriasService } from 'src/app/services/categorias.service';
import { CiudadesService } from 'src/app/services/ciudades.service';
import { CommonService } from 'src/app/services/common.service';
import { LocationService } from 'src/app/services/location.service';
import { StaticService } from 'src/app/services/static.service';
import { NewLokComponent } from '../../new-lok/new-lok/new-lok.component';
import { FiltersComponent } from '../filters/filters.component';
import { MainMenuComponent } from '../main-menu/main-menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // Header Fixed
  isFixed: boolean = false;
  @ViewChild("nav") navElement: any;
  @ViewChild("imgHeader") imgElement: any;
  isHomeComponent: boolean = false;

  // Search
  searchText: string = '';
  @ViewChild('search') search: any;
  placeholder: string = 'Buscar evento';
  searching!: string;

  // Select
  ciudades: Ciudad[];
  categorias: Observable<Categoria[]>;

  cityForm: FormControl = new FormControl('');
  city!: any;
  category!: any;
  profile!: any;
  isAdmin: boolean = false;
  localizacion!: any;

  isHomeInit: boolean = false;
  urlImages: string = AppSettings.MEDIA_URI + '/images/users';

  constructor(private router: Router, private categoriasService: CategoriasService, private ciudadesService: CiudadesService, private commonService: CommonService,
    private locationService: LocationService, private dialog: MatDialog, private auth: AuthenticationService, private staticService: StaticService, private renderer: Renderer2) {
    this.categorias = this.categoriasService.getAllCategories().pipe();
    this.auth.profileStorage.subscribe({
      next: (res) => { 
        this.profile = res;
        this.isAdmin = this.profile?.roleFunctions?.length > 0;
      }
    })
  }

  ngOnInit(): void {
    if (location.href != '/') {
      this.isHomeInit = false;
      this.isHomeComponent = false;
    }
    this.localizacion = localStorage.getItem('localizacion');
    if (this.localizacion != null) {
      let loc = JSON.parse(this.localizacion);
      this.commonService.setLocation(loc.latitud, loc.longitud);
    }
  }

  ngAfterViewInit() {
    this.getLocation();
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (!event.url.startsWith('/category')) {
          this.category = null;
          this.commonService.setCategory(null)
        } else {
          this.commonService.categoryStage.subscribe(cat => {
            this.category = cat;
          });
        }
        if (event.url == '/' || event.url.startsWith('/inicio') || event.url.startsWith('/about')) {
          this.isHomeComponent = true;
          this.onScroll();
        } else {
          this.isHomeComponent = false;
          this.headerWhite();
        }
      }
    });
    this.ciudadesService.getCiudades().subscribe({
      next: (val) => {
        this.ciudades = val;
      },
      complete: () => {
        this.getCityStorage();
      }
    });
    let location = localStorage.getItem('localizacion');
    if (location) {
      let loc = JSON.parse(location);
    }
  }

  getCityStorage() {
    this.commonService.cityStorage.subscribe(city => {
      if (city) {
        this.ciudadesService.getCiudadbyId(city).subscribe(object => {
          this.cityForm.setValue(object);
          this.placeholder = `Encuentra eventos en ${object.nombre}`;
        });
      } else {
        this.city = null;
        this.cityForm.setValue(null);
        this.placeholder = 'Buscar eventos';
      }
      this.city = city;
    });
    let storageCity = localStorage.getItem('city');
    if (storageCity) {
      this.commonService.setCity(+storageCity);
    }
    if (!storageCity) {
      this.getPermission();
    }
  }

  getPermission() {
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      this.locationService.permissionLocaton.next(permissionStatus.state);
      if (permissionStatus.state == 'granted') { this.getLocation() }
      permissionStatus.addEventListener("change", () => {
        this.getGeoLocation(permissionStatus.state);
      });
    });
  }

  getGeoLocation(permission) {
    this.locationService.permision.subscribe(per => {
      if (per == 'granted') {
        this.getLocation();
      }
    })
  }

  async getLocation() {
    await this.locationService.getPosition().then(location => {
      if (location?.lat != this.localizacion?.latitud || location?.lng != this.localizacion?.longitud) {
        this.commonService.setLocation(location.lat, location.lng);
      }
      if (!this.city) {
        this.getCityPosition(location);
      }
    }).catch();
  }

  getCityPosition(location: any) {
    this.locationService.getCityByPosition(location).subscribe(data => {
      let name = data.city || data.locality;
      this.ciudadesService.getCiudadByName(name).subscribe(city => {
        if (city[0]) {
          this.commonService.setCity(city[0].id);
        }
      });
    });
  }


  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.scrollY < 545) {
      this.isFixed = false;
    } else {
      this.isFixed = true;
    }
    if (window.scrollY < 550 && this.isHomeComponent) {
      this.imgElement.nativeElement.style['content'] = 'url(assets/img/citylok_white.svg)';
      this.navElement.nativeElement.classList.add('headerTransparent');
      this.navElement.nativeElement.classList.remove('headerWhite');

    } else {
      this.headerWhite();
    }
  }

  headerWhite() {
    this.isFixed = true;
    this.imgElement.nativeElement.style['content'] = 'url(assets/img/citylok_horizontal.svg)';
    this.navElement.nativeElement.classList.add('headerWhite');
    this.navElement.nativeElement.classList.remove('headerTransparent');
  }

  searchEvents(searching: string) {
    this.searching = searching;
    this.searchText = '';
    if (searching) {
      this.router.navigate(['/results'], { queryParams: { search: searching } })
    } else {
      this.router.navigate(['/']);
    }
  }

  openFilters() {
    let position: any = { top: '0px', right: '0px' };
    let animation: any = "animation-right-dialog";
    if (window.innerWidth < 1200) {
      position = { top: '0px', left: '0px' };
      animation = "animation-left-dialog";
    }
    const dialogRef = this.dialog.open(FiltersComponent, {
      width: "570px",
      panelClass: ['panel-dialog', 'panel-dialog-left', animation],
      position: position
    });
  }

  async changeCity(city: any) {
    if (city?.id) { if (this.city != city?.id) { this.commonService.setCity(city.id); } }
    else if (this.city) { this.commonService.setCity(null); this.cityForm.reset(); }
    if (city) {
      if (!city.id) {
        await this.ciudadesService.getCiudadByName(city).subscribe(
          res => {
            this.ciudades = res;
          }
        )
      }
    } else {
      await this.ciudadesService.getCiudades().subscribe(
        res => {
          this.ciudades = res;
        }
      )
    }
  }

  displayCity(city: any): string {
    return city ? city.nombre : '';
  }

  newLok() {
    if (this.auth.getUsersMe().pipe()) {
      const dialogRef = this.dialog.open(NewLokComponent, {
        panelClass: 'new-lok-dialog',
        disableClose: true
      })
    } else {
      this.staticService.setFrom('subir');
      this.router.navigate(['/login']);
    }
  }

  goToFavorites() {
    if (!this.profile) {
      this.staticService.setFrom('favoritos');
    }
    this.router.navigate(['/profile/favorites']);
  }

  openMenu() {
    const dialogRef = this.dialog.open(MainMenuComponent, {
      width: '440px',
      panelClass: ['main-menu-dialog', 'animate_slideRight'],
      position: {
        top: '0px',
        right: '0px'
      }
    })
  }

  goProfile() {
    let url = !this.isAdmin ? '/profile' : '/panel-control/config/info-profile';
    this.router.navigate([url]);
  }

}
