<div class="container-hours-dates">
    <div class="d-flex justify-content-end">
        <i class="fa-light fa-times fa-lg pointer-in" (click)="closeHoursEmit()"></i>
    </div>

    <div class="title-calendar">
        <!-- <i class="fa-light fa-calendar"></i>
        <p>24 de enero a las 22:00</p> -->
    </div>

    <!-- Calendar -->
    <div class="container-calendar-event">
        <div class="options-swiper d-flex align-items-center justify-content-between">
            <div class="button-swiper-custom button-prev-custom pointer-in">
                <i class="fa-light fa-chevron-left"></i>
            </div>
            <div class="button-swiper-custom button-next-custom pointer-in">
                <i class="fa-light fa-chevron-right"></i>
            </div>
            <swiper #swiperMonths class="swiper swiper-custom-events" [config]="swiperMonthsConfig">
                <ng-template *ngFor="let option of monthsOptions" swiperSlide>
                    <div (click)="goToMonth(dp, option.value)" class="swiper-months"
                        [class.monthActive]="currentMonth == option.value.month">
                        <p class="m-0">{{ option.string }}</p>
                    </div>
                </ng-template>
            </swiper>
        </div>

        <ngb-datepicker class="custom-datepicker-event" #dp [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden"
            (dateSelect)="changeDateSelected($event)" [minDate]="minDate" navigation="none">

            <ng-template #t let-date let-focused="focused">
                <span class="custom-day-event" [class.focused]="hoveredDate != null"
                    [class.select-day]="date.day == selectedDate.day && date.month == selectedDate.month && date.year == selectedDate.year" [class.disabled]="dayDisabled(date)"
                    [class.faded]="isHovered(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                    <span class="inside-day-event" [class.weekend]="isWeekend(date)">{{ date.day }}</span>
                </span>
            </ng-template>
        </ngb-datepicker>
    </div>

    <!-- Hours -->
    <mat-divider class="divider-hour"></mat-divider>
    <div *ngIf="optionsHours.length > 0">
        <div class="container-hours-event">
            <div class="chip-hour" [class.blockChip]="numDaySelect == null || 
             (dateString == (now | date:'yyyy-MM-dd') && (hour.horaInicio | date:'HH':'+0000') <= (now | date:'HH')) 
             || (fechasEvent.typeRepeat == 'week' && !hour.fixedSchedule && !hour.dayWeek.includes(numDaySelect)) 
             || (fechasEvent.typeRepeat == 'month' && !hour.fixedSchedule && !hour.dayMonth.includes(dateDaySelect))"
                [class.activeChip]="hourSelected==hour" (click)="changeHour(hour)" *ngFor="let hour of optionsHours">
                {{ hour.horaInicio | date:'HH:mm':"+0000" }}h</div>
        </div>
        <mat-divider class="divider-hour"></mat-divider>
    </div>


    <!-- Entradas -->
    <div *ngIf="!event.mapEvent; else divOpenMap">
        <div class="continar-tickets-event" *ngIf="optionsTickets?.length > 0">
            <div class="select-tickets">
                <mat-select placeholder="Seleccionar entrada" panelClass="optionsTicket" (click)="openSelect(true)">
                    <mat-option color="primary" (click)="addTicketOption(option)" [class.disableTicket]=""
                        *ngFor="let option of enabledTickets">
                        <span>{{ option.nombre }}</span></mat-option>
                </mat-select>
                <mat-select-trigger matSuffix style="vertical-align: middle;">
                    <i class="fa-light fa-angle-down pointer-in"></i>
                </mat-select-trigger>
            </div>
            <div class="list-select-tickets" *ngIf="selectTickets.length > 0">
                <div class="d-flex align-items-center justify-content-between" *ngFor="let option of selectTickets">
                    <span class="name-ticket">{{ option.ticket.nombre }}</span>
                    <div class="section-number-tickets d-flex align-items-center">
                        <span class="btn-change-ticket" (click)="changeTypeTicket(option, 'delete')">-</span>
                        <input class="number-ticket" type="number" min="1" max="10" [value]="option.number">
                        <span class="btn-change-ticket" (click)="changeTypeTicket(option, 'add')">+</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Total -->
        <div class="section-total-price d-flex justify-content-between align-items-center"
            *ngIf="event?.preciodesde != 0">
            <p>Total</p>
            <p>{{ priceTotal | number:'2.2-2' }}€</p>
        </div>

        <!-- Button -->
        <div class="w-100" *ngIf="!event?.informativo || !event.external">
            <button class="w-100 btn-buy" [class.disabled]="numDaySelect == null || ((optionsHours.length > 0 && !hourSelected) || optionsHours.length <= 0) || (selectTickets.length <= 0)"
                (click)="goBuyTickets()">
                Añadir
                <i class="fa-light fa-cart-plus" style="font-size: 16px;"></i>
            </button>
            <!-- Comprobar si tiene entradas tipo festival -->
            <button class="w-100 btn-buy" *ngIf="bondEnabled" (click)="goBuyTickets()">
                Comprar 
                <!-- Nombre abono -->
                <i class="fa-light fa-cart-plus" style="font-size: 16px;"></i>
            </button>
        </div>
    </div>
    <ng-template #divOpenMap>
        <!-- Button Seats -->
        <div class="w-100">
            <button class="w-100 btn-buy" (click)="openSeatPanel()" [class.disabled]="openingSeats || numDaySelect == null || ((optionsHours.length > 0 && !hourSelected) || optionsHours.length <= 0)">
                Elegir entradas
            </button>
        </div>
    </ng-template>
</div>