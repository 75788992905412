import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InvitationService } from 'src/app/services/invitation.service';
import { PermissionService } from 'src/app/services/permission.service';
import { NewManagerComponent } from '../new-manager/new-manager.component';
import { SelectPlacesManagerComponent } from '../select-places-manager/select-places-manager.component';

@Component({
  selector: 'app-permission-panel',
  templateUrl: './permission-panel.component.html',
  styleUrls: ['./permission-panel.component.scss']
})
export class PermissionPanelComponent implements OnInit {

  @Input() roleActive!: any;
  searchText!: string;
  private searchTerms = new Subject<string>();
  now: any = new Date();

  loadingTable: boolean = true;
  loadingPage: boolean = true;
  finishScroll: boolean = false;
  loadingScroll: boolean = false;

  usersManager!: any[];

  displayedColumns: string[] = ['options', 'email', 'nombre', 'role', 'place'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  sorted!: any;

  page = 1;
  totalPages = 1;
  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;

  // Obtener compaía
  business: any = JSON.parse(localStorage.getItem('business') || '{}');
  places = JSON.parse(localStorage.getItem('places') || '[]');
  idCompany: any = this.business?.id;

  // Select list
  @Input() activeSelectList: boolean = false;
  allSelected: boolean = false;
  selectedItems: any[] = [];
  profile = this.auth.getProfile();
  rolesProfile: any[] = this.profile?.roleFunctions.map((role: any) => role.role.code);
  admin: boolean = false;

  constructor(private permissionService: PermissionService, private router: Router, private activatedRoute: ActivatedRoute, private dialog: MatDialog,
    private invitationService: InvitationService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.rolesProfile.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER') {
        this.admin = true;
        return;
      }
    });
  }

  openNewUser(object?: any) {
    const dialogRef = this.dialog.open(NewManagerComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      disableClose: true,
      width: '750px',
      data: {
        idCompany: this.idCompany,
        object: object
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.user && res.functions) {
        this.openSelectPlace(res);
      }
    });
  }

  openSelectPlace(object: any) {
    const dialogRef = this.dialog.open(SelectPlacesManagerComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      width: '750px',
      disableClose: true,
      data: {
        idCompany: this.idCompany,
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.back) {
        this.openNewUser(object);
      }
      if (res.places) {
        this.sendInvite(object, res);
      }
    });
  }

  sendInvite(object: any, placeRes: any) {
    const payload = {
      name: object.user.name,
      lastname: object.user.lastname,
      email: object.user.email,
      functions: object.functions,
      places: placeRes.places,
      allPlaces: placeRes.allPlaces
    }
    this.invitationService.sendInvitation(this.idCompany, payload).subscribe({
      next: (res) => {
        this.openInfoCard('Invitación enviada correctamente al usuario ' + object.user.email.toLowerCase());
      },
      error: (err) => {
        this.openInfoCard('Error al enviar la invitación al usuario ' + object.user.email.toLowerCase());
      }
    });
  }

  ngAfterViewInit() {
    this.usersManager = [];
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.role) {
        this.searchText = '';
        this.sorted = null;
        this.roleActive = params.role == 'all' ? null : params.role;
      }
      this.getFilter();
    });
    this.searchTerms.pipe(
      debounceTime(300), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.searchText = items;
      this.getFilter();
    });
  }

  getData() {
    this.permissionService.getPermissionCompany(this.idCompany, this.page, this.searchText, this.roleActive, this.sorted).subscribe({
      next: (res) => {
        if (res) {
          if (res.data.length <= 0 || res.data.length < 20) {
            this.finishScroll = true;
          }
          this.usersManager = this.usersManager.concat(res.data);
          this.dataSource = new MatTableDataSource(this.usersManager);
          this.dataSource.sort = this.sort;
        }
        this.loadingPage = false;
        this.loadingTable = false;
        this.loadingScroll = false;
      },
      error: (err) => {
        this.loadingPage = false;
        this.loadingTable = false;
        this.loadingScroll = false;
      }
    });
  }

  getFilter() {
    this.usersManager = [];
    this.page = 1;
    this.finishScroll = false;
    this.loadingTable = true;
    this.getData();
  }

  searchUser(res: string) {
    this.searchTerms.next(res);
  }

  openUser(user: any) {
    if (!user.inviteId) {
      this.router.navigate(['/panel-control/config/permission/function/user', user.id]);
    }
  }

  getRolesUser(roles: any) {
    let rolesString = '';
    let owner = roles.find((data: any) => data.role.code == 'ACCOUNT_OWNER');
    if (owner) {
      return owner.role.name + ', Administración';
    }
    let admin = roles.find((data: any) => data.role.code == 'ACCESS_ADMIN');
    if (admin) {
      return rolesString += admin.role.name;
    }
    roles.forEach((data: any, index: number) => {
      if (data.role.code != 'ACCESS_CLOUD') {
        rolesString += data.role.name + ', ';
      }
    });

    return rolesString.slice(0, -2);
  }

  getPlacesUser(places: any) {
    let placesString = '';
    places.forEach((place: any, index: number) => {
      placesString += place.name + ', ';
    });
    return placesString.slice(0, -2);
  }

  // Sort
  sortData(sort: Sort) {
    const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
    if (sort.direction) {
      this.sorted = { sortedBy: sort.active, sort: isAsc };
    } else {
      this.sorted = null;
    }
    this.getFilter();
  }

  openSendInvite(user: any) {
    // Solo permitir reenviar invitación si la fecha de actualización no es mayor a 2 horas
    this.invitationService.resendInvitation(user.inviteId).subscribe({
      next: (res) => {
        if (res) {
          //  Dialog informativo
          this.openInfoCard('Se ha enviado la invitación al usuario ' + user.email.toLowerCase());
        }
      },
      error: (err) => {
        this.openInfoCard('Error al enviar la invitación al usuario ' + user.email.toLowerCase());
      }
    });
  }

  deleteUser(users: any[]) {
    let userDeleted = users.map((value) => value.id);
    this.permissionService.deleteUsersPermission(this.idCompany, {users: userDeleted}).subscribe({
      next: (res) => {
        if (res) {
          //  Dialog informativo
          this.openInfoCard('El usuario ha sido eliminado correctamente');
        }
      },
      error: (err) => {
        this.openInfoCard('Error al eliminar el usuario');
      }
    });
  }

  openInfoCard(msg: string, disabledClose: boolean = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        text: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getFilter();
    });
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingTable) {
      if (!this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingScroll = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
      } else {
        this.finishScroll = true;
      }
    }
  }

  containsBlocked() {
    return this.selectedItems.filter((item) => item.status == 'blocked').length <= 0;
  }

  activeSelecting() {
    this.activeSelectList = !this.activeSelectList;
    // Eliminar la seleccion actual 
  }

  selectAll() {
    this.dataSource.data.forEach((item) => item.selected = this.allSelected);
    this.selectedItems = this.dataSource.data.filter((item) => item.selected);
  }

  changeSelectItem(item: any) {
    item.selected = !item.selected;
    this.selectedItems = this.dataSource.data.filter((item) => item.selected);
    this.allSelected = this.selectedItems.length === this.dataSource.data.length;
  }

}
