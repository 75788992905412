<div class="orders-panel">
    <div *ngIf="loadingPage" class="container-lottie">
        <div class="loading-container">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>
    </div>

    <div #containerScroll class="table-orders table-panel multiple-lines" *ngIf="loaded"
        (scroll)="isScrolledInfinityView($event)">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort (matSortChange)="sortData($event)">
            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef><b>
                        <div class="d-flex align-items-center" style="gap: 10px;">
                            <mat-checkbox [(ngModel)]="allSelected" class="checkbox" color="primary"
                                (change)="selectAll()" *ngIf="activeSelectList"></mat-checkbox>
                            <span *ngIf="!activeSelectList">Acciones</span>
                        </div>
                    </b></th>
                <td mat-cell *matCellDef="let order">
                    <div class="d-flex align-items-center" style="gap: 10px;">
                        <mat-checkbox class="checkbox" [checked]="allSelected" color="primary"
                            (change)="changeSelectItem(order)" *ngIf="activeSelectList"></mat-checkbox>

                        <div class="d-flex" style="gap: 20px;" *ngIf="!activeSelectList">
                            <div [matMenuTriggerFor]="actionOptions" style="width: 20px;"
                                class="pointer-in d-flex justify-content-center">
                                <i class="icon-option fal fa-ellipsis-vertical"></i>
                            </div>
                            <mat-menu class="options-menu-control-panel" #actionOptions="matMenu">
                                <button mat-menu-item class="deleteIcon" (click)="openOrder(order.id)">Ver</button>
                                <button mat-menu-item class="deleteIcon" (click)="cancelPendingOrder(order)"
                                    *ngIf="order.transaction_status?.id == 5 && allowCancel">Cancelar</button>
                                <button mat-menu-item class="deleteIcon" (click)="downloadTicketOrder(order.id, 'a4')"
                                    *ngIf="order.transaction_status?.id == 1">Imprimir A4</button>
                                <button mat-menu-item class="deleteIcon"
                                    (click)="downloadTicketOrder(order.id, 'ticket')"
                                    *ngIf="order.transaction_status?.id == 1">Imprimir ticket</button>
                                <button mat-menu-item class="deleteIcon" (click)="sendEmailConfirm(order)"
                                    *ngIf="order.transaction_status?.id == 1">Volver a enviar entradas</button>
                                <!-- <button mat-menu-item class="deleteIcon"
                                    (click)="freeSeats(order)"
                                    *ngIf="order.transaction_status?.id == 1 && order.sales_channel?.id != 1">Liberar asientos</button> -->
                                <button mat-menu-item class="deleteIcon" (click)="cancelOrder(order)"
                                    *ngIf="(order.transaction_status?.id == 1 || order.transaction_status?.id == 7) && (allowCitylok || 
                                    (order.sales_channel?.id != 1 && (now|date:'dd/MM/yyyy')==(order.created_at|date:'dd/MM/yyyy')))">Devolver pedido</button>
                            </mat-menu>
                            <i class="icon-option fal fa-print icon-primary pointer-in"
                                [class.disabled-download]="order.status !== 1"
                                (click)="downloadTicketOrder(order.id, 'a4')"></i>
                            <i class="icon-option fal fa-ticket icon-primary pointer-in"
                                [class.disabled-download]="order.status !== 1"
                                (click)="downloadTicketOrder(order.id, 'ticket')"></i>
                        </div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="id">
                <th mat-header-cell mat-sort-header *matHeaderCellDef><b>ID Pedido</b></th>
                <td mat-cell *matCellDef="let order" (click)="openOrder(order.id)">
                    {{order.num_ticket||'-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="name_citylok">
                <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Nombre y apellidos</b></th>
                <td mat-cell *matCellDef="let order" (click)="openOrder(order.id)">
                    {{order.name_citylok||'-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="email_citylok">
                <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Correo electrónico</b></th>
                <td mat-cell *matCellDef="let order" (click)="openOrder(order.id)">
                    {{order.email_citylok||'-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="channel">
                <th mat-header-cell *matHeaderCellDef><b>Canal</b></th>
                <td mat-cell *matCellDef="let order" (click)="openEvent(order.id)">
                    {{order.sales_channel?.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Fecha y hora trans.</b></th>
                <td mat-cell *matCellDef="let order" (click)="openOrder(order.id)">
                    {{order.created_at | date: 'dd/MM/yyyy HH:mm'}} h
                </td>
            </ng-container>
            <ng-container matColumnDef="total_order">
                <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Precio</b></th>
                <td mat-cell *matCellDef="let order" (click)="openOrder(order.id)">
                    {{order.total_order | number:'1.2-2'}} €
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef><b>Estado</b></th>
                <td mat-cell *matCellDef="let order" (click)="openInfoPayment(order)">
                    <div class="d-flex align-items-center" style="gap: 5px;">
                        <i class="fas fa-circle"
                            [style.color]="getColorStatus(order.transaction_status?.short_name)"></i>
                        <span
                            [style.color]="getColorStatus(order.transaction_status?.short_name)">{{order.transaction_status?.name||'-'}}</span>
                        <i class="fal fa-memo-circle-info"></i>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="pagination-component">
            <div class="d-flex align-items-center pagination-panel pagination-table"
                *ngIf="!loadingPage && !finishScroll">
                <div>
                    <ng-lottie class="lottie-pagination" [options]="{path: 'assets/lotties/loading.json'}"
                        containerClass="loading-box"></ng-lottie>
                </div>
            </div>
        </div>
    </div>

    <p *ngIf="loaded && !loadingPage && orders?.length <= 0" style="text-align: center; margin-top: 80px;">No hay
        pedidos disponibles</p>

</div>