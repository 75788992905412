import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { LoginComponent } from '../../shared/login/login.component';

@Component({
  selector: 'app-tickets-bond',
  templateUrl: './tickets-bond.component.html',
  styleUrls: ['./tickets-bond.component.scss']
})
export class TicketsBondComponent {

  ticket!: any;
  priceTotal: number;
  date!: any;
  time!: any;
  eventId!: any;
  eventName!: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<TicketsBondComponent>,
    private router: Router, private shoppingCartService: ShoppingCartService, private dialog: MatDialog, private auth: AuthenticationService) {
    if (data) {
      this.ticket = this.data.ticket;
      this.eventId = this.data.eventId;
      this.eventName = this.data.eventName;
      this.priceTotal = this.ticket.priceTickets[0].price;
      this.date = this.ticket.ticketSpecial[0].day;
      this.time = this.ticket.ticketSpecial[0].hour;
    }
  }

  addTicketBond() {
    let user = this.auth.getProfile();
    if (!user) {
      const dialogRef = this.dialog.open(LoginComponent, {
        panelClass: ['info-dialog', 'dialog-login'],
        data: {
          exit: false,
          seats: true
        }
      });
    }

    if (user) {
      const res = this.getDateTime(this.date, this.time);
      this.data.ticket.price = this.priceTotal;
      const payload = { tickets: [{ ticket: this.data.ticket, number: 1 }], date: res.date, time: res.time, event: this.eventId, eventName: this.eventName, price: this.priceTotal };
      this.shoppingCartService.addToCartSimple(payload).subscribe({
        next: (res) => {
          this.priceTotal = res.price;
          this.dialogRef.close(true);
        },
        error: (err) => {
          if (err.status == 400 && err.error.ticket) {
            // Quitar asientos que ya no están disponibles
            // Volver a llamar a la comprobación de asientos
            this.dialog.open(InfoCardComponent, {
              panelClass: 'info-dialog',
              data: {
                icon: false,
                text: err.error.msg,
                btnCancel: false
              }
            });
          }
        }
      })
    }
  }

  getDateTime(date: any, hour: any) {
    let dateAux = new Date(date);
    let time = null;
    if (hour) {
      let init = new Date(hour);
      dateAux.setUTCHours(init.getUTCHours());
      dateAux.setUTCMinutes(init.getUTCMinutes());

      time = dateAux.toISOString();
    }
    return { date: date, time: time };
  }
}
