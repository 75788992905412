import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { BusinessService } from 'src/app/services/business.service';
import { EventosService } from 'src/app/services/eventos.service';

@Component({
  selector: 'app-select-event-bond',
  templateUrl: './select-event-bond.component.html',
  styleUrls: ['./select-event-bond.component.scss']
})
export class SelectEventBondComponent {

  eventsCompany: any[] = [];
  searchText: string = '';
  allEvents: boolean = false;

  displayedColumns: string[] = ['actions', 'name', 'dates'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  eventSelected: any[] = [];
  page: number = 1;
  finishScroll: boolean = false;
  loaded: boolean = false;
  loadingPage: boolean = true;
  edit: boolean = false;
  object!: any;

  @ViewChild('containerScrollDialog', { static: false }) containerScroll: ElementRef;
  private searchTerms = new Subject<string>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<SelectEventBondComponent>, private businessService: BusinessService) { }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    if (this.edit) {
      this.data?.object.events.forEach(element => {
        this.changeSelectEvent(element);
      });
    }
    this.searchTerms.pipe(
      debounceTime(500), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.searchText = items;
      this.updateEvents();
    });
  }

  getData() {
    let places = [];
    this.edit = this.data?.edit;
    this.businessService.getEventsCompanyFilter(this.data.idCompany, this.page, this.searchText, null, places, false).subscribe({
      next: (res) => {
        this.eventsCompany = this.eventsCompany.concat(res);
        if (this.eventsCompany.length <= 0 || this.eventsCompany.length < 20) {
          this.finishScroll = true;
        }
        this.dataSource = new MatTableDataSource<any>(this.eventsCompany);
        this.loaded = true;
        this.loadingPage = false;
        if (!this.edit) {
          this.selectAll();
        }
      }
    })
  }

  updateEvents() {
    this.page = 1;
    this.loadingPage = true;
    this.finishScroll = false;
    this.eventsCompany = [];
    this.getData();
  }

  selectAll() {
    if (this.allEvents) {
      this.eventSelected = [];
      this.dataSource.data.forEach((event: any) => {
        this.eventSelected.push(event.id);
      });
    }
  }

  changeSelectEvent(event: any) {
    if (this.eventSelected.includes(event.id)) {
      this.eventSelected = this.eventSelected.filter((id: any) => id !== event.id);
    } else {
      this.eventSelected.push(event.id);
    }
    if (this.eventSelected.length == 0) {
      this.allEvents = false;
    }
  }

  searchEvents(event) {
    this.searchTerms.next(event);
  }

  goBackNewBond() {
    this.dialogRef.close({ back: true });
  }

  closeNew() {
    this.dialogRef.close();
  }

  createNewBond() {
    if (this.eventSelected.length > 0) {
      this.dialogRef.close({ object: this.data?.object, events: this.eventSelected, allEvents: this.allEvents });
    }
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingPage) {
      if (!this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingPage = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
        // }
      } else {
        this.finishScroll = true;
      }
    }
  }
}

