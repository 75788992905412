import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';
import { SelectPlacesManagerComponent } from '../select-places-manager/select-places-manager.component';

@Component({
  selector: 'app-new-manager',
  templateUrl: './new-manager.component.html',
  styleUrls: ['./new-manager.component.scss']
})
export class NewManagerComponent implements OnInit {

  openedPermission: boolean = false;
  companyInfo: Observable<any> = new Observable();
  permissionExtra: any[] = [];
  idCompany: any = 1;

  formNewManager: FormGroup = new FormGroup({});
  functionsSelected: any[] = [];
  send: boolean = false;

  constructor(private permissionService: PermissionService, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<NewManagerComponent>,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.data ? this.idCompany = this.data.idCompany : null;
    this.send = this.data.send;
    this.formNewManager = this.formBuilder.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required]
    });
    if(this.data.object) {
      this.formNewManager.patchValue(this.data.object.user);
      this.functionsSelected = this.data.object.functions;
    }
  }

  ngAfterViewInit() {
    this.companyInfo = this.permissionService.getInfoEnabledCompany(this.idCompany).pipe(map((res: any) => res.data));
    this.permissionExtra = [
      {
        name: 'Acceso a informes'
      },
      {
        name: 'Acceso a pedidos',
        extra: [
          {
            name: 'Acceso a devolución de entradas',
          }
        ]
      },
      {
        name: 'Acceso a soporte',
        extra: [
          {
            name: 'Acceso a gestión de comentarios de un evento',
          },
          {
            name: 'Acceso a gestión de comunicaciones por email',
          }
        ]
      },
      {
        name: 'Crear eventos'
      }
    ];
  }

  selectFunctions(functionObject: any) {
    if (this.functionsSelected.includes(functionObject.id)) {
      this.functionsSelected = this.functionsSelected.filter((func: any) => func !== functionObject.id);
    } else {
      this.functionsSelected.push(functionObject.id);
    }
  }

  cancelNew() {
    this.dialogRef.close();
  }

  nextStep() {
    if (this.formNewManager.valid && this.functionsSelected.length > 0) {
      // Indicar abrir select place
      this.dialogRef.close({ user: this.formNewManager.value, functions: this.functionsSelected });
    }
  }

}
