import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  // Filter
  private filterSelected = new BehaviorSubject<any[]>([]);
  public filter = this.filterSelected.asObservable();
  private allFilters: any[] = [];

  private searchString = new BehaviorSubject<any>(null);
  public searching = this.searchString.asObservable();

  private orderBySelected = new BehaviorSubject<any>(null);
  public orderBy = this.orderBySelected.asObservable();

  private categoriesSelected = new BehaviorSubject<any[]>([]);
  public categories = this.categoriesSelected.asObservable();

  private today = new Date();
  private tomorrow = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1);
  private datesSelected = new BehaviorSubject<any>(null);
  public dates = this.datesSelected.asObservable();

  private pricesSelected = new BehaviorSubject<any>(null);
  public prices = this.pricesSelected.asObservable();

  private locationSelected = new BehaviorSubject<any>(null);
  public location = this.locationSelected.asObservable();

  constructor() { 
    let filters = localStorage.getItem('filters');
    if(filters) {
      const json = JSON.parse(filters);
      this.allFilters = json;
      this.filterSelected.next(json);
      let categories = json.filter(value => value.type == 'category');
      this.setCategories(categories);
      this.setDatesRange(json.find(value => value.type == 'fecha'));
      let price = json.find(value => value.type == 'price');
      if(price) { this.setPrices(price.max, price.min) }
      let order = json.find(value => value.type == 'order');
      if(order) { this.setOrderBy(order) }
    }
    this.searchString.next(localStorage.getItem('search'));
  }

  getFiltersValue() {
    return this.filterSelected.getValue();
  }

  setOrderBy(order: any) {
    this.orderBySelected.next(order);
    if(order) {
      this.addFilter(order);
    }
  }

  setCategories(categories: any[]) {
    this.categoriesSelected.next(categories);
    categories.forEach(element => {
      let name = element.nombre || element.name;
      let cat = {id: element.id, name: name, type: 'category'}
      this.addFilter(cat);
    });
  }

  removeCategory(category: any) {
    let categories = this.categoriesSelected.getValue();
    let index = categories.findIndex(value => value.id == category.id);
    if(index != -1) {
      categories.splice(index, 1);
      this.categoriesSelected.next(categories);
    }
  }

  setDatesRange(dates: any) {
    this.datesSelected.next(dates);
    this.addFilter(dates);
  }

  setPrices(max: number, min: number = 0) {
    const price = {id: 1, name: 'Precio', type: 'price', min: min, max: max};
    this.pricesSelected.next(price);
    if(max != this.getMaxPrice() || min != 0) {
      this.addFilter(price);
    }
  }

  addFilter(filter: any) {
    let arr: any[] = this.allFilters;
    if(filter) {
      let find = arr.findIndex(value => (value.type == filter.type && value.id == filter.id));
      if(find == -1) {
        arr.push(filter);
        this.allFilters = arr;
      }else if(filter.type == 'location') {
  
        this.allFilters[find].minLon = filter.minLon;
        this.allFilters[find].minLat = filter.minLat;
        this.allFilters[find].maxLon = filter.maxLon;
        this.allFilters[find].maxLat = filter.maxLat;
      }
    
    }
  }

  removeFilter(filter: any) {
    let arr: any[] = this.filterSelected.getValue();
    let find = arr.findIndex(value => (value.type == filter.type && value.id == filter.id));
    if(find != -1) {
      arr.splice(find, 1);
      switch (filter.type) {
        case 'category':
          this.removeCategory(filter);
          break;
        case 'fecha':
          this.datesSelected.next(null);          
          break;
        case 'price':
          this.pricesSelected.next(null);
          break;
        case 'order':
          this.orderBySelected.next(null);
          break;
        default:
          break;
      }
      this.allFilters = arr;
    }  
    this.saveJsonFilters();
  }

  saveJsonFilters() {
    this.filterSelected.next(this.allFilters);
    if(this.allFilters.length > 0) {
      const json = JSON.stringify(this.filterSelected.getValue());
      localStorage.setItem('filters', json);
    } else {
      localStorage.removeItem('filters');
    }
  }

  resetFilters() {
    localStorage.removeItem('filters');
    this.allFilters = [];
  }

  resetAllFilters() {
    this.resetFilters();
    this.saveJsonFilters();
    this.orderBySelected.next(null);
    this.categoriesSelected.next([]);
    this.datesSelected.next(null);
    this.pricesSelected.next(null);
  }

  getOrderOptions() {
     return [
      {id: 1, name: 'Fecha (próximos)', type: 'order', filter: 'fechas.fechaInicio', mode: 'asc'},
      {id: 2, name: 'Nuevos', type: 'order', filter: 'created', mode: 'desc'},
      {id: 3, name: 'Destacados', type: 'order', filter: 'mediavaloraciones', mode: 'desc'},
      {id: 4, name: 'Precio más bajo', type: 'order', filter: 'preciodesde', mode: 'asc'},
      {id: 5, name: 'Precio más alto', type: 'order', filter: 'preciodesde', mode: 'asc'}
    ];
  }

  getDatesOptions() {
    this.today = new Date();
    this.tomorrow = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1);
    const firstDayWeek = this.today.getDate() - this.today.getDay()+1;
    let fridayWeek = new Date(this.today.getFullYear(), this.today.getMonth(), firstDayWeek+4);
    if(fridayWeek < this.today) {
      fridayWeek = this.today;
    }
    const sundayWeek = new Date(this.today.getFullYear(), this.today.getMonth(), firstDayWeek+6);
    const firstNextWeek = new Date(this.today.getFullYear(), this.today.getMonth(), firstDayWeek+7);
    const lastNextWeek = new Date(this.today.getFullYear(), this.today.getMonth(), firstDayWeek+13);
    const lastDayMonth = new Date(this.today.getFullYear(), this.today.getMonth()+1, 0);
    const firstNextMonth = new Date(this.today.getFullYear(), this.today.getMonth()+1, 1);
    const nextMonth = new Date(this.today.getFullYear(), this.today.getMonth()+2, 0);
    return [
      {id: 1, name: 'Hoy', type: 'fecha', desde: this.today, hasta: this.today},
      {id: 2, name: 'Mañana', type: 'fecha', desde: this.tomorrow, hasta: this.tomorrow},
      {id: 3, name: 'Este fin de semana', type: 'fecha', desde: fridayWeek, hasta: sundayWeek},
      {id: 4, name: 'Esta semana', type: 'fecha', desde: this.today, hasta: sundayWeek},
      {id: 5, name: 'La próxima semana', type: 'fecha', desde: firstNextWeek, hasta: lastNextWeek},
      {id: 6, name: 'Este mes', type: 'fecha', desde: this.today, hasta: lastDayMonth},
      {id: 7, name: 'El próximo mes', type: 'fecha', desde: firstNextMonth, hasta: nextMonth}
    ];
  }

  selectDateToday() {
    const optionToday = this.getDatesOptions()[0];
    this.setDatesRange(optionToday);
    this.saveJsonFilters();
  }

  getMaxPrice() {
    return 255;
  }

  setLocation(locationObjet: any) {
    let valueLocation = this.locationSelected.getValue()
    if( valueLocation?.minLon != locationObjet?.minLon || valueLocation?.minLat != locationObjet?.minLat) {
      if(locationObjet) {
        this.addFilter(locationObjet);
        this.saveJsonFilters();
      }
      this.locationSelected.next(locationObjet);
    }

  }

  getUrlFilters() {
    let queryParams: any = {};
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    const fecha = this.datesSelected.getValue();
    if(fecha) {
      if(fecha.desde < this.today) {
        fecha.desde = this.today;
      }
      const desde = formatDate(fecha.desde, 'yyyy-MM-dd', 'es-ES')
      const hasta = formatDate(fecha.hasta, 'yyyy-MM-dd', 'es-ES')
      queryParams['fechas.fechaInicio[after]'] = desde;
      queryParams['fechas.fechaFin[before]'] = hasta;
    } else {
      queryParams['fechas.fechaInicio[after]'] = now;
    }
    const categories = this.categoriesSelected.getValue();
    categories.forEach(element => {
      queryParams['categoria.id[]'] = element.id;
    });
    const prices = this.pricesSelected.getValue();
    if(prices) {
      queryParams['preciodesde[gte]'] = prices.min;
      queryParams['preciodesde[lte]'] = prices.max;
    };
    const orderBy = this.orderBySelected.getValue();
    if(orderBy) {
      queryParams[`order[${orderBy.filter}]`] = orderBy.mode;
    }
    return queryParams;
  }
}
