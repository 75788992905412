<div class="home-wrapper">
    <div class="home-swiper">
        <div slot="container-start" class="swiper-bg">
            <div class="img-container"></div>
            <div class="swiper-container h-100 position-absolute m-auto w-100">
                <div class="slide-wrapper container-text-white container">
                    <!-- <p class="clash-medium">eventos</p> -->
                    <div>
                        <h1 class="mb-2 text-center text-md-start">¿Qué hacer y qué ver?</h1>
                        <p class="text-center mb-4" [class.clash-medium]="city">Buscar planes en <span
                                class="city-selected" (click)="resetCity()">{{ city }}</span></p>
                        <div class="container-select-border container-search-home" *ngIf="!city"
                            ng-focus="focusActive=true" [class.active]="focusActive">
                            <mat-form-field appearance="fill">
                                <!-- <div style="align-items: center; display: flex;"> -->
                                <input type="text" class="select-border select-white" [placeholder]="placeholder"
                                    onblur="this.placeholder='Selecciona lugar'"
                                    onfocus="this.placeholder='Buscar ciudad'" matInput [(ngModel)]="selectLugar"
                                    (ngModelChange)="setCity($event)" [matAutocomplete]="autoComboCity">
                                <mat-autocomplete #autoComboCity="matAutocomplete" [displayWith]="displayCity"
                                    class="panelHomeCity">
                                    <mat-option *ngFor="let ciudad of ciudades" [value]="ciudad"> {{ ciudad.nombre }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-select-trigger matSuffix style="vertical-align: middle;">
                                    <i class="fal fa-angle-down pointer-in"></i>
                                </mat-select-trigger>
                                <!-- </div> -->
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="sections-home">
    <div class="first-cards padding-home" *ngIf="(explorar|async)?.length > 0">
        <app-cards *ngIf="(explorar|async)?.length > 0" sizeTitle="big" [title]="(tituloObs | async)"
            [titleWhite]="true" [events]="explorar"></app-cards>
        <div class="container" #container>
        <div class="title-cards d-flex pointer-in align-items-center title-disc" [class.color-white]="titleWhite"
            [class.pointer-in]="link" (click)="goToLink()">
            <p class="h2" style="margin-bottom: 30px;">Descubre todos los eventos</p>
            <!-- <i *ngIf="arrow" class="fal fa-long-arrow-right icon-title fa-2x"></i> -->
        </div>
    </div>
</div>

<!-- <div class="default-cards" *ngIf="(destacados | async)?.length > 0">
        <app-cards title="Descubre eventos" [events]="destacados" [small]="false" link="/destacados"></app-cards>
    </div> -->

<!-- <div class="default-cards" *ngIf="(eventosCategoriasHome[0]?.events | async)?.length > 0">
        <app-cards title="{{ eventosCategoriasHome[0]?.titulo }}" [events]="eventosCategoriasHome[0]?.events" link="/category/{{eventosCategoriasHome[0]?.slug}}"></app-cards>
    </div> -->

<div class="first-cards container infinity-home">
    <app-infinity-scroll [fromHome]="true"></app-infinity-scroll>
</div>

<div class="default-cards">
    <app-circles></app-circles>
</div>

<!-- <div class="default-cards" *ngFor="let category of eventosCategoriasHome | slice:1 ; let i = index">
        <app-cards *ngIf="(category?.events | async)?.length > 0" title="{{ category?.titulo }}" [events]="category?.events" link="/category/{{category.slug}}"></app-cards>
    </div> -->

<!-- <div class="default-cards">
        <app-info-loks></app-info-loks>
    </div> -->
</div>

<!-- <div>
    <app-download-app></app-download-app>
</div> -->
<!-- 
<div class="icon-chatbot w-100">
    <div class="container-chatbot-btn pointer-in" (click)="openChatbot()">
        <img src="../assets/img/icon-chatbot-white.svg" alt="">
    </div>
</div> -->

<!-- <div class="icon-new-lok w-100" *ngIf="profile?.sms">
    <div class="container-new-lok" (click)="newLok()">
        <i class="fa-solid fa-location-pin icon-background-lok fa-4x"></i>
        <i class="fa-light fa-camera fa-camera-lok fa-xl"></i>
    </div>
</div> -->