<div class="container-card" [class.list-card]="list">
    <div class="etiqueta-event pointer-in" (click)="goToPage(card)"
        [class.tag-fire]="card.numvaloraciones > 20 || card.mediavaloraciones > 4" [class.tag-new]="card.new" [class.tag-today]="datesEquals(card)">
        <i class="fal {{ tagIcon(card) }}"></i> {{ tagString(card) }}
    </div>
    <div class="container-img pointer-in" (click)="goToPage(card)">
        <img (error)="imageUrl = '../../../../assets/img/image_default.png'" class="img-card-event" [src]="imageUrl" />                        
    </div>
    <div class="info-card d-grid">
        <div class="d-flex w-100 align-items-center">
            <i class="fa-heart icon-card pointer-in" [class]="isWishlist ? 'fas icon-primary' : 'fal'" (click)="editFavorito()"></i>         
            <i class="fal fa-share-alt pointer-in" *ngIf="sharedSupported" (click)="shareEvent()"></i>                        
            <span *ngIf="card.preciodesde == 0 && !card.imageurl?.includes('http') && !card.external && !card.informativo" class="price-free">GRATUITO</span>
            <span *ngIf="card.preciodesde == null || (card.external && (card.imageurl.includes('http')) || card.external || card.informativo)" class="price-free">+INFO</span>
            <span *ngIf="card.preciodesde > 0 && !card.imageurl?.includes('http')" class="justify-self-end price-event">Desde <span class="price-free">{{ card.preciodesde }} €</span></span>
        </div>
        <p class="text-info-event pointer-in" (click)="goToPage(card)">{{ card.titulo }}</p>
        <div class="align-self-end date-event pointer-in" (click)="goToPage(card)"
        *ngIf="!card.fechaInicio && (!card.datesRange?.end || (card.datesRange?.end | date: 'dd MMM') == (card.datesRange?.start | date: 'dd MMM')); else divRange">
            <i class="fa-light fa-calendar"></i>         
            <span>{{ (card.datesRange?.next | date: 'dd MMM') == (today | date: 'dd MMM') ? 'Hoy' : (card.datesRange?.next | date: 'EEE dd MMM' | titlecase) }}</span>
            <span *ngIf="card.hourStart != null">|</span>
            <span *ngIf="card.hourStart">{{ card.hourStart }}</span>
        </div>
        <ng-template #divRange>
            <div class="align-self-end date-event pointer-in" (click)="goToPage(card)" *ngIf="card.datesRange">
                <i class="fa-light fa-calendar"></i>         
                <span>{{ (card.datesRange?.start | date: 'dd MMM') }}</span>
                <span> - </span>
                <span>{{ (card.datesRange?.end | date: 'dd MMM') }}</span>
            </div>
            <div class="align-self-end date-event pointer-in" (click)="goToPage(card)" *ngIf="!card.datesRange">
                <i class="fa-light fa-calendar"></i>         
                <span>{{ (card.fechaInicio | date: 'EEE dd MMM') }}</span>
                <span *ngIf="card.fechaFin && (card.fechaInicio | date: 'dd MMM') != (card.fechaFin | date: 'dd MMM')"> - </span>
                <span *ngIf="card.fechaFin && (card.fechaInicio | date: 'dd MMM') != (card.fechaFin | date: 'dd MMM')">{{ (card.fechaFin | date: 'EEE dd MMM') }}</span>
            </div>
        </ng-template>
    </div>
</div>