import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-user-purchases',
  templateUrl: './user-purchases.component.html',
  styleUrls: ['./user-purchases.component.scss']
})
export class UserPurchasesComponent {

  searchText!: string;
  loadingTable: boolean = true;
  finishScroll: boolean = false;
  loadingScroll: boolean = false;
  loaded: boolean = false;
  users!: any[];

  displayedColumns: string[] = ['options', 'name', 'email', 'purchases'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  sorted!: any;

  page = 1;
  totalPages = 1;

  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;

  private searchTerms = new Subject<string>();
  business = JSON.parse(localStorage.getItem('business') || '{}');
  places = JSON.parse(localStorage.getItem('places') || '[]');

  constructor(private router: Router, private placeService: PlacesService, private apiBusiness: ApiBusinessService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.users = [];
    this.searchTerms.pipe(
      debounceTime(300), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.getFilter();
    });

    this.business = JSON.parse(localStorage.getItem('business') || '{}');
    this.places = JSON.parse(localStorage.getItem('places') || '[]');

    this.apiBusiness.changed.subscribe({
      next: (value) => {
        if (value) {
          this.business = JSON.parse(localStorage.getItem('business') || '{}');
          this.searchText = value?.searchOrder;

          this.users = [];
          this.getFilter();
        }
      }
    })
  }

  getData() {
    this.placeService.getPurchasesVenues(this.business?.id, this.page, this.places, this.searchText, this.sorted).subscribe({
      next: (res) => {
        if (res) {
          this.users = this.users.concat(res);
          if (this.users.length <= 0 || this.users.length < 20) {
            this.finishScroll = true;
          }
          this.dataSource = new MatTableDataSource(this.users);
        }
        this.loaded = true;
        this.loadingTable = false;
        this.loadingScroll = false;
      },
      error: (err) => {
        this.finishScroll = true;
        this.loaded = true;
        this.loadingTable = false;
        this.loadingScroll = false;
      }
    });
  }

  getFilter() {
    this.users = [];
    this.page = 1;
    this.loadingTable = true;
    this.loadingScroll = true;
    this.finishScroll = false;
    this.getData();
  }

  searchUser(res: string) {
    this.searchText = res;
    this.searchTerms.next(this.searchText);
  }

  // Sort
  sortData(sort: Sort) {
    const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
    if (sort.direction) {
      let sortedBy = sort.active == 'name' ? 'nombre' : sort.active;
      this.sorted = { sortedBy: sortedBy, sort: isAsc };
    } else {
      this.sorted = null;
    }
    this.getFilter();
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingTable) {
      if (!this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingScroll = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
      } else {
        this.finishScroll = true;
      }
    }
  }
}
