<div class="container-swiper-images">
    <div class="button-swiper-custom button-prev-custom pointer-in" [class.d-md-none]="images.length<=2"><i
            class="fa-light fa-chevron-left fa-lg"></i></div>
    <div class="button-swiper-custom button-next-custom pointer-in" [class.d-md-none]="images.length<=2"><i
            class="fa-light fa-chevron-right fa-lg"></i></div>
    <swiper #swiperImages class="swiper" [config]="swiperImagesConfig">
        <ng-template class="imagesCards" *ngFor="let item of images; index as i" swiperSlide>
            <img fallimg class="img-single-swiper" src="{{urlImages}}/{{idEvent}}/extra/{{item.image}}" />
        </ng-template>
    </swiper>
</div>