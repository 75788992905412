import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PlacesService } from 'src/app/services/places.service';
import { SalesService } from 'src/app/services/sales.service';

@Component({
  selector: 'app-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss']
})
export class MenuPanelComponent implements OnInit {

  @Input() itemActive = 1;
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();
  profile: any = this.auth.getProfile();

  placeSelect!: any;
  placesUser!: Observable<any>;
  rolesUser!: Observable<any>;

  companySelect: any = {
    name: 'AYTO. MIRANDA DE EBRO',
    id: 1
  };
  allBusiness!: any[];
  places: any[] = [];
  rolesProfile: any[] = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
  admin: boolean = false;
  allowOrder: boolean = false;
  allowSales: boolean = false;
  allowMarketing: boolean = false;
  loadingPlaces: boolean = true;
  allowSuperAdmin: boolean = false;

  constructor(private router: Router, private auth: AuthenticationService, private placesServices: PlacesService,
    private apiBusiness: ApiBusinessService, private salesService: SalesService) { }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
    this.allowSuperAdmin = this.profile.citylok;
    this.companySelect = localStorage.getItem('business');

    this.apiBusiness.getAllBusiness().subscribe({
      next: (res: any) => {
        this.loadingPlaces = true;
        this.allBusiness = res;
        this.companySelect = localStorage.getItem('business') ? JSON.parse(localStorage.getItem('business') || '{}') : this.allBusiness[0];
        if (this.companySelect) {
          this.placesUser = null;
          this.apiBusiness.setBusinessStorage(this.companySelect);
          this.getPlaces();
        }
      }
    });

    this.rolesProfile?.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER') {
        this.admin = true;
      }
      if (element == 'ACCESS_SALES') {
        this.allowSales = true;
      }
      if (element == 'ACCESS_SUPPORT' || element == 'ACCESS_REPORTS' || element == 'ACCESS_SALES') {
        this.allowOrder = true;
      }
      if (element == 'ACCESS_MARKETING' || element == 'ACCESS_SUPPORT') {
        this.allowMarketing = true;
      }
    });
  }

  ngAfterViewInit() {

  }

  getPlaces() {
    this.placesUser = this.placesServices.getPlacesUser(this.companySelect?.id).pipe();
    this.placeSelect = localStorage.getItem('places') ? JSON.parse(localStorage.getItem('places')) : null;
    if (this.placeSelect) {
      this.changePlaceUser();
    } else {
      this.apiBusiness.setChanges(this.companySelect);
    }
    this.loadingPlaces = false;
  }

  activeTab(tab: number, name: string) {
    this.itemActive = tab;
    this.changeTab.emit({ tab: tab, name: name });
  }

  changeBusinessEdit(business: any) {
    this.companySelect = business;
    localStorage.removeItem('places');
    this.apiBusiness.setBusinessStorage(business);
    this.getPlaces();
  }

  changePlaceUser() {
    this.apiBusiness.setPlaceStorage(this.placeSelect);
    let place = this.placeSelect ? this.placeSelect : 'place';
    this.apiBusiness.setChanges(place);
  }

  openTicketOffice() {
    if (!this.admin && !this.allowSales) {
      return;
    }
    this.salesService.getTokenSales(this.companySelect?.id).subscribe({
      next: (value) => {
        if (value.token) {
          const url = `${AppSettings.SALES}/login/${value.token}`;
          window.open(url, '_blank');
        } else {
          console.log('Error Sales')
        }
      },
      error: (err) => {
        console.log('Error Sales')
      }
    });
  }

  goEditProfile() {
    this.router.navigate(['/panel-control/config/info-profile']);
  }

  logout() {
    this.auth.logout();
  }

}
