import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventosService } from 'src/app/services/eventos.service';
import { OrdersEventService } from 'src/app/services/orders-event.service';

@Component({
  selector: 'app-map-panel',
  templateUrl: './map-panel.component.html',
  styleUrls: ['./map-panel.component.scss']
})
export class MapPanelComponent implements OnInit {

  @Input() event!: any;
  capacity!: any;
  map!: any;
  business = JSON.parse(localStorage.getItem('business') || '{}');

  openedCreateMap: boolean = false;
  editCapacity: boolean = false;
  allowCreate: boolean = false;

  constructor(private eventService: EventosService, private dialog: MatDialog, private activatedRoute: ActivatedRoute,
    private eventosService: EventosService, private auth: AuthenticationService, private ordersEventService: OrdersEventService) { }

  ngOnInit(): void {
    let rolesProfile = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
    rolesProfile?.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'CREATE_EVENTS') {
        this.allowCreate = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        let idEvent = +params.idEvent;
        this.eventService.getEventById(idEvent).subscribe({
          next: (res: any) => {
            this.event = res;
            this.capacity = res.capacity;
            if (res.mapEvent) {
              this.map = res.mapEvent;
              this.eventService.setActionMap({ open: true });
            }
          }
        });
      }
    });
  }

  changeCapacity() {
    if (this.editCapacity) {
      // Comprobar aforo antes de cambiar.
      // Añadir fecha y hora seleccionadas
      this.ordersEventService.getCountTickets(this.event.id).subscribe({
        next: (val) => {
          if(this.capacity>=val.count) {
            this.eventosService.putEventos(this.event.id, { capacity: this.capacity }).subscribe({
              next: (result) => {
                this.openInfoCard('Los cambios se han guardado correctamente.', false);
              }
            });
          } else {
            this.capacity = val.count;
            this.openInfoCard('No se puede poner un aforo menor que las entradas vendidas.', false);
          }
        }, error: (err) => {

        }
      })
    }
    this.editCapacity = !this.editCapacity;
  }

  createMap() {
    // this.openedCreateMap = !this.openedCreateMap;
    // let action = !this.openedCreateMap ? 'close' : 'edit';
    // this.eventService.setActionMap({ action: action });
    this.openInfoCard('Aún no puedes crear mapas de butacas, por favor, contacta con el administrador de Citylok para más información.');
  }

  openInfoCard(msg: string, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

  ngOnDestroy(): void {
    this.eventService.setActionMap({ open: false });
  }

}
