import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class ApiBusinessService {

  private businessObject = new BehaviorSubject<any>(null);
  public businessStorage = this.businessObject.asObservable();

  private placeObject = new BehaviorSubject<any>(null);
  public placeStorage = this.placeObject.asObservable();

  private existChanges = new BehaviorSubject<any>(null);
  public changed = this.existChanges.asObservable();

  constructor(private http: HttpClient) { }

  setBusinessStorage(business: any) {
    this.businessObject.next(business);
    localStorage.setItem('business', JSON.stringify(business));
  }

  setPlaceStorage(places: any) {
    this.placeObject.next(places);
    localStorage.setItem('places', JSON.stringify(places));
  }

  setChanges(places: any) {
    this.existChanges.next(places);
  }

  getAllBusinessAdmin(page: number, search: string|null, sort: any) {
    const url = `${AppSettings.API_URI}/all/business/admin`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getAllBusiness() {
    const url = `${AppSettings.API_URI}/all/business/user`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getBusinessById(idBusiness: number) {
    const url = `${AppSettings.API_URI}/business/${idBusiness}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  setBusinessById(idBusiness: number, payload: any) {
    const url = `${AppSettings.API_URI}/upload/business/${idBusiness}`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  updateImage(businessId: number, payload: any) {
    const url = `${AppSettings.API_URI}/upload/image/business/${businessId}`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

}
