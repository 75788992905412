<div class="form-dates-lok">
    <div class="container-dates">
        <!-- Fecha -->
        <p class="m-0 title-section-create">Fecha<span class="required-field">*</span></p>
        <div class="calendars-selection section-dates">
            <div>
                <p class="title-dates">Empieza</p>
                <div class="select-style calendar-date pointer-in" [class.active]="typeDatepicker == 'start'"
                    (click)="openDatePicker('start')">
                    <i class="fa-light fa-calendar"></i>
                    <input placeholder="Fecha inicio" class="input-date" readonly [(ngModel)]="startStringDate">
                    <i class="icon-down fa-light fa-chevron-down"></i>
                </div>
                <div class="calendar-option" *ngIf="typeDatepicker == 'start'">
                    <app-calendar-event-dates [selectedDate]="startDate" (saveDate)="saveDate($event)"
                        [minDate]="dateOld"></app-calendar-event-dates>
                </div>
            </div>
            <div *ngIf="!fecha.controls['repeat'].value">
                <p class="title-dates">Termina</p>
                <div class="calendar-date select-style pointer-in" [class.active]="typeDatepicker == 'end'"
                    (click)="openDatePicker('end')">
                    <i class="fa-light fa-calendar"></i>
                    <input placeholder="Fecha fin" class="input-date" readonly [(ngModel)]="endStringDate">
                    <i class="icon-down fa-light fa-chevron-down"></i>
                </div>
                <div class="calendar-option" *ngIf="typeDatepicker == 'end'">
                    <app-calendar-event-dates [selectedDate]="endDate" [minDate]="startDate"
                        (saveDate)="saveDate($event)"></app-calendar-event-dates>
                </div>
            </div>
        </div>
        <form [formGroup]="fecha">
            <!-- Repetir -->
            <!-- <div class="section-dates" *ngIf="profile?.site">
                <div class="d-flex align-items-center">
                    <p class="title-section-dates m-0">Se repite</p>
                    <mat-slide-toggle formControlName="repeat" (change)="changeTypeHour()"></mat-slide-toggle>
                </div>
                <div *ngIf="fecha.controls['repeat'].value"> -->
            <!-- Repatir cada -->
            <!-- <div class="d-flex align-items-center">
                        <p class="title-dates m-0">Repetir cada </p>
                        <input class="select-option-dates" type="number" min="1" [max]="maxType"
                            formControlName="repeatCount" placeholder="0">
                        <div class="select-style pointer-in">
                            <mat-select panelClass="optionsFormPayment" formControlName="typeRepeat"
                                (selectionChange)="infoPeriod()" placeholder="Seleccionar">
                                <mat-option value="week">Semana</mat-option>
                                <mat-option value="month">Mes</mat-option>
                                <mat-option value="year">Año</mat-option>
                            </mat-select>
                            <mat-select-trigger matSuffix style="vertical-align: middle;">
                                <i class="fal fa-angle-down pointer-in"></i>
                            </mat-select-trigger>
                        </div>
                    </div> -->
            <!-- Repetir el -->
            <!-- <div class="mt-2">
                        <p class="title-dates m-0">Se repite el </p>
                        <div class="d-grid options-repeat">
                            <div class="option-day" (click)="addDay(opt)" [class.active]="selectRepeat.includes(opt.number)"
                                *ngFor="let opt of repeatWeek; let index = i">{{ opt.day }}</div>
                        </div>
                    </div> -->
            <!-- Termina -->
            <!-- <div class="align-items-center mt-2 end-repeat">
                        <p class="title-dates">Se repite hasta</p> -->
            <!-- <div class="select-style pointer-in" style="margin-left: 20px;">
                            <mat-select [(ngModel)]="endRepeat" [ngModelOptions]="{standalone: true}"
                                panelClass="optionsFormPayment" required placeholder="Seleccionar">
                                <mat-option value="date">La fecha</mat-option>
                                <mat-option value="never">Nunca</mat-option>
                            </mat-select>
                            <mat-select-trigger matSuffix style="vertical-align: middle;">
                                <i class="fal fa-angle-down pointer-in"></i>
                            </mat-select-trigger>
                        </div> -->
            <!-- <div>
                            <div class="select-style pointer-in" (click)="openDatePicker('end')">
                                <i class="fa-light fa-calendar"></i>
                                <input placeholder="Seleccionar fecha" class="input-date pointer-in" readonly 
                                [(ngModel)]="endStringDate" [ngModelOptions]="{standalone: true}">
                                <i class="icon-down fa-light fa-chevron-down"></i>
                            </div>
                            <div class="calendar-option" *ngIf="typeDatepicker == 'end'">
                                <app-default-calendar [selectedDate]="endDate" [minDate]="startDate"
                                    (saveDate)="saveDate($event)"></app-default-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="info-period d-flex align-items-center" *ngIf=infoRep>
                        <i class="fa-light fa-info-circle"></i>
                        <p class="m-0">{{ infoRep }} </p>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider> -->
            <!-- Horas -->
            <p class="title-section-create" style="margin-top: 20px; margin-bottom: 0px;">Hora<span class="required-field">*</span></p>
            <div class="section-dates">

                <!-- <div class="d-flex align-items-center mt-1" style="margin-bottom: 10px;" *ngIf="!existVenue">
                    <p class="title-dates m-0">Sin horarios</p>
                    <mat-slide-toggle formControlName="informativo"></mat-slide-toggle>
                </div> -->
                <div *ngIf="!fecha.value.informativo">
                    <!-- <div class="d-flex align-items-center mt-1" style="margin-bottom: 10px;">
                        <p class="title-dates m-0">Mismo horario todos los días</p>
                        <mat-slide-toggle [(ngModel)]="sameHours" (ngModelChange)="changeTypeHour()"
                            [ngModelOptions]="{standalone: true}"></mat-slide-toggle>
                    </div> -->
                    <div *ngIf="(selectedSchedules && sameHours); else divSchedules" class="chips-hours row">
                        <div *ngFor="let time of selectedSchedules; let i = index" class="chip-item pointer-in col">
                            <span>{{ time.horaInicio | date:'HH:mm':"+0000" }}h</span>
                            <i class="fa-light fa-times pointer-in" (click)="deleteTime(i)"></i>
                        </div>
                    </div>
                    <ng-template #divSchedules>
                        <div *ngFor="let schedule of selectedSchedulesRepeat; let i = index" class="d-flex">
                            <span class="col text-schedule" [class.small]="fecha.controls['repeat'].value">{{
                                fecha.controls['repeat'].value ? (schedule.day) : (schedule.day | date:'EEE dd' |
                                uppercase)
                                }}</span>
                            <div class="chips-hours row align-items-center">
                                <div *ngFor="let time of schedule.times; let n = index"
                                    class="chip-item pointer-in col">
                                    <span>{{ time.horaInicio | date:'HH:mm':"+0000" }}h</span>
                                    <i class="fa-light fa-times pointer-in" (click)="deleteTime(i, true, n)"></i>
                                </div>
                                <div class="new-hour chip-item chip-small pointer-in col"
                                    (click)="openInputHour(schedule)">
                                    <i class="fa-light fa-plus"></i>
                                    <span *ngIf="schedule.times?.length == 0">Añadir hora</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <div class="new-hour btn-hour chip-item pointer-in" (click)="openInputHour()"
                        *ngIf="(selectedSchedules && sameHours)">
                        <i class="fa-light fa-plus"></i>
                        <span>Añadir hora</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>