import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-swiper-images',
  templateUrl: './swiper-images.component.html',
  styleUrls: ['./swiper-images.component.scss']
})
export class SwiperImagesComponent implements OnInit {

  @Input() images: any[];
  @Input() idEvent: number;
  urlImages: string = AppSettings.MEDIA_URI + '/images/events/';

  swiperImagesConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    updateOnWindowResize: true,
    navigation: {
      nextEl: '.button-next-custom',
      prevEl: '.button-prev-custom',
      enabled: true
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
