<div class="page-tickets-type">
    <!-- Descuentos -->
    <div class="d-flex justify-content-between align-items-center">
        <p class="title-tab">Descuentos para este evento</p>
        <!-- <div class="btn-create m-0" (click)="openCreateDiscount()">
            <i class="fal fa-plus-circle"></i>
            <p class="m-0">Crear descuento para este evento</p>
        </div> -->
    </div>
    <div *ngIf="discounts; else divNotDiscounts">

    </div>
    <ng-template #divNotDiscounts>
        <p>No existen descuentos para este evento.</p>
    </ng-template>
    <!-- Abonos -->
    <div class="div-pass">
        <!-- <p class="title-tab">Abonos</p> -->
        <!-- <p>Este evento aún no pertenece a ningún abono. Puedes añadirlo a un abono disponible o crear uno nuevo desde el apartado <a class="link-open" target="_blank" href="/panel-control/events/bonds">Abonos.</a></p> -->
        <!-- <div class="btn-create btn-create-border m-0" (click)="openCreateDiscount()">
            <i class="fal fa-plus-circle"></i>
            <p class="m-0">Añadir evento a un abono disponible</p>
        </div> -->
    </div>
    <!-- Descuentos base -->
    <div *ngIf="discountsPlace">
        <div class="d-flex justify-content-between align-items-center">
            <p class="title-tab">Descuentos base del Teatro Apolo</p>
            <div class="link-open m-0" (click)="openDiscountPlace()">
                <i class="fal fa-pencil"></i>
                <p class="m-0">Editar los descuentos base</p>
            </div>
        </div>
    </div>
</div>