import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { CommonService } from './common.service';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EventosService {

  // EventPanel
  private eventPanel = new BehaviorSubject<number>(null);
  public eventPanelStorage = this.eventPanel.asObservable();

  // Action Map
  private mapAction = new BehaviorSubject<any>(null);
  public mapActionObservable = this.mapAction.asObservable();

  // Save Map
  private mapSaveAction = new BehaviorSubject<any>(null);
  public mapSaveObservable = this.mapSaveAction.asObservable();

  // Create event
  stepObservable = new BehaviorSubject<any>(null);
  step = this.stepObservable.asObservable();

  constructor(private http: HttpClient, private common: CommonService) { }

  setActionMap(action: any) {
    this.mapAction.next(action);
  }

  saveMapAction(save: any) {
    this.mapSaveAction.next(save);
  }

  setEventPanel(idEvent: number) {
    this.eventPanel.next(idEvent);
  }

  setStepCreate(step: string) {
    this.stepObservable.next(step);
  }

  getEventos(ciudad?: number, cerca?: boolean) {
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    let url = `${AppSettings.API_URI}/eventos?activo=true&order[fechas.fechaInicio]=asc&order[fechas.fechaFin]=asc&fechas.fechaFin[after]=${now}`;
    if (cerca) {
      const arr = this.common.getCercanas();
      arr.forEach((element: any) => {
        url += `&ciudad.id[]=${element}`
      });
    }
    if (!cerca && ciudad) {
      url += `&ciudad.id=${ciudad}`
    }
    return this.common.getDataHydra(url);
  }

  getEventosExplorar(ciudad?: number) {
    let url = `${AppSettings.API_URI}/home/explorar/${ciudad || 1}`;
    return this.common.getData(url);
  }

  getAllEvents(page: number = 1, items: number = 1, sorted: string = null, search: String = '') {
    var urlFilter = '';
    let sort = '&order[fechas.fechaInicio]=asc';
    if (search != '') { urlFilter += `&titulo=${search}` }
    if (sorted != null) { sort = `&${sorted}` }
    urlFilter += sort;

    let url = `${AppSettings.API_URI}/eventos?items=${items}&page=${page}${urlFilter}`;

    return this.common.getData(url);
  }

  getAllEventsSearch(search: string) {
    let url = `${AppSettings.API_URI}/eventos`;
    return this.common.getDataHydra(url);
  }

  getEventsBest(ciudad?: number, cerca?: boolean) {
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    let url = `${AppSettings.API_URI}/eventos?activo=true&order[mediavaloraciones]=desc&order[fechas.fechaInicio]=asc&order[fechas.fechaFin]=asc&fechas.fechaFin[after]=${now}&mediavaloraciones[gte]=3`;
    if (cerca) {
      const arr = this.common.getCercanas();
      arr.forEach((element: any) => {
        url += `&ciudad.id[]=${element}`
      });
    }
    if (!cerca && ciudad) {
      url += `&ciudad.id=${ciudad}`
    }
    return this.common.getDataHydra(url);
  }

  getEventosBestClose(ciudad?: number) {
    let url = `${AppSettings.API_URI}/home/best/${ciudad || 1}`;
    return this.common.getData(url);
  }

  getEventosCategoryClose(category: any, ciudad?: number) {
    let url = `${AppSettings.API_URI}/home/event/${category}/${ciudad || 1}`;
    return this.common.getData(url);
  }

  getEventById(id: any) {
    const url = `${AppSettings.API_URI}/eventos/${id}?activo=true`;
    return this.common.getData(url);
  }

  getEventByIdAdmin(id: any) {
    const url = `${AppSettings.API_URI}/eventos/${id}`;
    return this.common.getData(url);
  }

  getEventDraftById(id: any) {
    const url = `${AppSettings.API_URI}/eventos/${id}?draft=true`;
    return this.common.getData(url);
  }

  getDiscountsByEvent(id: any) {
    const url = `${AppSettings.API_URI}/discounts/event/${id}`;
    return this.common.getData(url);
  }

  getEventsCategoryHome(slugCategory: string, city?: number, cerca?: boolean) {
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    let url = `${AppSettings.API_URI}/eventos?activo=true&categoria.slug=${slugCategory}&order[fechas.fechaInicio]=asc&order[fechas.fechaFin]=asc&fechas.fechaFin[after]=${now}&page=1&items=8`;
    if (cerca) {
      const arr = this.common.getCercanas();
      arr.forEach((element: any) => {
        url += `&ciudad.id[]=${element}`
      });
    }
    if (!cerca && city) {
      url += `&ciudad.id=${city}`
    }
    return this.common.getDataHydra(url);
  }

  getEventsByCategoryCity(slugCategory: string, page: number = 1, items: number = 12, city?: number, map?: boolean, search?: any, filters?: any) {
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    let url = `${AppSettings.API_URI}/eventos?activo=true&categoria.slug=${slugCategory}&fechas.fechaFin[after]=${now}&page=${page}&items=${items}`;
    if (!map && !city) {
      const arr = this.common.getCercanas();
      arr.forEach((element: any) => {
        url += `&ciudad.id[]=${element}`
      });
    }
    if (city && !map) {
      url += `&ciudad.id=${city}`
    }
    if (search) {
      url += `&titulo=${search}`;
    }
    url += this.addFiltros(map, filters);
    return this.common.getData(url);
  }

  getEventsByIds(category: any, city: any, cerca?: boolean) {
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    let url = `${AppSettings.API_URI}/eventos?activo=true&page=1&items=20&fechas.fechaFin[after]=${now}`;
    if (cerca) {
      const arr = this.common.getCercanas();
      arr.forEach((element: any) => {
        url += `&ciudad.id[]=${element}`
      });
    }
    if (!cerca && city) {
      url += `&ciudad.id=${city}`
    }
    if (category) {
      url += `&categoria.id=${category}`;
    }
    return this.common.getDataHydra(url);
  }

  getEventosSearchingClose(search: string, page: number, items: number, city?: number, filtros?: any, map?: boolean, slug?: any) {
    return this.getEventosCloseSearching(search, page, items, city, filtros, slug);
  }

  getEventosCloseSearching(search: string, page: number, items: number, city?: number, filtros?: any, slug?: any) {
    const fecha = filtros?.find(value => value.type == 'fecha');
    city = +localStorage.getItem('city');

    let desde: any = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    let hasta = '';
    if (fecha) {
      if (fecha.desde < new Date()) {
        fecha.desde = new Date();
      }
      desde = formatDate(fecha.desde, 'yyyy-MM-dd', 'es-ES')
      hasta = formatDate(fecha.hasta, 'yyyy-MM-dd', 'es-ES')
    }
    const categories = filtros?.filter(value => value.type == 'category');
    const prices = filtros?.find(value => value.type == 'price');
    const orderBy = filtros?.find(value => value.type == 'order');
    const filterLocation = filtros?.find(value => value.type == 'location');

    const searchBody = {
      "search": search,
      "latitud": filterLocation?.minLat,
      "longitud": filterLocation?.minLon,
      "city": city || 1,
      "pricemin": prices?.min || 0,
      "pricemax": prices?.max || null,
      "categorias": categories?.map(value => value.id),
      "fechaInicio": desde,
      "fechaFin": hasta,
      "activo": true,
      "slug": slug || '',
      "order": orderBy?.filter || '',
      "mode": orderBy?.mode || '',
      "items": items,
      "page": page
    }

    let url = `${AppSettings.API_URI}/eventos/areclose`;
    return this.common.postData(url, searchBody);
  }

  getEventosSearching(search: string, page: number, items: number, city?: number, filtros?: any, map?: boolean) {
    let url = `${AppSettings.API_URI}/eventos?activo=true&page=${page}&items=${items}`;
    if (!map && !city) {
      const arr = this.common.getCercanas();
      arr.forEach((element: any) => {
        url += `&ciudad.id[]=${element}`
      });
    }
    if (city && !map) {
      url += `&ciudad.id=${city}`
    }
    if (search) {
      url += `&titulo=${search}`;
    }
    url += this.addFiltros(map, filtros);
    return this.common.getData(url);
  }

  addFiltros(map?: boolean, filtros?: any) {
    let url = '';
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    // Localizacion
    const filterLocation = filtros?.find(value => value.type == 'location');
    if (map && filterLocation) {
      url += `&latitude[gte]=${filterLocation.minLat}&latitude[lte]=${filterLocation.maxLat}`;
      url += `&longitude[gte]=${filterLocation.minLon}&longitude[lte]=${filterLocation.maxLon}`;
    }
    const fecha = filtros?.find(value => value.type == 'fecha');
    let fechaUrl = `&fechas.fechaFin[after]=${now}`;
    if (fecha) {
      if (fecha.desde < new Date()) {
        fecha.desde = new Date();
      }
      const desde = formatDate(fecha.desde, 'yyyy-MM-dd', 'es-ES')
      const hasta = formatDate(fecha.hasta, 'yyyy-MM-dd', 'es-ES')
      fechaUrl = `&fechas.fechaInicio[before]=${hasta}&fechas.fechaFin[after]=${desde}`;
    }
    url += fechaUrl;
    const categories = filtros?.filter(value => value.type == 'category');
    categories?.forEach(element => {
      url += `&categoria.id=${element.id}`;
    });
    const prices = filtros?.find(value => value.type == 'price');
    if (prices) {
      url += `&preciodesde[gte]=${prices.min}&preciodesde[lte]=${prices.max}`;
      // Precio max
    };
    const orderBy = filtros?.find(value => value.type == 'order');
    let orderUrl = `&order[fechas.fechaInicio]=asc`;
    if (orderBy) {
      orderUrl = `&order[${orderBy.filter}]=${orderBy.mode}`;
    }
    url += orderUrl;
    return url;
  }

  postEventos(eventdata: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let form = JSON.parse(JSON.stringify(eventdata));
    form.draft = !form.draft;
    const url = `${AppSettings.API_URI}/create/event`;
    return this.http.post<any>(url, form, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  putEventos(eventoId: number, eventdata: any) {
    const jwt_token = localStorage.getItem('jwt_token');
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt_token}` //Authorization
      })
    };
    const url = `${AppSettings.API_URI}/eventos/${eventoId}`;
    return this.http.put<any>(url, eventdata, formHeaders).pipe(
      map(data => {
        return data;
      })
    );
  }

  putEventoImage(eventdata: any, event_id?: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/event_image/${event_id}`;
    return this.http.put<any>(url, { file: eventdata }, formHeaders).pipe(
      map(data => {
        return data;
      })
    );
  }
  putExtraEventoImage(eventoId: number, eventdata: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/extra_event_image/${eventoId}`;
    return this.http.put<any>(url, eventdata, formHeaders).pipe(
      map(data => {
        return data;
      })
    );
  }

  deleteEvento(eventoId: number) {
    const url = `${AppSettings.API_URI}/eventos/${eventoId}`;
    return this.http.delete<any>(url).pipe(
      map(data => {
        if (data) {
          return of(true);
        } else {
          return of(false);
        }
      }), catchError(err => {
        return of(false);
      })
    );
  }

  // Design functions
  getTagString(card: any) {
    if (this.datesEquals(card.datesRange?.next)) {
      return '¡Es hoy!';
    } else if (card.new) {
      return '¡Nuevo!';
    } else if (card.numvaloraciones > 20 || card.mediavaloraciones > 4) {
      return '¡Lo está petando!';
    }
  }

  getTagIcon(card: any) {
    if (this.datesEquals(card.datesRange?.next)) {
      return 'fa-bell';
    } else if (card.new) {
      return 'fa-sparkles';
    } else if (card.numvaloraciones > 20 || card.mediavaloraciones > 4) {
      return 'fa-fire-alt';
    }
  }

  datesEquals(fechaCard: any, format: string = 'd/M/yyyy') {
    let today = new Date();
    return this.ConvertDateToStringFormat(fechaCard, format) == today.toLocaleDateString();
  }

  ConvertDateToStringFormat(date: any, format: string = 'dd MMM'): string {
    if (date) {
      date = new Date(date);
      let myFormat = formatDate(date, format, 'es-ES')
      return myFormat;
    }
    return null;
  }

  getDateString(fecha: any) {
    let stringHour = this.ConvertDateToStringFormat(fecha, 'HH:mm');
    let stringDate = 'Hoy';
    if (!this.datesEquals(fecha)) {
      stringDate = this.ConvertDateToStringFormat(fecha);
    }
    if (stringHour != '00:00') {
      stringDate += ' | ' + stringHour + 'h';
    }
    return stringDate;
  }

  getAllCity() {
    let url = `${AppSettings.API_URI}/ciudades`;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getCity(ciudad: number) {
    let url = `${AppSettings.API_URI}/ciudades/${ciudad}`;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getCategories() {
    let uriandName = []
    let url = `${AppSettings.API_URI}/categorias`;
    return this.http.get<any>(url).pipe(
      map(data => {
        if (data["hydra:member"]) {
          data["hydra:member"].forEach(element => {
            uriandName.push({ "uri": element['@id'], "nombre": element.nombre });
          });
        }
        return uriandName;
      })
    );
  }
  getcomAuto() {
    let uriandName = []
    let url = `${AppSettings.API_URI}/comunidad_autonomas`;
    return this.http.get<any>(url).pipe(
      map(data => {
        if (data["hydra:member"]) {
          data["hydra:member"].forEach(element => {
            uriandName.push({ "uri": element['@id'], "nombre": element.nombre });

          });
        }

        return uriandName;
      })
    );
  }
  getCitys(id: number) {
    let uriandName = []
    let url = `${AppSettings.API_URI}/ciudades?comautonoma=${id}`;
    return this.http.get<any>(url).pipe(
      map(data => {
        if (data["hydra:member"]) {
          data["hydra:member"].forEach(element => {
            uriandName.push({ "uri": element['@id'], "nombre": element.nombre });

          });
        }
        return uriandName;
      })
    );
  }

  editLocationEvent(idEvent: number, payload: any) {
    let url = `${AppSettings.API_URI}/location/event/${idEvent}`;
    return this.http.put<any>(url, payload).pipe(
      map(data => {
        return data;
      }), catchError(err => { return throwError(() => err); })
    );
  }

  getQrEvent(idEvent: number) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'image/svg+xml'
      })
    };
    let url = `${AppSettings.API_CHATBOT}/api/event/qr/${idEvent}`;
    return this.http.get(url, { responseType: 'text' }).pipe(
      map(data => {
        return data;
      }), catchError(err => { return throwError(() => err); })
    );
  }

  getCountTicketsByEvent(idEvent: number, hour: any, date: any) {
    const url = `${AppSettings.API_URI}/tickets/enabled/event/${idEvent}`;
    return this.http.post(url, {date: date, time: hour}).pipe(
      map(data => {
        return data;
      }), catchError(err => { return throwError(() => err); })
    );
  }
}
