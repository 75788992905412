<div class="cityblog-page container">
    <div class="d-flex align-items-center justify-content-center title-cityblog">
        <i class="fa-light fa-newspaper icon-primary"></i>
        <p>Cityblog</p>
    </div>
    <div class="header-pods d-flex justify-content-between" *ngIf="posts && posts.length>0">
        <div class="col-6 col-info">
            <div class="date-post d-flex align-items-center">
                <i class="fa-light fa-clock"></i>
                <p class="m-0">{{ posts[0]?.date | date: 'dd MMMM yyyy' | titlecase }}</p>
            </div>
            <div class="title-post" [innerHTML]="posts[0]?.title.rendered"></div>
            <div class="res-post" [innerHTML]="getSanitizer(posts[0]?.excerpt)"></div>
            <div class="link-more d-flex align-items-center pointer-in" [routerLink]="['.', posts[0]?.slug]">Leer más <i class="fa-light fa-arrow-right-long arrow"></i></div>
        </div>
        <div class="col-6">
            <div class="img-post-main">
                <span class="tag-img"><i class="fa-regular fa-fire"></i>Destacada</span>
                <img (error)="post?.acf.img_main = '../../../../assets/img/image_default.png'" src="{{posts[0]?.acf.img_main}}" alt="{{posts[0]?.title.rendered}}" />
            </div>
        </div>
    </div>
    <div class="main-pods">
        <mat-divider id="topPosts" style="margin-bottom: 26px;"></mat-divider>
        <div class="section-filter d-grid d-md-flex justify-content-md-between align-items-center">
            <div class="pointer-in filter-btn d-flex align-items-center justify-content-center">
                <span>Filtrar</span>
                <i class="fal fa-sliders-h text-primary"></i>
            </div>
            <div class="container-buscador">
                <div class="bloque-buscador buscador-post container-form d-flex align-items-center borderInput">
                    <i class="fal fa-search search-icon pointer-in" (click)="searchPosts(search.value)"></i>
                    <input #search class="input-custom w-100" type="text" placeholder="Buscar en el blog" [(ngModel)]="searchBlog" (keyup.enter)="searchPosts(search.value)">
                </div>
            </div>
        </div>
        <div class="cards-posts">
            <div class="container-results-posts d-grid">
                <div #containerPosts class="container-posts-scroll">
                    <div class="post-card col pointer-in" *ngFor="let post of posts" [routerLink]="['.', post.slug]">
                        <div class="img-post">
                            <img (error)="post.acf.img_main = '../../../../assets/img/image_default.png'" src="{{post.acf.img_main}}" alt="{{post.title.rendered}}" />
                        </div>
                        <div class="info-post">
                            <div class="date-post d-flex align-items-center">
                                <i class="fa-light fa-clock"></i>
                                <p class="m-0">{{ post.date | date: 'dd MMMM yyyy' | titlecase }}</p>
                            </div>
                            <div class="container-short-text">
                                <div class="title-card short-text" [innerHTML]="post.title.rendered"></div>
                                <div class="res-card short-text" [innerHTML]="getSanitizer(post.excerpt)"></div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <!-- <div class="loading-container">
                    <ng-lottie *ngIf="loadingPage" class="lottie" [options]="loadingLottie" containerClass="loading-box"></ng-lottie>
                </div> -->
            
                <div class="paginator-scroll" *ngIf="scrollTopActive">
                    <div class="up-scroll pointer-in" (click)="onScrollToTop()"><i class="fa-solid fa-angle-up fa-lg"></i></div>
                </div>

                <div *ngIf="totalPages > 1">
                    <app-paginator-results [currentPage]="page" [totalPages]="totalPages" (page)="goToPage($event)"></app-paginator-results>
                </div>
            </div>
        </div>
    </div>
</div>