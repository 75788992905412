<div class="container-results-cards d-grid" *ngIf="!map; else divList">
    <div #containerScroll class="container-events-scroll">
        <div class="smallCards-search col" *ngFor="let event of eventos">
            <app-card [card]="event" (eliminado)="eliminado($event)" [teatroIdeal]="teatroIdeal"></app-card>
        </div>
    </div>

    <div class="loading-container">
        <ng-lottie *ngIf="loadingPage" class="lottie" [options]="loadingLottie" containerClass="loading-box"></ng-lottie>
    </div>

    <!-- <div #infinityScroll class="paginator-scroll" [class.fromHome]="fromHome" *ngIf="exists">
        <div class="up-scroll pointer-in" (click)="onScrollToTop()"><i class="fa-solid fa-angle-up fa-lg"></i></div>
    </div> -->
 
</div>

<ng-template #divList>
    <div class="container-events-list" (scroll)="isScrolledInfinityView($event)">
        <div #containerScroll class="events-list d-grid">
            <div class="col" *ngFor="let event of eventos">
                 <app-card (mouseenter)='mouseEnter(event.id)' [card]="event" [list]="true" (eliminado)="eliminado($event)"></app-card>
            </div>
            <div class="loading-container" *ngIf="!finishScroll; else divFinish">
                <ng-lottie  class="lottie" [options]="loadingLottie" containerClass="loading-box"></ng-lottie>
            </div>
            <ng-template #divFinish>
                <div class="d-flex justify-content-center align-items-center info-finish-results">
                    <i class="fa-light fa-circle-info"></i>
                    <p class="m-0">¡Has visto todos los loks! Modifica los filtros para ampliar los resultados de búsqueda.</p>
                  </div>
            </ng-template>
        </div>
    </div>
</ng-template>
