import { environment } from '../environments/environment.prod';

export class AppSettings {
  public static API_URI = environment.backend;
  public static API_CHATBOT = environment.chatbot;
  public static API_LOGIN = environment.login;
  public static MEDIA_URI = environment.media;
  public static FRONT = environment.front;
  public static SALES = environment.sales;
  public static DEBUG = environment.debug;
  public static KEYS = environment.keys;
  public static WP_API = environment.wp_api;
}
