import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventosService } from 'src/app/services/eventos.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { InfoAbonosComponent } from '../info-abonos/info-abonos.component';
import { PrivateEventComponent } from '../private-event/private-event.component';

@Component({
  selector: 'app-open-external',
  templateUrl: './open-external.component.html',
  styleUrls: ['./open-external.component.scss']
})
export class OpenExternalComponent implements OnInit {

  @Input() event!: any;
  @Input() pastEvent!: boolean;
  @Input() repeat: any;
  @Output() openHours: EventEmitter<boolean> = new EventEmitter();
  disabledSales: boolean = true;
  endSales: boolean = true;
  enableAbono: boolean = false;

  constructor(private eventosService: EventosService, private dialog: MatDialog, private router: Router) {
  }

  ngOnInit(): void {
    if (this.event.abonos?.length > 0) {
      this.enableAbono = true;
    }
    let now = new Date();
    this.disabledSales = this.event?.activeSales && this.event?.startSales && ((new Date(this.event?.startSales) > now));
    this.endSales = (this.event?.startSales && ((new Date(this.event?.startSales) <= now) && (this.event?.endSales && ((new Date(this.event?.endSales) <= now)))));
    // Comprobar la hora de fin del evento
    if(!this.endSales) {
      // console.log(this.event)
      // let next = this.event?.datesRange?.end ? this.event?.datesRange?.end : this.event?.datesRange?.next;
      // let hoursEvent = this.event?.horarios?.sort((a, b) => new Date(a.horaInicio).getTime() - new Date(b.horaInicio).getTime());
      // let hourInit = this.event?.hourStart;
      // Si el evento no ha temrinado mirar que la ultima fecha y el ultimo horario es menor que la fehc ay hora actuales

    }
  }

  buyAbono() {
    // Comprobar si es un evento privado
    if (this.event.privateEvent) {
      const refAbonosDialog = this.dialog.open(PrivateEventComponent, {
        panelClass: ['info-dialog', 'not-padding', 'info-abono-dialog'],
      });
      refAbonosDialog.afterClosed().subscribe({
        next: (result) => {
          if (result) {
            if (result == this.event.accessCodeSales) {
              this.openDialogBond();
            } else {
              const dialogRef = this.dialog.open(InfoCardComponent, {
                panelClass: 'info-dialog',
                data: {
                  icon: true,
                  text: 'Lo sentimos, el código es incorrecto.',
                  btnCancel: false
                }
              });
            }
          }
        }
      })
    } else {
      this.openDialogBond();
    }
  }

  openDialogBond() {
    const refAbonosDialog = this.dialog.open(InfoAbonosComponent, {
      panelClass: ['info-dialog', 'not-padding', 'info-abono-dialog'],
      data: { abono: this.event.abonos }
    });
    refAbonosDialog.afterClosed().subscribe({
      next: (result) => {
        if (result?.data) {
          result?.exit ? this.router.navigate(['/cart-checkout']) : this.buyAbono();
        }
      }
    })
  }

  goToScrollPage(idPage: string) {
    var height = document.getElementById(idPage).offsetTop - 30;
    window.scrollTo(0, height);
  }

  openEvent(url: string) {
    if (this.event.external) {
      window.open(url, '_blank');
    } else {
      // Comprobar si es un evento privado
      if (this.event.privateEvent) {
        const refAbonosDialog = this.dialog.open(PrivateEventComponent, {
          panelClass: ['info-dialog', 'not-padding', 'info-abono-dialog'],
        });
        refAbonosDialog.afterClosed().subscribe({
          next: (result) => {
            if (result) {
              if (result == this.event.accessCodeSales) {
                this.openHours.emit(true);
              } else {
                const dialogRef = this.dialog.open(InfoCardComponent, {
                  panelClass: 'info-dialog',
                  data: {
                    icon: true,
                    text: 'Lo sentimos, el código es incorrecto.',
                    btnCancel: false
                  }
                });
              }
            }
          }
        })
      } else {
        this.openHours.emit(true);
      }
    }
  }

  convertDateToStringFormat(date: Date, format: string) {
    return (this.eventosService.ConvertDateToStringFormat(date, format));
  }

}
