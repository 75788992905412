import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  private allFields: string = `_fields[]=id&_fields[]=slug&_fields[]=date&_fields[]=date_gmt&_fields[]=title&_fields[]=content&_fields[]=acf&acf_format=standard`;
  private fields: string = `_fields[]=id&_fields[]=slug&_fields[]=date&_fields[]=date_gmt&_fields[]=title&_fields[]=excerpt&_fields[]=acf&acf_format=standard`;

  constructor(private common: CommonService) { }

  getPostsHeaders() {
    const url = `https://wpcitylok.sdi.es/wp-json/wp/v2/posts?${this.fields}&page=1&per_page=12`;
    return this.common.getDataWithHeaders(url);
  }

  getPostsPagination(page: number, items: number = 3) {
    const url = `https://wpcitylok.sdi.es/wp-json/wp/v2/posts?${this.fields}&page=${page}&per_page=${items}`;
    return this.common.getData(url);
  }

  getPostBySlug(slug: string) {
    const url = `https://wpcitylok.sdi.es/wp-json/wp/v2/posts?slug=${slug}&${this.allFields}`;
    return this.common.getData(url, true);
  }

  getTagsByPost(post: number) {
    const url = `https://wpcitylok.sdi.es/wp-json/wp/v2/tags?post=${post}&_fields[]=id&_fields[]=name`;
    return this.common.getData(url);
  }
}
