import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-update-business',
  templateUrl: './update-business.component.html',
  styleUrls: ['./update-business.component.scss']
})
export class UpdateBusinessComponent {

  changeForm: FormGroup;
  valueChange: any;
  valueInit: any;
  nameValue: any;
  idBusiness!: any;

  editorConfigBusiness: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '400px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Condiciones generales de uso y políticas de compra de la organización.',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Satoshi-Regular',
    defaultFontSize: '',
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  constructor(private formBuilder: FormBuilder, private businessService: ApiBusinessService,
    private auth: AuthenticationService, private dialogRef: MatDialogRef<UpdateBusinessComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.idBusiness = this.data['id'];
    this.nameValue = this.data['name'];
    this.valueChange = this.data['valueChange'];
    this.valueInit = this.data['value'];
    this.changeForm = this.formBuilder.group({
      name: [this.valueInit.name, Validators.required],
      address: [this.valueInit.address],
      city: [this.valueInit.city],
      postal_code: [this.valueInit.postal_code],
      country: [this.valueInit.country],
      state: [this.valueInit.state],
      general_conditions: [this.valueInit.general_conditions],
      description: [this.data['description']]
    });
  }

  get value() { return this.changeForm.get(this.valueChange); }

  changeBusiness() {
    if (this.changeForm.valid) {
      if(this.valueChange == 'description' && this.changeForm.value.description?.length > 510) {
        this.dialogRef.close({ status: 'error', msg: 'Lo sentimos, no puedes superar los 500 caracteres permitidos.' });
        return;
      }
      const value = this.changeForm.value;
      this.businessService.setBusinessById(this.idBusiness, value).subscribe({
        next: (data) => {
          this.dialogRef.close({ status: 'changed', value: this.changeForm.value });
        },
        error: (err) => {
          this.dialogRef.close({ status: 'error' });
        }
      });
    }
  }
}
