import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { map, Observable } from 'rxjs';
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-select-places-manager',
  templateUrl: './select-places-manager.component.html',
  styleUrls: ['../../../dashboard-panel/dashboard-panel.component.scss', './select-places-manager.component.scss']
})
export class SelectPlacesManagerComponent implements OnInit {

  placesCompany: any[] = [];
  searchText: string = '';
  allPlaces: boolean = true;

  displayedColumns: string[] = ['actions', 'name', 'location'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  placesSelected: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<SelectPlacesManagerComponent>, private placeService: PlacesService) { }

  ngOnInit(): void {
    this.placeService.getPlacesCompany(this.data.idCompany).subscribe({
      next: (res: any) => {
        this.placesCompany = res;
        this.dataSource = new MatTableDataSource<any>(this.placesCompany);
        this.selectAll();
      }
    });
  }

  selectAll() {
    if (this.allPlaces) {
      this.placesSelected = [];
      this.dataSource.data.forEach((place: any) => {
        this.placesSelected.push(place.id);
      });
    }
  }

  changeSelectPlace(place: any) {
    if (this.placesSelected.includes(place.id)) {
      this.placesSelected = this.placesSelected.filter((id: any) => id !== place.id);
    } else {
      this.placesSelected.push(place.id);
    }
    if (this.placesSelected.length == 0) {
      this.allPlaces = false;
    }
  }

  searchPlaces(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goBackNewManager() {
    this.dialogRef.close({ back: true });
  }

  closeNew() {
    this.dialogRef.close();
  }

  createNewManager() {
    if (this.placesSelected.length > 0) {
      this.dialogRef.close({ places: this.placesSelected, allPlaces: this.allPlaces });
    }
  }
}
