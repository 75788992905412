import { formatDate } from '@angular/common';
import { Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, Observable, Subject, switchMap } from 'rxjs';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { ApiSalesService } from 'src/app/services/api-sales.service';
import { OrdersEventService } from 'src/app/services/orders-event.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit {

  searchText!: any;
  business!: any;
  places: any[] = [];
  @Input() idEvent!: any;

  // Select Items
  activeSelectList: boolean = false;
  selectedItems: any[] = [];

  statusOptions: Observable<any> = new Observable<any>();
  methodsOptions: Observable<any> = new Observable<any>();
  maxPriceEnabled: number = 500;

  // Calendar
  datesRange!: any;
  stringDate: string = '';
  openDatePicker: boolean = false;

  // Filter Options
  activeFilters: boolean = false;
  dateFilter!: any;
  selectedPriceRange!: any;
  priceFilter: any = { min: 0, max: 500 };
  statusFilter!: any;
  openPriceRange: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  filterActive: boolean = false;
  methodFilter!: any;

  activeSearchItems: boolean = false;
  private searchTerms = new Subject<string>();

  constructor(private orderService: OrdersEventService, private apiSalesService: ApiSalesService,
    public formatter: NgbDateParserFormatter, private reportService: ReportsService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.business = JSON.parse(localStorage.getItem('business') || '{}');
    this.places = JSON.parse(localStorage.getItem('places') || '[]');

    this.orderService.setLoadingTable(true);
    this.statusOptions = this.apiSalesService.getStatusByBusiness(this.business?.id).pipe();
    this.methodsOptions = this.apiSalesService.getAllMethods().pipe();
    this.apiSalesService.getMaxPriceByCompany(this.business?.id).subscribe({
      next: (res) => {
        this.priceFilter.max = res?.max_price;
        this.maxPriceEnabled = res?.max_price;
      }
    });
    this.searchTerms.pipe(
      debounceTime(500), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.activeSearchItems = false;
      this.orderService.setSearchOrder(items);
    });
  }

  searchOrder(event) {
    this.searchText = event;
    this.searchTerms.next(this.searchText);
    // this.orderService.setSearchOrder(this.searchText);
  }

  openFilters() {
    this.activeFilters = !this.activeFilters;
    if (!this.activeFilters && this.filterActive) {
      this.dateFilter = null;
      this.priceFilter = null;
      this.statusFilter = null;
      this.methodFilter = null;
      this.clearFilters();
    }
  }

  activeSelecting() {
    this.activeSelectList = !this.activeSelectList;
    // Eliminar la seleccion actual
  }

  containsBlocked() {
    return this.selectedItems.filter((item) => item.sales_channel?.name != 'Web').length <= 0;
  }

  selectedItemsChange(event: any) {
    this.selectedItems = event;
  }

  printOrders(typePrint: string) {
    const idTickets = this.selectedItems.map((item) => item.id);
    this.reportService.getUrlsDownloadAll(idTickets).subscribe({
      next: (url) => {
        let param = typePrint == 'a4' ? 'ticket_a4' : 'ticket';
        let uriDownload = url[param];
        window.open(uriDownload, '_blank');
        this.openInfoCard('Se ha descargado el ticket correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar las entradas.', true, false);
      }
    })
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

  returnAll() {

  }

  updateFilter(active: boolean = true) {
    this.filterActive = active;
    this.orderService.setFilters(this.dateFilter, this.priceFilter, this.statusFilter, this.methodFilter);
  }


  changeValuesPrice() {
    this.selectedPriceRange = this.priceFilter.min + '€ - ' + this.priceFilter.max + '€';
    this.filterActive = true;
    this.updateFilter();
  }

  selectDates(datesRange: any) {
    this.datesRange = datesRange;
    let stringDates = this.formatStringDate(datesRange.min);
    if(datesRange.max) {
      stringDates += ' - ' + this.formatStringDate(datesRange.max);
    }
    this.stringDate = stringDates;
    this.dateFilter = {date_from: this.formatStringDate(datesRange.min, 'yyyy-MM-dd'), date_to: this.formatStringDate(datesRange.max, 'yyyy-MM-dd')};
    this.filterActive = true;
    this.updateFilter();
  }

  formatStringDate(date: any, format: string = 'dd MMM yy') {
    let dateFormat = null;
    if(date) {
      dateFormat = formatDate(new Date(date), format, 'es'); 
    }
    return dateFormat;
  }

  clearFilters() {
    this.dateFilter = null;
    this.priceFilter = { min: 0, max: this.maxPriceEnabled };
    this.statusFilter = null;
    this.selectedPriceRange = null;
    this.stringDate = '';
    this.openPriceRange = false;
    this.filterActive = false;
    this.updateFilter(false);
  }

}
