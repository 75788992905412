import { Location } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { fabric } from 'fabric';
import { element } from 'protractor';
import { AreasSeatService } from 'src/app/services/areas-seat.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CreateEventService } from 'src/app/services/create-event.service';
import { EventosService } from 'src/app/services/eventos.service';
import { PlacesService } from 'src/app/services/places.service';
import { SeatMapService } from 'src/app/services/seat-map.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { NameMapComponent } from '../name-map/name-map.component';
import { SelectTypeComponent } from '../select-type/select-type.component';

@Component({
  selector: 'app-generate-canvas',
  templateUrl: './generate-canvas.component.html',
  styleUrls: ['./generate-canvas.component.scss']
})
export class GenerateCanvasComponent implements OnInit {

  output: string[] = [];
  selectObject!: any;

  canvas: fabric.Canvas;

  pausePanning = true;
  zoomStartScale = 0;
  currentX;
  currentY;
  xChange;
  yChange;
  lastX;
  lastY;

  canvasWidth!: any;
  canvasHeight!: any;
  groupGrid!: any;

  @Input() fromVenue!: boolean;
  @Input() fromEvent!: boolean;
  @Input() fromCreate!: boolean;
  @Input() idMap!: number;
  @Input() idEvent!: number;
  @Input() titleMap: string = '';
  @Input() editEvent!: boolean;
  @Input() capacity: number = 0;
  elements: any = 0;

  @Output() loadCanvas = new EventEmitter<any>();
  subscribeSaveBtn!: any;
  sectionNumber = 1;

  activeTab: boolean = false;
  openedEditSection: boolean = false;
  openedEditSeats: boolean = false;
  openedLevels: boolean = false;
  openedHidden: boolean = false;
  titleTab: string = '';

  // Sections
  sectionsCreated: any[] = [];

  // Default colors
  colorDefaultSeat = this.areaSeatService.colorDefaultSeat;
  colorDefaultStand = this.areaSeatService.colorDefaultStand;
  colorDefaultFree = this.areaSeatService.colorDefaultFree;
  colorDefaultStage = this.areaSeatService.colorDefaultStage;
  colorDefaultHidden = this.areaSeatService.colorDefaultHidden;
  colorDefaultDisplay = this.areaSeatService.colorDefaultDisplay;
  colorDefaultBasic = this.areaSeatService.colorDefaultBasic;

  // Levels
  levels: any[] = [];
  idLevel: number = 1;
  selectedSeats: any[] = [];
  assignedSeats: any[] = [];
  levelEdit!: any;

  // Hidden
  selectedHidden: any[] = [];
  idHidden: number = 1;
  hiddenTypes: any[] = [];
  hiddenSeats: any[] = [];
  hiddenTypeEdit!: any;

  seatsElement: string = '/assets/img/canvas/seat-airline-light.svg';
  shoesElement: string = '/assets/img/canvas/shoe-prints-light.svg';
  microElement: string = '/assets/img/canvas/microphone-light.svg';
  screenElement: string = '/assets/img/canvas/screen-users-light.svg';

  //Edit Object
  objectEditing!: any;
  openSeatEditing!: boolean;
  openEditObject: boolean = false;
  openEditSection: boolean = false;
  beforeCleared!: any;
  blockComponents: boolean = false;

  sitSelect!: any;
  seatsEditSelected: any[] = [];
  privateMap: boolean = false;

  profile!: any;
  @Input() bookings!: any;
  @Input() allowCreate!: any;

  constructor(private dialog: MatDialog, private seatMapService: SeatMapService, private activatedRoute: ActivatedRoute,
    private location: Location, private router: Router, private areaSeatService: AreasSeatService, private auth: AuthenticationService,
    private apiEvents: EventosService, private apiVenues: PlacesService, private createEventService: CreateEventService) { }

  ngOnInit(): void {
    this.subscribeSaveBtn = this.seatMapService.saveEvent.subscribe({ next: (event: any) => { if (event) { this.save(); } } })
    let subcribe = this.activatedRoute.params.subscribe({
      next: () => {
        params => {
          this.privateMap = params.newLok ? true : false;
        }
      }
    });
  }

  openSelect(): void {
    const dialogRef = this.dialog.open(SelectTypeComponent, {
      panelClass: ['info-dialog', 'not-padding'],

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  ngAfterViewInit(): void {
    this.canvas = new fabric.Canvas('myCanvas', {
      backgroundColor: '#fff',
      selection: false,
    });
    this.onResize();

    this.canvas.on('touch:gesture', e => {
      if (e.e.touches && e.e.touches.length == 2) {
        this.pausePanning = true;
        var point = new fabric.Point(e.self.x, e.self.y);
        if (e.self.state == "start") {
          this.zoomStartScale = this.canvas.getZoom();
        }
        var delta = this.zoomStartScale * e.self.scale;
        this.canvas.zoomToPoint(point, delta);
        this.output.push(`zoom`);
        this.pausePanning = false;
      }
    });

    this.canvas.on('selection:created', opt => {
      if (opt.selected?.length > 0 && !this.blockComponents) {
        const el = opt.selected[0];
        el.set('hasControls', true);
        if (el.isSection || el.groupSeat) {
          this.clickSelectObject(opt.selected[0]);
        }
        if (el.isInfoSection) {
          this.clickSelectSection(opt.selected[0]);
        }
      }
    })

    this.canvas.on('selection:updated', opt => {
      if (opt.selected?.length > 0 && !this.blockComponents) {
        const el = opt.selected[0];
        if ((el.isSection || el.groupSeat)) {
          this.clickSelectObject(opt.selected[0]);
        } else if (el.isInfoSection) {
          this.clickSelectSection(opt.selected[0]);
        } else {
          this.clearedObjectSelect();
        }
      }
    })

    this.canvas.on('before:selection:cleared', opt => {
      this.beforeCleared = this.canvas.getActiveObject();
    })

    this.canvas.on('selection:cleared', opt => {
      if (opt.deselected?.length > 0 && this.openEditObject && !this.blockComponents) {
        this.clearedObjectSelect();
      }
    })

    this.canvas.on('mouse:wheel', opt => {
      var delta = opt.e.deltaY;
      this.zoomMap(false, delta);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    })

    this.canvas.on('mouse:down', e => {
      if (e.target && (e.target.eventSeat || e.target.groupSeat && e.subTargets.length > 0)) {
        let elementSeat = e.target.groupSeat ? e.subTargets[0] : e.target;
        if (elementSeat.selectable) {
          // Seleccionar asientos a niveles
          if (this.openedLevels && this.levelEdit) {
            elementSeat.selected = elementSeat.selected || elementSeat.level ? false : true;
            let defaultColor = elementSeat.isSection ? this.colorDefaultFree : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat);
            let color = elementSeat.selected ? this.levelEdit.color : defaultColor;
            changeSeatColor(elementSeat, color);

            if (elementSeat.selected) {
              this.selectedSeats.push(elementSeat);
            }
            if (!elementSeat.selected) {
              let indexEl = this.selectedSeats.findIndex(value => value.section.id == elementSeat.section.id && value.row == elementSeat.row && value.seat == elementSeat.seat);
              if (indexEl != -1) this.selectedSeats.splice(indexEl, 1);
              elementSeat.level = null;
            }
          }

          // Seleccionar asientos ocultos
          if (this.openedHidden && this.hiddenTypeEdit) {
            elementSeat.selected = elementSeat.selected || elementSeat.hiddenType ? false : true;
            elementSeat.hidden = elementSeat.hidden ? false : true;
            let defaultColor = elementSeat.isSection ? this.colorDefaultFree : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat);
            let color = elementSeat.hidden ? this.hiddenTypeEdit.color : defaultColor;
            changeSeatColor(elementSeat, color);

            if (elementSeat.selected) {
              this.selectedHidden.push(elementSeat);
            }
            if (!elementSeat.selected) {
              let indexEl = this.selectedHidden.findIndex(value => value.section.id == elementSeat.section.id && value.row == elementSeat.row && value.seat == elementSeat.seat);
              if (indexEl != -1) this.selectedHidden.splice(indexEl, 1);
              elementSeat.hiddenType = null;
            }
          }

          // Editar asientos
          if (this.openEditObject) {
            this.sitSelect = elementSeat
            if (elementSeat.eventSeat) {
              if (elementSeat.indexEditing) {
                elementSeat.editing = !elementSeat.editing;
                this.seatsEditSelected[elementSeat.indexEditing - 1] = elementSeat.editing ? elementSeat : null;
              }
              if (!elementSeat.indexEditing) {
                elementSeat.indexEditing = this.seatsEditSelected.push(elementSeat);
                elementSeat.editing = true;
              }
              let color = !elementSeat.editing ? (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat) : this.colorDefaultDisplay;
              changeSeatColor(elementSeat, color);
              let arrayActive = this.seatsEditSelected.filter(value => value != null);
              if (arrayActive.length == 1) {
                this.sitSelect = arrayActive[0];
              }
            }
            elementSeat.editSeat = !elementSeat.editSeat;
          }
        }
      }

      if (!this.canvas.getActiveObject() && this.pausePanning == true) {
        this.pausePanning = false;
        this.canvas.discardActiveObject(this.canvas.getActiveObject());
        this.canvas.defaultCursor = 'move';
      }

      if (e.target?.isInfoSection) {
        if (e.target.edit) {
          // var point = {
          //   x: 4, y: 4
          // };
          // var insideP = checkcheck(point, e.target.points);
          // console.log("Inside: " + insideP);

          var points = e.target.points;
          // points.push(point);
        }
      }
      this.canvas.renderAll();
    });

    var previousTouch;
    this.canvas.on('mouse:move', e => {
      if (this.pausePanning == false && e && e.e) {
        if (!e.e.touches) {
          this.canvas = keepPositionInBounds(this.canvas, e);
        }
        if (e.e.touches) {
          const touch = e.e.touches[0];
          if (previousTouch) {
            // be aware that these only store the movement of the first touch in the touches array
            e.e.movementX = touch.pageX - previousTouch.pageX;
            e.e.movementY = touch.pageY - previousTouch.pageY;
            this.canvas = keepPositionInBounds(this.canvas, e);
          };
          previousTouch = touch;
        }
      }
    });

    this.canvas.on('mouse:up', e => {
      if (!this.canvas.getActiveObject() && this.pausePanning == false) {
        if (e.target && (e.target.eventSeat || e.target.groupSeat && e.subTargets.length > 0)) {
          let elementSeat = e.target?.groupSeat ? e.subTargets[0] : e.target;
          if (this.idEvent && !elementSeat.selectable && this.openedHidden && this.hiddenTypeEdit) {
            alert('No se pueden editar asientos reservados');
          }
        }
        this.pausePanning = true;
        this.canvas.defaultCursor = 'default';
      }
    });
    this.profile = this.auth.profileStorage.pipe();

    if (this.fromVenue) {
      this.apiVenues.mapSaveObservable.subscribe({
        next: (save) => {
          if (save) {
            this.apiVenues.saveMapAction(null);
            this.setActionRoute(save);
          } else {
            this.closeTab();
          }
        }
      });
    } else if (this.fromEvent) {
      this.apiEvents.mapSaveObservable.subscribe({
        next: (save) => {
          if (save) {
            this.apiEvents.saveMapAction(null);
            this.setActionRoute(save);
          } else {
            this.closeTab();
          }
        }
      });
    }
  }

  setActionRoute(save: boolean) {
    this.closeTab();
    if (save) {
      this.idMap ? this.editMap() : this.editName();
    } else {
      this.getMap();
    }
  }

  getMap() {
    let event = this.fromCreate ? null : this.idEvent;
    let subscribe = this.seatMapService.getMap(this.idMap, event).subscribe({
      next: (res) => {
        const stringJson = JSON.parse(res.map);
        this.canvas.loadFromJSON(stringJson, function () {
        });
        this.saveBakground();
        this.titleMap = res.name;
        this.capacity = res.capacity;
        this.levels = res.level_maps.sort((a, b) => a.orderBuy - b.orderBuy);
        this.idLevel = res.level_maps.length + 1;
        this.hiddenTypes = res.hidden_seats.sort((a, b) => a.orderBuy - b.orderBuy);
        this.idHidden = res.hidden_seats.length + 1;
        if (this.idEvent || this.fromCreate) {
          this.activeEdit(true);
        }
      },
      complete: () => { this.canvas.renderAll(); this.loadCanvas.emit(this.titleMap); }
    })
  }

  // Botones externos de zoom
  zoomMap(plus: boolean, delta: any = 50) {
    var zoom = this.canvas.getZoom();
    var plusDelta = plus ? -delta : delta;
    zoom *= 0.999 ** plusDelta;
    if (zoom > 2) zoom = 2;
    if (zoom < 0.2) zoom = 0.2;
    this.canvas.setZoom(zoom);
    this.canvas.renderAll();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.canvasWidth = 2220;
    this.canvasHeight = 1400;
    this.canvas.setDimensions({ width: this.canvasWidth, height: this.canvasHeight });
    this.canvas.setZoom(0.6);
    this.idMap ? this.getMap() : this.saveBakground();
  }

  // Background canvas
  saveBakground() {
    var imageUrl = "/assets/img/canvas/svg_grid.svg";
    this.canvas.setBackgroundImage(imageUrl, this.canvas.renderAll.bind(this.canvas), {
      scaleX: 5,
      scaleY: 5,
      top: -50,
      left: -50,
      originX: "left",
      originY: "top",
    })
    this.canvas.renderAll();
    this.loadCanvas.emit(false);
  }

  @HostListener('document:keydown', ['$event'])
  onDeleteComponent(event: KeyboardEvent) {
    const key = event.key.toLowerCase();
    if (!this.activeTab || this.openEditObject || this.openEditSection && !this.blockComponents) {
      if ((key == 'backspace' || key == 'forward backspace' || key == 'delete') && this.canvas.getActiveObject()) {
        this.canvas.remove(this.canvas.getActiveObject());
        this.closeTab();
      }
    }
  }

  // Añadir asientos y secciones
  addScenary() {
    this.canvas.discardActiveObject();
    const rectangle = this.createRect(this.colorDefaultStage, 700, 250);
    const textComponent = this.createTextComponent('Stage', 20, -30);
    let elements = [rectangle, textComponent];
    var canvas = this.canvas;
    fabric.loadSVGFromURL(this.screenElement, function (objects, options) {
      var obj = fabric.util.groupSVGElements(objects, options);
      obj.set({ top: -5, scaleX: 0.05, scaleY: 0.05, originX: 'center', hasControls: false })
      elements.push(obj);
      var groupText = new fabric.Group(elements, { originX: 'center', originY: 'center', width: 600, height: 250 });
      var group = new fabric.Group(elements, {
        left: window.innerWidth * 0.2,
        top: 100,
        width: 700,
        height: 250,
        lockRotation: true,
      });
      group.setControlVisible('mtr', false);
      canvas.add(group);
      canvas.renderAll();
    })
  }

  addForm() {
    this.canvas.discardActiveObject();
    const rectangle = this.createRect(this.colorDefaultStage, 700, 250);
    var canvas = this.canvas;
    var group = new fabric.Group([rectangle], {
      isInfoSection: true,
    });
    canvas.add(group);
    canvas.renderAll();
  }

  addSectionFree() {
    this.canvas.discardActiveObject();
    this.addGroupInfo(this.seatsElement, 'Sección libre', this.colorDefaultFree, 'Capacidad');
  }

  addStandSection() {
    this.canvas.discardActiveObject();
    this.addGroupInfo(this.shoesElement, 'Pista', this.colorDefaultStand, 'Capacidad');
  }

  addGroupInfo(icon: string, text: string, color: any, subtitle?: string) {
    const rectangle = this.createRect(color, 300, 150);
    const textComponent = this.createTextComponent(text, 18, -30);
    let elements = [rectangle, textComponent];
    if (subtitle) {
      const textCapacity = this.createTextComponent(subtitle, 16, 40);
      elements.push(textCapacity);
    }
    var canvas = this.canvas;
    var section = this.sectionNumber;
    fabric.loadSVGFromURL(icon, function (objects, options) {
      var obj = fabric.util.groupSVGElements(objects, options);
      obj.set({ top: -5, scaleX: 0.05, scaleY: 0.05, originX: 'center', hasControls: false })
      elements.push(obj);
      var groupText = new fabric.Group(elements, { originX: 'center', originY: 'center', width: 300, height: 150 });
      var group = new fabric.Group(elements, {
        left: window.innerWidth * 0.2,
        top: 100,
        width: 300,
        height: 150,
        capacity: 50,
        lockRotation: true,
        eventSeat: true,
        isSection: true,
        section: { id: section, name: 'Sección ' + section },
      });
      group.setControlVisible('mtr', false);
      canvas.add(group);
      canvas.renderAll();
    })
    this.sectionNumber++;
  }

  // Crear rectángulo
  createRect(color: string, width: number, height: number) {
    const rectangle = new fabric.Rect({
      fill: color,
      stroke: '#a9a8b3',
      strokeWidth: 0.5,
      originX: 'center',
      originY: 'center',
      width: width,
      height: height,
      eventSeat: false
    });

    return rectangle;
  }

  // Añadir asiento
  addSeat() {
    this.canvas.discardActiveObject();
    const filas = 1;
    const columns = 0;
    var top = 10;
    var left = 0;
    var arrayElements = [];
    const text = new fabric.Textbox('Seccion', {
      fontSize: 18,
      // fontFamily: 'Satoshi-Regular',
      left: 'left',
      eventSeat: false,
      textAlign: 'center',
    });
    let aux = filas;
    for (let numF = 0; numF < filas; numF++) {
      const file = this.createSeat((aux).toString(), false);
      file.originX = left;
      file.originY = top - numF * 1.2;
      file.column = 1;
      file.isVisible = true;
      arrayElements.push(file);
      for (let numC = 0; numC < columns; numC++) {
        const element = this.createSeat((numC + 1).toString(), true);
        element.originX = left - 1.2 - numC * 1.2;
        element.originY = top - numF * 1.2;
        element.eventSeat = true;
        element.row = aux;
        element.seat = numC + 1;
        element.column = numC + 1;
        element.isVisible = true;
        element.section = { id: this.sectionNumber, name: 'Sección ' + this.sectionNumber },
          arrayElements.push(element);
      }
      aux--;
      // const endFile = this.createSeat((numF + 1).toString(), false);
      // endFile.originX = left - 1.2 - columns * 1.2;
      // endFile.originY = top - numF * 1.2;
      // endFile.column = columns;
      // endFile.isVisible = true;
      // arrayElements.push(endFile);
    }
    var group = new fabric.Group(arrayElements, {
      left: 0,
      top: 30,
      scaleX: 0.4,
      scaleY: 0.4,
      hasControls: false,
      arraySeats: true,
      eventSeat: false,
      originX: 'center',
      rows: filas,
      columns: columns,
      showRows: true,
      section: { id: this.sectionNumber, name: 'Sección ' + this.sectionNumber },
    });
    var group = new fabric.Group([text, group], {
      groupSeat: true,
      hasControls: false,
      eventSeat: false,
      originX: 'center',
      originY: 'center'
    });
    // group.setControlVisible('mtr', false);
    this.sectionNumber++;
    this.canvas.add(group);
  }

  // Crear asiento
  createSeat(seatNumber: any, fill: boolean = true) {
    const circle = new fabric.Circle({
      radius: 50,
      fill: 'transparent',
      strokeWidth: 2,
      originX: 'center',
      originY: 'center',
    });
    var color = 'black';
    if (fill) {
      circle.fill = this.colorDefaultSeat;
      circle.stroke = '#4D4D4D';
      color = 'black';
    }


    var text = this.createTextComponent(seatNumber.toString(), 40, 0, color);

    var group = new fabric.Group([circle, text], {
      hasControls: false,
      radius: 50,
    });
    return group;
  }

  addPoligon() {
    var points = [{
      x: 3, y: 4
    }, {
      x: 16, y: 3
    }, {
      x: 30, y: 5
    }, {
      x: 25, y: 55
    }, {
      x: 19, y: 44
    }, {
      x: 15, y: 30
    }, {
      x: 15, y: 55
    }, {
      x: 9, y: 55
    }, {
      x: 6, y: 53
    }, {
      x: -2, y: 55
    }, {
      x: -4, y: 40
    }, {
      x: 0, y: 20
    }]
    var polygon = new fabric.Polygon(points, {
      left: 100,
      top: 50,
      fill: this.colorDefaultStage,
      strokeWidth: 0.5,
      stroke: '#a9a8b3',
      scaleX: 4,
      scaleY: 4,
      objectCaching: false,
      transparentCorners: true,
      isInfoSection: true,
      originX: 'center',
      originY: 'center',
      width: 700,
      height: 250,
      eventSeat: false,
      perPixelTargetFind: true
    });
    this.canvas.viewportTransform = [0.7, 0, 0, 0.7, -50, 50];
    this.canvas.add(polygon);
  }

  addText() {
    const text = new fabric.Textbox('Escribe un texto...', {
      width: 200,
      height: 100,
      fontSize: 24,
      // fontFamily: 'Satoshi-Regular',
      cursorColor: '#FF4D80',
      left: 50,
      top: 50,
      eventSeat: false
    });
    this.canvas.add(text);
  }

  createTextComponent(text: any, fontsize: number, top: number = 0, color: string = 'black') {
    let textComponent = new fabric.Text(text, {
      // fontFamily: 'Satoshi-Regular',
      fontSize: fontsize,
      fill: color,
      textAlign: 'center',
      originX: 'center',
      originY: 'center',
      eventSeat: false,
      top: top
    });
    return textComponent;
  }

  // Select Object and Edit
  clickSelectObject(element: any) {
    this.activeTab = true;
    this.titleTab = 'Sección';
    this.openEditObject = true;
    this.objectEditing = element;
  }

  clickSelectSection(element: any) {
    this.activeTab = true;
    this.titleTab = 'Información de sección';
    this.openEditSection = true;
    this.objectEditing = element;
  }

  createObj(obj: any) {
    this.canvas.add(obj);
    this.canvas.renderAll();
  }

  clearedObjectSelect() {
    this.openEditObject = false;
    this.openEditSection = false;
    this.activeTab = false;
    if (!this.fromCreate) {
      this.blockComponents = false;
    }
    this.selectedHidden = [];
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
    this.clearObjects();
  }

  activeEdit(event: any) {
    this.blockComponents = true;
    this.canvasOnEditSeats(true, 'pointer', this.objectEditing);
    this.canvas.renderAll();
  }

  editObject() {
    this.blockComponents = true;
    this.canvasOnEditSeats(true, 'pointer', this.objectEditing);
    var shadow = new fabric.Shadow({
      color: "black",
      blur: 20,
      offsetX: 10,
      offsetY: 10,
    });
    const shadowBack = new fabric.Rect({
      fill: '#00000047',
      originX: 'center',
      originY: 'center',
      width: 8000,
      height: 8000,
      isShadow: true,
      selectable: false
    });
    this.canvas.add(shadowBack);
    this.canvas._objects.forEach(obj => {
      if (obj == this.objectEditing) {
        obj.shadow = shadow
        if (obj.groupSeat) {
          let nameSection = obj._objects[0];
          nameSection.set('fill', 'white');
          let arrayObject = obj._objects[1];
          let numbers = arrayObject._objects.filter(value => !value.eventSeat);
          numbers.forEach(element => {
            let elementsText = element._objects.filter(value => value.type == 'text' || value.type == 'path');
            elementsText.forEach(elementText => {
              elementText.set('fill', 'white');
            });
            element.visible = element.isVisible;
          });
        }
      }
    });
    this.canvas.bringToFront(this.objectEditing)
    this.canvas.renderAll();
  }

  cancelEditSeat() {
    this.openSeatEditing = false;
    this.seatsEditSelected = [];
    this.sitSelect = null;
    this.canvasOnEditSeats(false);
    this.canvasColorHidden(false);
    if (!this.fromCreate) {
      this.blockComponents = false;
    }
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  }

  clearObjects() {
    this.canvasOnEditSeats(false, 'default');
    let shadowEl = null;
    this.canvas._objects.forEach(obj => {
      obj.shadow = null;
      obj.selectable = true;
      if (obj.groupSeat) {
        let nameSection = obj._objects[0];
        nameSection.set('fill', 'black');
        let arrayObject = obj._objects[1];
        let numbers = arrayObject._objects.filter(value => !value.eventSeat);
        numbers.forEach(element => {
          let elementsText = element._objects.filter(value => value.type == 'text' || value.type == 'path');
          elementsText.forEach(elementText => {
            elementText.set('fill', 'black');
          });
          if (element.eventSeat) {
            element.visible = element.isVisible;
            changeSeatColor(element, (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat));
          }
        });
      }
      if (obj.isShadow) {
        shadowEl = obj;
      }
    });
    this.canvas.remove(shadowEl);
    this.canvas.renderAll();

  }

  // Guardaar y editar mapa
  updateCanvas(exit: boolean) {
    this.clearObjects();
    this.canvas.renderAll();
    if (exit) {
      this.closeTab();
    }
  }

  saveColorSection(cancel: boolean) {
    this.canvas.renderAll();
  }

  save() {
    if (this.canvas) {
      this.closeTab();
      this.idMap ? this.editMap() : this.editName();
    }
  }

  saveJson() {
    const jsonMap = this.loadJson();
    this.createEventService.setJsonMap(jsonMap);
  }

  loadJson() {
    this.canvas.setBackgroundImage(null);
    this.canvas.renderAll();
    let maxHeight = 600;
    let maxWidth = 1000;
    this.canvas.renderAll.bind(this.canvas), function (o, object) {
      maxHeight = Math.max(maxHeight, Math.abs(o.top + o.height));
      maxWidth = Math.max(maxWidth, Math.abs(o.left + o.width));
    };
    this.canvas.setDimensions({ width: maxWidth + 50, height: maxHeight + 50 });
    let json = this.canvas.toDatalessJSON(this.areaSeatService.arrayParams);
    json.objects.forEach((element, index) => {
      element.hasControls = false;
    });
    this.getHiddenSeats();
    let svgData = JSON.stringify(json);
    let jsonMap = {
      name: this.titleMap,
      capacity: this.capacity,
      levels: this.levels,
      hiddenSeats: this.hiddenTypes,
      map: svgData,
      private: this.privateMap
    }
    this.canvas.setBackgroundImage();
    return jsonMap;
  }

  editMap() {
    const jsonMap = this.loadJson();
    this.seatMapService.editMap(jsonMap, this.idMap).subscribe({
      next: (res) => {
        this.openInfo('Se han guardado tus cambios correctamente.');

        // this.editEvent ? this.location.back() :
        //   this.router.navigate(['/profile/seat-maps']);
      },
      error: (err) => {
        this.openError();
      }
    })
  }

  createMap(jsonMap: any) {
    this.seatMapService.createMap(jsonMap).subscribe({
      next: (res) => {
        this.openInfo('Se ha guardado correctamente. Ya puedes empezar a usar tu mapa en todos los eventos.')
        this.router.navigate(['/profile/seat-maps'])
      },
      error: (err) => {
        this.openError();
      }
    })
  }

  openError() {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: false,
        text: '¡Vaya, algo ha ido mal! Vuelve a itentarlo más tarde.',
        btnCancel: false
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.editEvent ? this.location.back() :
          this.router.navigate(['/profile/seat-maps']);
      }
    })
  }

  editName() {
    const dialogRef = this.dialog.open(NameMapComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: { edit: this.idMap != null, name: this.titleMap }
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result.edit) {
          this.titleMap = result.name;
          let jsonMap = this.loadJson();
          jsonMap.name = result.name;
          this.createMap(jsonMap);
        }
      }
    })
  }

  deleteMap() {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¿Estás seguro de que deseas eliminar el mapa de asientos? Todos los datos se perderán.',
        btnCancel: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.seatMapService.deleteMap(this.idMap).subscribe({
          next: () => {
            this.openInfo('Se han guardado tus cambios correctamente')
            this.router.navigate(['/profile/seat-maps'])
          },
          error: (err) => {
            this.openError();
          }
        })
      }
    })
  }

  openInfo(text: any) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: false,
        text: text,
        btnCancel: false
      }
    });
    // dialogRef.afterClosed().subscribe({
    //   next: (data) => {
    // if (!profile?.admin && (profile?.organizer || profile?.potocolo)) {
    //   this.router.navigate(['/event/' + this.idEvent + '-Evento']);
    // } else {
    //   this.editEvent || this.idEvent ? this.location.back() :
    //     this.router.navigate(['/profile/seat-maps']);
    // }
    //   }
    // })
  }

  // Levels & Hidden
  openLevelsHidden(type: string) {
    this.canvas.discardActiveObject();
    this.countAssign();
    const pointer = type == 'level' || type == 'hidden' ? 'default' : 'pointer';
    this.canvasOnEditSeats(true, pointer);
    type == 'level' ? this.canvasColorLevels(true) : this.canvasColorHidden(true);
    this.titleTab = type == 'level' ? 'Tipos de asiento o entrada' : 'Ocultar asientos';
    this.titleTab = type == 'hidden' ? 'Tipos de asientos ocultos' : this.titleTab;
    this.activeTab = true;
    this.openedLevels = type == 'level' ? true : false;
    this.openedHidden = type == 'hidden' ? true : false;
  }

  addLevel() {
    let color = this.areaSeatService.getIndexColor(this.idLevel - 1);
    let objLevel = { orderBuy: this.idLevel, name: 'Área ' + this.idLevel, color: color, seats: 0 };
    this.levels.push(objLevel);
    this.idLevel++;
  }

  deleteLevel(level: any) {
    let indexLevel = this.levels.findIndex(value => value.orderBuy == level.orderBuy);
    this.levels.splice(indexLevel, 1);
  }

  assignedSeatsLevel(assigned: any) {
    if (assigned) {
      // this.assignedSeats += this.selectedSeats?.length || 0;
      this.canvasOnEditColor(true, this.levelEdit);
      this.canvasOnEditSeats(true, 'default');
      this.levelEdit = null;
    }
    if (!assigned) {
      this.levelEdit = null;
      this.canvasOnEditColor(false, null);
      this.canvasOnEditSeats(true, 'default');
    }
    this.countAssign();
  }

  addHiddenType() {
    let color = this.areaSeatService.getIndexColorHidden(this.idHidden - 1);
    let objLevel = { orderBuy: this.idHidden, name: 'Tipo ' + this.idHidden, color: color, numSeats: 0 };
    this.hiddenTypes.push(objLevel);
    this.idHidden++;
  }

  deleteHiddenType(type: any) {
    let indexLevel = this.hiddenTypes.findIndex(value => value.orderBuy == type.orderBuy);
    this.hiddenTypes.splice(indexLevel, 1);
  }

  assignedSeatsHidden(assigned: any) {
    if (assigned) {
      // this.assignedSeats += this.selectedSeats?.length || 0;
      this.canvasOnEditHidden(true, this.hiddenTypeEdit);
    }
    if (!assigned) {
      this.hiddenTypeEdit = null;
      this.canvasOnEditHidden(false, null);
    }
    this.canvasOnEditSeats(true, 'default');
    this.hiddenTypeEdit = null;
    this.countAssign();
  }

  countAssign() {
    this.assignedSeats = [];
    this.hiddenSeats = [];
    this.selectedHidden = [];
    let objects = this.canvas._objects;
    objects.forEach(element => {
      if (element.level && element.isSection) {
        this.assignedSeats.push({ section: element.section, row: null, seat: null, level: element.level });
      }
      if (element.groupSeat) {
        let arrayObject = element._objects[1];
        arrayObject._objects.forEach(seat => {
          if (seat.eventSeat && seat.level) {
            this.assignedSeats.push({ section: seat.section, row: seat.row, seat: seat.seat, level: seat.level });
          }
          if (seat.eventSeat && seat.hidden) {
            this.hiddenSeats.push({ section: seat.section, row: seat.row, seat: seat.seat, hiddenType: seat.hiddenType });
          }
        });
      }
    });
    this.levels.forEach(element => {
      const seats = this.assignedSeats.filter(value => value.level.orderBuy == element.orderBuy);
      element.seats = seats?.length;
    });

    this.hiddenTypes.forEach(element => {
      const seats = this.hiddenSeats.filter(value => value.hiddenType?.orderBuy == element.orderBuy);
      element.seats = seats?.length;
    });
  }

  activeEditHidden(hiddenEdit: any) {
    this.hiddenTypeEdit = hiddenEdit;
    this.canvasOnEditSeats(true, 'pointer');
  }

  activeEditLevel(levelEdit: any) {
    this.levelEdit = levelEdit;
    this.canvasOnEditSeats(true, 'pointer');
  }

  closeTab() {
    if (this.openedLevels || this.openedHidden) {
      let pointer = this.fromCreate ? 'default' : 'pointer';
      this.canvasOnEditSeats(false, pointer);
      this.openedLevels ? this.canvasColorLevels(false) : this.canvasColorHidden(false);
    }
    this.openedLevels = false;
    this.openedHidden = false;
    this.levelEdit = null;
    this.selectedSeats = [];
    this.selectedHidden = [];
    this.hiddenTypeEdit = null;
    this.activeTab = false;
    this.openEditSection = false;
    this.clearedObjectSelect();
    if (this.idEvent || this.fromCreate) {
      this.activeEdit(true);
    }
    this.saveJson();
  }

  // Update Colors
  canvasOnEditSeats(edit: boolean = true, pointer: string = 'pointer', editElement?: any) {
    let objects = this.canvas._objects;
    objects.forEach(element => {
      element.lockMovementY = edit;
      element.lockMovementX = edit;
      element.lockRotation = edit;
      element.lockScalingFlip = edit;
      element.lockScalingY = edit;
      element.lockScalingX = edit;
      element.hoverCursor = edit ? pointer : 'move';
      element.selectable = pointer == 'pointer';
      if (element.groupSeat && (!editElement || editElement == element)) {
        element.subTargetCheck = edit;
        element.perPixelTargetFind = edit;
        let arrayObject = element._objects[1];
        arrayObject.subTargetCheck = edit;
        arrayObject.perPixelTargetFind = edit;
        arrayObject._objects.forEach(seat => {
          let exist = this.bookings?.find(value => value.section == seat.section?.id && value.row == seat.row && value.seat == seat.seat && value.level?.orderBuy == seat.level?.orderBuy)
          if (seat.eventSeat) {
            seat.hoverCursor = edit && !exist ? pointer : 'default';
            seat.selectable = !exist;
            seat.visible = seat.isVisible;
          }
        });
      }
      if (!element.eventSeat) {
        element.selectable = !edit;
        element.hoverCursor = edit ? 'default' : pointer;
      }
    });
    this.canvas.renderAll();
  }

  canvasColorLevels(colorLevel: boolean = false) {
    let objects = this.canvas._objects;
    objects.forEach(element => {
      let defaultColor = element.isSection ? element.level?.color || this.colorDefaultFree : this.colorDefaultFree;
      if (element.isSection) {
        changeSeatColor(element, colorLevel ? defaultColor : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat));
      }
      if (element.groupSeat) {
        let arrayObject = element._objects[1];
        arrayObject._objects.forEach(seat => {
          let defaultColorSeat = seat.eventSeat ? seat.level?.color || this.colorDefaultSeat : (this.fromCreate ? this.colorDefaultBasic : this.colorDefaultSeat);
          let exist = false;
          if (!colorLevel && this.idEvent && !this.fromCreate) {
            exist = this.bookings?.find(value => value.section == seat.section?.id && value.row == seat.row && value.seat == seat.seat && value.level?.orderBuy == seat.level?.orderBuy)
          }
          if (exist && !colorLevel) {
            changeSeatColor(seat, this.colorDefaultHidden);
          } else if (seat.eventSeat) {
            changeSeatColor(seat, colorLevel ? defaultColorSeat : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultFree));
          }
        });
      }
    });
    this.canvas.renderAll();
  }

  canvasColorHidden(hiddenActive: boolean) {
    let objects = this.canvas._objects;
    objects.forEach(element => {
      if (element.isSection && element.hidden) {
        let defaultColor = element.isSection ? element.hiddenType?.color || this.colorDefaultSeat : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat);
        changeSeatColor(element, hiddenActive ? defaultColor : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat));
      }
      if (element.groupSeat) {
        let arrayObject = element._objects[1];
        arrayObject._objects.forEach(seat => {
          let exist = false;
          if (this.idEvent) {
            exist = this.bookings?.find(value => value.section == seat.section?.id && value.row == seat.row && value.seat == seat.seat && value.level?.orderBuy == seat.level?.orderBuy)
          }
          if (exist && hiddenActive) {
            changeSeatColor(seat, this.colorDefaultHidden);
          } else if (seat.eventSeat && seat.hidden) {
            let defaultColorSeat = seat.eventSeat ? seat.hiddenType?.color || this.colorDefaultSeat : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat);
            changeSeatColor(seat, hiddenActive ? defaultColorSeat : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat));
          }
        });
      }
    });
    this.canvas.renderAll();
  }

  canvasOnEditColor(saveLevel: boolean = false, level: any) {
    this.selectedSeats.forEach(element => {
      if (element.eventSeat && saveLevel) {
        element.level = {
          name: level.name,
          color: level.color,
          orderBuy: level.orderBuy,
          seats: level.seats
        }
        element.assign = true
      }
      let defaultColor = element.isSection ? this.colorDefaultFree : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat);
      let color = element.level ? element.level.color : defaultColor;

      changeSeatColor(element, saveLevel ? level.color : color);
    });
    this.selectedSeats = [];
    this.canvas.renderAll();
  }

  canvasOnEditHidden(saveLevel: boolean = false, item: any) {
    this.selectedHidden.forEach(element => {
      if (element.eventSeat && saveLevel) {
        element.hiddenType = {
          name: item.name,
          color: item.color,
          orderBuy: item.orderBuy,
          seats: item.seats
        }
        element.assign = true
      }
      let defaultColor = element.isSection ? this.colorDefaultFree : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat);
      let color = element.hiddenType ? element.hiddenType.color : defaultColor;

      changeSeatColor(element, saveLevel ? item.color : color);
    });
    this.selectedHidden = [];
    this.canvas.renderAll();
  }

  // Hidden seats change
  hiddenAllChange(event: any) {
    let objects = this.canvas._objects;
    objects.forEach(element => {
      let colorSection = element.isSection ? this.colorDefaultHidden : this.colorDefaultFree;
      if (element.isSection && element.hidden) {
        changeSeatColor(element, event ? colorSection : this.colorDefaultFree);
      }
      if (element.isSection) { element.hidden = event };
      if (element.groupSeat) {
        let arrayObject = element._objects[1];
        arrayObject._objects.forEach(seat => {
          if (seat.eventSeat && seat.hidden) {
            changeSeatColor(seat, event ? this.colorDefaultHidden : (this.fromCreate || this.fromEvent ? this.colorDefaultBasic : this.colorDefaultSeat));
          }
          if (seat.eventSeat) { seat.hidden = event };
        });
      }
    });
    this.canvas.renderAll();
    this.countAssign();
  }

  getHiddenSeats() {
    let objects = this.canvas._objects;
    objects.forEach(element => {
      if (element.groupSeat) {
        let arrayObject = element._objects[1];
        arrayObject._objects.forEach(seat => {
          if (seat.eventSeat && seat.hidden) {
            this.hiddenSeats.push({ section: seat.section, row: seat.row, seat: seat.seat, level: seat.level, hiddenType: seat.hiddenType });
          }
        });
      }
    });

    this.hiddenTypes.forEach(element => {
      const seats = this.hiddenSeats.filter(value => value.hiddenType?.orderBuy == element.orderBuy);
      const objectSeats = seats.map(seat => { return { section: seat.section, row: seat.row, seat: seat.seat, level: seat.level } })
      element.objectsSeats = objectSeats;
      element.seats = seats?.length;
    });
  }

  ngOnDestroy() {
    this.subscribeSaveBtn?.unsubscribe();
  }

}

function changeSeatColor(elementSeat, color) {
  let elementStroke = elementSeat._objects.find(value => value.type != 'text');
  elementStroke?.set('fill', color);
  let rgb = hexToRgb(color);
  let textColor = (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186 ? '#000' : '#fff'
  let elementText = elementSeat._objects.find(value => value.type == 'text');
  elementText?.set('fill', textColor);
  return elementSeat;
}

function keepPositionInBounds(canvas: any, e: any) {
  var zoom = canvas.getZoom();
  var xMax = zoom * canvas.getWidth() * 2.5;
  var yMax = zoom * canvas.getHeight() * 2.5;
  var transport = canvas.viewportTransform;

  let deltaX = transport[4] + e.e.movementX > 0 ? -transport[4] : e.e.movementX;
  deltaX = -(transport[4] + e.e.movementX) < xMax || (-(transport[4] + e.e.movementX) <= xMax && e.e.movementX > 0) ? (transport[4] + e.e.movementX > 0 ? -transport[4] : e.e.movementX) : 0;

  let deltaY = transport[5] + e.e.movementY > 0 ? -transport[5] : e.e.movementY;
  deltaY = -(transport[5] + e.e.movementY) < yMax || (-(transport[5] + e.e.movementY) <= yMax && e.e.movementY > 0) ? (transport[5] + e.e.movementY > 0 ? -transport[5] : e.e.movementY) : 0;

  canvas.relativePan(new fabric.Point(deltaX, deltaY));
  return canvas;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function getObjectSizeWithStroke(object) {
  var stroke = new fabric.Point(
    object.strokeUniform ? 1 / object.scaleX : 1,
    object.strokeUniform ? 1 / object.scaleY : 1
  ).multiply(object.strokeWidth);
  return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
}

function actionHandler(eventData, transform, x, y) {
  var polygon = transform.target,
    currentControl = polygon.controls[polygon.__corner],
    mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
    polygonBaseSize = getObjectSizeWithStroke(polygon),
    size = polygon._getTransformedDimensions(0, 0),
    finalPointPosition = {
      x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
      y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
    };
  polygon.points[currentControl.pointIndex] = finalPointPosition;
  return true;
}

function anchorWrapper(anchorIndex, fn) {
  return function (eventData, transform, x, y) {
    var fabricObject = transform.target,
      absolutePoint = fabric.util.transformPoint({
        x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
        y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
      }, fabricObject.calcTransformMatrix()),
      actionPerformed = fn(eventData, transform, x, y),
      newDim = fabricObject._setPositionDimensions({}),
      polygonBaseSize = getObjectSizeWithStroke(fabricObject),
      newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
      newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  }
}

function polygonPositionHandler(dim, finalMatrix, fabricObject) {
  var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
    y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
  return fabric.util.transformPoint(
    { x: x, y: y },
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas.viewportTransform,
      fabricObject.calcTransformMatrix()
    )
  );
}

function inside(point, vs) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

  var x = point.x, y = point.y;

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i].x, yi = vs[i].y;
    var xj = vs[j].x, yj = vs[j].y;

    var intersect = ((yi > y) != (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
};

function checkcheck(pointTest, points: any[]) {

  var i, j = points.length - 1;
  var x = pointTest.x;
  var y = pointTest.y;
  var odd = false;

  var pX = points.map(value => value.x);
  var pY = points.map(value => value.y);

  for (i = 0; i < points.length; i++) {
    if ((pY[i] < y && pY[j] >= y || pY[j] < y && pY[i] >= y)
      && (pX[i] <= x || pX[j] <= x)) {
      odd !== (pX[i] + (y - pY[i]) * (pX[j] - pX[i]) / (pY[j] - pY[i])) < x;
    }

    j = i;
  }

  return odd;
}