import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventosService } from 'src/app/services/eventos.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { InfoAbonosComponent } from '../info-abonos/info-abonos.component';
import { PrivateEventComponent } from '../private-event/private-event.component';
import { TicketsBondComponent } from '../tickets-bond/tickets-bond.component';

@Component({
  selector: 'app-open-external',
  templateUrl: './open-external.component.html',
  styleUrls: ['./open-external.component.scss']
})
export class OpenExternalComponent implements OnInit {

  @Input() event!: any;
  @Input() pastEvent!: boolean;
  @Input() repeat: any;
  @Output() openHours: EventEmitter<boolean> = new EventEmitter();
  disabledSales: boolean = true;
  endSales: boolean = false;
  enableAbono: boolean = false;
  optionsHour: any[] = [];

  ticketsBondEnabled!: any;

  constructor(private eventosService: EventosService, private dialog: MatDialog, private router: Router) {
  }

  ngOnInit(): void {
    if (this.event.abonos?.length > 0) {
      this.enableAbono = true;
    }
    let now = new Date();
    let nowHour = new Date();
    now.setHours(0);
    now.setMinutes(0);
    this.disabledSales = this.event?.startSales && ((new Date(this.event?.startSales) > nowHour));
    let hoursEvent = this.event.horarios.filter(hor => hor.activo == true);
    this.optionsHour = hoursEvent.sort((a: any, b: any) => {
      return new Date(a.horaInicio).getTime() - new Date(b.horaInicio).getTime();
    })
    if (this.event?.endSales) {
      this.endSales = (new Date(this.event?.endSales) <= nowHour);
    } else {
      // No estará activa la venta si el evento ha empezado. Mirar la fecha fin del evento y la hora fin
      let enabledDates = this.event.fechas.filter((fecha: any) => fecha.activo && (new Date(fecha.fechaInicio) >= now || (fecha.fechaFin && new Date(fecha.fechaFin) >= now)));
      if (enabledDates?.length <= 0) {
        this.endSales = true;
      } else {
        let datesSort = enabledDates.sort((a, b) => new Date(a.fechaInicio).getTime() - new Date(b.fechaInicio).getTime());
        let last = datesSort[datesSort.length - 1];
        if ((last?.fechaFin && new Date(last?.fechaFin).getFullYear() == now.getFullYear() && new Date(last?.fechaFin).getMonth() == now.getMonth() && new Date(last?.fechaFin).getDate() == now.getDate())
          || (!last?.fechaFin && new Date(last?.fechaInicio).getFullYear() == now.getFullYear() && new Date(last?.fechaInicio).getMonth() == now.getMonth() && new Date(last?.fechaInicio).getDate() == now.getDate())) {
          // Si la fecha Fin es igual al día actual, comprobar la hora
          let lastHour = this.optionsHour[this.optionsHour.length - 1];
          if (new Date(lastHour.horaInicio).getUTCHours() < nowHour.getHours()) {
            this.endSales = true;
          } else if (new Date(lastHour.horaInicio).getUTCHours() == nowHour.getHours() && new Date(lastHour.horaInicio).getUTCMinutes() <= nowHour.getMinutes()) {
            this.endSales = true;
          }
        }
      }
    }
    this.ticketsBondEnabled = this.event?.entradas.some((value) => value.typeBond);
  }

  buyAbono() {
    // Comprobar si es un evento privado
    if (this.event.privateEvent) {
      const refAbonosDialog = this.dialog.open(PrivateEventComponent, {
        panelClass: ['info-dialog', 'not-padding', 'info-abono-dialog'],
      });
      refAbonosDialog.afterClosed().subscribe({
        next: (result) => {
          if (result) {
            if (result == this.event.accessCodeSales) {
              this.openDialogBond();
            } else {
              const dialogRef = this.dialog.open(InfoCardComponent, {
                panelClass: 'info-dialog',
                data: {
                  icon: true,
                  text: 'Lo sentimos, el código es incorrecto.',
                  btnCancel: false
                }
              });
            }
          }
        }
      })
    } else {
      this.openDialogBond();
    }
  }

  openTicketsBond() {
    let bondTicket = this.event?.entradas.find((value) => value.typeBond);
    const refAbonosDialog = this.dialog.open(TicketsBondComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: { ticket: bondTicket, eventId: this.event.id, eventName: this.event.titulo }
    });
    refAbonosDialog.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          this.router.navigate(['/cart-checkout']);
        }
      }
    })
  }

  openDialogBond() {
    const refAbonosDialog = this.dialog.open(InfoAbonosComponent, {
      panelClass: ['info-dialog', 'not-padding', 'info-abono-dialog'],
      data: { abono: this.event.abonos }
    });
    refAbonosDialog.afterClosed().subscribe({
      next: (result) => {
        if (result?.data) {
          result?.exit ? this.router.navigate(['/cart-checkout']) : this.buyAbono();
        }
      }
    })
  }

  goToScrollPage(idPage: string) {
    var height = document.getElementById(idPage).offsetTop - 30;
    window.scrollTo(0, height);
  }

  openEvent(url: string) {
    if (this.event.external) {
      window.open(url, '_blank');
    } else {
      // Comprobar si es un evento privado
      if (this.event.privateEvent) {
        const refAbonosDialog = this.dialog.open(PrivateEventComponent, {
          panelClass: ['info-dialog', 'not-padding', 'info-abono-dialog'],
        });
        refAbonosDialog.afterClosed().subscribe({
          next: (result) => {
            if (result) {
              if (result == this.event.accessCodeSales) {
                this.openHours.emit(true);
              } else {
                const dialogRef = this.dialog.open(InfoCardComponent, {
                  panelClass: 'info-dialog',
                  data: {
                    icon: true,
                    text: 'Lo sentimos, el código es incorrecto.',
                    btnCancel: false
                  }
                });
              }
            }
          }
        })
      } else {
        this.openHours.emit(true);
      }
    }
  }

  convertDateToStringFormat(date: Date, format: string) {
    return (this.eventosService.ConvertDateToStringFormat(date, format));
  }

}
