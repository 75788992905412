import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventosService } from 'src/app/services/eventos.service';
import { OrdersEventService } from 'src/app/services/orders-event.service';
import { ReportsService } from 'src/app/services/reports.service';
import { OrdersBillingComponent } from '../orders-billing/orders-billing.component';

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss']
})
export class OrdersTableComponent implements OnInit {

  @Input() idEvent!: any;
  orders!: any[];
  searchText!: string;
  filterOrders!: any;

  displayedColumns: string[] = ['options', 'id', 'name_citylok', 'email_citylok', 'channel', 'created_at', 'total_order', 'status'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort;

  page = 1;
  totalPages = 1;

  sorted!: any;
  loaded: boolean = true;
  @Input() loadingPage: boolean = true;
  finishScroll: boolean = false;
  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;

  @Input() activeSelectList: boolean = false;
  allSelected: boolean = false;
  @Input() selectedItems: any[] = [];
  @Output() selectedItemsChange = new EventEmitter<any[]>();

  @Input() priceFilters: any = { min: null, max: null };
  @Input() business!: any;
  @Input() places: any[] = [];
  placeSelected!: any;
  allowCancel: boolean = false;

  allowCitylok: boolean = false;
  now: Date = new Date();

  constructor(private dialog: MatDialog, private orderService: OrdersEventService, private eventosService: EventosService,
    private router: Router, private reportService: ReportsService, private apiBusiness: ApiBusinessService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    let rolesProfile = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
    rolesProfile?.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'CREATE_EVENTS' || element == 'ACCESS_SALES' || element == 'ACCESS_SUPPORT') {
        this.allowCancel = true;
      }
    });
    this.allowCitylok = this.auth.getProfile()?.citylok;
    // Permisos temporales para devoluciones
  }

  ngAfterViewInit() {
    this.business = JSON.parse(localStorage.getItem('business') || '{}');
    this.places = JSON.parse(localStorage.getItem('places') || '[]');
    this.orderService.filtersOrderStorage.subscribe({
      next: (res) => {
        if (res) {
          let place = localStorage.getItem('places');
          this.placeSelected = place;
          this.places = [place];
          this.orders = [];
          this.searchText = res?.searchOrder;
          this.filterOrders = res?.filterOrders;
          this.updateOders();
        }
      }
    });
    this.apiBusiness.changed.subscribe({
      next: (value) => {
        if (value) {
          let place = localStorage.getItem('places');
          if (place != this.placeSelected) {
            this.places = [place];
            this.business = JSON.parse(localStorage.getItem('business') || '{}');
            if (value) {
              this.orders = [];
              this.updateOders();
            }
          } else {
            this.business = JSON.parse(localStorage.getItem('business') || '{}');
            this.orders = [];
            this.updateOders();
          }
        }
      }
    })
  }

  getData() {
    if (this.idEvent) {
      this.orderService.getOrdersByEvent(this.idEvent, this.page, this.searchText, this.filterOrders, this.sorted).subscribe({
        next: (res) => {
          this.contentTable(res);
        }
      });
    } else {
      this.orderService.getReportsByBusiness(this.business.id, this.page, this.searchText, this.filterOrders, this.sorted, this.places).subscribe({
        next: (res) => {
          // console.log(res)
          this.contentTable(res);
        }
      })
    }
  }

  contentTable(result: any) {
    this.orders = this.orders.concat(result.data);
    if (this.orders.length <= 0 || this.orders.length < 20) {
      this.finishScroll = true;
    }
    this.dataSource = new MatTableDataSource(this.orders);
    this.totalPages = result.last_page;
    this.loaded = true;
    this.loadingPage = false;
    // Cambiar estado de loading filters
    this.orderService.setLoadingTable(false);
    if (this.allSelected) {
      this.selectAll();
    }
  }

  updateOders() {
    this.page = 1;
    this.finishScroll = false;
    this.orders = [];
    this.loadingPage = true;
    if (this.loaded) {
      this.loaded = false;
      this.getData();
    }
  }

  openOrder(id: number) {
    let url = '/panel-control/orders/detail/info-order';
    if (this.idEvent) {
      window.open(`${AppSettings.FRONT}${url}?search=${id}`, '_blank');
    } else {
      this.router.navigate([url], { queryParams: { search: id } });
    }
  }

  containsBlocked() {
    return this.selectedItems.filter((item) => item.transaction_status?.short_name == 'pending').length > 0;
  }

  getColorStatus(statusKey: string) {
    let color = '';
    switch (statusKey) {
      case 'pending':
        color = '#E9A41B';
        break;
      case 'paid':
        color = '#2DAE29';
        break;
      case 'refund':
        color = '#13CDE2';
        break;
      default:
        color = '#ff0000';
        break;
    }
    return color;
  }

  downloadTicketOrder(idOrder: number, type: string) {
    this.reportService.getUrlsDownload(idOrder).subscribe({
      next: (url) => {
        let param = type == 'a4' ? 'ticket_a4' : 'ticket';
        let uriDownload = url[param];
        window.open(uriDownload, '_blank');
        this.openInfoCard('Se ha descargado el ticket correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar las entradas.', true, false);
      }
    })
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

  // Sort
  sortData(sort: Sort) {
    const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
    if (sort.direction) {
      this.sorted = { orderBy: sort.active, order: isAsc };
    } else {
      this.sorted = null;
    }
    this.updateOders();
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingPage) {
      if (this.page < this.totalPages && !this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingPage = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
        // }
      } else {
        this.finishScroll = true;
      }
    }
  }

  selectAll() {
    this.dataSource.data.forEach((item) => item.selected = this.allSelected);
    this.selectedItems = this.dataSource.data.filter((item) => item.selected);
    this.selectedItemsChange.emit(this.selectedItems);
  }

  changeSelectItem(item: any) {
    item.selected = !item.selected;
    this.selectedItems = this.dataSource.data.filter((item) => item.selected);
    this.allSelected = this.selectedItems.length === this.dataSource.data.length;
    this.selectedItemsChange.emit(this.selectedItems);
  }

  cancelPendingOrder(order: any) {
    // Pedir confirmación 
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¿Estás seguro de que quieres cancelar el pedido? Esta acción no podrá deshacerse.',
        btnCancel: true,
        warning: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reportService.cancelPendingOrder(order.id).subscribe({
          next: (url) => {
            order.transaction_status = url.data?.transaction_status;
            this.openInfoCard('Se ha cancelado el pedido correctamente.', false, false);
          }, error: (err) => {
            this.openInfoCard('Lo sentimos, no se ha podido cancelar el pedido, vuelve a intentarlo más tarde.', true, false);
          }
        })
      }
    })
  }

  freeSeats(order: any) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¿Estás seguro de que quieres liberar los asientos? Esta acción no podrá deshacerse.',
        btnCancel: true,
        warning: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.reportService.cancelSeatsOrder(order.id).subscribe({
        //   next: (url) => {
        //     order.transaction_status = url.data?.transaction_status;
        //     order.status = url.data?.status;
        //     this.openInfoCard('Se han liberado los asientos correctamente.', false, false);
        //   }, error: (err) => {
        //     this.openInfoCard('Lo sentimos, no se ha podido cancelar el pedido, vuelve a intentarlo más tarde.', true, false);
        //   }
        // })
      }
    })
  }

  cancelOrder(order: any) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¿Estás seguro de que quieres devolver el pedido? Esta acción no podrá deshacerse.',
        btnCancel: true,
        warning: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reportService.cancelOrder(order.id, {}).subscribe({
          next: (url) => {
            order.transaction_status = url.data?.transaction_status;
            order.status = url.data?.status;
            this.openInfoCard('Se ha devuelto el pedido correctamente.', false, false);
          }, error: (err) => {
            this.openInfoCard('Lo sentimos, no se ha podido devolver el pedido, vuelve a intentarlo más tarde.', true, false);
          }
        })
      }
    })
  }

  openInfoPayment(order: any) {
    this.reportService.getInfoTransaction(this.business.id, order.id).subscribe({
      next: (data) => {
        this.dialog.open(OrdersBillingComponent, {
          panelClass: 'info-dialog',
          data: {
            order: data,
            id: order.id,
            num_ticket: order.num_ticket,
            user: this.auth.getProfile()
          }
        });
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se ha podido obtener la información del pedido, vuelve a intentarlo más tarde.', true, false);
      }
    })

  }

  sendEmailConfirm(order: any) {
    const payload = {
      billing: {
        "total_order": order.total_order,
        "total_fee": order.total_fee,
      }
    }
    this.reportService.sendEmailConfirm(order.id, payload).subscribe({
      next: (data) => {
        this.openInfoCard('Las entradas se han enviado correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('¡Vaya! Algo ocurrido un error al mandar el correo, vuelve a intentarlo más tarde.', true, false);
      }
    })
  }

}
