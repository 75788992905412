import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor(private http: HttpClient) { }

  getBasicDiscountsByEvent(idEvent: any) { 
    const url = `${AppSettings.API_URI}/list/discounts/event/${idEvent}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  postBasicDiscount(idEvent: any, payload: any) { 
    const url = `${AppSettings.API_URI}/create/basic/discount/event/${idEvent}`;
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  editDiscount(idDiscount: any, payload: any) { 
    const url = `${AppSettings.API_URI}/edit/basic/discount/${idDiscount}`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getVenueDiscounts(idVenue: any) { 
    const url = `${AppSettings.API_URI}/list/discounts/venue/${idVenue}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getVenueDiscountByEvent(idEvent: any) { 
    const url = `${AppSettings.API_URI}/list/event/discounts/venue/${idEvent}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}
