import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { DiscountService } from 'src/app/services/discount.service';
import { EventosService } from 'src/app/services/eventos.service';
import { CreateDiscountComponent } from '../../../create-discount/create-discount.component';

@Component({
  selector: 'app-discount-pass',
  templateUrl: './discount-pass.component.html',
  styleUrls: ['./discount-pass.component.scss']
})
export class DiscountPassComponent implements OnInit {

  discounts: any[] = [];
  discountsPlace!: any[];

  @Input() idEvent!: any;
  displayedColumns: string[] = ['actions', 'name', 'price', 'description', 'code', 'sold'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  loaded: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private eventService: EventosService,
    private discountService: DiscountService, private dialog: MatDialog) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.idEvent = +params.idEvent;
        this.discountService.getBasicDiscountsByEvent(this.idEvent).subscribe({
          next: (res) => {
            this.discounts = res;
            this.dataSource = new MatTableDataSource(this.discounts);
            this.loaded = true;
          }
        });
      }
    });
  }

  editDiscount(discount: any, index: number) {
    const dialogRef = this.dialog.open(CreateDiscountComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: discount[0]
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.discountService.editDiscount(discount[0].id, result).subscribe({
          next: (res) => {
            this.discounts[index][0] = res;
            this.dataSource = new MatTableDataSource(this.discounts);
          },
          error: (err) => {
            this.dialog.open(InfoCardComponent, {
              panelClass: 'info-dialog',
              data: {
                icon: false,
                text: '¡Vaya! Algo ha fallado, vuelve a intentarlo más tarde.',
                btnCancel: false
              }
            });
          }
        })
      }
    });
  }

  openCreateDiscount() {
    const dialogRef = this.dialog.open(CreateDiscountComponent, {
      panelClass: ['info-dialog', 'not-padding'],
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.discountService.postBasicDiscount(this.idEvent, result).subscribe({
          next: (res) => {
            let discount = {0: res, purchases: 0};
            this.discounts.push(discount);
            this.dataSource = new MatTableDataSource(this.discounts);
          },
          error: (err) => {
            this.dialog.open(InfoCardComponent, {
              panelClass: 'info-dialog',
              data: {
                icon: false,
                text: '¡Vaya! Algo ha fallado, vuelve a intentarlo más tarde.',
                btnCancel: false
              }
            });
          }
        })
      }
    });
  }

  openDiscountPlace() {

  }

}
