import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardsComponent } from './cards/cards.component';
import { SwiperModule } from 'swiper/angular';
import { CirclesComponent } from './circles/circles.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { InfoLoksComponent } from './info-loks/info-loks.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CardComponent } from './card/card.component';
import { RouterModule } from '@angular/router';
import { SwiperImagesComponent } from './swiper-images/swiper-images.component';
import { EtiquetasComponent } from './etiquetas/etiquetas.component';
import { CommentsComponent } from './comments/comments.component';
import { OpenExternalComponent } from './open-external/open-external.component';
import { HoursDatesEventComponent } from './hours-dates-event/hours-dates-event.component';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginCardComponent } from './login-card/login-card.component';
import { RegisterCardComponent } from './register-card/register-card.component';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
// import { NgFallimgModule } from 'ng-fallimg';
import { AppSettings } from 'src/app/app-settings';
import { SeatsPanelComponent } from './seats-panel/seats-panel.component';
import { QrCardComponent } from './qr-card/qr-card.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CategoriesListComponent } from './categories-list/categories-list.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';
import { InfoAbonosComponent } from './info-abonos/info-abonos.component';
import { RememberPasswordComponent } from './remember-password/remember-password.component';
import { LottieModule } from 'ngx-lottie';
import { CropperImageComponent } from './cropper-image/cropper-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MapSearchComponent } from '../new-lok/map-search/map-search.component';
import { PrivateEventComponent } from './private-event/private-event.component';

@NgModule({
  declarations: [
    CardsComponent,
    CirclesComponent,
    DownloadAppComponent,
    InfoLoksComponent,
    CardComponent,
    SwiperImagesComponent,
    CommentsComponent,
    EtiquetasComponent,
    OpenExternalComponent,
    HoursDatesEventComponent,
    LoginCardComponent,
    RegisterCardComponent,
    SeatsPanelComponent,
    QrCardComponent,
    CategoriesListComponent,
    QrCodeComponent,
    InfoAbonosComponent,
    RememberPasswordComponent,
    CropperImageComponent,
    MapSearchComponent,
    PrivateEventComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    SocialLoginModule,
    NgxSkeletonLoaderModule,
    LottieModule,
    ImageCropperModule,
    QRCodeModule
  ],
  exports: [
    CardsComponent,
    CirclesComponent,
    DownloadAppComponent,
    InfoLoksComponent,
    CardComponent,
    SwiperImagesComponent,
    CommentsComponent,
    EtiquetasComponent,
    OpenExternalComponent,
    HoursDatesEventComponent,
    LoginCardComponent,
    RegisterCardComponent,
    MapSearchComponent
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(AppSettings.KEYS.google)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(AppSettings.KEYS.facebook)
          }
        ],
        onError: (err) => {
          if(AppSettings.DEBUG) {
            console.error(err);
          }
        }
      } as SocialAuthServiceConfig,
    }
  ]
})
export class CardsModule { }
