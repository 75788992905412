import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPanelComponent } from './dashboard-panel/dashboard-panel.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminModule } from '../admin/admin.module';
import { LottieModule } from 'ngx-lottie';
// import { NgFallimgModule } from 'ng-fallimg';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SubmenuPanelComponent } from './submenu-panel/submenu-panel.component';
import { EventsPanelComponent } from './events/events-panel/events-panel.component';
import { MenuEventsComponent } from './events/menu-events/menu-events.component';
import { RouterEventsComponent } from './events/router-events/router-events.component';
import { InfoEventComponent } from './events/detail-event/info-event/info-event.component';
import { TicketsTypeComponent } from './events/detail-event/tickets-type/tickets-type.component';
import { DiscountPassComponent } from './events/detail-event/discount-pass/discount-pass.component';
import { MapPanelComponent } from './events/detail-event/map-panel/map-panel.component';
import { OrdersEventComponent } from './events/detail-event/orders-event/orders-event.component';
import { ReportsEventComponent } from './events/detail-event/reports-event/reports-event.component';
import { CommentsEventComponent } from './events/detail-event/comments-event/comments-event.component';
import { MailsEventComponent } from './events/detail-event/mails-event/mails-event.component';
import { BondsComponent } from './bonds/bonds.component';
import { DraftEventsComponent } from './events/draft-events/draft-events.component';

import { OrdersTableComponent } from './orders/orders-table/orders-table.component';
import { DetailOrderComponent } from './orders/detail-order/detail-order.component';
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
// import { OrdersBillingComponent } from './orders/orders-billing/orders-billing.component';
import { ConfigPanelComponent } from './config/config-panel/config-panel.component';
import { RouterPermissionComponent } from './config/permission/router-permission/router-permission.component';
import { MenuPermissionComponent } from './config/permission/menu-permission/menu-permission.component';
import { PermissionPanelComponent } from './config/permission/permission-panel/permission-panel.component';
import { UserPermissionComponent } from './config/permission/user-permission/user-permission.component';
import { SubscribersListComponent } from './subscribers/subscribers-list/subscribers-list.component';
import { NewsletterComponent } from './subscribers/newsletter/newsletter.component';
import { SubscribersNotificationsComponent } from './subscribers/subscribers-notifications/subscribers-notifications.component';
import { SubscribersCommentsComponent } from './subscribers/subscribers-comments/subscribers-comments.component';
import { NewManagerComponent } from './config/permission/new-manager/new-manager.component';
import { SelectPlacesManagerComponent } from './config/permission/select-places-manager/select-places-manager.component';
import { NewSubscriberComponent } from './subscribers/new-subscriber/new-subscriber.component';
import { NewComunicationComponent } from './subscribers/new-comunication/new-comunication.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomCalendarComponent } from './custom-calendar/custom-calendar.component';
import { InfoProfileComponent } from './config/info-profile/info-profile.component';
import { MenuBusinessComponent } from './config/business/menu-business/menu-business.component';
import { InfoBusinessComponent } from './config/business/info-business/info-business.component';
import { HelpBusinessComponent } from './config/business/help-business/help-business.component';
import { RouterBusinessComponent } from './config/business/router-business/router-business.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VenuesListComponent } from './config/venues/venues-list/venues-list.component';
import { InfoVenueComponent } from './config/venues/detail-venue/info-venue/info-venue.component';
import { TicketsVenueComponent } from './config/venues/detail-venue/tickets-venue/tickets-venue.component';
import { DiscountsVenueComponent } from './config/venues/detail-venue/discounts-venue/discounts-venue.component';
import { MapVenueComponent } from './config/venues/detail-venue/map-venue/map-venue.component';
import { EventsVenueComponent } from './config/venues/detail-venue/events-venue/events-venue.component';
import { MenuVenueComponent } from './config/venues/detail-venue/menu-venue/menu-venue.component';
import { RouterVenueComponent } from './config/venues/detail-venue/router-venue/router-venue.component';
import { UpdateBusinessComponent } from './config/business/update-business/update-business.component';
import { ContactPanelComponent } from './config/contact-panel/contact-panel.component';
import { UserPurchasesComponent } from './subscribers/user-purchases/user-purchases.component';
import { UpdateVenueComponent } from './config/venues/update-venue/update-venue.component';
import { SeatMapModule } from '../seat-map/seat-map.module';
import { DocsVenueComponent } from './config/venues/detail-venue/docs-venue/docs-venue.component';
import { ActivationControlComponent } from './activation-control/activation-control.component';
import { OrderEventComponent } from './events/detail-event/order-event/order-event.component';
import { EventsGeneralComponent } from './events/events-general/events-general.component';
import { DialogTicketComponent } from './events/dialogs-event/dialog-ticket/dialog-ticket.component';
import { DialogDiscountComponent } from './events/dialogs-event/dialog-discount/dialog-discount.component';
import { DialogPlaceComponent } from './events/dialogs-event/dialog-place/dialog-place.component';
import { UpdateGeneralValuesComponent } from './events/dialogs-event/update-general-values/update-general-values.component';
import { DialogPriceMinComponent } from './events/dialogs-event/dialog-price-min/dialog-price-min.component';
import { CreateBondComponent } from './events/create-bond/create-bond.component';
import { SelectEventBondComponent } from './events/select-event-bond/select-event-bond.component';
import { CreateEventComponent } from './events/create-event/create-event.component';
import { RouterCreateComponent } from './events/components-create/router-create/router-create.component';
import { MenuCreateComponent } from './events/components-create/menu-create/menu-create.component';
import { MapSeatsCreateComponent } from './events/components-create/map-seats-create/map-seats-create.component';
import { TicketsCreateComponent } from './events/components-create/tickets-create/tickets-create.component';
import { DiscountsCreateComponent } from './events/components-create/discounts-create/discounts-create.component';
import { DateSelectorComponent } from './events/create-event/date-selector/date-selector.component';
import { HashtagsEventComponent } from './events/create-event/hashtags-event/hashtags-event.component';
import { CalendarEventDatesComponent } from './events/create-event/calendar-event-dates/calendar-event-dates.component';
import { InfoBondComponent } from './events/info-bond/info-bond.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CreateVenueComponent } from './config/venues/create-venue/create-venue.component';
import { BusinessComponent } from './business/business.component';
import { OrdersBillingComponent } from './orders/orders-billing/orders-billing.component';
import { CreateDiscountComponent } from './create-discount/create-discount.component';
import { ReportsPageComponent } from './reports-page/reports-page.component';


@NgModule({
  declarations: [
    DashboardPanelComponent,
    MenuPanelComponent,
    BreadcrumbComponent,
    SubmenuPanelComponent,
    EventsPanelComponent,
    MenuEventsComponent,
    RouterEventsComponent,
    InfoEventComponent,
    TicketsTypeComponent,
    DiscountPassComponent,
    MapPanelComponent,
    OrdersEventComponent,
    ReportsEventComponent,
    CommentsEventComponent,
    MailsEventComponent,
    BondsComponent,
    DraftEventsComponent,
    OrdersTableComponent,
    DetailOrderComponent,
    OrdersListComponent,
    // OrdersBillingComponent,
    ConfigPanelComponent,
    RouterPermissionComponent,
    MenuPermissionComponent,
    PermissionPanelComponent,
    UserPermissionComponent,
    SubscribersListComponent,
    NewsletterComponent,
    SubscribersNotificationsComponent,
    SubscribersCommentsComponent,
    NewManagerComponent,
    SelectPlacesManagerComponent,
    NewSubscriberComponent,
    NewComunicationComponent,
    CustomCalendarComponent,
    InfoProfileComponent,
    MenuBusinessComponent,
    InfoBusinessComponent,
    HelpBusinessComponent,
    RouterBusinessComponent,
    VenuesListComponent,
    InfoVenueComponent,
    TicketsVenueComponent,
    DiscountsVenueComponent,
    MapVenueComponent,
    EventsVenueComponent,
    MenuVenueComponent,
    RouterVenueComponent,
    UpdateBusinessComponent,
    ContactPanelComponent,
    UserPurchasesComponent,
    UpdateVenueComponent,
    DocsVenueComponent,
    ActivationControlComponent,
    OrderEventComponent,
    EventsGeneralComponent,
    DialogTicketComponent,
    DialogDiscountComponent,
    DialogPlaceComponent,
    UpdateGeneralValuesComponent,
    DialogPriceMinComponent,
    CreateBondComponent,
    SelectEventBondComponent,
    RouterCreateComponent,
    MenuCreateComponent,
    MapSeatsCreateComponent,
    TicketsCreateComponent,
    DiscountsCreateComponent,
    CreateEventComponent,
    DateSelectorComponent,
    HashtagsEventComponent,
    CalendarEventDatesComponent,
    InfoBondComponent,
    CreateVenueComponent,
    BusinessComponent,
    OrdersBillingComponent,
    CreateDiscountComponent,
    ReportsPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AdminModule,
    LottieModule,
    AngularEditorModule,
    NgbModule,
    MatTooltipModule,
    SeatMapModule,
    PickerModule
  ],
  exports: [
    DashboardPanelComponent
  ]
})
export class ControlPanelModule { }
