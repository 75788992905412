import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  private fields: string = `_fields[]=id&_fields[]=slug&_fields[]=date&_fields[]=date_gmt&_fields[]=title&_fields[]=acf&acf_format=standard`;

  constructor(private common: CommonService) { }

  getFAQs(page: number, items: number = 3) {
    const url = `${AppSettings.WP_API}/wp-json/wp/v2/faq?${this.fields}&page=${page}&per_page=${items}`;
    return this.common.getData(url);
  }

  getAllFAQs() {
    const url = `${AppSettings.WP_API}/wp-json/wp/v2/faq?${this.fields}`;
    return this.common.getData(url);
  }

  getFAQsByCategory(slug: string) {
    const url = `${AppSettings.WP_API}/wp-json/wp/v2/faq?slug=${slug}&${this.fields}`;
    return this.common.getData(url);
  }

}
