<div class="reports-event">
    <div class="reports-download">
        <div class="btns-create">
            <div class="btn-create btn-bono m-0" (click)="downloadPdf()">
                <i class="fal fa-download"></i>
                <p class="m-0">Descargar informe</p>
            </div>
        </div>
    </div>
    <!-- Asistencia -->
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <p class="title-tab">Asistencia</p>
            <p style="margin-right: 16px;"><strong>Total: </strong>{{totalReaded}}</p>
        </div>
        <div>
            <div class="margin-table" *ngIf="ticketsSold; else divNotTickets">
                <!-- Tabla entradas por nivel (mapa) -->
                <div class="table-events table-panel table-grey" *ngIf="loaded">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort
                        (matSortChange)="sortData($event)">
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let ticket; let i = index">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef><b>Fecha</b></th>
                            <td mat-cell *matCellDef="let ticket">
                                <span>{{ticket.date | date: 'EEE, d \'de\' MMM \'de\' y'}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="time">
                            <th mat-header-cell *matHeaderCellDef><b>Hora</b></th>
                            <td mat-cell *matCellDef="let ticket">
                                <span>{{ticket.time}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="sold">
                            <th mat-header-cell *matHeaderCellDef><b>Vendidas</b></th>
                            <td mat-cell *matCellDef="let ticket">
                                <span>{{ticket.sold}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="readed">
                            <th mat-header-cell *matHeaderCellDef><b>Leídas</b></th>
                            <td mat-cell *matCellDef="let ticket">
                                <span>{{ticket.readed}}</span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
            <ng-template #divNotTickets>
                <p class="text-center m-2" *ngIf="loaded">No existen entradas para este evento</p>
            </ng-template>
        </div>
    </div>
</div>