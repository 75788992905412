<div class="container content-terms">
    <h3 class="title-terms" style="color: var(--color-primary); ">Términos y condiciones</h3>

    <p class="sub-title-terms">General</p>

    <p>El usuario queda obligado a no utilizar la página para la realización de actividades contrarias a las leyes, a la
        moral, al orden público y, en general se obliga a hacer un uso correcto del portal de conformidad con las
        disposiciones legales y las cláusulas del presente Aviso Legal, respondiendo frente al titular de cualquier daño
        y perjuicio que se puedan derivar del incumplimiento de dicha obligación.
    </p>
    <p class="sub-title-terms">Contenidos</p>

    <p>El usuario se obliga a utilizar los contenidos de la presente página de conformidad con el presente Aviso Legal,
        absteniéndose de reproducir, copiar, distribuir, poner a disposición, comunicar públicamente, transformar o
        modificar los contenidos salvo en los casos autorizados en la ley o expresamente consentidos por el titular.
    </p>
    <p>El contenido de los servicios ofrecidos y su uso quedan limitados a usuarios mayores de 18 años. Se recuerda a
        los usuarios mayores de edad, que tengan a su cargo menores, que será de su exclusiva responsabilidad determinar
        qué servicios y/o contenidos son no apropiados para la edad de estos últimos. En caso de que tenga lugar el
        acceso y registro por parte de un menor, se presumirá que dicho acceso se ha realizado con autorización previa y
        expresa de sus padres, tutores o representantes legales, para lo que el titular se reserva el derecho a realizar
        cuantas verificaciones y comprobaciones considere oportunas a este respecto.
    </p>

    <p class="sub-title-terms">Formularios de recogida de datos</p>
    <p>Sin perjuicio de lo dispuesto en la cláusula relativa a la Protección de Datos de Carácter Personal, toda la
        información que facilite el usuario a través de los formularios del portal deberá ser veraz, garantizado la
        autenticidad de todos aquellos datos que comunique, siendo el usuario el único responsable de las
        manifestaciones falsas o inexactas que realice y de los perjuicios que se puedan derivar.
    </p>

    <p class="sub-title-terms">Introducción de enlaces.</p>
    <p>Esta página puede incluir enlaces o links a sitios de terceros. Reiteramos que las páginas pertenecientes a estos
        terceros no han sido revisadas ni son objeto de controles por parte de la titular de esta web, por lo que no
        podrá ser considerada responsable de los contenidos de estos sitios web, ni de las medidas que se adopten
        relativas a su privacidad o al tratamiento de sus datos de carácter personal u otros que pudieran derivarse.
    <p>El usuario que quiera introducir enlaces desde páginas de su propiedad deberá obtener el consentimiento expreso y
        cumplir con las condiciones recogidas a continuación:
        El enlace únicamente vinculará con la página principal del portal, pero no podrá reproducirla de ninguna forma.
    </p>
    <p>Queda en todo caso prohibido, de acuerdo con la legislación aplicable, establecer marcos de cualquier tipo que
        permitan la visualización de los contenidos a través de direcciones de Internet distintas a las del portal y, en
        cualquier caso, cuando se visualicen conjuntamente los contenidos ajenos al portal de forma que:
    </p>
    <p>
        <li>Produzca o pueda producir error, confusión o engaño en los usuarios sobre la verdadera procedencia del
            Servicio. </li>
        <li>Suponga un acto de comparación o imitación desleal. </li>
        <li>Sirva para aprovechar la reputación de la marca y prestigio de la compañía o profesional. </li>
    </p>
    <p>No obstante, la titular se reserva el derecho de oposición a la activación de enlaces con su sitio web. </p>

</div>