import { Component, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, map, Observable, of, Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventosService } from 'src/app/services/eventos.service';
import { CreateEventComponent } from '../../create-event/create-event.component';
import { TicketsService } from 'src/app/services/tickets.service';
import { CreateEventService } from 'src/app/services/create-event.service';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-router-create',
  templateUrl: './router-create.component.html',
  styleUrls: ['./router-create.component.scss']
})
export class RouterCreateComponent {

  event!: any;
  profile!: any;
  title!: any;
  nameTab: string = 'Información del evento';
  numberTab: number = 1;
  isDraftEvent: boolean = false;
  activePublish: boolean = false;
  mapPage: boolean = false;
  actionMap!: any;
  idEvent!: number;

  payload: any = {};

  @ViewChild(CreateEventComponent) createEvent!: CreateEventComponent;

  step!: any;

  // Datos evento
  configTickets!: any;
  tickets!: any;
  ticketsApi!: any;
  venue!: any;

  savedEvent: boolean = false;

  // Validators tab
  validInfo: boolean = false;
  validTickets: boolean = false;
  validDiscounts: boolean = false;

  // Id tickets
  numTickets: number = 0;
  loadingTickets: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private ticketService: TicketsService,
    private auth: AuthenticationService, private apiEvents: EventosService, private dialog: MatDialog,
    private createEventService: CreateEventService) { }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
    this.step = this.apiEvents.step.pipe();
    let url = location.href;
    let slugUrl = url.split('/');
    let index = slugUrl.indexOf('new-event');
    let step = slugUrl[index + 1];
    this.apiEvents.setStepCreate(step);
    // this.tickets = this.ticketService.getTicketsSaved();
    // if (!this.tickets) {
    //   this.getTickets();
    // }

    this.activatedRoute.params.subscribe({
      next: (params) => {
        if (params.step) {
          this.apiEvents.setStepCreate(params.step);
          // Cambiar titulo de navegador
          if (params.idEvent) {
            this.idEvent = +params.idEvent;
            this.getData();
          }
          this.tickets = this.ticketService.getTicketsSaved();
          if (!this.tickets) {
            this.getTickets();
          }

        } else {
          this.apiEvents.setStepCreate(step);
        }
      }
    });
  }

  changePlace(event: any) {
    this.venue = event;
    if (this.venue) {
      this.getTickets();
    }
  }

  getData() {
    this.apiEvents.getEventByIdAdmin(this.idEvent).subscribe({
      next: (data) => {
        this.title = data.titulo;
        this.event = data;
        this.configTickets = {
          startSales: this.event.startSales,
          endSales: this.event.endSales,
          assumeFees: this.event.assumeFees,
          needValidation: this.event.needValidation,
          maxTickets: this.event.maxTikets,
          publishDate: this.event.publishDate || null,
          progPublish: this.event.publishDate ? true : false,
        };
        this.ticketService.setTicketsConfig(this.configTickets);
        this.venue = this.event.place;
        if (this.event.place) {
          this.getTickets();
          this.getDiscountVenue();
        }
      }
    });
  }

  getTickets() {
    this.loadingTickets = true;
    this.ticketService.getTikcetsByVenue(this.venue.id).subscribe({
      next: (val) => {
        this.numTickets = this.ticketService.getTicketsNum();
        this.tickets = [];
        let levels = val.levels;
        let arr = [];
        levels.forEach(element => {
          element.isSection = true;
          element.open = true;
          element.level = element.id;
          arr.push(element);
          this.numTickets = element.priceTickets?.length > this.numTickets ? element.priceTickets?.length : this.numTickets;
          let pricing = element.priceTickets;
          pricing.sort((a, b) => a.ticket.id - b.ticket.id);
          pricing.forEach(el => {
            let ticketPayload = {
              id: el.ticket.id,
              level: element.id,
              name: el.ticket.nombre,
              description: el.ticket.description,
              open: true,
              ticketsMin: el.ticket.ticketsMin,
              ticketsMax: el.ticket.ticketsMax,
              price: null
            }
            arr.push(ticketPayload);
          });
        });
        this.tickets = this.tickets.concat(arr);
        this.tickets.sort((a, b) => a.level - b.level);

        this.ticketService.setTicketsVenue(this.tickets);
        this.loadingTickets = false;
      }
    });
  }

  getDiscountVenue() {
    // Falta API - No hay descuentos de momento

  }

  changeMenuEvent(event: any) {
    this.nameTab = event.name;
    this.numberTab = event.tab;
    this.apiEvents.setStepCreate(event.slug);
    let saved = this.createEventService.getInfoEvent();
    this.validInfo = saved?.valid;
    if (this.validInfo && this.numberTab == 3) {
      this.validTickets = true;
    }
  }

  changeActionMap(save: boolean) {
    this.apiEvents.saveMapAction(save);
  }

  openLinkEvent() {
    let saved = this.createEventService.getInfoEvent();
    if (saved?.valid && this.savedEvent) {
      let titulo = saved?.values?.titulo;
      let titleUrl = titulo.replace(/[^\w\s]/g, '-').replace(/\s+/g, '-').replace(/-{2,}/g, '-').replace(/-{3,}/g, '-');
      if (titleUrl.charAt(titleUrl.length - 1) === '-') titleUrl = titleUrl.slice(0, -1);
      const url = `${AppSettings.FRONT}/draft/event/${this.idEvent + '-' + titleUrl.toLowerCase()}`;
      window.open(url, '_blank');
    } else {
      let msg = 'Debes rellenar los campos obligatorios antes de previsualizar el evento.';
      if (saved?.valid && !this.savedEvent) {
        msg = 'Debes guardar antes de previsualizar el evento.';
      }
      this.openInfoCard(msg);
    }
  }

  saveEvent(publish: boolean = false) {
    let saved = this.createEventService.getInfoEvent();
    const categoriaAux = saved?.values?.categoria;
    let payload = saved?.values;
    // Config tickets
    let configTickets = this.ticketService.getTicketsConfig();
    if (configTickets) {
      payload.configTickets = configTickets;
    }
    // Tickets
    let tickets = this.ticketService.getTicketsSaved();
    if (tickets) {
      let priceTickets = tickets.filter(value => !value.isSection);
      let ticketsSend = [];
      priceTickets.forEach(element => {
        let exist = ticketsSend.find((value) => { return (value.id && value.id == element.id) || (value.numTickets && value.numTickets == element.numTickets) });
        if (exist) {
          if (element.price != null) {
            exist.priceTickets.push({
              level: element.level,
              price: element.price
            });
          }
        } else {
          exist = {
            id: element.id,
            name: element.name,
            numTickets: element.numTickets,
            description: element.description,
            ticketsMin: element.ticketsMin,
            ticketsMax: element.ticketsMax,
            priceTickets: []
          };
          if (element.price != null) {
            exist.priceTickets.push({
              level: element.level,
              price: element.price
            });
          }
          ticketsSend.push(exist);
        }
      });
      payload.entradas = ticketsSend;
    }
    // Capacity
    let capacity = this.createEventService.getCapacity();
    if (capacity) {
      payload.capacity = capacity;
    }
    // Map
    if (payload.place && payload.place.mainMap) {
      payload.mapEvent = payload.place.mainMap;
    }

    let map = this.createEventService.getJsonMap();
    if (map) {
      map.hiddenSeats.forEach(element => {
        element.objectsSeats = element.objectsSeats.filter((value) => !value.level);
        element.seats = element.objectsSeats.length;
      });
      // payload.mapEvent = map;
      // payload.mapEvent.hiddenSeats = map.hiddenSeats.map;
      payload.mapEvent = {
        "name": map.name,
        "hiddenSeats": map.hiddenSeats,
        "capacity": map.capacity,
        "levels": map.levels
      };
    }
    if (saved?.valid) {
      payload.categoria = payload.categoria.id;
      payload.infoDates = null;
      payload.infoHours = null;
      const croppedAux = payload.cropped;
      payload.cropped = null;

      if (this.idEvent) {
        payload.id = this.idEvent;
      }
      if (publish) {
        payload.draft = false;
        payload.activo = true;
      }
      // saved.values.categoria = categoriaAux;
      // payload.cropped = croppedAux;
      this.createEventService.createEventBusiness(payload).subscribe({
        next: (res) => {
          saved.values.categoria = categoriaAux;
          saved.values.cropped = croppedAux;
          this.idEvent = res.id;
          if (publish) {
            this.openInfoCard('El evento se ha creado correctamente.', true, false, true);
          } else {
            this.savedEvent = true;
            this.openInfoCard('El evento se ha guardado correctamente.', false, false);
          }
        }, error: (err) => {
          saved.values.categoria = categoriaAux;
          saved.values.cropped = croppedAux;
          this.openInfoCard('¡Vaya! Ha ocurrido un error al crear el evento, vuelve a intentarlo más tarde.');
        }
      })
    } else {
      if(saved?.values) {
        saved.values.categoria = categoriaAux;
      }
      let errors = [];
      if(!saved?.values?.categoria) {
        errors.push('la categoría');
      }
      if(!saved?.values?.titulo) {
        errors.push('el título');
      }
      if(!saved?.values?.cropped) {
        errors.push('la imagen');
      }
      if(!saved?.values?.descripcion) {
        errors.push('la descripción');
      }
      if(saved?.values?.fechas.length <= 0) {
        errors.push('la fecha');
      }

      if(!saved || !saved.values || (saved?.values.place && saved?.values.horarios.length <= 0)) {
        errors.push('la hora');
      } else if(!saved?.values.place && !saved?.values.localizacion) {
        errors.push('una dirección');
      }
      let msg = `Debes introducir `;
      errors.forEach((err, index) => {
        if(errors.length > 1 && index == errors.length-2) {
          msg+= err+' y ';
        } else {
          msg+= err+', ';
        }
      });
      msg = msg.slice(0,-2);

      this.openInfoCard('Rellena los campos obligatorios. '+msg);
    }
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true, close: boolean = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
    if (close) {
      dialogRef.afterClosed().subscribe({
        next: (val) => {
          this.router.navigate(['/panel-control/events']);
        }
      })
    }
  }
}
