import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CreatorService } from 'src/app/services/creator.service';
import { EventosService } from 'src/app/services/eventos.service';
import { SeguidoresService } from 'src/app/services/seguidores.service';
import { UserService } from 'src/app/services/user.service';
import { InfoCardComponent } from '../shared/info-card/info-card.component';
import { SubscriberFrontComponent } from '../subscriber-front/subscriber-front.component';

@Component({
  selector: 'app-page-owner',
  templateUrl: './page-owner.component.html',
  styleUrls: ['./page-owner.component.scss']
})
export class PageOwnerComponent implements OnInit {

  urlImages: string = AppSettings.MEDIA_URI + '/images/';
  isBusiness: boolean = false;
  eventosCerca: Observable<any[]>;
  results: boolean = true;

  owner: any = {};
  sharedSupported: boolean = false;

  user!: any;
  isWishlist: boolean = false;
  index: number = 0;
  teatroIdeal: boolean = false;

  constructor(private titleService: Title, private activatedRoute: ActivatedRoute, private eventosService: EventosService,
    private userService: UserService, private router: Router, private dialog: MatDialog, private creatorService: CreatorService,
    private siguiendoService: SeguidoresService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let idOwner = params.idOwner;
      let pathUrl = params.pathUrl;
      this.isBusiness = location.href.indexOf('/events/') != -1 ? true : false;
      this.urlImages += this.isBusiness ? 'business/' : 'venues/';
      const idCity = localStorage.getItem('city') || 1;
      if (!idOwner && location.href.indexOf('teatroideal') != -1) {
        pathUrl = 'teatroideal';
        this.teatroIdeal = true;
      }
      this.creatorService.getInfoCreator(this.isBusiness, pathUrl).subscribe(data => {
        if (data) {
          this.owner = data;
          this.urlImages += data.id;
          this.titleService.setTitle('Citylok - ' + this.owner.name);
        }
      })
    });
    const navigator = window.navigator as any;
    if (navigator.share) {
      this.sharedSupported = true;
    }
  }

  existsResults(exists: any) {
    this.results = exists;
  }

  changeTab(event: any) {
    this.index = event.index;
  }

  editSiguiendo() {
    this.isWishlist ? this.deleteFavorito() : this.addFavorito();
  }

  addFavorito() {
    if (this.user) {
      this.siguiendoService.postSiguiendo(this.owner.id).subscribe(res => {
        if (res) {
          this.isWishlist = true;
        }
      })
    } else {
      this.openInfoDialog('Inicia sesión y empieza a guardar tus favoritos');
    }
  }

  deleteFavorito() {
    if (this.user) {
      this.siguiendoService.deleteSiguiendo(this.owner.id).subscribe(res => {
        if (res) {
          this.isWishlist = false;
        }
      })
    } else {
      this.openInfoDialog('Inicia sesión y empieza a guardar tus favoritos');
    }
  }

  shareOwner() {
    if (this.sharedSupported) {
      const shareData = {
        title: this.owner.nombre,
        url: AppSettings.FRONT + '/events/' + this.owner.id + '/' + this.owner.nombre
      };
      navigator.share(shareData).catch((error) => {
        if (AppSettings.DEBUG) {
          console.log('Error sharing', error)
        }
      });
    }
  }

  openInfoDialog(msg: string) {
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: false,
        text: msg,
        btnCancel: false
      }
    });
  }

  openNewSubscriber() {
    this.dialog.open(SubscriberFrontComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: {
        idPlace: this.owner.id,
        place: this.owner,
        user: this.user
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.openInfoDialog(res.msg);
      }
    });
  }

}
