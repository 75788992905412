<div class="event-menu-container">
    <div class="item-submenu" (click)="changeTabEvent({tab: 1, name: 'Información del evento'})" [routerLink]="[urlPath+'/info-event', idEvent]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Información del evento</span>
    </div>
    <div class="item-submenu" (click)="changeTabEvent({tab: 2, name: 'Venta de entradas'})" [routerLink]="[urlPath+'/ticket-event', idEvent]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Venta de entradas</span>
    </div>
    <div class="item-submenu" (click)="changeTabEvent({tab: 3, name: 'Descuentos/abonos'})" [routerLink]="[urlPath+'/discount-event', idEvent]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Descuentos/abonos</span>
    </div>
    <div class="item-submenu" (click)="changeTabEvent({tab: 4, name: 'Mapa de butacas'})" [routerLink]="[urlPath+'/map-event', idEvent]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Mapa de butacas</span>
    </div>
    <mat-divider></mat-divider>
    <div class="item-submenu" [class.disabled]="!allowCreate && !allowOrder" (click)="changeTabEvent({tab: 5, name: 'Pedidos del evento'})" [routerLink]="[urlPath+'/order-event', idEvent]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Pedidos del evento</span>
    </div>
    <div class="item-submenu" [class.disabled]="!allowCreate && !allowOrder" (click)="changeTabEvent({tab: 6, name: 'Informes del evento'})" [routerLink]="['/panel-control/events/detail/report-event', idEvent]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Informes del evento</span>
    </div>
    <!-- <div class="item-submenu disabled">
        <span>Informes del evento</span>
    </div> -->
    <mat-divider></mat-divider>
    <!-- <div class="item-submenu" (click)="changeTabEvent({tab: 7, name: 'Envío de comunicaciones'})" [routerLink]="['/panel-control/events/detail/mailing-event', idEvent]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Envío de comunicaciones</span>
    </div> -->
    <div class="item-submenu disabled">
        <span>Envío de comunicaciones</span>
    </div>
    <!-- <div class="item-submenu" (click)="changeTabEvent({tab: 8, name: 'Gestión de comentarios'})" [routerLink]="['/panel-control/events/detail/comment-event', idEvent]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Gestión de comentarios</span>
    </div> -->
    <div class="item-submenu disabled">
        <span>Gestión de comentarios</span>
    </div>
</div>