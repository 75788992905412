import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-cropper-image',
  templateUrl: './cropper-image.component.html',
  styleUrls: ['./cropper-image.component.scss']
})
export class CropperImageComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  objectImage!: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private sanitizer: DomSanitizer,
  private dialogRef: MatDialogRef<CropperImageComponent>) { }

  ngOnInit(): void {
    this.imageChangedEvent = this.data;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.base64);
    this.objectImage = event;
    // event.blob can be used to upload the cropped image
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  saveImage() {
    this.dialogRef.close({pathUrl: this.croppedImage, object: this.objectImage});
  }

}
