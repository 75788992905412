<div class="page-tickets-buy">
    <div class="list-container">
        <div *ngFor="let ticket of compra">
            <div class="align-items-center info-ticket">
                <div class="div-img pointer-in">
                    <!-- <img fallimg class="img-event" src="{{urlEvents+'/'+opt.entradas[0]?.entrada.evento.imageurl}}" /> -->
                    <img (error)="imageUrl = '../../../../assets/img/image_default.png'" class="img-event" [src]="urlEvents+ticket.entrada.evento.id+'/'+ticket.entrada.evento.imageurl" />                        
                </div>
                <div class="d-grid align-items-center">
                    <div class="d-flex align-items-center date-event">
                        <i class="fa-light fa-calendar"></i>
                        <span>{{ ticket.date ? (ticket.date | date: 'dd MMM') : (compra.date | date: 'dd MMM') }}</span>
                        <span *ngIf="ticket.time || compra.time"> | </span>
                        <span *ngIf="ticket.time || compra.time">{{ ticket.time ? (ticket.time | date: 'HH:mm':'+0000') :
                            (compra.time | date: 'HH:mm':'+0000') }}h</span>
                    </div>
                    <p class="m-0 name-event">{{ ticket.entrada.evento.titulo }}</p>
                    <div class="d-flex info-position-ticket">
                        <p class="m-0 price-ticket">{{ ticket.priceTotal | number:'2.2-2' }}€</p>
                        <mat-divider [vertical]="true"></mat-divider>
                        <p class="m-0 name-ticket">{{ ticket.entrada.nombre }}</p>
                        <mat-divider [vertical]="true" *ngIf="ticket.sectionName"></mat-divider>
                        <!-- Position -->
                        <p class="m-0 name-ticket" *ngIf="ticket.sectionName">{{ ticket.sectionName }}</p>
                        <mat-divider [vertical]="true" *ngIf="ticket.seat"></mat-divider>

                        <p class="m-0 name-ticket" *ngIf="ticket.seat">Butaca: {{ ticket.seat }}</p>
                        <mat-divider [vertical]="true" *ngIf="ticket.row"></mat-divider>

                        <p class="m-0 name-ticket" *ngIf="ticket.row">Fila: {{ ticket.row }}</p>
                    </div>
                </div>
                <div class="content-qr" (click)="openQr(ticket)">
                    <i class="fa-light fa-qrcode"></i>
                    <p class="show-ticket m-0">Ver entrada</p>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>
</div>