<div class="new-discount">
    <div class="main-new-discount">
        <h1 class="title-new-discount">{{editDiscount ? 'Editar' : 'Crear'}} Descuento</h1>
        <div class="form-info-user">
            <form class="form-grid" [formGroup]="formNewDiscount">
                <!-- <div class="main-inputs"> -->
                    <div class="mb-3">
                        <p class="label-input">Nombre*</p>
                        <div class="form-input-container ">
                            <input type="text" class="w-100" formControlName="name" [required]="true">
                        </div>
                    </div>
                    <div class="mb-3">
                        <p class="label-input">Código*</p>
                        <div class="form-input-container ">
                            <input type="text" class="w-100" formControlName="code" (blur)="editCodeSpace()">
                        </div>
                    </div>
                    <div class="mb-3">
                        <p class="label-input">Descripción</p>
                        <div class="form-input-container ">
                            <input type="text" class="w-100" formControlName="description">
                        </div>
                    </div>
                <!-- </div> -->
                <div class="main-inputs">
                    <div>
                        <p class="label-input">Descuento €*</p>
                        <mat-form-field appearance="fill" class="filter-place form-input-container">
                            <mat-select placeholder="Tipo de descuento" formControlName="type">
                                <mat-option value="amount">De cantidad</mat-option>
                                <mat-option value="percentage">Porcentaje</mat-option>
                            </mat-select>
                            <mat-select-trigger matSuffix style="vertical-align: middle;">
                                <i class="fal fa-angle-down pointer-in"></i>
                            </mat-select-trigger>
                        </mat-form-field>
                    </div>
                    <div>
                        <p class="label-input">Cantidad*</p>
                        <div class="form-input-container d-flex">
                            <input type="number" class="w-100" formControlName="cuantity">
                            <i matSuffix>{{formNewDiscount.value.type == 'amount' ? '€' : '%'}}</i>
                        </div>
                    </div>
                </div>
            </form>
        </div>        
    </div>
    <mat-divider></mat-divider>
    <div class="footer-new-discount">
        <div class="btn-new btn-create btn-create-border m-0" mat-dialog-close="">
            <p class="m-0">Cancelar</p>
        </div>
        <div class="btn-new btn-create m-0" (click)="saveDiscount()"
            [class.disabled]="formNewDiscount.invalid">
            <p class="m-0">Guardar</p>
        </div>
    </div>
</div>