<div class="info-payment">
    <p class="title-info-payment">Pedido: <span>{{num_ticket ? num_ticket : idOrder}}</span></p>
    <!-- <mat-divider></mat-divider> -->
    <div class="div-info-transaction">
        <div class="title-info-transaction">
            Canal:
        </div>
        <div class="text-info-transaction">
            {{order.transaction.channel}}
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="div-info-transaction">
        <div class="title-info-transaction">
            Estado:
        </div>
        <div class="text-info-transaction">
            {{order.transaction.status}}
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="div-info-transaction">
        <div class="title-info-transaction">
            Fecha:
        </div>
        <div class="text-info-transaction">
            {{order.transaction.date | date:'dd/MM/y HH:mm'}}
        </div>
    </div>
    <div *ngIf="order.payment">
        <!-- <mat-divider></mat-divider> -->
        <div class="title-payment">
            Información de pago:
        </div>
        <!-- <mat-divider></mat-divider> -->
        <div class="div-info-transaction">
            <div class="title-info-transaction">
                Estado:
            </div>
            <div class="text-info-transaction">
                {{order.payment.status}}
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="div-info-transaction">
            <div class="title-info-transaction">
                Fecha confirmación:
            </div>
            <div class="text-info-transaction">
                {{order.payment.date_order | date:'dd/MM/y HH:mm'}}
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="div-info-transaction">
            <div class="title-info-transaction">
                Tarjeta:
            </div>
            <div class="text-info-transaction">
                {{order.payment.detail_order[0].tarjeta }} *{{order.payment.detail_order[0].last_digits }}
            </div>
        </div>
        <div *ngIf="order.payment.detail_order[0].bank">
            <mat-divider></mat-divider>
            <div class="div-info-transaction">
                <div class="title-info-transaction">
                    Banco:
                </div>
                <div class="text-info-transaction">
                    {{order.payment.detail_order[0].bank }}
                </div>
            </div>
        </div>

        <div *ngIf="user.admin">
            <mat-divider></mat-divider>
            <div class="div-info-transaction">
                <div class="title-info-transaction">
                    IP:
                </div>
                <div class="text-info-transaction">
                    {{order.payment.ip}}
                </div>
            </div>
            <!-- <mat-divider></mat-divider> -->
        </div>
    </div>
</div>