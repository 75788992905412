import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BusinessService } from 'src/app/services/business.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent {

  business: any[] = [];
  profile!: any;
  searchText!: string;
  now: Date = new Date();

  displayedColumns: string[] = ['actions', 'name', 'cif', 'address', 'created', 'open'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  sorted!: any;

  page = 1;

  urlImages: string = AppSettings.MEDIA_URI + '/images/business/';
  @Output() changeTag: EventEmitter<any> = new EventEmitter<any>();
  loaded: boolean = false;
  loadingPage: boolean = true;

  finishScroll: boolean = false;
  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;
  private searchTerms = new Subject<string>();

  constructor(private dialog: MatDialog, private router: Router, 
    private auth: AuthenticationService, private apiBusiness: ApiBusinessService) { }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
    this.now.setHours(0);
    this.now.setMinutes(0);
    this.getData();
  }

  ngAfterViewInit() {
    this.searchTerms.pipe(
      debounceTime(500), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.searchText = items;
      this.updateEvents();
    });
  }

  getData() {
    this.apiBusiness.getAllBusinessAdmin(this.page, this.searchText, this.sorted).subscribe({
      next: (res) => {
        this.business = this.business.concat(res);
        if (this.business.length <= 0 || this.business.length < 20) {
          this.finishScroll = true;
        }
        this.dataSource = new MatTableDataSource(this.business);
        this.dataSource.sort = this.sort;

        this.loaded = true;
        this.loadingPage = false;
      }
    })
  }

  updateEvents() {
    this.page = 1;
    this.loadingPage = true;
    this.finishScroll = false;
    this.business = [];
    this.getData();
  }

  searchEvents(event) {
    this.searchTerms.next(event);
  }

  openEvent(event: any) {
    // const url = this.draft ? 'events/draft' : 'events';
    // if (this.idVenue) {
    //   const urlFront = AppSettings.FRONT + '/panel-control/' + url + '/detail/info-event/' + event[0].id;
    //   window.open(urlFront, '_blank');
    // } else {
    //   this.router.navigate(['/panel-control/' + url + '/detail/info-event', event[0].id]);
    // }
  }

  // changeBusinessEdit(business: any) {
  //   this.companySelect = business;
  //   localStorage.removeItem('places');
  //   this.apiBusiness.setBusinessStorage(business);
  //   this.getPlaces();
  // }

  // Sort
  sortData(sort: Sort) {
    if (this.business.length > 1) {
      const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
      if (sort.direction) {
        this.sorted = { sortedBy: sort.active, sort: isAsc };
      } else {
        this.sorted = null;
      }
      this.updateEvents();
    }
  }

  openInfoCard(msg: string, disabledClose: boolean = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        text: msg
      }
    });
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingPage) {
      if (!this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingPage = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
        // }
      } else {
        this.finishScroll = true;
      }
    }
  }
}
