import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { OrdersEventService } from 'src/app/services/orders-event.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-detail-order',
  templateUrl: './detail-order.component.html',
  styleUrls: ['./detail-order.component.scss']
})
export class DetailOrderComponent implements OnInit {

  idOrder!: any;
  order!: any;
  seats: any[] = [];

  fees: number = 0;

  constructor(private activatedRoute: ActivatedRoute, private orderService: OrdersEventService, private reportService: ReportsService,
    private dialog: Dialog) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      let idOrder: number = params.search;
      if (idOrder) {
        this.orderService.getOrdersById(idOrder).subscribe({
          next: (res) => {
            this.order = res[0];
            let seatsJson = JSON.parse(this.order.seats);
            seatsJson.forEach(element => {
              let obj = this.seats.find(value => value.id_event == element.id_event);
              if (!obj) {
                this.seats.push({ id_event: element.id_event, name_event: element.name_event, date_event: element.date_event, hour_event: element.hour_event, url_image: element.url_image, location: element.location, items: [element] });
              } else {
                obj.items.push(element);
              }
              this.fees += element.fee * 1.21;
            });
          }
        });
      }
    })
  }

  printA4Ticket(ticket: any) {
    this.reportService.printA4SingleOrder(this.order.id, ticket.id, this.order.channel).subscribe({
      next: (url) => {
        // let file = new Blob([res], { type: 'application/pdf' });
        // let fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        window.open(url, '_blank');
        this.openInfoCard('Se ha descargado el pedido correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar las entradas.', true, false);
      }
    });
  }

  printTicket(ticket: any) {
    this.reportService.printSingleTicket(this.order.id, ticket.id, this.order.channel).subscribe({
      next: (res) => {
        // let file = new Blob([res], { type: 'application/pdf' });
        // let fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        window.open(res, '_blank');
        this.openInfoCard('Se ha descargado el pedido correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar las entradas.', true, false);
      }
    });
  }

  refundOrder() {
    this.reportService.refundAllTickets(this.order.id).subscribe({
      next: (res) => {
        // let file = new Blob([res], { type: 'application/pdf' });
        // let fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        window.open(res, '_blank');
        this.openInfoCard('Se ha descargado el pedido correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar las entradas.', true, false);
      }
    });
  }

  refundTicket(ticket: any) {
    this.reportService.refundSingleTicket(this.order.id, this.order.channel, ticket.id).subscribe({
      next: (res) => {
        // let file = new Blob([res], { type: 'application/pdf' });
        // let fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        window.open(res, '_blank');
        this.openInfoCard('Se han descargado los tickets correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar los tickets.', true, false);
      }
    });
  }

  openConfirm(msg?: string, close?: boolean): void {
    let data = {
      icon: true,
      text: msg,
      btnCancel: true
    };
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: data
    });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result && close) {
    //     this.location.back();
    //   }
    // });
  }

  openPrintA4() {
    this.downloadTicketOrder(this.order.id, 'a4');
  }

  openPrintTicket() {
    this.downloadTicketOrder(this.order.id, 'ticket');
  }

  downloadTicketOrder(idOrder: number, type: string) {
    this.reportService.getUrlsDownload(idOrder).subscribe({
      next: (url) => {
        let param = type == 'a4' ? 'ticket_a4' : 'ticket';
        let uriDownload = url[param];
        window.open(uriDownload, '_blank');
        this.openInfoCard('Se ha descargado el ticket correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar las entradas.', true, false);
      }
    })
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true) {
    // const dialogRef = this.dialog.open(InfoCardComponent, {
    //   panelClass: ['info-dialog'],
    //   disableClose: disabledClose,
    //   data: {
    //     icon: icon,
    //     text: msg,
    //     btnCancel: false
    //   }
    // });
  }

}
