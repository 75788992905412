import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { CountdownService } from 'src/app/services/countdown.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { ConfirmPayComponent } from '../../checkout/confirm-pay/confirm-pay.component';
import { WaitingPayComponent } from '../../checkout/waiting-pay/waiting-pay.component';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { InfoConditionsComponent } from '../info-conditions/info-conditions.component';

@Component({
  selector: 'app-cart-checkout',
  templateUrl: './cart-checkout.component.html',
  styleUrls: ['./cart-checkout.component.scss']
})
export class CartCheckoutComponent implements OnInit, OnDestroy {

  shoppingCartUser!: any[];
  idCart!: number;
  loaded: boolean = false;
  urlImages: string = AppSettings.MEDIA_URI + '/images/events/';

  // Discounts
  discountCode!: string;
  savedCode!: string;
  errorCode: boolean = false;
  openFormDiscount: boolean = false;
  discountObject!: any;

  lessEpiration!: any;
  countdown: any;
  openDetail!: number;
  totalPrice: number = 0;
  gestionPrice: number = 0;
  totalTickets: number = 0;
  totalDiscount: number = 0;

  subscriber!: any;
  subscriptionCountDown!: any;
  invoiceLines: any[] = [];
  bookings: any[] = [];
  profile!: any;
  isProtocolUser!: any;
  organizerEvents: any[];
  eventsCart: any[] = [];

  waiting: boolean = false;

  business!: any;
  paymentMethod!: any;

  @ViewChild('btn_submit') btn_submit: ElementRef;
  Ds_MerchantParameters: string;
  Ds_Signature: string;
  Ds_SignatureVersion: string;
  url: string;

  activePayRedsys: boolean = false;
  errorApi: boolean = false;
  isAdmin: boolean = false;

  checkAcceptConditions: boolean = false;
  checkConditionsCompany: boolean = false;

  constructor(private shoppingCartService: ShoppingCartService, private dialog: MatDialog, private auth: AuthenticationService,
    private countdownService: CountdownService, private router: Router, private ticketsService: TicketsService,
    private checkoutService: CheckoutService, private location: Location, private apiBusiness: ApiBusinessService) { }

  ngOnInit(): void {
    let profile = this.auth.getProfile();
    this.isAdmin = profile?.roleFunctions?.length > 0;
    this.profile = this.auth.getProfile();
    this.checkCartBooking();
  }

  ngAfterViewInit() {
  }

  checkCartBooking() {
    this.waiting = true;
    let auxTotal = 0;
    let gestionAux = 0;
    this.bookings = [];
    this.auth.getMyData().subscribe({
      next: (data) => {
        this.organizerEvents = data.organizerEvents.map(value => value.id);
        this.subscriber = this.shoppingCartService.getCartUser().subscribe({
          next: (res) => {
            this.shoppingCartService.setModifyCart(res.cart.id);
            if (res.expired > 0 && location.href.indexOf('cart-checkout') != -1) {
              this.dialog.open(InfoCardComponent, {
                panelClass: 'info-dialog',
                data: {
                  icon: false,
                  text: 'Algunas de tus entradas ya no están disponibles',
                  btnCancel: false
                }
              });
            }

            if (res.cart.discount) {
              this.openFormDiscount = true;
              this.discountObject = res.cart.discount;
              this.errorCode = false;
              this.discountCode = this.discountObject.discountCode;
              this.savedCode = this.discountCode;
            }

            this.shoppingCartUser = [];
            this.countdown = null;
            this.loaded = false;
            this.idCart = res.cart?.id;

            if (res.cart.tickets.length > 0) {
              // Agrupar por evento y por fecha y hora
              let events = [];
              res.cart.tickets.forEach(elEvent => {
                if (!this.bookings.includes(elEvent.id)) {
                  this.bookings.push(elEvent.id);
                }
                let event = events.find(value => value.id == elEvent.event.id);
                if (!this.eventsCart.includes(elEvent.event.id)) { this.eventsCart.push(elEvent.event.id); }
                if (!event) {
                  event = { id: elEvent.event.id, imageurl: elEvent.event.imageurl, localizacion: elEvent.event.localizacion, ciudad: elEvent.event.ciudad?.nombre, price: 0, tickets: 0, titulo: elEvent.event.titulo, usuario: elEvent.event.usuario, fees: elEvent.event.fees, asumeFees: elEvent.event.asumeFees, company: elEvent.event.company };
                  events.push(event);

                  let tickets = res.cart.tickets.filter(value => value.event.id == event.id);
                  event.tickets = tickets.length;
                  let options = [];
                  tickets.forEach(element => {
                    let hour = null;
                    if (element.time) {
                      let t: string = element.time;
                      t = t.split('T')[1];
                      t = t.split('+')[0];
                      let h = t.split(':');
                      hour = h[0] + ':' + h[1];
                    }

                    let option = options.find(value => value.date == element.date && value.time == hour);
                    let price = element.price;
                    let discount = 0;
                    let discountName = element.bono?.name;
                    if (element.bono && element.bono?.discount) {
                      discount = element.bono.discount.amount ? Math.min(element.bono.discount.amount, element.price * 0.9) : (element.price) * (element.bono.discount.percentage) / 100;
                      price -= discount;
                    }
                    auxTotal += price;
                    event.price += price;
                    if (element.discount) {
                      discount = element.discount.amount ? Math.min(element.discount.amount, element.original_price) : (element.original_price) * (element.discount.percentage) / 100;
                      discountName = element.discount.name;
                    }
                    let objTicket = { id: element.id, price: element.price, row: element.row, showRow: element.showRow, seat: element.seat, section: element.section, sectionName: element.sectionName, level: element.level, abono: element.bono, ticket: { id: element.ticket.id, nombre: element.ticket.nombre, descripcion: element.ticket.descripcion }, discount: discount, discountName: discountName, priceTotal: price, originalPrice: element.original_price }
                    let priceDate = price;
                    if (option) {
                      option.priceDate = option.priceDate + priceDate;
                      option.tickets.push(objTicket);
                    }
                    if (!option) {
                      option = { date: element.date, time: hour, tickets: [objTicket], priceDate: priceDate };
                      options.push(option);
                    }
                    option.tickets = option.tickets?.sort((a: any, b: any) => {
                      return b.price - a.price;
                    })
                    if (!elEvent.event.assumeFees && !(this.profile?.protocolo && this.organizerEvents?.includes(elEvent.event.id))) {
                      let fees = elEvent.event.fees != null ? elEvent.event.fees : 0;
                      gestionAux += fees;
                    }
                  });
                  event.options = options;
                }
              });
              this.shoppingCartUser = events;

              this.getFirstExpirationDate(res.cart.tickets);
              // this.checkPriceDiscount();
              let companyObject = res.cart.tickets[0].event.company;
              if (companyObject) {

                this.apiBusiness.getBusinessById(companyObject.id).subscribe({
                  next: (res) => {
                    this.business = res.business;
                    // if(!this.business.general_conditions) {
                    //   this.checkConditionsCompany = true;
                    // }
                    this.paymentMethod = res.business.payment_method.find(value => value.id_channel_type == 1);
                    this.getPriceCart();
                  }, error: (err) => {
                    this.loaded = true;
                    this.waiting = false;
                    this.errorApi = true;
                    this.openInfoCard('¡Vaya! No se puede completar el pago, por favor, inténtalo de nuevo más tarde.');
                  }
                });
              }
            } else {
              this.loaded = true;
              this.waiting = false;
            }
          }
        })
      }
    })
  }

  getPriceCart() {
    this.checkoutService.getPriceCart(this.business['id_citylok'], this.bookings, this.discountCode).subscribe({
      next: (res) => {
        this.totalPrice = res['billing_order']['total_order'];
        this.gestionPrice = res['billing_order']['total_fee'];
        this.loaded = true;
        this.waiting = false;
      }, error: (err) => {
        this.loaded = true;
        this.waiting = false;
        this.errorApi = true;
        this.openInfoCard('¡Vaya! No se puede completar el pago, por favor, inténtalo de nuevo más tarde.');
      }
    });
  }

  getFirstExpirationDate(arrayTickets: any[]) {
    this.lessEpiration = null;
    arrayTickets.forEach(element => {
      let date = new Date(element.expirationDate);
      if (this.lessEpiration == null || this.lessEpiration > date) {
        this.lessEpiration = date;
      }
    });
    this.getCountDown();
  }

  getCountDown() {
    this.countdown = this.countdownService.getInitialCountDown(this.lessEpiration.getTime());
    this.subscriptionCountDown = this.countdownService.getCountDown(this.lessEpiration.getTime()).subscribe((count: any) => {
      this.countdown = count;
      if ((count.minutes == 0 && count.seconds == 0) || count.days < 0) {
        this.subscriptionCountDown.unsubscribe();
        this.shoppingCartService.setModifyCart(true);
        this.checkCartBooking();
      }
    })
  }

  openEvent(id: number, name: string) {
    this.router.navigate([`/event/${id}-${name.replaceAll(' ', '-')}`]);
  }

  goBack() {
    this.location.back();
  }

  deleteAllEvent(items: any[]) {
    this.waiting = true;
    let ids = [];
    let existAbono: boolean = false;
    let idsAbono = [];
    items.forEach(value => {
      let ticketsBono = value.tickets.filter((value) => value.abono);
      idsAbono.push(...ticketsBono.map((value) => value.abono.id));
      if (ticketsBono.length > 0) {
        existAbono = true;
      }
      ids.push(...value.tickets.map(el => el.id));
    });
    if (existAbono) {
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: true,
          text: '¡Existe un abono en alguna de las entradas, ¿estás seguro de que deseas eliminarlas? El resto de entradas que contengan ese abono se eliminarán también.',
          btnCancel: true
        }
      });
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result) {
            this.shoppingCartUser.forEach(element => {
              element.options.forEach(option => {
                option.tickets.forEach(ticket => {
                  if (ticket.abono && idsAbono.includes(ticket.abono.id)) {
                    if (!ids.includes(ticket.id)) {
                      ids.push(ticket.id);
                    }
                  }
                });
              });
            });
            this.postDelete(ids);
          } else {
            this.waiting = false;
          }
        }
      });
    } else {
      this.postDelete(ids);
    }
  }

  deleteItemCart(item: any) {
    this.waiting = true;
    // Comprar si tiene abono y si tiene, eliminar otra entrada del resto de eventos
    // Sacar mensaje de confirmación
    let tickets = [item.id];
    if (item.abono) {
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: true,
          text: '¡Existe un abono en alguna de las entradas, ¿estás seguro de que deseas eliminarlas? El resto de entradas que contengan ese abono se eliminarán también.',
          btnCancel: true
        }
      });
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result) {
            // Coger todos los tickets del resto de eventos y quitarles el abono
            this.shoppingCartUser.forEach(element => {
              element.options.forEach(option => {
                option.tickets.forEach(ticket => {
                  if (ticket.abono && ticket.abono.id == item.abono.id) {
                    if (!tickets.includes(ticket.id)) {
                      tickets.push(ticket.id);
                    }
                  }
                });
              });
            });
            this.postDelete(tickets);
          } else {
            this.waiting = false;
          }
        }
      });
    } else {
      this.postDelete(tickets);
    }
  }

  postDelete(tickets: any[]) {
    this.shoppingCartService.deleteItemToCart({ tickets: tickets }).subscribe({
      next: () => {
        this.checkCartBooking();
        this.checkPriceDiscount();
      }
    })
  }

  showDetailTickets(eventId: number) {
    this.openDetail = this.openDetail == eventId ? null : eventId;
  }

  // Discount
  openDiscountForm() {
    this.openFormDiscount = !this.openFormDiscount;
    if (this.errorCode) {
      this.discountCode = null;
      this.errorCode = false;
    }
  }

  applyDiscountCode() {
    this.discountCode = this.discountCode.trim();
    this.ticketsService.checkDiscountCode(this.discountCode, this.idCart).subscribe({
      next: (res) => {
        if (res) {
          this.discountObject = res;
          this.errorCode = false;
          this.savedCode = this.discountCode;
          this.checkCartBooking();
        }
      },
      error: (err) => {
        this.errorCode = true;
      }
    })
  }

  deleteDiscount() {
    this.ticketsService.deleteDiscountCart(this.idCart).subscribe({
      next: (res) => {
        this.savedCode = null;
        this.errorCode = false;
        this.discountObject = null;
        this.discountCode = null;
        this.checkCartBooking();
      }, error: (err) => {

      }
    })
  }

  checkPriceDiscount() {
    const abono = this.discountObject?.abono;
    let abonosTickets = [];
    let isCorrect = true;

    this.shoppingCartUser.forEach(event => {
      if (!abonosTickets.includes(event.id)) {
        abonosTickets.push(event.id)
      }
    });
    abono?.events?.forEach(idEvent => {
      if (!abonosTickets.includes(idEvent.id)) {
        isCorrect = false;
        this.discountObject = null;
        this.errorCode = true;
        this.savedCode = null;
      }
    });
    if (isCorrect && this.discountObject) {
      this.applyPriceDiscount();
    }
  }

  applyPriceDiscount() {
    const abono = this.discountObject?.abono;
    // Calcula el numero maximo de abonos disponibles
    let maxAbono = null;
    this.shoppingCartUser.forEach(element => {
      if (maxAbono == null || element.tickets < maxAbono) {
        maxAbono = element.tickets
      }
    });

    if (maxAbono != null) {
      this.shoppingCartUser.forEach(event => {
        for (let index = 0; index < maxAbono; index++) {
          event.options[index].tickets[0].discount = this.discountObject.amount != null ? this.discountObject.amount : event.options[index].tickets[index].price * (this.discountObject.percentage / 100);
        }
      });
    }
    this.getPriceCart();
  }

  openPlatformBuy() {
    this.waiting = true;
    this.getInvoice();
    let fees = (Math.round(this.gestionPrice * 100) / 100).toFixed(2);
    let object = { totalPrice: this.totalPrice, fees: +fees, invoiceLines: this.invoiceLines, bookings: this.bookings, discount: this.discountObject, tickets: this.shoppingCartUser };
    if ((this.totalPrice) > 0 && this.loaded) {
      this.waiting = false;
      let type = 'paylands';
      if (this.paymentMethod.id_payment_type == 2) {
        type = 'redsys';
      }
      // Llamada de pago para obtener url de redirección
      this.checkoutService.chargePayWeb(this.business.id_citylok, this.bookings, this.discountCode, type).subscribe({
        next: (data) => {
          this.activePayRedsys = true;
          const paymentData = data['payment_data'];
          // console.log(data);
          if (type == 'redsys') {
            this.Ds_MerchantParameters = paymentData['Ds_MerchantParameters'];
            this.Ds_Signature = paymentData['Ds_Signature'];
            this.Ds_SignatureVersion = paymentData['Ds_SignatureVersion'];
            this.url = paymentData['url'];

            const form = document.createElement('form');
            form.method = 'POST';
            form.action = this.url;
            const dsSignatureVersion = document.createElement('input');
            dsSignatureVersion.type = 'hidden';
            dsSignatureVersion.name = 'Ds_SignatureVersion';
            dsSignatureVersion.value = this.Ds_SignatureVersion;

            const dsMerchantParameters = document.createElement('input');
            dsMerchantParameters.type = 'hidden';
            dsMerchantParameters.name = 'Ds_MerchantParameters';
            dsMerchantParameters.value = this.Ds_MerchantParameters;

            const dsSignature = document.createElement('input');
            dsSignature.type = 'hidden';
            dsSignature.name = 'Ds_Signature';
            dsSignature.value = this.Ds_Signature;

            form.appendChild(dsSignatureVersion);
            form.appendChild(dsMerchantParameters);
            form.appendChild(dsSignature);

            document.body.appendChild(form);
            form.submit();

            this.waiting = false;

            // this.checkoutService.postUrlRedsys(this.url, payload).subscribe({
            //   next: (redsys) => {
            //     // console.log(redsys);
            //     const dialogRef = this.dialog.open(WaitingPayComponent, {
            //       panelClass: 'info-dialog',
            //       data: {
            //         url: data.url,
            //         idOdoo: data.idOdoo
            //       }
            //     });
            //     this.waiting = false;
            //   }, error: (err) => {
            //     this.openInfoCard('Ha ocurrido un error al procesar el pago, por favor, inténtelo de nuevo.');
            //     this.waiting = false;
            //   }
            // });
          }
          if (type == 'paylands') {
            if (data['payment_data']['order']['status'] == "CREATED") {
              object['redirect_url'] = data['redirect_url'];
              object['id_order'] = data['order']['pnp_id_transaction'];
              object['response'] = data['response'];
              this.router.navigate(['/checkout/validate'], { state: object });
            } else {
              this.openInfoCard('Ha ocurrido un error al procesar el pago, por favor, inténtelo de nuevo más tarde.');
            }
          }
        }, error: (err) => {
          this.openInfoCard('Ha ocurrido un error al procesar el pago, por favor, inténtelo de nuevo más tarde.');
        }
      });
    }

    if ((this.totalPrice) == 0 && this.loaded) {
      let isEnabled = true;
      this.checkoutService.chargePayWeb(this.business.id_citylok, this.bookings, this.discountCode, 'protocol').subscribe({
        next: (data) => {
          const idOdoo = data['order']['id'];
          this.shoppingCartUser = null;
          const dialogRef = this.dialog.open(ConfirmPayComponent, {
            panelClass: 'info-dialog',
            data: {
              idOdoo: idOdoo,
            }
          });
          this.waiting = false;
          dialogRef.afterClosed().subscribe({
            next: (data) => {
              let url = !this.isAdmin ? '/profile' : '/panel-control/orders/list';
              if (data.print) {
                // Comprobar si es gestor y mandar a pedidos
                this.router.navigate([url]).then(() => {
                  let text = 'Se han descargado las entradas correctamente.';
                  this.openInfoCard(text);
                });
              } else {
                this.router.navigate([url]).then(() => {
                  this.openInfoCard('El pago se ha realizado correctamente. Podrás consultar las entradas en tu correo electrónico o en el apartado Mis compras de tu perfil.');
                });
              }
            }
          })
        }
      });
    }
  }

  openInfoCard(msg: string) {
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: false,
        text: msg,
        btnCancel: false
      }
    });
  }

  getInvoice() {
    this.shoppingCartUser.forEach(event => {
      event.options.forEach(option => {
        option.tickets.forEach(element => {
          let line = this.invoiceLines.find(value => value.id_event_citylok == event.id && value.id_ticket == element.ticket.id && value.price_unit == element.priceTotal && value.discount == element.discount);
          if (line) {
            line.quantity++;
          }
          if (!line) {
            let fees = 0;
            if (!event.assumeFees && !(this.profile?.protocolo && this.organizerEvents?.includes(event.id))) {
              fees = event.fees || 1;
            }
            let price = element.price - element.discount;
            this.invoiceLines.push({
              "id_ticket": element.ticket.id,
              "description": element.ticket.nombre,
              "quantity": 1,
              "price_unit": +(Math.round(price * 100) / 100).toFixed(2),
              "discount": element.discount,
              "name_discount": element.discountName || "",
              "id_event_citylok": event.id,
              "fee": +(Math.round(fees * 100) / 100).toFixed(2)
            });
          }
        });
      });
    });
    // this.shoppingCartService.createInvoice({ invoiceLines: this.invoiceLines, bookings: this.bookings, totalPrice: this.totalPrice }).subscribe({
    //   next: (res) => {
    //     if (res) {
    //       this.dialog.open(InfoCardComponent, {
    //         panelClass: 'info-dialog',
    //         data: {
    //           icon: false,
    //           text: 'Facturas creadas correctamente',
    //           btnCancel: false
    //         }
    //       });
    //     }
    //   },
    //   error: (err) => {
    //     this.dialog.open(InfoCardComponent, {
    //       panelClass: 'info-dialog',
    //       data: {
    //         icon: true,
    //         text: '¡Vaya! Algo ha fallado vuelve a intentarlo más tarde.',
    //         btnCancel: false
    //       }
    //     });
    //   }
    // })
  }

  openDialogInfo() {
    this.dialog.open(InfoConditionsComponent, {
      panelClass: 'info-dialog',
      data: {
        text: null,
      },
      autoFocus: false
    });
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

}
