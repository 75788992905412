<nav #nav class="header navbar navbar-expand-xl fixed-top nav-expand position-absolute p-0 headerScroll"
    [class.headerWhite]="!isHomeInit" [class.position-fixed]="isFixed">
    <div
        class="header-wrapper container container-text-white d-flex align-items-center justify-content-between container-fluid">
        <div class="navbar-toggler">
            <div class="d-flex align-items-center">
                <i class="fal fa-sliders-h icon-menu icon-left pointer-in fa-lg" (click)="openFilters()"></i>
                <i *ngIf="profile && !isAdmin && !profile?.organizer && !(profile?.sms || profile?.site)"
                    class="fal fa-heart icon-left icon-menu pointer-in fa-lg" (click)="goToFavorites()"></i>
                <i *ngIf="profile?.citylok || profile?.lite" class="fal fa-camera icon-menu icon-left pointer-in fa-lg"
                    (click)="newLok()"></i>
            </div>
        </div>

        <div class="logo pointer-in" [routerLink]="['/']" [class.isHome]="isHomeInit">
            <img fallimg #imgHeader src="assets/img/citylok_horizontal.svg" alt="Citylok logo">
        </div>

        <div class="collapse navbar-collapse">
            <div class="d-flex justify-content-end justify-content-xl-between w-100">
                <div class="select-filter d-none d-xl-flex">
                    <!-- Select City -->
                    <mat-form-field appearance="fill" class="filter-city">
                        <div style="align-items: center; display: flex;">
                            <input #citySelect type="text" (click)="categorySelect.close()"
                                class="select-autocomplete-white" placeholder="Todas las ciudades" matInput
                                [formControl]="cityForm" (ngModelChange)="changeCity($event)"
                                [matAutocomplete]="autoCity">
                            <mat-autocomplete #autoCity="matAutocomplete" [displayWith]="displayCity"
                                class="filterPanel">
                                <mat-option [value]="">Todas las ciudades</mat-option>
                                <mat-option *ngFor="let ciudad of ciudades" [value]="ciudad"> {{ ciudad.nombre }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-select-trigger matSuffix style="vertical-align: middle;">
                                <i class="fal fa-angle-down pointer-in"></i>
                            </mat-select-trigger>
                        </div>
                    </mat-form-field>

                    <mat-divider class="divider-default" [vertical]="true"></mat-divider>
                    <!-- Select Category -->
                    <mat-form-field appearance="fill" class="filter-category">
                        <mat-select #categorySelect class="select-white-filter text-black" panelClass="filterCategories"
                            placeholder="Categorías" [(ngModel)]="category" disableOptionCentering="false">
                            <mat-option *ngFor="let categoria of categorias | async" [value]="categoria.id"
                                [routerLink]="['/category', categoria.slug]">
                                <i class="fa-thin {{ categoria.icon }} icon-primary"></i>
                                <span>{{categoria.nombre}}</span></mat-option>
                        </mat-select>
                        <mat-select-trigger matSuffix style="vertical-align: middle;">
                            <i class="fal fa-angle-down pointer-in"></i>
                        </mat-select-trigger>
                    </mat-form-field>
                </div>
                <div class="d-flex align-items-center">
                    <!-- Search Box -->
                    <div class="container-buscador">
                        <div class="bloque-buscador container-form d-flex align-items-center borderInput">
                            <i class="fal fa-search search-icon pointer-in" (click)="searchEvents(search.value)"></i>
                            <input #search class="input-custom w-100" type="text" [placeholder]="placeholder"
                                [(ngModel)]="searchText" (keyup.enter)="searchEvents(search.value)">
                            <i class="fal fa-sliders-h icon-menu pointer-in fa-lg" (click)="openFilters()"></i>
                        </div>
                    </div>
                    <!-- Action Icons -->
                    <div class="d-flex m-0">
                        <!-- <i *ngIf="profile?.citylok || profile?.lite" class="fal fa-camera icon-menu pointer-in fa-lg"
                            (click)="newLok()"></i> -->
                        <i *ngIf="profile && !isAdmin && !profile?.organizer"
                            class="fal fa-heart icon-menu pointer-in fa-lg" (click)="goToFavorites()"></i>
                    </div>
                    <div class="navbar-brand d-flex m-0 align-items-center">
                        <div *ngIf="profile" [matBadge]="numTicketsCart" matBadgeSize="small"
                            [matBadgeHidden]="!(numTicketsCart)" matBadgeOverlap="false" class="barge-cart">
                            <i *ngIf="profile" class="fal fa-cart-shopping icon-menu pointer-in fa-lg"
                                [routerLink]="['/cart-checkout']"></i>
                        </div>
                        <i class="fal fa-user-circle icon-menu pointer-in fa-lg" (click)="goProfile()"
                            *ngIf="!profile?.imagen; else divAvatar"></i>
                        <!-- Img usuario -->
                        <ng-template #divAvatar>
                            <div class="avatar-profile icon-menu pointer-in" (click)="goProfile()">
                                <img fallimg src="{{urlImages}}/{{profile?.imagen}}" alt="Avatar user" />
                            </div>
                        </ng-template>
                        <!-- <span #menuButton class="navbar-toggler" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i class="far fa-bars fa-lg icon-menu" (click)="openMenu()"></i>
                        </span> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="navbar-toggler">
            <div class="d-flex align-items-center">
                <div *ngIf="profile" [matBadge]="numTicketsCart" matBadgeSize="small"
                    [matBadgeHidden]="!(numTicketsCart)" matBadgeOverlap="false" class="barge-cart">
                    <i class="fal fa-cart-shopping icon-menu pointer-in fa-lg" [routerLink]="['/cart-checkout']"></i>
                </div>
                <i class="fal fa-user-circle icon-menu pointer-in fa-lg" (click)="goProfile()"
                    *ngIf="!profile?.imagen; else divAvatar"></i>
                <!-- Img usuario -->
                <ng-template #divAvatar>
                    <div class="avatar-profile icon-menu pointer-in">
                        <img fallimg src="{{urlImages}}/{{profile?.imagen}}" alt="Avatar user" />
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
    <div class="w-100 navbar-toggler buscador-grey">
        <div class="buscador-toggler container-form d-flex align-items-center container borderInput borderToggler">
            <input class="input-custom w-100" type="text" [placeholder]="placeholder" [(ngModel)]="searchText"
                (keyup.enter)="searchEvents(search.value)">
            <i class="fal fa-search search-icon search-icon-right pointer-in" (click)="searchEvents(search.value)"></i>
        </div>
    </div>
</nav>