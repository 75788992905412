import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  private placeObject = new BehaviorSubject<any>(null);
  public placeObservable = this.placeObject.asObservable();

  private mapAction = new BehaviorSubject<any>(null);
  public mapActionObservable = this.mapAction.asObservable();

  // Save Map
  private mapSaveAction = new BehaviorSubject<any>(null);
  public mapSaveObservable = this.mapSaveAction.asObservable();

  constructor(private http: HttpClient) { }

  saveMapAction(save: any) {
    this.mapSaveAction.next(save);
  }
  
  getPlaceSaved() {
    this.placeObject.getValue();
  }

  setActionMap(action: any) {
    this.mapAction.next(action);
  }

  getPlacesUser(business: any) {
    const url = `${AppSettings.API_URI}/all/places/user/${business}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getCompaniesUser() {
    const url = `${AppSettings.API_URI}/companies`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data['hydra:member']
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getPlacesCompany(idCompany: any) {
    const url = `${AppSettings.API_URI}/list/places/organizer/${idCompany}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getListFilterPlaces(idBusiness: number, page: number, searchText: any, sortFilter: any) {
    let url = `${AppSettings.API_URI}/list/places/organizer/${idBusiness}?page=${page}`;
    if (searchText) {
      url += `&search=${searchText}`;
    }
    if (sortFilter) {
      url += `&orderBy=${sortFilter.sortedBy}&order=${sortFilter.sort}`;
    }
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getPlaceById(idVenue: number) {
    const url = `${AppSettings.API_URI}/places/${idVenue}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        this.placeObject.next(data);
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getPurchasesVenues(business: number, page: number, venues: any, searchText: string, sortFilter: any) {
    let url = `${AppSettings.API_URI}/purchases/venue/${business}?page=${page}`;
    if (venues && venues?.length > 0) {
      venues?.forEach(element => {
        url += `&venues[]=${element}`;
      });
    }
    if (searchText) {
      url += `&search=${searchText}`;
    }
    if (sortFilter) {
      url += `&orderBy=${sortFilter.sortedBy}&order=${sortFilter.sort}`;
    }
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  updateVenue(venueId: number, payload: any) {
    const url = `${AppSettings.API_URI}/upload/venue/${venueId}`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  updateImage(venueId: number, payload: any) {
    const url = `${AppSettings.API_URI}/upload/image/venue/${venueId}`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getMainMap(idVenue: number) {
    const url = `${AppSettings.API_URI}/venue/map/${idVenue}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getTicketsCustom(idVenue: number) {
    const url = `${AppSettings.API_URI}/venue/tickets/${idVenue}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  createVenue(idBusiness: number, payload: any) {
    const url = `${AppSettings.API_URI}/create/venue/${idBusiness}`;
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}
